import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { MKMPagination } from '../../../@types/common';
import { ProductsByIdsResponse } from '../../../@types/response';
import { CompletedReview, ToReview } from '../../../@types/review';
import { getCompletedReviews, getToReviews } from '../../../api/backend/review';
import client from '../../../api/client';
import { GET_PRODUCTS_BY_PRODUCT_IDS_QUERY } from '../../../api/storefront/products';
import { ReviewContainer } from '../../../components/account/reviews';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import Loading from '../../../components/loading/Loading';
import Pagination from '../../../components/pagination/Pagination';
import Tab, { TabData } from '../../../components/tab/Tab';
import { useViewport } from '../../../helper/hooks';
import styles from './styles/Reviews.module.scss';

const Reviews = () => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const [tabs, setTabs] = useState<TabData[]>([
    { name: t('account_review_tab1') },
    { name: t('account_review_tab2') },
  ]);

  const [clickedTab, setClickedTab] = useState<number>(0);

  const [toReviews, setToReviews] = useState<ToReview['result']>([]);
  const [completedReviews, setCompletedReviews] = useState<CompletedReview['reviews']>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [pagination, setPagination] = useState<MKMPagination>();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1');

  const isPaginationExists =
    (toReviews.length > 0 && clickedTab === 0) || (completedReviews.length > 0 && clickedTab === 1);

  const handleChangePage = (page: number) => {
    setSearchParams(new URLSearchParams({ page: page.toString() }));
    window.location.reload();
  };

  const getProductImagesByIds = async (ids: string[]) => {
    const {
      data: { nodes },
    } = await client.query<ProductsByIdsResponse>({
      query: GET_PRODUCTS_BY_PRODUCT_IDS_QUERY,
      variables: { ids },
    });
    const images = nodes.map((node) => node?.featuredImage?.url ?? '');
    return images;
  };

  const getReviewsRequest = useCallback(
    async (page: number) => {
      if (clickedTab === 0) {
        const {
          data: { result, pagination },
        } = await getToReviews(page);

        const images = await getProductImagesByIds(result.map((review) => review.product.id));

        const preprocessedReviews = result.map((review, idx) => ({
          ...review,
          product: {
            id: review.product.id,
            mkmId: review.product.mkmId,
            title: review.product.title,
            image: images[idx],
          },
        }));

        setToReviews(preprocessedReviews);
        setPagination(pagination);
      } else {
        if (completedReviews.length < 1) {
          setIsLoading(true);
        }

        const {
          data: { reviews, pagination },
        } = await getCompletedReviews(page);

        const images = await getProductImagesByIds(reviews.map((review) => review.product.id));

        const preprocessedReviews = reviews.map((review, idx) => ({
          ...review,
          product: {
            ...review.product,
            image: images[idx],
          },
        }));

        setCompletedReviews(preprocessedReviews);
        setPagination(pagination);
      }

      setIsLoading(false);
    },
    [clickedTab],
  );

  useEffect(() => {
    getReviewsRequest(page);
  }, [clickedTab, page]);

  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.SHOW_ALL, title: t('account_review') }}
      pageTitle={t('account_review_page')}
      className="no-padding-bottom"
    >
      <div className={styles['div']}>
        <Tab data={tabs} clickedTab={clickedTab} setClickedTab={setClickedTab} align="LEFT" />
        <main className={styles['main']}>
          {isLoading ? (
            <Loading />
          ) : (
            <ReviewContainer
              reviews={clickedTab === 0 ? toReviews : completedReviews}
              isWritten={clickedTab === 1}
            />
          )}
          {isMobile && isPaginationExists && (
            <Pagination handleChangePage={handleChangePage} pagination={pagination} />
          )}
        </main>
      </div>
      {!isMobile && isPaginationExists && (
        <Pagination handleChangePage={handleChangePage} pagination={pagination} />
      )}
    </AccountLayout>
  );
};

export default Reviews;

import instance from './instance';

export async function auth(
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  shopifyAccessToken: string,
) {
  const response = await instance.post(
    '/auth/login',
    JSON.stringify({ email, firstName, lastName, phone, shopifyAccessToken }),
  );

  if (!response.data.isOk) {
    // TODO: redirect to login, remove shopify login information
    return;
  }

  localStorage.setItem('access-token-mkm', response.data.data.accessToken || '');
  localStorage.setItem('refresh-token-mkm', response.data.data.refreshToken || '');
}

export async function refresh() {
  const refreshToken = localStorage.getItem('refresh-token-mkm');

  const response = await instance.post('/auth/refresh', JSON.stringify({ refreshToken }));
  if (!response.data.isOk) {
    localStorage.removeItem('access-token-mkm');
    localStorage.removeItem('refresh-token-mkm');
    window.location.href = '/login';
    return;
  }

  localStorage.setItem('access-token-mkm', response.data.data.accessToken || '');

  return response.data.data.accessToken;
}

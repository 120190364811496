import {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  ReactElement,
  SetStateAction,
} from 'react';
import { useViewport } from '../../../helper/hooks';
import { FixedBottomButtonContainer } from '../../button';
import { Input } from '../../input';
import Label from '../../label/Label';
import { FormButton } from '../../login/atoms';
import styles from './CheckoutOverlays.module.scss';

type CardObjectKeyType = 'cardName' | 'cardNumber' | 'expirationDate' | 'securityCode';

export const INITIAL_CARD_INFO = {
  cardName: '',
  cardNumber: '',
  expirationDate: '',
  securityCode: '',
};

export type NewCardObjectType = {
  [key in CardObjectKeyType]: string;
};

const AddNewCard = ({
  currentCard,
  setCurrentCard,
}: {
  currentCard: NewCardObjectType;
  setCurrentCard: Dispatch<SetStateAction<NewCardObjectType>>;
}): ReactElement => {
  // TODO: credit card validation logic, design
  const { width, isMobile } = useViewport();
  const onChangeValue: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name } = event.target;
    let { value } = event.target;
    if (name === 'cardNumber' && value.length < 19) {
      value = value.replace(/\D/gi, '');
      if (value.length < 16) {
        value = value.replace(/(.{4})/g, '$1 ');
      }
    } else if (name === 'expirationDate' && value.length < 5) {
      value = value.replace(/\D/gi, '');
      if (value.length < 4) {
        value = value.replace(/(.{2})/g, '$1/');
      }
    }
    setCurrentCard((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
  };
  return (
    <main className={styles['add-new-card-main']}>
      <form onSubmit={onSubmit}>
        <Label label="Card Name">
          <Input
            placeholder="Card Name"
            name="cardName"
            value={currentCard.cardName}
            onChange={onChangeValue}
          />
        </Label>
        <Label label="Card Number">
          <div className={styles['card-number-container']}>
            <Input
              placeholder="1234 1234 1234 1234"
              name="cardNumber"
              value={currentCard.cardNumber}
              onChange={onChangeValue}
              minLength={19}
              maxLength={19}
            />
          </div>
        </Label>
        <Label label="Expiration date">
          <Input
            placeholder="MM/YY"
            name="expirationDate"
            value={currentCard.expirationDate}
            onChange={onChangeValue}
            minLength={5}
            maxLength={5}
          />
        </Label>
        <Label label="Security code">
          <Input
            placeholder="Security code"
            name="securityCode"
            value={currentCard.securityCode}
            onChange={onChangeValue}
          />
        </Label>
        {isMobile && (
          <FixedBottomButtonContainer>
            <FormButton contents="SAVE" />
          </FixedBottomButtonContainer>
        )}
      </form>
    </main>
  );
};

export default AddNewCard;

import { Options } from '@splidejs/react-splide';
import { ReactElement, ReactNode } from 'react';
import { useViewport } from '../../helper/hooks';
import SplideSlick from '../splide-slick/SplideSlick';

interface Props {
  rows: number;
  productElements: ReactNode;
}

const ProductContainerSlick = ({ rows, productElements }: Props): ReactElement => {
  const { width } = useViewport();

  const options: Options = {
    pagination: false,
    speed: 500,
    arrows: true,
    drag: false,
    grid: {
      rows,
      cols: width >= 1280 ? 6 : 5,
      gap: {
        row: 32,
        col: 24,
      },
    },
    breakpoints: {
      1023: {
        drag: 'free',
        snap: true,
        arrows: false,
        autoWidth: true,
        padding: {
          right: '24px',
        },
        grid: {
          rows,
          cols: 2,
          gap: {
            row: 16,
            col: 8,
          },
        },
      },
    },
  };

  return (
    <SplideSlick
      slickData={{
        elements: productElements,
        options,
      }}
    />
  );
};

export default ProductContainerSlick;

import { ChangeEventHandler, FormEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { postPartnership } from '../../../api/backend/partnership';
import { Input } from '../../../components/input';
import Label from '../../../components/label/Label';
import Loading from '../../../components/loading/Loading';
import { FormButton } from '../../../components/login/atoms';
import Textarea from '../../../components/textarea/Textarea';
import Upload from '../../../components/upload/Upload';
import { modalState } from '../../../stores/modal/atoms';
import styles from './styles/HelpPartnership.module.scss';

type PartnershipForm = {
  company: string;
  email: string;
  inquiry: string;
};

const HelpPartnership = () => {
  const { t } = useTranslation();
  const go = useNavigate();
  const initialForm = {
    company: '',
    email: '',
    inquiry: '',
  };

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [files, setFiles] = useState<File[]>([]);
  const [form, setForm] = useState<PartnershipForm>(initialForm);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modal, setModal] = useRecoilState(modalState);
  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setButtonDisabled(true);

    const formData = new FormData();

    formData.append('company', form.company);
    formData.append('from', form.email);
    formData.append('content', form.inquiry);

    files.forEach((file) => formData.append('files', file));

    const res = await postPartnership(formData);
    const completedAction = () => go('/');

    setIsLoading(false);

    if (res.data.isOk) {
      setModal({
        modal: true,
        childrenText: t('account_help_partnership_modal'),
        buttons: [
          {
            text: t('account_help_partnership_modal_button'),
            action: completedAction,
          },
        ],
        closeButtonAction: completedAction,
      });

      return;
    }

    setModal({
      modal: true,
      childrenText: t('account_help_partnership_modal_error'),
      buttons: [
        {
          text: t('account_help_partnership_modal_error_button'),
        },
      ],
    });
  };

  useEffect(() => {
    const { company, email, inquiry } = form;
    const emailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    setButtonDisabled(!company || !email.match(emailRegex) || !inquiry);
  }, [form]);

  return (
    <>
      <form className={styles['form']} onSubmit={handleSubmit}>
        <div className={styles['container']}>
          <Label label={t('account_help_partnership_company')}>
            <Input
              name="company"
              type="text"
              placeholder={t('account_help_partnership_company_placeholder')}
              defaultValue={form.company}
              onChange={handleChange}
            />
          </Label>

          <Label label={t('account_help_partnership_email')}>
            <Input
              name="email"
              type="email"
              placeholder={t('account_help_partnership_email_placeholder')}
              defaultValue={form.email}
              onChange={handleChange}
            />
          </Label>

          <Label label={t('account_help_partnership_detail')}>
            <Textarea
              name="inquiry"
              placeholder={t('account_help_partnership_detail_placeholder')}
              defaultValue={form.inquiry}
              onChange={handleChange}
              style={{ marginTop: '15px' }}
            />
          </Label>

          <div className={styles['request__upload']}>
            <Upload files={files} setFiles={setFiles} isImage={false} />
          </div>
        </div>

        <div className={styles['form__button']}>
          <FormButton contents={t('account_help_partnership_button')} disabled={buttonDisabled} />
        </div>

        {isLoading && (
          <div className={styles['loading-container']}>
            <Loading />
          </div>
        )}
      </form>
    </>
  );
};

export default HelpPartnership;

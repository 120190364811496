import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import ToggleSwitch from '../../toggle-switch/ToggleSwitch';
import styles from './NotificationsContainer.module.scss';

type NotificationsContainerProps = {
  title: string;
  description: string;
  checkList: string[];
  toggle?: boolean;
  setToggle?: Dispatch<SetStateAction<boolean>>;
  handleToggle?: () => void;
};

const NotificationsContainer = ({
  title,
  description,
  checkList,
  toggle,
  setToggle,
  handleToggle,
}: NotificationsContainerProps): ReactElement => {
  return (
    <div className={styles['container']}>
      <h2 className={styles['title']}>{title}</h2>
      <p className={styles['description']}>{description}</p>

      {checkList?.map((check) => (
        <div className={styles['check__item']} key={`${title}-${check}`}>
          <div className={styles['check__text']}>{check}</div>
          <ToggleSwitch
            active={toggle!}
            handleToggle={() => {
              setToggle!(!toggle);
              handleToggle ? handleToggle() : () => {};
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default NotificationsContainer;

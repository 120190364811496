import { useState, useEffect } from 'react';
import { ProductImage } from '../../../@types/product';
import { useResponsiveElement } from '../../../helper/hooks';
import Slick from '../../slick/Slick';
import styles from './styles/ProductThumbnail.module.scss';

type ProductThumbnailType = {
  images: {
    mobileImages: ProductImage[];
    desktopImages: ProductImage[];
  };
  isSoldout?: boolean;
};

const ProductThumbnailMobile = ({
  images,
  isSoldout,
}: {
  images: ProductImage[];
  isSoldout: boolean;
}) => {
  const makeThumbnailImages = (images: ProductImage[]) =>
    images?.map((data, idx) => (
      <article key={`${data.toString()}--${idx}`}>
        <img src={data.url} alt="배너" />
      </article>
    ));

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    useTransform: false, // prevent last slide blinking
  };

  return (
    <section className={styles['section']}>
      <div className={styles['slick__container']}>
        <div
          className={`${
            isSoldout ? styles['section__overlay--soldout'] : styles['section__overlay']
          }`}
        >
          <div>Sold Out</div>
        </div>
        <Slick
          slickData={{
            elements: makeThumbnailImages(images),
            options: { slickSettings },
          }}
        />
      </div>
    </section>
  );
};

const ProductThumbnailDesktop = ({
  images,
  isSoldout,
}: {
  images: ProductImage[];
  isSoldout: boolean;
}) => {
  const [selected, setSelected] = useState<string>(images[0].url);

  useEffect(() => {
    setSelected(images[0].url);
  }, [images]);

  return (
    <section className={styles['section']}>
      <div className={styles['thumnail__images']}>
        {images.slice(0, 5).map(({ url }) => (
          <div className={styles['thumnail__image']} onClick={() => setSelected(url)} key={url}>
            <img src={url} alt="제품 사진" />
          </div>
        ))}
        {new Array((images.length > 0 && images.length < 5 ? 5 - images.length : 0)).fill(0).map((_, i) => (
          <div className={styles['thumnail__image__default']} key={i + 'thumbnail'}>
            <img src={`/images/desktop/default_thumbnail.png`} alt="제품 사진" />
          </div>
        ))}
      </div>
      <div className={styles['thumnail__image--selected']}>
        <div
          className={`${
            isSoldout ? styles['section__overlay--soldout'] : styles['section__overlay']
          }`}
        >
          <div>Sold Out</div>
        </div>
        <img src={selected} alt="제품 사진" />
      </div>
    </section>
  );
};

const ProductThumbnail = ({ images, isSoldout = false }: ProductThumbnailType) => {
  const element = useResponsiveElement({
    MOBILE: <ProductThumbnailMobile images={images.mobileImages} isSoldout={isSoldout} />,
    DESKTOP: <ProductThumbnailDesktop images={images.desktopImages} isSoldout={isSoldout} />,
  });

  return <>{element}</>;
};

export default ProductThumbnail;

import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles/Logo.module.scss';

const Logo = ({ imgUrl, customStyle = {} }: { imgUrl: string; customStyle?: CSSProperties }) => {
  return (
    <Link to="/" className={styles['link']} reloadDocument={true}>
      <img src={imgUrl} alt="로고" style={customStyle} />
    </Link>
  );
};

export default Logo;

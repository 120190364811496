import { gql } from '@apollo/client';
import { CustomerAddress } from '../../@types/response';
import client from '../client';

export async function getAddresses() {
  const customerAccessToken = localStorage.getItem('access-token') || '';

  const query = gql`
    query getCustomerAddresses($customerAccessToken: String!) {
      customer(customerAccessToken: $customerAccessToken) {
        email
        defaultAddress {
          id
        }
        addresses(first: 10) {
          edges {
            node {
              id
              name
              firstName
              lastName
              address1
              address2
              country
              province
              phone
              city
              zip
            }
          }
        }
      }
    }
  `;

  const response = await client.query<CustomerAddress>({
    query,
    variables: {
      customerAccessToken,
    },
  });

  const addresses = response.data.customer.addresses.edges.map(({ node }) => node);

  return { response, addresses };
}

/**
 * create a new address
 * @param lastName
 * @param firstName
 * @param address1
 * @param address2
 * @param province
 * @param country
 * @param zip
 * @param city
 * @returns
 */
export async function customerAddressCreate(
  province: string,
  country: string,
  input: {
    lastName: string;
    firstName: string;
    address1: string;
    address2: string;
    phone: string;
    zip: string;
    city: string;
  },
) {
  const customerAccessToken = localStorage.getItem('access-token') || '';
  const { lastName, firstName, address1, address2, zip, city, phone } = input;

  const query = gql`
    mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
      customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
        customerUserErrors {
          code
          field
          message
        }
        customerAddress {
          id
        }
      }
    }
  `;

  return await client.mutate({
    mutation: query,
    variables: {
      customerAccessToken,
      address: {
        lastName,
        firstName,
        address1,
        address2,
        province,
        country,
        zip,
        city,
        phone,
      },
    },
  });
}

export async function customerAddressUpdate(
  id: string,
  province: string,
  country: string,
  input: {
    lastName: string;
    firstName: string;
    address1: string;
    address2: string;
    phone: string;
    zip: string;
    city: string;
  },
) {
  const { lastName, firstName, address1, address2, phone, zip, city } = input;
  const customerAccessToken = localStorage.getItem('access-token');

  const query = gql`
    mutation customerAddressUpdate(
      $address: MailingAddressInput!
      $customerAccessToken: String!
      $id: ID!
    ) {
      customerAddressUpdate(address: $address, customerAccessToken: $customerAccessToken, id: $id) {
        customerUserErrors {
          code
          field
          message
        }
        customerAddress {
          id
        }
      }
    }
  `;

  const response = await client.mutate({
    mutation: query,
    variables: {
      customerAccessToken,
      id,
      address: { province, country, lastName, firstName, address1, address2, phone, zip, city },
    },
  });

  return response;
}

export async function customerDefaultAddressUpdate(id: string) {
  const customerAccessToken = localStorage.getItem('access-token');

  const query = gql`
    mutation customerDefaultAddressUpdate($addressId: ID!, $customerAccessToken: String!) {
      customerDefaultAddressUpdate(
        addressId: $addressId
        customerAccessToken: $customerAccessToken
      ) {
        customerUserErrors {
          code
          field
          message
        }
        customer {
          id
        }
      }
    }
  `;

  const response = await client.mutate({
    mutation: query,
    variables: { addressId: id, customerAccessToken },
  });

  return response;
}

export async function customerAddressDelete(id: string) {
  const customerAccessToken = localStorage.getItem('access-token');

  const query = gql`
    mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
      customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
        customerUserErrors {
          code
          field
          message
        }
        deletedCustomerAddressId
      }
    }
  `;

  const response = await client.mutate({
    mutation: query,
    variables: {
      customerAccessToken,
      id,
    },
  });

  return response;
}

import { gql } from '@apollo/client';
import { ProductByCurationResponse, ProductByIdResponse } from '../../@types/response';
import {
  CURATION_MODULE_PRODUCT_MAIN_CATEGORY_TYPE,
  CURATION_MODULE_PRODUCT_SUB_CATEGORY_TYPE,
} from '../../constants/category';
import client from '../client';

export async function getProductsTitleByKeyword(keyword: string) {
  const query = gql`
    query getProductsTitleByKeyword($first: Int!, $query: String) {
      products(first: $first, query: $query) {
        edges {
          cursor
          node {
            id
            title
          }
        }
      }
    }
  `;

  const response = await client.query<ProductByCurationResponse>({
    query,
    variables: { first: 10, query: getFilterQuery({ value1: keyword })['Keyword'] },
  });

  return response.data.products.edges.map(({ node }) => node);
}

export async function getProductById(id?: string) {
  const query = gql`
    query getProductById($id: ID!) {
      node(id: $id) {
        id
        ... on Product {
          id
          title
          description
          descriptionHtml
          tags
          createdAt
          updatedAt
          publishedAt
          handle
          vendor
          totalInventory
          availableForSale
          productType
          metafield(namespace: "custom", key: "review_rating") {
            value
          }
          variants(first: 5) {
            edges {
              node {
                id
              }
            }
          }
          featuredImage {
            url
            altText
          }
          priceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          compareAtPriceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          images(first: 10) {
            edges {
              node {
                url
                altText
              }
            }
          }

          collections(first: 10) {
            edges {
              node {
                title
                products(first: 10) {
                  edges {
                    node {
                      id
                      title
                      totalInventory
                      featuredImage {
                        url
                        altText
                      }
                      priceRange {
                        maxVariantPrice {
                          amount
                        }
                        minVariantPrice {
                          amount
                        }
                      }
                      compareAtPriceRange {
                        maxVariantPrice {
                          amount
                        }
                        minVariantPrice {
                          amount
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const response = await client.query<ProductByIdResponse>({ query, variables: { id } });
  const product = response.data.node;

  return { response, product };
}

export const SORT_KEY_MAP = {
  BEST_SELLING: 'BEST_SELLING',
  NEWEST: 'CREATED_AT',
  PRICE_LOW_TO_HIGH: 'PRICE',
  PRICE_HIGH_TO_LOW: 'PRICE',
};

export const GET_PRODUCTS_BY_CURATION_QUERY = gql`
  query getProductByCuration(
    $first: Int!
    $sortKey: ProductSortKeys!
    $reverse: Boolean!
    $query: String
    $after: String
  ) {
    products(first: $first, sortKey: $sortKey, reverse: $reverse, query: $query, after: $after) {
      edges {
        cursor
        node {
          id
          title
          description
          tags
          createdAt
          updatedAt
          publishedAt
          handle
          availableForSale
          metafield(namespace: "custom", key: "review_rating") {
            value
          }
          variants(first: 5) {
            edges {
              node {
                id
                sku
              }
            }
          }
          featuredImage {
            url
            altText
          }
          priceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          compareAtPriceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          totalInventory
          vendor
          productType

          collections(first: 10) {
            edges {
              node {
                title
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_PRODUCTS_BY_PRODUCT_IDS_QUERY = gql`
  query getProductByProductIds($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Product {
        id
        title
        description
        tags
        createdAt
        updatedAt
        publishedAt
        handle
        availableForSale
        metafield(namespace: "custom", key: "review_rating") {
          value
        }
        featuredImage {
          url
          altText
        }
        priceRange {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        compareAtPriceRange {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        totalInventory
        vendor
        productType
        variants(first: 1) {
          edges {
            node {
              id
            }
          }
        }

        collections(first: 10) {
          edges {
            node {
              title
            }
          }
        }
      }
    }
  }
`;

export const getFilterQuery = ({ value1, value2 }: { value1: string; value2?: string }) => {
  const mainCategories = Object.keys(CURATION_MODULE_PRODUCT_MAIN_CATEGORY_TYPE);
  if (mainCategories.includes(value1) && value2 === 'default') {
    const subCategories = Object.keys(
      CURATION_MODULE_PRODUCT_SUB_CATEGORY_TYPE[
        value1 as keyof typeof CURATION_MODULE_PRODUCT_MAIN_CATEGORY_TYPE
      ],
    ).filter((sub) => sub !== 'default');
    value2 = subCategories.join(' OR product_type:');
  }

  return {
    Category: `product_type:${value2 || value1}`,
    Brand: `vendor:${value1
      .split(',')
      .map((v) => v.trim())
      .join(' OR vendor:')}`,
    Keyword: `title:${value1}`,
    Period: `(created_at:>=${value1} AND created_at:<=${value2})`,
    Price: `(variants.price:>=${value1} AND variants.price:<=${value2})`,
    Tag: `tag:${value1
      .split(',')
      .map((v) => v.trim())
      .join(' OR tag:')}`,
    Ids: value1
      .split('\n')
      .filter((pid) => pid.length > 0)
      .map((pid) => `gid://shopify/Product/${pid.trim()}`),
  };
};

import { ReactElement, useCallback, useEffect, useState } from 'react';
import { INITIAL_IS_OPEN_STATUS_OBJECT } from '../../../pages/checkout/Checkout';
import { AddressBookAdd, AddressBookList } from '../../account/address-book';
import { BaseLayout } from '../../layout';
import { MobileNavItem } from '../../layout/BaseLayout';
import { CouponApply, AddNewCard, PaymentMethod, PurchaseItems } from '../overlays';
import { INITIAL_CARD_INFO, NewCardObjectType } from '../overlays/AddNewCard';
import styles from './useCheckoutOverlays.module.scss';
import {
  OpenHandler,
  ShippingInfoState,
  PaymentMethodState,
  CouponDataState,
  SelectedCouponState,
  ClickedCouponState,
} from '../../../@types/checkout';
import { useTranslation } from 'react-i18next';

type useCheckoutOverlayType = OpenHandler &
  ShippingInfoState &
  PaymentMethodState &
  CouponDataState &
  SelectedCouponState &
  ClickedCouponState;

const useCheckoutOverlay = ({
  isOpenStatusObject,
  setIsOpenStatusObject,
  selectedShippingInfo,
  setSelectedShippingInfo,
  coupon,
  setCoupon,
  selectedCoupon,
  setSelectedCoupon,
  clickedCouponType,
  setClickedCouponType,
  paymentMethod,
  setPaymentMethod,
  shippingInfoArray,
  handleChangeShippingInfo,
  openAddressForm,
  addressType,
  setAddressType,
}: useCheckoutOverlayType): ReactElement => {
  const { t } = useTranslation();

  const checkoutOverlayNavTitleMap = {
    deliveryInfo: t('checkout_overlay_delivery'),
    deliveryInfoAdd: t('checkout_overlay_delivery_add'),
    paymentMethod: t('checkout_overlay_payment'),
    addNewCard: t('checkout_overlay_card'),
    couponApply: t('checkout_overlay_coupon'),
    purchaseSummary: t('checkout_overlay_purchase'),
  } as const;

  const navTitle = () => {
    const currentOpenOverlay = Object.entries(isOpenStatusObject).filter(([k, v]) => v)[0][0];
    return checkoutOverlayNavTitleMap[
      currentOpenOverlay as keyof typeof checkoutOverlayNavTitleMap
    ];
  };

  const onBackAction = useCallback(() => {
    if (isOpenStatusObject.deliveryInfo && (addressType === 'EDIT' || addressType === 'ADD')) {
      setAddressType('NONE');
      return;
    }
    if (isOpenStatusObject.addNewCard) {
      setIsOpenStatusObject((prev) => ({
        ...prev,
        addNewCard: false,
        paymentMethod: true,
      }));
    } else {
      setIsOpenStatusObject(INITIAL_IS_OPEN_STATUS_OBJECT);
    }
  }, [isOpenStatusObject, addressType]);

  const [currentCard, setCurrentCard] = useState<NewCardObjectType>(INITIAL_CARD_INFO);

  useEffect(() => {
    if (shippingInfoArray.length < 1) {
      setAddressType('ADD');
      return;
    }

    setAddressType('NONE');
  }, [shippingInfoArray]);

  const adjustPadding = () => {
    switch (true) {
      case isOpenStatusObject.deliveryInfo &&
        (isOpenStatusObject.deliveryInfoAdd || addressType !== 'NONE'):
      case isOpenStatusObject.purchaseSummary:
        return 'footer-add-top-remove-bottom-padding';
      case isOpenStatusObject.couponApply:
        return 'footer-add-top-padding';
      default:
        return '';
    }
  };

  return (
    <>
      {Object.values(isOpenStatusObject).filter((b) => b).length > 0 && (
        <div className={`${styles['overlay-container']} ${styles[adjustPadding()]}`}>
          <BaseLayout
            mobileNav={{
              type: MobileNavItem.HOME_SEARCH,
              title: navTitle(),
            }}
            customActions={{
              onBack: onBackAction,
            }}
          >
            {isOpenStatusObject.deliveryInfo && addressType === 'NONE' && (
              <AddressBookList
                shippingInfoArray={shippingInfoArray}
                selectedShippingInfo={selectedShippingInfo}
                setSelectedShippingInfo={setSelectedShippingInfo}
                openAddressForm={openAddressForm}
                setIsOpenStatusObject={setIsOpenStatusObject}
                onBack={onBackAction}
                type="Checkout Mobile"
              />
            )}
            {isOpenStatusObject.deliveryInfo &&
              (isOpenStatusObject.deliveryInfoAdd || addressType !== 'NONE') && (
                <AddressBookAdd
                  addressType={addressType}
                  shippingInfoArray={shippingInfoArray}
                  selectedShippingInfo={selectedShippingInfo}
                  setSelectedShippingInfo={setSelectedShippingInfo}
                  handleChangeShippingInfo={handleChangeShippingInfo}
                  setIsOpenStatusObject={setIsOpenStatusObject}
                  openAddressForm={openAddressForm}
                />
              )}
            {isOpenStatusObject.paymentMethod && !isOpenStatusObject.addNewCard && (
              <PaymentMethod
                setIsOpenStatusObject={setIsOpenStatusObject}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
              />
            )}
            {isOpenStatusObject.addNewCard && (
              <AddNewCard currentCard={currentCard} setCurrentCard={setCurrentCard} />
            )}
            {isOpenStatusObject.couponApply && (
              <CouponApply
                coupon={coupon}
                setCoupon={setCoupon}
                selectedCoupon={selectedCoupon}
                setSelectedCoupon={setSelectedCoupon}
                setIsOpenStatusObject={setIsOpenStatusObject}
                clickedCouponType={clickedCouponType}
                setClickedCouponType={setClickedCouponType}
              />
            )}
            {isOpenStatusObject.purchaseSummary && <PurchaseItems />}
          </BaseLayout>
        </div>
      )}
    </>
  );
};

export default useCheckoutOverlay;

type CategoryPathProps = {
  category?: string | null;
  subCategory?: string;
};

const CategoryPath = ({ category, subCategory }: CategoryPathProps) => {
  const hasCategoryTitle = !!subCategory;
  const categoryPath = hasCategoryTitle
    ? `${category} > ${subCategory}`
    : `${category}`;
  return <>{categoryPath}</>;
};

export default CategoryPath;

import { Options } from '@splidejs/react-splide';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { MKMPagination } from '../../../@types/common';
import { Order } from '../../../@types/customer';
import { getOrders } from '../../../api/backend/order';
import { OrderListContainer } from '../../../components/account/orders';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import Loading from '../../../components/loading/Loading';
import Pagination from '../../../components/pagination/Pagination';
import SplideSlick from '../../../components/splide-slick/SplideSlick';
import { DesktopTabItem, MobileTabItem } from '../../../components/tab/Tab';
import { useViewport } from '../../../helper/hooks';
import { queryKeys } from '../../../react-query/queryKeys';
import styles from './styles/MyOrders.module.scss';

const MyOrders = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<Order[]>([]);

  const { isMobile } = useViewport();

  const tabs = [
    { name: t('account_order_tab') },
    { name: t('account_order_tab_pending'), img: '/icons/order__clock.svg' },
    { name: t('account_order_tab_shipping'), img: '/icons/order__flight.svg' },
    { name: t('account_order_tab_shipped'), img: '/icons/order__box.svg' },
    { name: t('account_order_tab_returns'), img: '/icons/order__package.svg' },
  ];

  const tabTypes = [null, 'pending', 'shipping', 'shipped', 'cancelled'] as const;

  const [clickedTab, setClickedTab] = useState<number>(0);

  const [pagination, setPagination] = useState<MKMPagination>();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1');

  const isPaginationExists = orders && orders.length > 0;

  const handleChangePage = (page: number) => {
    if (tabTypes[clickedTab]) {
      setSearchParams(
        new URLSearchParams({ type: tabTypes[clickedTab] as string, page: page.toString() }),
      );
    } else {
      setSearchParams(new URLSearchParams({ page: page.toString() }));
    }
  };

  const mobileTabElement = tabs.map(({ name }, idx) => (
    <MobileTabItem
      key={`${name}-${idx}`}
      data={name}
      isSelected={idx === clickedTab}
      handleClick={() => setClickedTab(idx)}
    />
  ));

  const desktopTabElement = (
    <div className={styles['my-orders__desktop-tab']}>
      {tabs.map(({ name, img }, idx) => (
        <DesktopTabItem
          key={`${name}-${idx}`}
          data={name}
          isSelected={idx === clickedTab}
          handleClick={() => setClickedTab(idx)}
          img={img}
        />
      ))}
    </div>
  );

  const slickOptions: Options = {
    arrows: false,
    pagination: false,
    autoWidth: true,
    drag: false,
    snap: true,
    bounce: false,
    gap: 16,
    padding: {
      left: 16,
      right: 16,
    },
  };

  const { isLoading, data } = useQuery([queryKeys.GetOrders, clickedTab, page], () =>
    getOrders({
      type: tabTypes[clickedTab],
      page,
    }),
  );

  useEffect(() => {
    if (isLoading || !data) {
      return;
    }
    const { orders, pagination } = data;

    setPagination(pagination);
    setOrders(orders);
  }, [isLoading, data]);

  useEffect(() => {
    if (tabTypes[clickedTab]) {
      setSearchParams(new URLSearchParams({ type: tabTypes[clickedTab] as string, page: '1' }));
    } else {
      setSearchParams(new URLSearchParams({ page: '1' }));
    }
  }, [clickedTab]);

  useEffect(() => {
    const getType = searchParams.get('type');
    const getPage = searchParams.get('page');
    if (getType) setClickedTab((tabTypes as readonly string[]).indexOf(getType!));
    setSearchParams(new URLSearchParams({ type: getType as string, page: getPage as string }));
  }, []);

  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.HOME_SEARCH, title: t('account_order_title') }}
      pageTitle={t('account_order_page_title')}
      className="no-padding-bottom"
    >
      <div className={styles['container']}>
        {isMobile ? (
          <SplideSlick
            slickData={{
              elements: mobileTabElement,
              options: slickOptions,
            }}
          />
        ) : (
          desktopTabElement
        )}
        {isLoading ? (
          <Loading />
        ) : (
          <main className={styles['main']}>
            <OrderListContainer data={orders} />
            {isMobile && isPaginationExists && (
              <div className={styles['pagination-margin']}>
                <Pagination handleChangePage={handleChangePage} pagination={pagination} />
              </div>
            )}
          </main>
        )}
        {!isMobile && isPaginationExists && (
          <div className={styles['pagination-margin']}>
            <Pagination handleChangePage={handleChangePage} pagination={pagination} />
          </div>
        )}
      </div>
    </AccountLayout>
  );
};

export default MyOrders;

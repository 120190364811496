import { ChangeEvent, FormEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { customerRecover } from '../../api/storefront/customer';
import { CHATBOT_HASH } from '../../constants/chatbot';
import Label from '../label/Label';
import { FormButton, Input } from '../login/atoms';
import styles from './PasswordContainer.module.scss';
import { modalState } from '../../stores/modal/atoms';
import { useRecoilState } from 'recoil';

const PasswordAssistContainer = () => {
  const go = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [modal, setModal] = useRecoilState(modalState);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setEmail(value);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    // TODO: Request change password

    const { data } = await customerRecover(email);

    if (!data) {
      return;
    }

    if (!data.customerRecover) {
      setModal({
        modal: true,
        childrenText: t('password_modal_error_null'),
        buttons: [
          {
            text: t('password_modal_button'),
          },
        ],
      });
      return;
    }
    if (data.customerRecover.customerUserErrors.length > 0) {
      // TODO: Handle Error
      setModal({
        modal: true,
        childrenText: t('password_modal_error', {
          message: data.customerRecover.customerUserErrors[0].message,
        }),
        buttons: [
          {
            text: t('password_modal_button'),
          },
        ],
      });
      return;
    } else {
      setModal({
        modal: true,
        childrenText: t('password_modal'),
        buttons: [
          {
            text: t('password_modal_button'),
            action: ()=> {
              go('/login')
            }
          },
        ],
      });
    }
  };

  const onClickCustomerService = () => {
    window.location.hash = CHATBOT_HASH;
  };

  return (
    <div className={styles['password-container']}>
      <h2 className={styles['password-title']}>{t('password_title')}</h2>
      <p className={styles['password-subdescription']}>{t('password_subdescription')}</p>
      <form onSubmit={handleSubmit}>
        <Label label={t('password_form_label')}>
          <Input
            name="email"
            type="text"
            placeholder={t('password_form_placeholder')}
            defaultValue={email}
            onChange={handleChange}
          />
        </Label>
        <FormButton contents={t('password_form_button')} />
      </form>

      <p className={styles['password-description']}></p>
      {t('password_description')}
      <p className={styles['password-subdescription']}>
        <Trans
          i18nKey={t('password_description_subdescription')}
          components={{
            b: <b className={styles['customer-service']} onClick={onClickCustomerService} />,
          }}
        ></Trans>
      </p>
    </div>
  );
};

export default PasswordAssistContainer;

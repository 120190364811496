import { ReactNode, useState } from 'react';
import { useViewport } from '../../helper/hooks';
import styles from './CheckoutItem.module.scss';

interface Props {
  element?: ReactNode;
  title?: string;
  mainText?: string;
  subText?: string;
  placeholder?: string;
  bgColor?: 'GREY';
  className?: string;
  accordionElement?: ReactNode;
  accordionArrow?: boolean;
  options?: {
    chevronShow: boolean;
    content?: string;
  };
  onClickOuter?: () => void;
  onClickInner?: () => void;
}

const CheckoutItem = ({
  element,
  title,
  mainText,
  subText,
  placeholder,
  bgColor,
  className,
  accordionElement,
  accordionArrow,
  options,
  onClickOuter,
  onClickInner,
}: Props) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);
  const { width, isMobile } = useViewport();

  return (
    <section className={`${styles['section']}`} onClick={onClickOuter}>
      {title && <h2 className={styles['title']}>{title}</h2>}
      <article
        className={`${bgColor ? styles[bgColor] : ''} ${className ? className : ''} ${
          isAccordionOpen && styles['accordion--opened']
        }`}
        onClick={() => {
          onClickInner && onClickInner();
          setIsAccordionOpen((prev) => !prev);
        }}
      >
        {element ? (
          element
        ) : (
          <>
            <div className={styles['content']}>
              {placeholder && <p>{placeholder}</p>}
              {mainText && <b>{mainText}</b>}
              {subText && <span>{subText}</span>}
            </div>
            {options?.chevronShow && !accordionElement ? (
              <img src={'/icons/checkout__chevron-right.svg'} alt="오른쪽 화살표" />
            ) : (
              <b>{options?.content}</b>
            )}
          </>
        )}

        {!isMobile && accordionArrow && (
          <img
            className={styles['arrow']}
            src="/icons/checkout__chevron-down.svg"
            alt="arrow down"
          />
        )}
      </article>
      {isAccordionOpen && accordionElement}
    </section>
  );
};

export default CheckoutItem;

const ko = {
  search_bar_basic__placeholder: '찾고 싶은 상품을 검색해보세요!',
  search_bar_search_submit: '검색',
  search_bar_search_hitory: '검색 기록',
  search_bar_search_hitory_delete: '모두 지우기',
  search_bar_search_popular: '인기 검색어',
  search_bar_search_popular_empty: '인기 검색어가 없습니다.',
  login_error_box: `이메일 또는 비밀번호가 올바르지 않습니다. <br/> <b>다시 시도해주세요.</b>`,
  login_error_box_bold: '다시 시도해주세요.',
  login_title: '로그인',
  login_register_radio_title: '회원가입',
  login_register_radio_subtitle: 'myKmarket에 처음오셨나요?',
  login_signin_radio_title: '로그인',
  login_signin_radio_subtitle: '이미 계정이 있으신가요?',
  login_signin_form_email: '이메일',
  login_signin_form_email_placeholder: '이메일을 입력해주세요.',
  login_signin_form_password: '비밀번호',
  login_signin_form_password_placeholder: '6자리 이상 입력해주세요.',
  login_form_button: '계속',
  login_policy:
    '계속하시면 myKmarket의 <link2>개인 정보 정책</link2> 과 <link1>서비스 약관</link1>에 동의합니다',
  login_forgot_password: '비밀번호를 잊으셨나요?',
  password_title: '비밀번호 찾기',
  password_subdescription: 'myKmarket 계정과 연결된 이메일을 입력해주세요.',
  password_form_label: '이메일',
  password_form_placeholder: '이메일을 입력해주세요.',
  password_form_button: '계속',
  password_description: '이메일 주소가 변경되었나요?',
  password_description_subdescription:
    'myKmarket 계정과 연결된 이메일 주소를 더 이상 사용하지 않는 경우 <b>고객 센터</b>에 문의하여 계정에 대한 액세스 복원에 도움을 받을 수 있습니다.',
  password_modal: `입력하신 이메일로 비밀번호 재설정 안내 메일이 전송되었습니다.`,
  password_modal_button: '확인',
  password_modal_error: `{{message}}`,
  password_modal_error_null: `메일을 다시 확인해주세요`,
  register_title: '간편 회원가입',
  register_description: '아래 계정 세부 정보를 사용하여 회원가입 하세요.',
  register_form_email: '이메일',
  register_form_email_placeholder: '이메일을 입력해주세요.',
  register_form_first_name: '이름',
  register_form_first_name_placeholder: '이름을 입력해주세요.',
  register_form_last_name: '성',
  register_form_last_name_placeholder: '성을 입력해주세요.',
  register_form_phone: '휴대폰 번호',
  register_form_phone_placeholder: '-없이 입력해주세요.',
  register_form_password: '비밀번호',
  register_form_password_placeholder: '6자리 이상 입력해주세요.',
  register_form_password_confirm: '비밀번호 재입력',
  register_form_password_confirm_placeholder: '비밀번호를 다시 입력해주세요',
  register_form_button: '가입완료',
  register_descrition: '계속하시면 myKmarket의 개인 정보 정책 과 서비스 약관에 동의합니다.',
  register_link_help: '도움이 필요하세요?',
  register_link_return: '스토어로 돌아가기',
  register_invite: `{{inviterName}}의 초대`,
  register_modal: '회원가입이 완료되었습니다.',
  register_modal_error: '계정 생성에 오류가 있습니다. (알 수 없는 오류)',
  register_modal_error_password: '비밀번호가 일치하지 않습니다.',
  register_modal_error_empty: '올바른 {{key}} 입력해 주세요.',
  register_modal_error_name_empty: '이름을 입력해 주세요.',
  register_modal_error_phone: '올바른 휴대폰 번호를 입력해주세요 .',
  register_modal_error_phone_empty: '올바른 휴대폰 번호를 입력해주세요 .',
  register_modal_error_phone_duplicated: '이미 가입되어 있는 휴대폰입니다.',
  register_modal_error_email: '올바른 이메일을 입력해주세요.',
  register_modal_error_email_duplicated: '이미 가입되어 있는 이메일입니다.',
  register_modal_error_invite: '초대 코드가 올바르지 않습니다.',
  account_title: '마이페이지',
  account_user_info_point: '나의 포인트',
  account_user_info_refer: '친구 초대하고, ',
  account_user_info_refer_span: '$10 리워드 받으세요!',
  account_user_info_my_orders: '주문목록',
  account_user_info_my_orders_pending: '주문대기',
  account_user_info_my_orders_shipping: '배송중',
  account_user_info_my_orders_shipped: '배송완료',
  account_user_info_my_orders_return: '취소/반품',
  account_user_info_wish: '찜한상품',
  account_user_info_reward: '나의 포인트',
  account_user_info_coupon: '쿠폰',
  account_user_info_review: '리뷰관리',
  account_user_info_help: '고객센터',
  account_user_info_help_sub: 'Q&A, 공지사항 등',
  account_user_info_setting: '설정',
  account_user_info_setting_sub: '알림, 푸시설정 등',
  account_user_info_language: '언어',
  account_user_info_language_sub: '영어, 한국어',
  account_user_info_info: '정보',
  account_user_info_policy: '정책',
  account_logout: '로그아웃',
  account_aside_point: '나의 포인트',
  account_aside_refer: '친구 초대하고,<br/>',
  account_aside_refer_span: '$10 리워드 받으세요!',
  account_aside_my_orders: '주문목록',
  account_aside_wish: '찜한상품',
  account_aside_coupon: '쿠폰',
  account_aside_review: '리뷰관리',
  account_aside_contact: '문의하기',
  account_aside_help: '고객센터',
  account_aside_setting: '설정',
  account_aside_language: '언어',
  account_aside_info: '정보',
  account_aside_policy: '정책',
  account_aside_logout: '로그아웃',
  account_aside_login: '로그인',
  account_profile_title: '회원 정보',
  account_profile_page_title: '회원 정보',
  account_profile_edit_title: '프로필 수정',
  account_profile_edit_page_title: '프로필 수정',
  account_profile_edit_button: '프로필 수정',
  account_profile_change_photo: '사진 변경',
  account_profile_name: '이름',
  account_profile_phone: '휴대폰 번호',
  account_profile_email: '이메일',
  account_profile_address_book: '배송지 관리',
  account_profile_change_password: '비밀번호 변경',
  account_profile_change_name_title: '이름 변경',
  account_profile_change_name_page_title: '이름 변경',
  account_profile_change_name_label: '이름',
  account_profile_change_name_first_placeholder: '이름',
  account_profile_change_name_last_placeholder: '성',
  account_profile_change_name_button: '저장하기',
  account_profile_change_name_modal: '이름이 변경되었습니다.',
  account_profile_change_name_modal_button: '확인',
  account_profile_change_name_modal_alert: '이름을 정확하게 입력해주세요.',
  account_profile_change_name_modal_alert_button: '확인',
  account_profile_change_name_modal_error: '이름을 정확하게 입력해주세요.',
  account_profile_change_name_modal_error_button: '확인',
  account_profile_change_phone_title: '핸드폰 번호 변경',
  account_profile_change_phone_page_title: '핸드폰 번호 변경',
  account_profile_change_phone_placeholder: '-없이 입력해주세요.',
  account_profile_change_phone_button: '저장하기',
  account_profile_change_phone_done: '핸드폰 번호가 변경되었습니다.',
  account_profile_change_phone_done_button: '저장',
  account_profile_change_phone_error: '휴대폰 번호를 정확하게 입력해주세요.',
  account_profile_change_phone_error_button: '저장',
  account_profile_change_phone_error_empty: '휴대폰 번호를 정확하게 입력해주세요.',
  account_profile_change_phone_error_empty_button: '저장',
  account_profile_delivery_title: '배송지 정보',
  account_profile_delivery_page_title: '배송지 정보',
  account_profile_delivery_address_title: '내 배송목록에서 선택',
  account_profile_delivery_address_add: '새 배송지 추가',
  account_profile_delivery_address_button: '저장하기',
  account_profile_delivery_address_modal: '주소를 다시 확인해주세요.',
  account_profile_delivery_address_modal_button: '확인',
  account_profile_delivery_address_empty: '배송지가 없습니다.',
  account_profile_delivery_address_empty_button: '배송지 추가하기',
  account_profile_delivery_address_error: `정확한 주소를 입력해주세요.
  주소가 정확하지 않을 경우 반송될 수 있습니다.`,
  account_profile_delivery_address_error_firstName: 'firstName',
  account_profile_delivery_address_error_lastName: 'lastName',
  account_profile_delivery_address_error_address1: 'address1',
  account_profile_delivery_address_error_city: 'city',
  account_profile_delivery_address_error_country: 'country',
  account_profile_delivery_address_error_phone: 'phone',
  account_profile_delivery_address_error_province: 'province',
  account_profile_delivery_address_error_zip: 'zip',
  account_profile_delivery_add_title: '배송지 추가',
  account_profile_delivery_add_page_title: '배송지 추가',
  account_profile_delivery_edit_title: '배송지 수정',
  account_profile_delivery_edit_page_title: '배송지 수정',
  account_profile_delivery_add_name: '수령인',
  account_profile_delivery_add_first_name: '이름',
  account_profile_delivery_add_last_name: '성',
  account_profile_delivery_add_description_alert: '전체 이름을 정확하게 입력하세요.',
  account_profile_delivery_add_description:
    '정확한 이름을 입력하지 않으면 세관통과가 어려울 수 있습니다.',
  account_profile_delivery_add_contact: '수령인 연락처',
  account_profile_delivery_add_phone: '휴대폰 번호',
  account_profile_delivery_add_address: '배송 주소',
  account_profile_delivery_add_country: '국가/지역',
  account_profile_delivery_add_street: '도로명',
  account_profile_delivery_add_unit: '지번 또는 아파트 #(선택사항)',
  account_profile_delivery_add_city: '도시',
  account_profile_delivery_add_state: '주',
  account_profile_delivery_add_zip: '우편번호',
  account_profile_delivery_add_save: '저장하기',
  account_profile_delivery_add_cancel: '취소',
  account_profile_change_password_title: '비밀번호 변경',
  account_profile_change_password_page_title: '비밀번호 변경',
  account_profile_change_password_new: '신규 비밀번호',
  account_profile_change_password_new_placeholder: '신규 비밀번호를 입력해주세요.',
  account_profile_change_password_re: '신규 비밀번호 재입력',
  account_profile_change_password_re_placeholder: '신규 비밀번호를 다시 입력해주세요.',
  account_profile_change_password_button: '저장하기',
  account_profile_change_password_modal: '비밀번호가 변경되었습니다.',
  account_profile_change_password_modal_button: '확인',
  account_profile_change_password_modal_error: '비밀번호가 일치하지 않습니다.',
  account_order_title: '나의 주문',
  account_order_page_title: '나의 주문',
  account_order_tab: '전체',
  account_order_tab_pending: '주문대기',
  account_order_tab_ready: '배송대기',
  account_order_tab_shipping: '배송중',
  account_order_tab_shipped: '배송완료',
  account_order_tab_returns: '취소/반품',
  account_order_empty: '주문 내역이 없습니다.',
  account_order_empty_button: '쇼핑하러 가기',
  account_order_empty_sub_message: '장바구니에 상품을 담고 무료 배송 혜택을 받아보세요!',
  account_order_no: '주문번호. ',
  account_order_status_pending: '주문대기',
  account_order_status_ready: '배송 준비 완료',
  account_order_status_shipping: '배송중',
  account_order_status_shipped: '배송완료',
  account_order_status_returns: '취소/반품',
  account_order_cancel: '주문 취소',
  account_order_change_address: '배송지 변경',
  account_order_tracking: '배송 조회',
  account_order_review: '리뷰 작성',
  account_order_cancelled: '취소 상세 보기',
  account_orders_detail_title: '주문 상세',
  account_orders_detail_page: '주문 상세',
  account_orders_modal_address: '주소가 변경되었습니다.',
  account_orders_modal_address_error: '다시 시도해 주세요.',
  account_orders_modal_address_fail: `주소지 변경은 ‘주문대기' 상태에서만 가능합니다.`,
  account_orders_modal_cancel: '주문을 취소하시겠습니까?',
  account_orders_modal_cancel_button: '주문취소',
  account_orders_modal_cancel_toast: '주문이 취소되었습니다.',
  account_point_title: '포인트 전환',
  account_point_page_title: '포인트 전환',
  account_point_state_point: '나의 포인트',
  account_point_state_conversion: '쿠폰 전환',
  account_point_state_conversion_mobile: '쿠폰 전환',
  account_point_state_description: '=$',
  account_point_redeem: '전환 예정 포인트',
  account_point_redeem_subtitle: '100 포인트 = $1',
  account_point_redeem_description: ' 포인트 = $',
  account_point_redeem_description2: ' 쿠폰',
  account_point_redeem_button: '전환하기',
  account_point_redeem_description_title: '무료 혜택 받는 법',
  account_point_redeem_description_item1: '친구 초대하고 $10 청구하기',
  account_point_redeem_description_item2: '리뷰 작성하고 $1 적립하기',
  account_point_redeem_description_item3: '지출 $1당 1포인트 적립',
  account_point_redeem_description_alert: '*100포인트를 $1로 전환',
  account_point_redeem_modal_title: '알림',
  account_point_redeem_modal_description:
    '쿠폰 전환이 완료되었습니다.\n나의 쿠폰함에서 확인해주세요.',
  account_point_redeem_modal_button: '확인',
  account_invite: '친구 초대',
  account_invite_page: '친구 초대',
  account_invite_description:
    'myKmarket을 친구에게 소개하고 친구와 함께 적립금을 받으세요. 친구가 myKmarket에서 구매 후 배송이 완료되면 각각 1000포인트($10)가 지급됩니다. 또 다른 친구에게 myKmarket을 소개하고 무제한 적립금을 차곡차곡 쌓아보세요. (100포인트=$1 기준이며 적립 포인트는 배송 완료 후 지급됩니다.)',
  account_invite_button: '친구 초대하기',
  account_invite_invited: '초대된 친구',
  account_invite_earned: '획득 리워드',
  account_invite_modal_title: '다음 {1}건의 주문에서 {$10} 리워드를 받으세요.',
  account_invite_modal_description: 'myKmarket에서 아시아 식료품을 쇼핑하세요!',
  account_invite_modal_bottom_description:
    '친구에게 <span>$10</span> 선물하고, <span>$10</span> 리워드 받기',
  account_wish: '찜한 상품',
  account_wish_page: '찜한 상품',
  account_wish_tab: '내가 찜한 상품',
  account_wish_button: '장바구니로 이동',
  account_wish_empty: '찜한 상품이 없습니다.',
  account_wish_empty_button: '쇼핑하러 가기',
  account_wish_soldout: '품절',
  account_coupon: '쿠폰',
  account_coupon_page: '쿠폰',
  account_coupon_tab1: '만료 예정',
  account_coupon_tab2: '사용 완료',
  account_coupon_tab3: '기간 만료',
  account_coupon_empty_message: '보유중인 쿠폰이 없습니다.',
  account_review: '리뷰 관리',
  account_review_page: '리뷰 관리',
  account_review_tab1: '리뷰 작성',
  account_review_tab2: '작성한 리뷰',
  account_review_empty_write_message: '리뷰를 작성할 수 있는 주문이 없습니다.',
  account_review_empty_edit_message: '작성된 리뷰가 없습니다.',
  account_review_write: '고객님의 후기가 꼭 필요한 상품입니다.',
  account_review_write_button: '상세 리뷰 작성하기',
  account_review_write_deadline: '작성 기한 : ',
  account_review_written_button: '수정',
  account_review_edit_write: '리뷰 작성하기',
  account_review_edit_edit: '리뷰 수정하기',
  account_review_edit_page_write: '리뷰 작성하기',
  account_review_edit_page_edit: '리뷰 수정하기',
  account_review_edit_header: '이 제품을 자세히 평가해 주세요.',
  account_review_edit_text_title: '상세내용',
  account_review_edit_text_placeholder:
    '다른 고객님에게 도움이 되도록 상품에 대한 솔직한 평가를 남겨주세요. (상품 품질과 관계없는 배송, 포장, 질문 응대, 상품 가격 등은 고객센터에 연락바랍니다.)',
  account_review_edit_pictures: '사진 첨부',
  account_review_edit_files: '파일 첨부',
  account_review_edit_edit_button: '수정완료',
  account_review_edit_write_button: '작성완료',
  account_help: '고객센터',
  account_help_page: '고객센터',
  account_help_contact: '문의하기',
  account_help_contact_page: '문의하기',
  account_help_faq: 'FAQ',
  account_help_faq_all: 'All',
  account_help_qna: 'Q&A',
  account_help_request: '제안',
  account_help_partnership: '입점제안',
  account_help_notification: '공지사항',
  account_help_faq_header: '도움이 필요하세요?',
  account_help_faq_mail: '고객센터',
  account_help_faq_title: 'FAQ',
  account_help_faq_empty: '자주 묻는 질문이 없습니다.',
  account_help_request_message: '찾는 상품이 없다면 myKmarket에 제안해주세요!',
  account_help_request_empty: '요청사항이 없습니다.',
  account_help_request_button: '신규제안',
  account_help_request_title: '제안',
  account_help_request_new_title: '제안하기',
  account_help_request_new_title_placeholder: "제안 상품의 '브랜드명'을 입력해주세요.",
  account_help_request_new_detail_placeholder: '제안하고 싶은 상품에 대해 자세히 입력해주세요.',
  account_help_request_new_button: '보내기',
  account_help_partnership_title: '입점제안',
  account_help_partnership_company: '회사 이름',
  account_help_partnership_company_placeholder: '회사 이름을 입력해주세요.',
  account_help_partnership_email: '답변받을 이메일',
  account_help_partnership_email_placeholder: '답변 받으실 이메일 주소를 입력해주세요.',
  account_help_partnership_detail: '제안내용',
  account_help_partnership_detail_placeholder: '상세한 제안 내용을 입력해주세요.',
  account_help_partnership_button: '보내기',
  account_help_partnership_modal: '파트너십 제안이 완료되었습니다.',
  account_help_partnership_modal_button: '확인완료',
  account_help_partnership_modal_error: '제안 사항을 다시 확인해주세요.',
  account_help_partnership_modal_error_button: '확인',
  account_help_notification_title: '공지사항',
  account_help_notification_tag: '공지',
  account_help_notification_empty: '공지사항이 없습니다.',
  account_notification: '푸시설정',
  account_notification_page: '푸시설정',
  account_notification_sales_title: '판매 & 프로모션',
  account_notification_sales_description: '이벤트 및 프로모션을 안내합니다.',
  account_notification_sales_toggle: '신상품, 베스트 셀러, 기간 한정 특가',
  account_notification_personalized_title: '개인 알림',
  account_notification_personalized_description: '쿠폰, 재입고 알림, 커뮤니티 메시지를 안내합니다.',
  account_notification_personalized_toggle: '신규쿠폰, 재입고, 커뮤니티 메시지',
  account_notification_order_title: '주문 및 시스템 알림',
  account_notification_order_description:
    '귀하의 편의를 위해 이러한 알림에 대해 비활성화할 수 없습니다.',
  account_language: '언어설정',
  account_language_page: '언어설정',
  account_language_en: '영어',
  account_language_ko: '한국어',
  account_language_button: '저장하기',
  account_information: '정보',
  account_information_page: '정보',
  account_information_tab1: '서비스 설명',
  account_information_tab2: '고객 혜택',
  main_render_header_more: '더보기',
  category_aside_filters: '필터',
  category_aside_for_sale: '판매중',
  category_aside_price: '가격',
  category_aside_price_unlimited: '제한 없음',
  category_aside_price_under5: '$5 미만',
  category_aside_price_under10: '$5 - $10',
  category_aside_price_under15: '$10 - $15',
  category_aside_price_under25: '$15 - $25',
  category_aside_price_over25: '$25 이상',
  category_aside_price_custom: '직접 입력',
  category_aside_price_custom_min: '최소값',
  category_aside_price_custom_min_placeholder: '최소 가격',
  category_aside_price_custom_max: '최대값',
  category_aside_price_custom_max_placeholder: '최대 가격',
  category_aside_price_custom_apply: '적용하기',
  category_aside_price_custom_modal: '가격 범위를 확인해주세요.',
  category_aside_price_custom_modal_button: '확인',
  category_name: '카테고리',
  category_name_All: '전체',
  category_name_foods: '식품',
  'category_name_nuts-dried-fruits': '견과류/건과일',
  'category_name_rice-mixed-grains': '쌀/잡곡',
  'category_name_dried-seafood': '건어물',
  'category_name_coffee-tea': '커피/차',
  'category_name_snacks-cereals': '스낵/시리얼',
  'category_name_candy-jelly': '캔디/젤리',
  category_name_ramen: '라면',
  'category_name_noodles-can-processed-food': '면/통조림/가공식품',
  'category_name_sauce-oil-seasoning': '소스/오일/조미료',
  'category_name_baby-kids-food': '유아 및 어린이 식품',
  category_name_beauty: '뷰티',
  'category_name_skin-care': '스킨케어',
  'category_name_shave-hair-removal': '면도/제모',
  'category_name_cleansing-peeling': '클렌징/필링',
  category_name_makeup: '메이크업',
  category_name_fragrances: '향수',
  'category_name_mens-care': '남성화장품',
  'category_name_hair-care-body-care': '네일/페디케어',
  'category_name_foot-hand-nail-care': '헤어/바디 케어',
  'category_name_tools-accessories': '뷰티소품',
  'category_name_kids-beauty': '키즈 뷰티',
  'category_name_vitamins-minerals': '비타민/미네랄',
  category_name_supplements: '영양제',
  'category_name_ginseng-red-ginseng': '인삼/홍삼',
  'category_name_concentrated-juice-drinks': '건강즙/음료',
  'category_name_honey-propolis': '꿀/프로폴리스',
  'category_name_powder-pills': '건강분말/건강환',
  'category_name_weight-management': '다이어트/이너뷰티',
  'category_name_medical-supplies-equipments': '의료용품',
  'category_name_health-supplements': '건강용품',
  category_name_baby: '출산유아동',
  'category_name_baby-fashion': '아동복',
  category_name_nursing: '수유용품',
  'category_name_baby-carrier-outdoor': '이유용품/유아식기',
  'category_name_baby-bedding': '아기띠/외출용품',
  'category_name_baby-safety': '유아동 침구',
  'category_name_baby-hair-care-body-care': '매트/안전용품',
  'category_name_pregnancy-maternity': '임부/태교용품',
  'category_name_baby-tableware': '베이비 헤어/바디 케어',
  category_name_home: '생활용품',
  category_name_laundry: '세탁세제',
  'category_name_household-cleaning-air-fresheners': '가정용 청소/방향제',
  'category_name_bathroom-supplies': '욕실 용품',
  'category_name_home-storage-organization': '수납/정리 용품',
  'category_name_household-supplies': '생활잡화',
  category_name_kitchen: '주방용품',
  category_name_cookware: '조리기구',
  'category_name_cutlery-knife-accessories': '칼/도마',
  'category_name_utensils-gadgets': '주방조리도구',
  category_name_dinnerware: '그릇',
  category_name_flatware: '수저/커트러리',
  'category_name_glassware-drinkware': '컵/잔/텀블러',
  'category_name_kitchen-storage-organization': '주방 수납/정리',
  'category_name_food-containers': '밀폐/보관용기',
  'category_name_kitchen-accessories': '주방잡화',
  'category_name_thermocoolers-thermoses': '보온/보냉용품',
  'category_name_baking-supplies': '베이킹 용품',
  'category_name_korean-traditional-supplies': '교자상/제수용품',
  'category_name_stationary-office': '사무용품',
  'category_name_stationery-office': '사무용품',
  'category_name_office-supplies': '사무용품',
  'category_name_arts-crafts': '미술/화방용품',
  'category_name_character-stationery': '캐릭터 문구',
  'category_name_party-decorations': '파티/이벤트',
  'category_name_gift-wrapping': '데코/포장용품',
  'category_name_cards-letters-envelopes': '카드/편지/봉투',
  category_name_albums: '앨범',
  category_name_toys: '장난감',
  'category_name_character-toys': '캐릭터완구',
  'category_name_baby-toddler-toys': '영유아완구',
  'category_name_robots-electronic-toys': '로봇/전자 완구',
  'category_name_sports-outdoor-play': '스포츠/야외 완구',
  'category_name_learning-education': '학습/교육',
  'category_name_games-puzzles': '게임/퍼즐',
  'category_name_remote-control-play': 'RC완구/부품',
  'category_name_action-figures-statues': '피규어/다이캐스트',
  'category_name_musical-instraments-equipments': '악기/음향기기',
  category_name_books: '도서/음반/DVD',
  'category_name_childrens-books': '아동 도서',
  'category_name_novel-essay-poet': '소설/에세이/시',
  'category_name_cookbooks-food-wine': '요리책/음식&와인',
  'category_name_health-fitness-hobby': '건강/피트니스/취미',
  'category_name_comics-graphic-novels': '만화/그림소설',
  'category_name_politics-social-sciences': '정치/사회과학',
  category_name_history: '역사',
  category_name_humanities: '인문학',
  'category_name_computers-technology': '컴퓨터/기술',
  'category_name_cd-lp': 'CD/LP',
  'category_name_sports-outdoors': '스포츠',
  category_name_camping: '캠핑',
  'category_name_exercise-fitness': '운동/피트니스',
  'category_name_water-sports': '수영/수상 스포츠',
  category_name_golf: '골프',
  category_name_bikes: '자전거',
  category_name_fishing: '낚시',
  'category_name_sports-goods-accessories': '스포츠 잡화',
  'category_name_racquet-sports': '라켓 스포츠',
  'category_name_boxing-martial-arts': '복싱/격투기',
  category_name_automotive: '자동차용품',
  'category_name_interior-accessories': '인테리어',
  'category_name_exterior-accessories': '익스테리어',
  'category_name_car-care-cleaning-supplies-fluids': '차량관리/청소용품/소모품',
  'category_name_tools-equipments-safety': '부품/안전/공구',
  category_name_fashion: '패션',
  'category_name_womens-fashion': '여성 의류',
  'category_name_mens-fashion': '남성 의류',
  category_name_pets: '반려동물용품',
  'category_name_dog-treats': '반려견 간식',
  'category_name_dog-supplies': '반려견 용품',
  'category_name_cat-treats': '반려묘 간식',
  'category_name_cat-supplies': '반려묘 용품',
  category_empty: '검색 결과가 없습니다.',
  cart_list_select: '전체 선택',
  cart_list_select_delete: '선택 삭제',
  cart_guide_a: '',
  cart_guide_b: ' 더 추가 시 무료배송',
  cart_guide_more: '배송비 절약상품 더보기',
  cart_item_remove: '삭제',
  cart_title: '장바구니',
  cart_desktop_sum: '합계',
  cart_desktop_shipping: '배송비',
  cart_desktop_shipping_description: '다음 단계에서 추가됩니다.',
  cart_desktop_total_sum: '총 합계',
  cart_unavailable: '구매 불가 상품 (총 {{items}}개)',
  cart_unavailable_clear: '전체 삭제',
  cart_checkout_button: '구매하기',
  cart_checkout_button_mobile: '구매하기(총 {{items}}개)',
  cart_free_shipping: '무료 배송',
  cart_delivery_total: '총 합계',
  cart_delivery_from: '배송 출발지',
  cart_delivery_where: '한국',
  cart_toast_a: '',
  cart_toast_b: ' 추가 시 무료배송',
  cart_item_empty_soldout: '품절',
  cart_item_empty_notify: '재입고 알림',
  cart_item_empty_remove: '삭제',
  cart_checkout_modal: '한 개 이상의 상품을 선택해주세요.',
  cart_checkout_modal_button: '확인',
  product_wish: '찜하기',
  product_cart_add: '장바구니 담기',
  product_cart_in: '개 장바구니 담기',
  product_cart_soldout: '재입고 알림',
  product_review: '리뷰',
  product_review_write: '고객님의 후기가 꼭 필요한 상품입니다.',
  product_detail: '상품 상세',
  product_detail_share_modal_description: 'myKmarket에서 아시아 식료품을 쇼핑하세요!',
  product_detail_empty: '다시 시도해주세요.',
  product_notify: '재입고 알림',
  product_notify_modal: `재입고 알림이 신청되었습니다. 
  상품이 준비되면 메일로 알려드리겠습니다.`,
  product_notify_modal_button: '확인',
  checkout: '결제하기',
  checkout_page: '결제하기',
  checkout_shipping: '배송지 정보',
  checkout_shipping_placeholder: '배송 주소와 연락처를 입력해주세요.',
  checkout_shipping_address_description: '배송요청이 없습니다. (선택)',
  checkout_shipping_address_edit: '수정',
  checkout_guide_free: '무료배송이 적용되었습니다.',
  checkout_guide_a: '',
  checkout_guide_b: ' 더 추가 시 무료배송',
  checkout_guide_more: '배송지 절약상품 더보기',
  checkout_carrier: 'Carrier Information',
  checkout_carrier_main: 'Carrier Information',
  checkout_carrier_sub: 'Carrier Information',
  checkout_carrier_free: 'Free',
  checkout_carrier_next: 'next step',
  checkout_carrier_alert: '안전한 배송을 위해 주소가 영어로 되어 있는지\n다시 확인해 주세요.',
  checkout_coupon: '쿠폰',
  checkout_coupon_placeholeder: '쿠폰을 선택하거나 코드를 입력해주세요.',
  checkout_coupon_code: '쿠폰 선택',
  checkout_coupon_code_deleted: '쿠폰을 선택하거나 코드를 입력해주세요.',
  checkout_coupon_delete_button: '삭제',
  checkout_coupon_button: '적용',
  checkout_coupon_apply_title: '쿠폰 적용',
  checkout_coupon_apply_code: '쿠폰 코드',
  checkout_coupon_apply_placeholder: '쿠폰 코드를 입력해주세요.',
  checkout_coupon_apply_or: '또는',
  checkout_coupon_apply_select: '쿠폰 선택',
  checkout_coupon_apply_save: '저장하기',
  checkout_coupon_modal_save: '저장하기',
  checkout_coupon_modal_cancel: '취소',
  checkout_coupon_modal_add: '새 카드 만들기',
  checkout_coupon_modal_add_save: '저장',
  checkout_coupon_modal_add_cancel: '취소',
  checkout_item: '전체 ({{items}}개)',
  checkout_purcahse: '구매 정보',
  checkout_purcahse_payment: '결제 정보',
  checkout_purcahse_subtotal: '전체 ({{items}}개)',
  checkout_purcahse_taxes: '세금',
  checkout_purcahse_shipping: '배송비',
  checkout_purcahse_total: '총 합계',
  checkout_purcahse_point: '총 할인',
  checkout_purcahse_amount: '최종 결제 금액',
  checkout_purcahse_button: '결제하기',
  checkout_overlay_delivery: '배송지 정보',
  checkout_overlay_delivery_add: '배송지 정보',
  checkout_overlay_payment: '결제 수단',
  checkout_overlay_card: '카드 추가',
  checkout_overlay_coupon: '쿠폰 적용',
  checkout_overlay_purchase: '상품 목록',
  checkout_overlay_purchase_quantity: '{{items}} 개',
  checkout_modal: '',
  checkout_modal_button: '확인',
  checkout_modal_alert: '800불 이상 구매 시 tax가 부과될 수 있습니다. 결제하시겠습니까?',
  checkout_modal_alert_button: '확인',
  checkout_modal_error: '결제 처리에 문제가 발생했습니다. 다시 시도해 주세요.',
  checkout_modal_error_button: '확인',
  checkout_modal_error_zip: '올바른 우편번호를 입력해주세요.',
  checkout_modal_error_city: '올바른 도시명을 입력해주세요.',
  checkout_modal_error_state: '주를 선택해 주세요.',
  checkout_modal_error_zip_button: '확인',
  checkout_modal_error_select: '배송지를 입력해주세요.',
  checkout_modal_error_select_button: '확인',

  common_empty_message: 'myKmarket 인기 상품을 추천드릴께요!',
  common_empty_button: '베스트 상품 보러가기',

  footer_welcome: 'mykmarket에 오신 것을 환영합니다!',
  footer_description: '궁금하신 사항은 고객센터로 문의 부탁드립니다.',
  footer_customer_center: '고객센터',
  footer_information: '정보',
  footer_information_about: '서비스 설명',
  footer_information_benefit: '고객 혜택',
  footer_information_contact: '문의하기',
  footer_policy: '정책',
  footer_policy_shipping: '배송 정책',
  footer_policy_refund: '반품 및 환불 정책',
  footer_policy_privacy: '개인 정보 정책',
  footer_policy_terms: '서비스 약관',
  footer_rights: 'ⓒ2021 (주)브랜드리스 All rights reserved.',
  footer_rights_description: 'MXN에 의해 이행됨.',

  policy_page: '정책',
  policy_shipping: '배송 정책',
  policy_shipping_title: '배송 정책',
  policy_shipping_category: '배송 정책',
  policy_refund: '환불 정책',
  policy_refund_title: '환불 정책',
  policy_refund_category: '반품 및 환불 정책',
  policy_privacy: '개인 정보 정책',
  policy_privacy_title: '개인 정보 정책',
  policy_privacy_category: '개인 정보 정책',
  policy_terms: '서비스 약관',
  policy_terms_title: '서비스 약관',
  policy_terms_category: '서비스 약관',

  search_popular: '인기 검색어',
  search_found: '<span>{{items}}</span> 검색 결과',
  search_empty: `찾는 상품이 없다면 myKmarket에 제안해주세요!`,
  search_empty_button: '제안하기',

  search_filter_best_selling: '최다 판매순',
  search_filter_newest: '최신순',
  search_filter_price_low_to_high: '최저 가격순',
  search_filter_price_high_to_low: '최고 가격순',

  order_detail_payment_info: '결제 정보',
  order_detail_payment_info_tax: '세금',
  order_detail_payment_info_shipping: '배송비',
  order_detail_payment_total: '총 합계',
  order_detail_payment_discount: '총 할인',
  order_detail_payment_tool: '결제 수단',
  order_detail_payment_total_amount: '최종 결제 금액',
  order_detail_payment_refund: '환불 금액',
  order_detail_payment_net_payment: '최종 결제 금액',
  order_detail_delivery_info: '배송지 정보',
  order_detail_items: '주문 목록',
  order_detail_title: '주문 상세',
  order_detail_date: '주문 일자',
  order_detail_order_number: '주문 번호',
  order_detail_bottom_button_pending: '주문 취소',
  order_detail_bottom_button_shipped: '리뷰 작성',
  order_detail_delivery_title: '주문 목록',
  order_detail_delivery_cancelled_title: '주문 취소',
  order_detail_delivery_shipped: '배송 완료',
  order_detail_delivery_arrived: '도착',
  order_detail_delivery_tracking: '배송 조회',
  order_detail_item_qty: '{{quantity}} 개',
  modal_default_button: '확인',
  modal_default_cancel_button: '취소',
  modal_default_header: '알림',

  alert_copied: '복사되었습니다.',

  roulette_header_text: '100% 당첨되는 룰렛 돌리고 스페셜 혜택을 받아보세요!',
  roulette_sub_header_text: '최대 20% 할인에 무료배송 쿠폰까지 드립니다.',
  roulette_guide_text_01: '룰렛은 한 번만 돌릴 수 있습니다.',
  roulette_guide_text_02: '발급된 쿠폰은 중복 사용 불가합니다.',
  roulette_input_placeholder: '이메일을 입력해주세요.',
  roulette_submit_button: 'START NOW',
  roulette_guide_sub_text:
    '귀하의 이메일 주소를 제출함으로써 귀하는 이용 및 판매 약관에 동의하고 귀하가 관심을 가질 만한 제품 및 서비스에 대한 마케팅 이메일을 수신하는데 동의하게 됩니다.',
  roulette_inner_popup_description: '축하합니다! 메일함을 확인해주세요.',
  roulette_alert_fail_text: '이미 사용된 이메일입니다.',
};

export default ko;

import { gql, useQuery } from '@apollo/client';
import { Options, Splide } from '@splidejs/react-splide';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { CurationModuleSortingType } from '../@types/curation';
import { ProductType } from '../@types/product';
import { ProductsByHandleResponse } from '../@types/response';
import { SORT_KEY_MAP } from '../api/storefront/products';
import {
  CategoryAside,
  CategoryMenu,
  CategoryPath,
  CategoryProducts,
} from '../components/category';
import { useDrawer } from '../components/drawer/Drawer';
import { BaseLayout } from '../components/layout';
import { MobileNavItem } from '../components/layout/BaseLayout';
import Loading from '../components/loading/Loading';
import Select from '../components/select/Select';
import SplideSlick from '../components/splide-slick/SplideSlick';
import { useResponsiveElement, useViewport } from '../helper/hooks';
import { categories } from '../utils/getCategories';
import styles from './styles/Categories.module.scss';

const GET_PRODUCTS_BY_CATEGORY = gql`
  query getProductByCategory(
    $handle: String!
    $first: Int
    $sortKey: ProductCollectionSortKeys!
    $reverse: Boolean!
    $filters: [ProductFilter!]
    $after: String
  ) {
    collectionByHandle(handle: $handle) {
      products(
        sortKey: $sortKey
        reverse: $reverse
        filters: $filters
        first: $first
        after: $after
      ) {
        edges {
          cursor
          node {
            id
            title
            description
            tags
            createdAt
            updatedAt
            publishedAt
            handle
            vendor
            totalInventory
            availableForSale
            productType
            metafield(namespace: "custom", key: "review_rating") {
              value
            }
            variants(first: 5) {
              edges {
                node {
                  id
                }
              }
            }
            featuredImage {
              url
              altText
            }
            priceRange {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            compareAtPriceRange {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }

            collections(first: 10) {
              edges {
                node {
                  title
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

interface PriceOptions {
  [key: string]: {
    name: string;
    price: PriceRangeOptions;
  };
}
export interface SelectedOptions {
  priceOption: string;
  countryOption: string;
  forSale: boolean;
}
export interface PriceRangeOptions {
  min: number;
  max?: number;
}

const Categories = (): ReactElement => {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const [products, setProducts] = useState<ProductType[]>([]);
  const { isMobile } = useViewport();

  const intersectionBar = useRef<HTMLDivElement | null>(null);

  const [categoryHandle, setCategoryHandle] = useState<string>('');
  const [subCategoryHandle, setSubCategoryHandle] = useState<string>('');
  const [after, setAfter] = useState<string | null>(null);

  const category = categories.find((category) => category.handle === categoryHandle);
  const categoryName = t(`category_name_${category?.handle}`);
  const subCategoryName =
    category?.inner.find((sub) => sub.handle === subCategoryHandle)?.handle &&
    t(`category_name_${category?.inner.find((sub) => sub.handle === subCategoryHandle)?.handle}`);
  const subCategories = category?.inner;

  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({
    priceOption: 'Unlimited',
    countryOption: 'Unlimited',
    forSale: false,
  });

  const [customPrice, setCustomPrice] = useState<PriceRangeOptions>({ min: 0.0 });

  const priceRange: PriceOptions = {
    Unlimited: { name: 'Unlimited', price: { min: 0.0 } },
    'Under $5': { name: 'Under $5', price: { min: 0.0, max: 5.0 } },
    '$5 - $10': { name: '$5 - $10', price: { min: 5.0, max: 10.0 } },
    '$10 - $15': { name: '$10 - $15', price: { min: 10.0, max: 15.0 } },
    '$15 - $25': { name: '$15 - $25', price: { min: 15.0, max: 20.0 } },
    '$25 - Above': { name: '$25 - Above', price: { min: 25.0 } },
    custom: { name: 'custom', price: customPrice },
  };

  const subSlickRef = useRef<Splide | null>(null);

  const subSlickOptions: Options = {
    arrows: false,
    pagination: false,
    autoWidth: true,
    drag: 'free',
    snap: true,
    bounce: false,
    gap: 16,
    padding: {
      left: 16,
      right: 16,
    },
  };

  const id = subCategories?.find((item) => item.handle === subCategoryHandle)?.id || 1;
  const [clickedTab, setClickedTab] = useState(id - 1);

  const mobileTabItems = subCategories?.map(({ handle, name }, idx) => (
    <div
      className={`${styles['tab__item']} ${clickedTab === idx ? styles['tab__item--active'] : ''}`}
      onClick={() => setClickedTab(idx)}
    >
      <Link
        to={`/categories?menu=${categoryHandle}${
          categoryHandle !== handle ? `&submenu=${handle}` : ``
        }`}
      >
        {t(`category_name_${name === 'All' ? name : handle}`)}
      </Link>
    </div>
  ));

  const element = useResponsiveElement({
    MOBILE: (
      <div className={styles['tab__items']}>
        <SplideSlick
          slickData={{
            elements: mobileTabItems,
            options: subSlickOptions,
          }}
          slickRef={subSlickRef}
        />
      </div>
    ),
    DESKTOP: (
      <CategoryAside
        categoryName={categoryName}
        categoryHandle={categoryHandle}
        subCategoryHandle={subCategoryHandle}
        categoryItems={subCategories}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        customPrice={customPrice}
        setCustomPrice={setCustomPrice}
      />
    ),
  });

  const searchSortKeyMap = {
    BEST_SELLING: t('search_filter_best_selling'),
    NEWEST: t('search_filter_newest'),
    PRICE_LOW_TO_HIGH: t('search_filter_price_low_to_high'),
    PRICE_HIGH_TO_LOW: t('search_filter_price_high_to_low'),
  };

  const { open, close, isOpened, render } = useDrawer();

  const [sort, setSort] = useState<string>('BEST_SELLING');

  const onClickMobileFilter = (sortType: CurationModuleSortingType) => {
    setAfter(null);
    setSort(sortType);
    close();
  };

  const mobileFilterModal = render(
    <>
      <Select.Option
        label={t('search_filter_best_selling')}
        value="BEST_SELLING"
        isSelected={sort === 'BEST_SELLING'}
        onClick={() => onClickMobileFilter('BEST_SELLING')}
      />
      <Select.Option
        label={t('search_filter_newest')}
        value="NEWEST"
        isSelected={sort === 'NEWEST'}
        onClick={() => onClickMobileFilter('NEWEST')}
      />
      <Select.Option
        label={t('search_filter_price_low_to_high')}
        value="PRICE_LOW_TO_HIGH"
        isSelected={sort === 'PRICE_LOW_TO_HIGH'}
        onClick={() => onClickMobileFilter('PRICE_LOW_TO_HIGH')}
      />
      <Select.Option
        label={t('search_filter_price_high_to_low')}
        value="PRICE_HIGH_TO_LOW"
        isSelected={sort === 'PRICE_HIGH_TO_LOW'}
        onClick={() => onClickMobileFilter('PRICE_HIGH_TO_LOW')}
      />
    </>,
  );

  const mobileFilterElement = (
    <div className={styles['result__header']}>
      <img src="/icons/search__filter.svg" alt="검색 결과 필터링" onClick={open} />
    </div>
  );

  const desktopFilterElement = (
    <div className={styles['select__container']}>
      <Select
        value={sort}
        setValue={setSort}
        placeholder={searchSortKeyMap[sort as CurationModuleSortingType]}
        onSelectClick={() => {
          setAfter(null);
        }}
        fontStyle={{ padding: '12px' }}
      >
        <Select.Option
          label={t('search_filter_best_selling')}
          value="BEST_SELLING"
          isSelected={sort === 'BEST_SELLING'}
        />
        <Select.Option
          label={t('search_filter_newest')}
          value="NEWEST"
          isSelected={sort === 'NEWEST'}
          onClick={() => onClickMobileFilter('NEWEST')}
        />
        <Select.Option
          label={t('search_filter_price_low_to_high')}
          value="PRICE_LOW_TO_HIGH"
          isSelected={sort === 'PRICE_LOW_TO_HIGH'}
        />
        <Select.Option
          label={t('search_filter_price_high_to_low')}
          value="PRICE_HIGH_TO_LOW"
          isSelected={sort === 'PRICE_HIGH_TO_LOW'}
        />
      </Select>
    </div>
  );

  const { loading, data, fetchMore } = useQuery<ProductsByHandleResponse>(
    GET_PRODUCTS_BY_CATEGORY,
    {
      variables: {
        handle: subCategoryHandle || categoryHandle,
        first: 16,
        sortKey: SORT_KEY_MAP[sort as CurationModuleSortingType],
        reverse: sort === 'PRICE_HIGH_TO_LOW' || sort === 'NEWEST',
        filters: [
          { available: selectedOptions.forSale ? selectedOptions.forSale : undefined }, // undefined : 모두 검색
          { price: priceRange[selectedOptions.priceOption].price },
        ],
      },

      notifyOnNetworkStatusChange: true,
    },
  );

  const handleFetchMore = (after: string | null) => {
    if (!after) {
      return;
    }

    fetchMore({
      variables: {
        handle: subCategoryHandle || categoryHandle,
        first: 16,
        sortKey: SORT_KEY_MAP[sort as CurationModuleSortingType],
        reverse: sort === 'PRICE_HIGH_TO_LOW' || sort === 'NEWEST',
        after,
      },
      updateQuery: (previouseResult, { fetchMoreResult }) => {
        return {
          collectionByHandle: {
            products: {
              edges: [
                ...previouseResult.collectionByHandle.products.edges,
                ...fetchMoreResult.collectionByHandle.products.edges,
              ],
              pageInfo: fetchMoreResult.collectionByHandle.products.pageInfo,
            },
          },
        };
      },
    });
  };

  const intersectionCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting) {
        handleFetchMore(after);
      }
    },
    [after],
  );

  const [firstLoading, setFirstLoading] = useState<boolean>();

  useEffect(() => {
    setCategoryHandle(search.get('menu') || '');
    setSubCategoryHandle(search.get('submenu') || '');
  }, [search]);

  useEffect(() => {
    setClickedTab(id - 1);
  }, [id]);

  useEffect(() => {
    if (!data) {
      setFirstLoading(true);
      return;
    }

    const { edges, pageInfo } = data.collectionByHandle?.products || {
      edges: [],
      pageInfo: { hasNextPage: false, hasPreviousPage: false },
    };

    setProducts(edges.map(({ node }) => node));

    setFirstLoading(false);
    if (!pageInfo.hasNextPage) {
      setAfter(null);
      return;
    }
    setAfter(edges[edges.length - 1].cursor);
  }, [categoryHandle, subCategoryHandle, data]);

  useEffect(() => {
    if (!intersectionBar.current) {
      return;
    }

    const observer = new IntersectionObserver(intersectionCallback, {
      rootMargin: '10px',
      threshold: 0.5,
    });

    observer.observe(intersectionBar.current);

    return () => observer.disconnect();
  }, [intersectionBar.current, intersectionCallback]);

  useEffect(() => {
    if (!subSlickRef?.current) {
      return;
    }

    subSlickRef.current.go(clickedTab);
  }, [subSlickRef?.current]);

  return (
    <BaseLayout desktopNav={'CATEGORY'} mobileNav={{ type: MobileNavItem.CATEGORY_ALL }}>
      <div className={styles['category']}>
        {isMobile && <CategoryMenu subSlickRef={subSlickRef} />}
        <div className={styles['category__container']}>
          {element}
          {isMobile ? mobileFilterElement : desktopFilterElement}
          {firstLoading ? (
            <div className={styles['category__container__loading']}>
              <Loading />
            </div>
          ) : (
            <div className={styles['category__container__products']}>
              <CategoryProducts
                products={products}
                categoryPath={
                  <CategoryPath category={categoryName} subCategory={subCategoryName} />
                }
              />
              {loading ? <Loading /> : <div ref={intersectionBar} style={{ height: '0px' }}></div>}
            </div>
          )}
        </div>
        {isOpened && mobileFilterModal}
      </div>
    </BaseLayout>
  );
};

export default Categories;

import { useEffect } from 'react';
import styles from './styles/HelpQna.module.scss';

const HelpQna = () => {
  useEffect(() => {
    location.hash = '#rp-customer-widget-home';
  }, []);

  return (
    <div className={styles['container']}>
      <img src="/images/common/chatbot-connect.svg" alt="챗봇 연결" />
    </div>
  );
};

export default HelpQna;

import { CSSProperties } from 'react';
import styles from './Label.module.scss';

type LabelType = {
  label: string;
  children: React.ReactNode;
  style?: CSSProperties;
};

const Label = ({ label, style = {}, children }: LabelType) => {
  return (
    <label className={styles['label-container']} style={style}>
      <div className={styles['form-label']}>{label}</div>
      {children}
    </label>
  );
};

export default Label;

import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children: ReactNode;
}

const ModalPortal = ({ children }: Props) => {
  const element = document.getElementById('modal-root') as HTMLDivElement;

  return createPortal(children, element);
};

export default ModalPortal;

import axios from 'axios';
import { refresh } from './auth';

const instance = axios.create({
  baseURL: process.env.REACT_APP_MKM_DOMAIN,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access-token-mkm')}`,
  },
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const {
      config,
      response: { status },
    } = error;

    if (status === 401) {
      const accessToken = await refresh();

      if (!accessToken) {
        return error.message;
      }

      config.headers.Authorization = `Bearer ${accessToken}`;
      return instance(config);
    }

    return error.response;
  },
);

export default instance;

import { ResponseMkm, StatisticsSearchResponse } from '../../@types/response-mkm';
import {
  StatisticsBannerType,
  StatisticsPageType,
  StatisticsProductType,
  StatisticsSearchType,
} from '../../@types/statistics';
import instance from './instance';

export async function postStatisticsClick(productStatistics: StatisticsProductType) {
  const response = await instance.post<ResponseMkm<null>>('/statistics/click', productStatistics);

  return response.data;
}

export async function postStatisticsCart(productStatistics: StatisticsProductType) {
  const response = await instance.post<ResponseMkm<null>>('/statistics/cart', productStatistics);

  return response.data;
}

export async function postStatisticsCustomer(productStatistics: StatisticsProductType) {
  const response = await instance.post<ResponseMkm<null>>(
    '/statistics/customer',
    productStatistics,
  );

  return response.data;
}

export async function postStatisticsOrder(productStatistics: StatisticsProductType) {
  const response = await instance.post<ResponseMkm<null>>('/statistics/order', productStatistics);

  return response.data;
}

export async function postStatisticsBanner(bannerStatistics: StatisticsBannerType) {
  const response = await instance.post<ResponseMkm<null>>('/statistics/banner', bannerStatistics);

  return response.data;
}

export async function postStatisticsSearch(searchStatistics: StatisticsSearchType) {
  const response = await instance.post<StatisticsSearchResponse>('/search', searchStatistics);

  return response.data;
}

export async function postStatisticsPage(pageStatistics: StatisticsPageType) {
  const response = await instance.post<ResponseMkm<null>>('/statistics/page', pageStatistics);

  return response.data;
}

import { CSSProperties, ReactElement } from 'react';
import styles from './Arrow.module.scss';

export function PrevArrow(props: {
  onClick?: React.MouseEventHandler<HTMLElement>;
  customStyles?: CSSProperties;
  customImage?: string;
}): ReactElement {
  const { onClick, customStyles, customImage } = props;
  return (
    <div style={{ ...customStyles }} onClick={onClick} className={styles['evt-slick-prev']}>
      <img src={customImage ?? '/icons/slick__chevron-left.svg'} alt={'prev'} />
    </div>
  );
}

export function NextArrow(props: {
  onClick?: React.MouseEventHandler<HTMLElement>;
  customStyles?: CSSProperties;
  customImage?: string;
}): ReactElement {
  const { onClick, customStyles, customImage } = props;
  return (
    <div style={{ ...customStyles }} onClick={onClick} className={styles['evt-slick-next']}>
      <img src={customImage ?? '/icons/slick__chevron-right.svg'} alt={'next'} />
    </div>
  );
}

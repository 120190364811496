import { ReactElement } from 'react';
import styles from './styles/Home.module.scss';

const Home = (): ReactElement => {
  return (
    <div className={styles['div']}>
      <img src="/icons/checkout__home.svg" alt="홈으로 가기" />
    </div>
  );
};

export default Home;

import { ChangeEventHandler, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { customerUpdate } from '../../../api/storefront/customer';
import { Input } from '../../../components/input';
import Label from '../../../components/label/Label';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import { FormButton } from '../../../components/login/atoms';
import styles from './styles/ChangeName.module.scss';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { customerState } from '../../../stores/customer/atoms';
import { modalState } from '../../../stores/modal/atoms';

const ChangeName = () => {
  const { t } = useTranslation();
  const customer = useRecoilValueLoadable(customerState);
  const [modal, setModal] = useRecoilState(modalState);
  const [form, setForm] = useState({
    firstName: customer.contents.firstName,
    lastName: customer.contents.lastName,
  });
  const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"0-9ㄱ-ㅎㅏ-ㅣ가-힣]/g;

  useEffect(() => {
    setForm({ firstName: customer.contents.firstName, lastName: customer.contents.lastName });
  }, [customer]);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!!form.firstName.match(regExp)) {
      setModal({
        modal: true,
        headerText: t('modal_default_header'),
        childrenText: t('account_profile_change_name_modal_error'),
        buttons: [
          {
            text: t('account_profile_change_name_modal_error_button'),
          },
        ],
      });

      return;
    }

    if (!!form.firstName.trim() && !!form.lastName.trim()) {
      const response = await customerUpdate({ firstName: form.firstName, lastName: form.lastName });

      setModal({
        modal: true,
        headerText: t('modal_default_header'),
        childrenText: t('account_profile_change_name_modal'),
        buttons: [
          {
            text: t('account_profile_change_name_modal_button'),
            action: () => {
              location.href = '/account/profile/edit';
            },
          },
        ],
        closeButtonAction: () => {
          location.href = '/account/profile/edit';
        },
      });

      return;
    }

    setModal({
      modal: true,
      headerText: t('modal_default_header'),
      childrenText: t('account_profile_change_name_modal_alert'),
      buttons: [
        {
          text: t('account_profile_change_name_modal_alert_button'),
        },
      ],
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (regExp.test(event.key)) event.preventDefault();
    if (event.key.match(regExp)) event.preventDefault();
  };
  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (regExp.test(event.key)) event.preventDefault();
    if (event.key.match(regExp)) event.preventDefault();
  };

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;

    const input = event.target.value.replace(regExp, '');

    setForm((prev) => ({
      ...prev,
      [name]: input,
    }));
  };

  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.HOME_SEARCH, title: t('account_profile_change_name_title') }}
      pageTitle={t('account_profile_change_name_page_title')}
    >
      <form className={styles['form']} onSubmit={onSubmit}>
        <div className={styles['form__container']}>
          <Label label={t('account_profile_change_name_label')}>
            <div className={styles['input__container--gap']}>
              <Input
                placeholder={t('account_profile_change_name_first_placeholder')}
                type="text"
                name="firstName"
                value={form.firstName}
                onChange={handleChangeInput}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
              />
              <Input
                placeholder={t('account_profile_change_name_last_placeholder')}
                type="text"
                name="lastName"
                value={form.lastName}
                onChange={handleChangeInput}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
              />
            </div>
          </Label>
        </div>
        <div className={styles['form__button__container']}>
          <FormButton contents={t('account_profile_change_name_button')} />
        </div>
      </form>
    </AccountLayout>
  );
};

export default ChangeName;

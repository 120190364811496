import { useNavigate } from 'react-router';
import Notification from '../../button/atoms/Notification';
import styles from './styles/Cart.module.scss';
import useCart from '../../../hooks/useCart';

const Cart = () => {
  const navigate = useNavigate();
  const cart = useCart();
  const totalCount = cart.getItemsTotal;
  return (
    <div
      className={styles['div']}
      onClick={() => {
        navigate('/cart');
      }}
    >
      <img src="/icons/shopping-cart.svg" alt="장바구니" />
      <Notification count={totalCount} customClassName={styles['nav-cart--notification-custom']} />
    </div>
  );
};

export default Cart;

import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PRICE_FOR_FREE_SHIPPING } from '../constants';
import { ViewportContext } from '../context/viewport/ViewportContext';
import useCart from '../hooks/useCart';

type DeviceType = 'MOBILE' | 'DESKTOP';

type Event = MouseEvent | TouchEvent;

export type UseResponsiveElementType = {
  [key in DeviceType]: ReactNode;
};

type IntersectHandlerType = (
  entry: IntersectionObserverEntry,
  observer: IntersectionObserver,
) => void;

export const useViewport = () => {
  const { width, height } = useContext(ViewportContext);
  const isMobile = width < 1024;
  return { width, height, isMobile };
};

export const useResponsiveElement = ({ MOBILE, DESKTOP }: UseResponsiveElementType) => {
  const { width } = useViewport();

  const element = useMemo(() => {
    if (width < 1024) {
      // 모바일 + 태블릿
      return MOBILE;
    } else {
      // 데스크탑
      return DESKTOP;
    }
  }, [DESKTOP, MOBILE, width]);

  return element;
};

export const useClickOutside = (handler: (event: Event) => void) => {
  const domNode = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const listener = (event: Event) => {
      if (!domNode.current || domNode.current?.contains(event.target as Node) || null) {
        return;
      }

      event.stopPropagation();

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [domNode, handler]);

  return domNode;
};

export function useLoadedHook<T>(initialValue: T) {
  const [t, setT] = useState<T>(initialValue);
  const [loaded, setLoaded] = useState<boolean>(false);

  return [t, setT, loaded, setLoaded] as const;
}

export const useMounted = () => {
  const mounted = useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  });
  return mounted;
};

export const useIntersect = (
  onIntersect: IntersectHandlerType,
  options?: IntersectionObserverInit,
) => {
  const ref = useRef<HTMLDivElement>(null);
  const callback = useCallback(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) onIntersect(entry, observer);
      });
    },
    [onIntersect],
  );

  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver(callback, options);
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref, options, callback]);

  return ref;
};

export const useLocalStorage = (key: string, defaultValue: unknown) => {
  const [value, setValue] = useState(() => {
    let currentValue;

    try {
      currentValue = JSON.parse(localStorage.getItem(key) || String(defaultValue));
    } catch (error) {
      currentValue = defaultValue;
    }
    return currentValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

export const useShippingRate = () => {
  const cart = useCart();
  const totalPrice = cart.getTotalPriceActive;
  return PRICE_FOR_FREE_SHIPPING - totalPrice <= 0 ? 0 : 20;
};

import {
  ChangeEventHandler,
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { OpenHandler } from '../../../../@types/checkout';
import { MailingAddress } from '../../../../@types/customer';
import { customerAddressCreate, customerAddressUpdate } from '../../../../api/storefront/addresses';
import { states } from '../../../../constants/states';
import { useViewport } from '../../../../helper/hooks';
import { Button } from '../../../button';
import { Input } from '../../../input';
import Label from '../../../label/Label';
import { FormButton } from '../../../login/atoms';
import Select from '../../../select/Select';
import styles from './AddressBookAdd.module.scss';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { modalState } from '../../../../stores/modal/atoms';

export type AddressType = 'ADD' | 'EDIT' | 'NONE';
export type AddressFormType = Pick<
  MailingAddress,
  'id' | 'firstName' | 'lastName' | 'address1' | 'address2' | 'city' | 'zip' | 'phone'
>;

const AddressBookAdd = ({
  addressType,
  selectedShippingInfo,
  setSelectedShippingInfo,
  handleChangeShippingInfo,
  setIsOpenStatusObject,
  openAddressForm,
}: {
  addressType?: AddressType;
  shippingInfoArray?: MailingAddress[];
  selectedShippingInfo?: MailingAddress;
  setSelectedShippingInfo?: Dispatch<SetStateAction<MailingAddress | undefined>>;
  handleChangeShippingInfo?: (currentShippingInfo?: MailingAddress, id?: string) => void;
  openAddressForm?: (type: AddressType, id?: string) => void;
} & Partial<OpenHandler>) => {
  const go = useNavigate();
  const [enabled, setEnabled] = useState<boolean>(false);
  const { t } = useTranslation();
  const [modal, setModal] = useRecoilState(modalState);
  const [country, setCountry] = useState<string>(
    addressType === 'EDIT' && selectedShippingInfo ? selectedShippingInfo.country : '',
  );
  const [state, setState] = useState<string>(
    addressType === 'EDIT' && selectedShippingInfo ? selectedShippingInfo.province : '',
  );

  const [form, setForm] = useState<AddressFormType>(
    addressType === 'EDIT' && selectedShippingInfo
      ? selectedShippingInfo
      : {
          firstName: '',
          lastName: '',
          phone: '',
          address1: '',
          address2: '',
          city: '',
          zip: '',
        },
  );

  const { width, isMobile } = useViewport();
  const { id } = useParams();

  const onClickSaveAndEditLogic = (id: string) => {
    if (addressType === 'NONE') {
      return;
    }

    if (!setSelectedShippingInfo) {
      return;
    }

    const address: MailingAddress = {
      ...form,
      id,
      province: state,
      country,
      name: form.firstName + form.lastName,
    };

    if (addressType === 'EDIT' && handleChangeShippingInfo) {
      setSelectedShippingInfo(selectedShippingInfo);
      handleChangeShippingInfo(address, selectedShippingInfo?.id);
    }

    if (addressType === 'ADD' && handleChangeShippingInfo) {
      handleChangeShippingInfo(address);
    }
  };

  const validation: () => boolean = () => {
    const except = ['address2', 'id'];
    const validations = { ...form, province: state, country };
    const validationKeys = Object.keys(validations);
    for (let i = 0; i < validationKeys.length; i++) {
      const key = validationKeys[i] as keyof typeof validations;
      if (!except.includes(key) && !validations[key]) {
        setModal({
          modal: true,
          childrenText: t(`account_profile_delivery_address_error`),
        });
        return false;
      }
    }
    return true;
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    if (!validation()) return;

    if (addressType === 'EDIT' && form.id) {
      const response = await customerAddressUpdate(form.id, state, country, form);
      const id = response.data.customerAddressUpdate.customerAddress.id as string;
      onClickSaveAndEditLogic(id);
    }

    if (addressType === 'ADD') {
      const response = await customerAddressCreate(state, country, form);
      const id = response.data.customerAddressCreate.customerAddress.id as string;
      onClickSaveAndEditLogic(id);
    }

    if (addressType === 'NONE') {
      await customerAddressCreate(state, country, form);
    }

    if (isMobile && setIsOpenStatusObject) {
      setIsOpenStatusObject((prev) => ({
        ...prev,
        deliveryInfo: true,
        deliveryInfoAdd: false,
      }));
    }

    if (location.pathname.includes('/orders/')) {
      window.location.href = `/account/orders/delivery-info/${encodeURIComponent(id!)}`;
      return;
    }
    if (location.pathname === '/checkout') {
      return;
    }

    go('/account/profile/delivery-info');
    location.reload();
  };

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: event.target.validity.valid ? value : prev[name as keyof AddressFormType],
    }));
  };
  const handleOnKeyUp: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const regex = /[ㄱ-ㅎ|가-힣|ㅏ-ㅣ]/;
    if (regex.test(event.key)) event.stopPropagation();
  };

  const handleClickCancel = () => {
    if (location.pathname.includes('/orders/')) {
      window.location.href = `/account/orders/delivery-info/${encodeURIComponent(id!)}`;
      return;
    }
    if (!openAddressForm) {
      go('/account/profile/delivery-info');
      return;
    }

    openAddressForm('NONE');
  };

  useEffect(() => {
    const fulfilled =
      Object.entries(form).filter(([key, value]) => {
        if (
          key === 'firstName' ||
          key === 'lastName' ||
          key === 'phone' ||
          key === 'address1' ||
          key === 'city' ||
          key === 'zip'
        ) {
          return !!value;
        }
        return false;
      }).length === 6;

    setEnabled(!!country && country !== '' && !!state && state !== '' && fulfilled);
  }, [form, country, state]);

  // FIXME: How to remove inline style
  return (
    <div className={styles['container']}>
      <form onSubmit={onSubmit}>
        <Label label={t('account_profile_delivery_add_name')}>
          <div className={styles['input__container--gap']}>
            <Input
              placeholder={t('account_profile_delivery_add_first_name')}
              type="text"
              name="firstName"
              pattern="[a-zA-Z]*"
              value={form.firstName}
              onChange={handleChangeInput}
            />
            <Input
              placeholder={t('account_profile_delivery_add_last_name')}
              type="text"
              name="lastName"
              pattern="[a-zA-Z]*"
              value={form.lastName}
              onChange={handleChangeInput}
            />
          </div>
        </Label>
        <div className={styles['description']}>
          <div className={styles['description__alert']}>
            {t('account_profile_delivery_add_description_alert')}
          </div>
          <div>{t('account_profile_delivery_add_description')}</div>
        </div>

        <Label label={t('account_profile_delivery_add_contact')}>
          <Input
            placeholder={t('account_profile_delivery_add_phone')}
            name="phone"
            type="text"
            pattern="[0-9]*"
            value={form.phone}
            onChange={handleChangeInput}
          />
          {/* <Input
            placeholder="Email"
            type="email"
            name="email"
            defaultValue={form.email}
            onChange={handleChangeInput}
          /> */}
        </Label>

        <Label label={t('account_profile_delivery_add_address')}>
          <></>
        </Label>
        <Select
          value={country}
          containerStyle={{
            borderRadius: '8px 8px 0 0',
          }}
          fontStyle={{ padding: '14px 16px', color: country === '' ? 'rgba(0, 0, 0, 0.25)' : '' }}
          setValue={setCountry}
          placeholder={t('account_profile_delivery_add_country')}
          alreadySelected
        >
          <Select.Option label="United States" value="United States" />
        </Select>
        <Input
          placeholder={t('account_profile_delivery_add_street')}
          type="text"
          style={{
            marginBottom: 0,
            borderRadius: '0',
          }}
          name="address1"
          pattern="[a-zA-Z0-9]*"
          value={form.address1}
          onChange={handleChangeInput}
        />
        <Input
          placeholder={t('account_profile_delivery_add_unit')}
          type="text"
          style={{
            marginBottom: 0,
            borderRadius: '0',
          }}
          name="address2"
          pattern="[a-zA-Z0-9]*"
          value={form.address2}
          onChange={handleChangeInput}
        />
        <Input
          placeholder={t('account_profile_delivery_add_city')}
          type="text"
          style={{
            marginBottom: 0,
            borderRadius: '0',
          }}
          name="city"
          pattern="[a-zA-Z0-9]*"
          value={form.city}
          onChange={handleChangeInput}
        />
        <div className={styles['input__container']}>
          <Select
            value={state}
            containerStyle={{
              borderRadius: '0 0 0 8px',
              marginBottom: '8px',
            }}
            fontStyle={{
              padding: '14px 16px 13px 16px',
              color: state === '' ? 'rgba(0, 0, 0, 0.25)' : '',
            }}
            setValue={setState}
            placeholder={t('account_profile_delivery_add_state')}
            alreadySelected
          >
            {states['United States'].map((value) => (
              <Select.Option key={value} label={value} value={value} />
            ))}
          </Select>
          <Input
            placeholder={t('account_profile_delivery_add_zip')}
            style={{
              borderRadius: '0 0 8px 0',
            }}
            name="zip"
            type="text"
            pattern="[0-9]*"
            onKeyUp={handleOnKeyUp}
            value={form.zip}
            onChange={handleChangeInput}
          />
        </div>
        {/* <Label label="Delivery Instructions (Optional)">
          <Input
            placeholder="e.g. use gate code 1234..."
            type="text"
            name="instruction"
            defaultValue={form.instruction}
            onChange={handleChangeInput}
          />
        </Label> */}

        <div className={styles['form__button']}>
          <Button className={styles['secondary']} action={handleClickCancel}>
            {t('account_profile_delivery_add_cancel')}
          </Button>
          <FormButton contents={t('account_profile_delivery_add_save')} />
        </div>
      </form>
    </div>
  );
};

export default AddressBookAdd;

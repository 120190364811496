import { MouseEventHandler, useEffect, useState, useCallback } from 'react';
import { ProductType } from '../../../@types/product';
import styles from './SearchPrediction.module.scss';
import useDebounce from '../../../hooks/useDebounce';
import { getProductsTitleByKeyword } from '../../../api/storefront/products';

type SearchPredictionProps = {
  keyword: string;
  handleSearchByKeyword: MouseEventHandler<HTMLDivElement>;
};

const SearchPredictionItem = ({
  item,
  keyword,
  handleSearchByKeyword,
}: {
  item: string;
  keyword: string;
  handleSearchByKeyword: MouseEventHandler<HTMLDivElement>;
}) => {
  const makeBold = (item: string, keyword: string) => {
    const regex = keyword && RegExp(keyword, 'i');
    const matched = item.match(regex);

    if (!matched) return item;
    const [word] = matched;
    const { index } = matched;

    if (!index) {
      return item;
    }

    return item.slice(0, index) + '<span>' + word + '</span>' + item.slice(index + word.length);
  };

  return (
    <div
      className={styles['item']}
      dangerouslySetInnerHTML={{ __html: makeBold(item, keyword) }}
      data-name={item}
      onMouseDown={handleSearchByKeyword}
    ></div>
  );
};

const SearchPrediction = ({ keyword, handleSearchByKeyword }: SearchPredictionProps) => {
  const [items, setItems] = useState<ProductType[]>([]);

  const debounceValue = useDebounce(keyword, 500);

  const getProductByKeywordRequest = useCallback(async () => {
    const products = await getProductsTitleByKeyword(debounceValue);

    setItems(products);
  }, [debounceValue]);

  useEffect(() => {
    if (!debounceValue || debounceValue.length < 2) {
      return;
    }

    getProductByKeywordRequest();
  }, [debounceValue]);

  return (
    <div className={styles['container']}>
      {items.map((item) => (
        <SearchPredictionItem
          key={item.id}
          item={item.title}
          keyword={keyword}
          handleSearchByKeyword={handleSearchByKeyword}
        />
      ))}
    </div>
  );
};

export default SearchPrediction;

import { ChangeEventHandler, KeyboardEventHandler, ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { CustomActions } from '../../layout/BaseLayout';
import SearchBar from '../../search/SearchBar';
import { Cart, ExternalLink, GoBack, Logo, MenuBar, Notification } from '../atoms';
import NavBarTitle from '../atoms/NavBarTitle';
import styles from './MobileNavBar.module.scss';

type PositionType = 'LEFT' | 'CENTER' | 'RIGHT';

type MobileNavItemType = {
  children: ReactNode;
  customChildren?: ReactNode;
  className?: string;
  action?: () => void;
};

type NavItemType =
  | 'GO_BACK'
  | 'TITLE'
  | 'LOGO'
  | 'CART'
  | 'NOTIFICATION'
  | 'EXTERNAL_LINK'
  | 'SEARCH_BAR'
  | 'SEARCH_ICON'
  | 'HOME'
  | 'CATEGORY';

type NavBarType = {
  pos: PositionType;
  items: Array<NavItemType>;
  props?: {
    title?: string;
    inputValue?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
    customActions?: CustomActions;
    customChildren?: ReactNode;
  };
};

type RenderNavItemType = {
  pos: PositionType;
  navBarProps: NavBarType[];
  mobileNavItemsObj: Record<NavItemType, MobileNavItemType>;
};

const RenderNavItem = ({ pos, navBarProps, mobileNavItemsObj }: RenderNavItemType) => {
  return (
    <div className={styles['navbar']}>
      {navBarProps
        .find((prop) => prop.pos === pos)
        ?.items.map((item, idx) => (
          <div
            key={`nav-item--${idx}`}
            onClick={mobileNavItemsObj[item].action}
            className={mobileNavItemsObj[item].className}
          >
            {mobileNavItemsObj[item].children}
          </div>
        ))}
    </div>
  );
};

const MobileNavBar = (navBarProps: NavBarType[]): ReactElement => {
  const navigate = useNavigate();
  const mobileNavItemsObj: Record<NavItemType, MobileNavItemType> = {
    GO_BACK: {
      children: navBarProps.find((prop) => prop.pos === 'LEFT')?.props?.customChildren ?? (
        <GoBack />
      ),
      action: () => {
        const customActions = navBarProps.find((prop) => prop.pos === 'LEFT')?.props?.customActions;
        if (customActions?.onBack) {
          customActions.onBack();
        } else if (customActions?.onHome) {
          customActions.onHome();
        } else {
          navigate(-1);
        }
      },
    },
    TITLE: {
      children: (
        <NavBarTitle
          title={navBarProps.find((prop) => prop.items.includes('TITLE'))?.props?.title}
        />
      ),
    },
    LOGO: {
      children: <Logo imgUrl="/images/mobile/logo.svg" />,
    },
    CART: {
      children: <Cart />,
    },
    NOTIFICATION: {
      children: <Notification />,
    },
    EXTERNAL_LINK: {
      children: <ExternalLink />,
    },
    SEARCH_BAR: {
      children: <SearchBar />,
    },
    SEARCH_ICON: {
      children: <SearchBar hideBar={true} />,
    },
    HOME: {
      children: <Logo imgUrl="/icons/nav_home.svg" />,
    },
    CATEGORY: {
      children: <MenuBar />,
    },
  };
  return (
    <div className={styles['div']}>
      {['LEFT', 'CENTER', 'RIGHT'].map((pos, idx) => (
        <RenderNavItem
          key={`${pos.toString()}--${idx}`}
          pos={pos as PositionType}
          navBarProps={navBarProps}
          mobileNavItemsObj={mobileNavItemsObj}
        />
      ))}
    </div>
  );
};

export default MobileNavBar;

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { MKMPagination } from '../../../@types/common';
import { TRequest } from '../../../@types/help';
import { getRequests } from '../../../api/backend/help';
import { Request } from '../../../components/account/help-center';
import Empty from '../../../components/empty/Empty';
import Loading from '../../../components/loading/Loading';
import { FormButton } from '../../../components/login/atoms';
import Pagination from '../../../components/pagination/Pagination';
import HelpRequestDetail from './HelpRequestDetail';
import HelpRequestForm from './HelpRequestForm';
import styles from './styles/HelpRequest.module.scss';

const HelpRequest = () => {
  return (
    <Routes>
      <Route path="/" element={<HelpRequestMain />} />
      <Route path="/:id" element={<HelpRequestDetail />} />
      <Route path="/form" element={<HelpRequestForm />} />
    </Routes>
  );
};

export const HelpRequestMain = () => {
  const { t } = useTranslation();
  const go = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [requests, setReqeusts] = useState<TRequest[]>([]);
  const [pagination, setPagination] = useState<MKMPagination>();
  const [searchParam, setSearchParam] = useSearchParams();

  const page = parseInt(searchParam.get('page') || '1');

  const handleChangePage = (page: number) => {
    setSearchParam(new URLSearchParams({ page: page.toString() }));
    window.location.reload();
  };

  const getRequestsRequest = useCallback(async (page: number) => {
    const { isOk, data } = await getRequests(page);

    if (!isOk) {
      return;
    }

    setReqeusts(data.suggestions);
    setPagination(data.pagination);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getRequestsRequest(page);
  }, [page]);

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['suggestion__message']}>
          <div>
            <img src="/icons/help__suggestion.svg" alt="" />
          </div>
          <div>{t('account_help_request_message')}</div>
        </div>
        <div className={styles['suggestions']}>
          {isLoading ? (
            <Loading />
          ) : requests.length > 0 ? (
            requests.map((request) => <Request key={request.id} request={request} />)
          ) : (
            <Empty message={t('account_help_request_empty')} isProduct={false} />
          )}
        </div>
      </div>

      <div className={styles['button']}>
        <FormButton
          contents={t('account_help_request_button')}
          style={{ marginTop: 0 }}
          onClick={() => go('/account/help-center/request/form')}
        />
      </div>

      <Pagination handleChangePage={handleChangePage} pagination={pagination} />
    </>
  );
};

export default HelpRequest;

import { TextareaHTMLAttributes } from 'react';
import styles from './Textarea.module.scss';

const Textarea = ({
  name,
  defaultValue,
  placeholder,
  onChange,
  ...props
}: TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  return (
    <div className={styles['container']}>
      <textarea
        className={styles['textarea']}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        {...props}
      ></textarea>
    </div>
  );
};

export default Textarea;

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getOrderStatus } from '../api/backend/order';

export function useOrderStatus(orderId: number): string[] {
  const { t } = useTranslation();
  const [status, setStatus] = useState<string>('');
  const [completedAt, setCompletedAt] = useState<string>('');

  const orderStatusMap: {
    [key in string]: string[];
  } = {
    PENDING: [t('account_order_status_pending'), 'PENDING'],
    READY: [t('account_order_status_ready'), 'READY'],
    SHIPPING: [t('account_order_status_shipping'), 'SHIPPING'],
    SHIPPED: [t('account_order_status_shipped'), 'SHIPPED', completedAt],
    CANCELLED: [t('account_order_status_returns'), 'CANCELLED'],
  };

  const getStatus = useCallback(async () => {
    const {
      order: { status, completedAt },
    } = await getOrderStatus(orderId.toString());
    setStatus(status);
    setCompletedAt(completedAt);
  }, []);

  useEffect(() => {
    getStatus();
  }, []);

  if (status in orderStatusMap) {
    return orderStatusMap[status];
  }

  return ['', ''];
}

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { MKMPagination } from '../../../@types/common';
import { Notice } from '../../../@types/help';
import { getNotice } from '../../../api/backend/help';
import Accordion from '../../../components/accordion/Accordion';
import Empty from '../../../components/empty/Empty';
import Pagination from '../../../components/pagination/Pagination';
import styles from './styles/HelpNotification.module.scss';
import Loading from '../../../components/loading/Loading';

const Title = ({
  title,
  created,
  isTop = false,
}: {
  title: string;
  created: string;
  isTop?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles['title__container']}>
      {isTop && <div className={styles['top-tag']}>{t('account_help_notification_tag')}</div>}
      <h3 className={styles['title']}>{title}</h3>
      <span className={styles['created']}>{new Date(created).toLocaleDateString()}</span>
    </div>
  );
};

const HelpNotification = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notices, setNotices] = useState<Notice[]>([]);
  const [pagination, setPagination] = useState<MKMPagination>();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1');

  const handleChangePage = (page: number) => {
    setSearchParams(new URLSearchParams({ page: page.toString() }));
    window.location.reload();
  };

  const getNoticeRequest = useCallback(async (page: number) => {
    const { isOk, data } = await getNotice(page);

    if (!isOk) {
      return;
    }

    setPagination(data.pagination);
    setNotices(data.notices);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getNoticeRequest(page);
  }, [page]);

  return (
    <>
      <div className={styles['container']}>
        {isLoading ? (
          <Loading />
        ) : notices.length > 0 ? (
          notices.map(({ title, content, createdAt, isTop }, idx) => (
            <Accordion
              key={`${title}-${idx}`}
              title={<Title title={title} created={createdAt} isTop={isTop} />}
              description={<p className={styles['description']}>{content}</p>}
            />
          ))
        ) : (
          <Empty message={t('account_help_notification_empty')} isProduct={false} />
        )}
      </div>
      <Pagination handleChangePage={handleChangePage} pagination={pagination} />
    </>
  );
};
export default HelpNotification;

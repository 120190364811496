import { CSSProperties } from 'react';
import { Settings } from 'react-slick';

export const TOP_BANNER_COMMON_ARROW_STYLES: CSSProperties = {
  cursor: 'pointer',
  position: 'absolute',
  top: '50%',
  transform: 'translate(0, -50%)',
  zIndex: 10,
  width: '40px',
  height: '40px',
};

export const BANNER_COMMON_SLICK_STYLES: Settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3000,
  swipe: true,
  useTransform: false, // prevent last slide blinking
};

export const BANNER_COMMON_BG_STYLES: CSSProperties = {
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

import styles from './ErrorBox.module.scss';

interface Props {
  children: React.ReactNode;
}

const ErrorBox = ({ children }: Props) => {
  return <div className={styles['container']}>{children}</div>;
};

export default ErrorBox;

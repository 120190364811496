import { ReactElement, ReactNode } from 'react';
import Footer from '../footer/Footer';
import { BaseNavBar } from '../navbar';
import styles from './styles/BaseLayout.module.scss';
import { DesktopNavType } from '../navbar/desktop/DesktopNavBar';

export const MobileNavItem = {
  ONLY_TITLE: 'ONLY_TITLE',
  ONLY_LOGO: 'ONLY_LOGO',
  TITLE_CART: 'TITLE_CART',
  'TITLE_BELL-CART': 'TITLE_BELL-CART',
  TITLE_SHARE: 'TITLE_SHARE',
  SHOW_ALL: 'SHOW_ALL',
  HOME_SEARCH: 'HOME_SEARCH',
  CATEGORY_ALL: 'CATEGORY_ALL',
} as const;

export type MobileNavItemType = typeof MobileNavItem[keyof typeof MobileNavItem];

export type MobileNavType = {
  type: MobileNavItemType;
  title?: string;
};

type CustomActionType = 'onBack' | 'onHome';

export type CustomActions = {
  [key in CustomActionType]?: () => void;
};

interface IBaseLayout {
  children: ReactNode;
  mobileNav: MobileNavType;
  className?: string;
  desktopNav?: DesktopNavType;
  customActions?: CustomActions;
  customChildren?: ReactNode;
  hideFooter?: boolean;
}

const BaseLayout = ({
  children,
  mobileNav,
  className,
  desktopNav,
  customActions,
  customChildren,
  hideFooter = false,
}: IBaseLayout): ReactElement => {
  return (
    <div className={`${styles['div']} ${styles[className ? className : '']}`}>
      <BaseNavBar
        mobileNav={mobileNav}
        desktopNav={desktopNav}
        customActions={customActions}
        customChildren={customChildren}
      />
      {children}
      {!hideFooter && <Footer />}
    </div>
  );
};

export default BaseLayout;

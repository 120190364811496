import { ChangeEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { Customer, CustomerFromMkm } from '../../../@types/customer';
import { deleteProfile, postProfile } from '../../../api/backend/customer';
import { useViewport } from '../../../helper/hooks';
import { customerState } from '../../../stores/customer/atoms';
import BorderSeparator from '../../border/BorderSeparator';
import { Button } from '../../button';
import styles from './UserProfile.module.scss';

type UserProfileType = {
  userImage?: string;
  type: 'ACCOUNT' | 'EDIT';
  userNickname?: string;
};

const UserProfile = ({ userImage, type, userNickname }: UserProfileType): ReactElement => {
  const navigate = useNavigate();
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const editProfileAction = () => {
    navigate('/account/profile/edit', {
      state: { image: userImage },
    });
  };

  const setProfile = useSetRecoilState<(Customer & CustomerFromMkm) | null>(customerState);

  const uploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const formData = new FormData();
    formData.append('image', e.target.files[0]);

    const response = postProfile(formData);
    response.then((res) => {
      if (res.isOk) {
        setProfile((prev) => {
          return {
            ...prev,
            profileImageUrl: res.data.profileImageUrl,
          } as Customer & CustomerFromMkm;
        });
      }
    });
  };

  const removeProfile = async () => {
    const response = deleteProfile();
    response.then((res) => {
      if (res.isOk) {
        setProfile((prev) => {
          return {
            ...prev,
            profileImageUrl: null,
          } as Customer & CustomerFromMkm;
        });
      }
    });
  };

  return (
    <div
      className={`${styles['container']} ${
        styles[`container--${type === 'EDIT' ? 'edit' : 'view'}`]
      }`}
    >
      <div className={styles['user']}>
        <div className={styles['user__image__container']}>
          <img
            className={styles['user__image']}
            src={userImage ? userImage : '/icons/account__profile-default.svg'}
            alt="사용자 프로필 이미지"
          />
          {type === 'EDIT' && userImage && (
            <Button className={styles['user__image__remove']} action={removeProfile}>
              <img src="/icons/account__remove.svg" alt="프로필 삭제" />
            </Button>
          )}
        </div>
        {userNickname && (
          <div className={styles['user__information']}>
            <span className={styles['user__information__nickname']}>{userNickname}</span>
          </div>
        )}
      </div>

      {type === 'ACCOUNT' && (
        <Button className={styles['user__button__edit']} action={editProfileAction}>
          <img src="/icons/account__profile-edit.svg" alt="프로필 편집" />
          <span>{t('account_profile_edit_button')}</span>
        </Button>
      )}

      {type === 'ACCOUNT' && isMobile && <BorderSeparator className={'border-horizon'} />}

      {type === 'EDIT' && (
        <label htmlFor="profileImage">
          <Button className={styles['button--change-profile-photo']}>
            <img src="/icons/account__profile-photo.svg" alt="프로필 사진 바꾸기" />
            <span>{t('account_profile_change_photo')}</span>
            <input id="profileImage" type="file" accept="image/*" onChange={uploadImage} />
          </Button>
        </label>
      )}
    </div>
  );
};

export default UserProfile;

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { auth } from '../../api/backend/auth';
import { customerAccessTokenCreate, getCustomer } from '../../api/storefront/customer';
import { customerState } from '../../stores/customer/atoms';
import ErrorBox from '../error/ErrorBox';
import styles from './LoginContainer.module.scss';
import { FormButton, LoginForm, Radio } from './atoms';

type SelectType = 'login' | 'register';

type UserType = {
  userId: string;
  password: string;
};

const LoginContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const customer = useRecoilValueLoadable(customerState);

  const [isFailedLogin, setIsFailedLogin] = useState<boolean>(false);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<SelectType>('login');
  const [user, setUser] = useState<UserType>({ userId: '', password: '' });

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    setUser((prev) => ({ ...prev, [name]: value }));
  };

  const isCheckedLogin = selected === 'login';

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isFailedLogin) {
      setIsFailedLogin(false);
    }

    const { value } = event.currentTarget;
    setSelected(value as SelectType);
  };

  const login = async () => {
    setLoginLoading(true);

    const { data } = await customerAccessTokenCreate(user.userId, user.password);
    if (!data?.customerAccessTokenCreate.customerAccessToken?.accessToken) {
      setLoginLoading(false);

      setIsFailedLogin(true);
      setTimeout(() => setIsFailedLogin(false), 2000);
      return;
    }

    const accessToken = data?.customerAccessTokenCreate.customerAccessToken?.accessToken;

    localStorage.setItem('access-token', accessToken || '');

    const customer = await getCustomer();

    await auth(
      customer.data.customer.email,
      customer.data.customer.firstName,
      customer.data.customer.lastName,
      customer.data.customer.phone,
      accessToken,
    );

    setLoginLoading(false);
    window.location.href = '/';
  };

  const handleClick = async () => {
    if (!isCheckedLogin) {
      navigate('/register');
      return;
    }

    await login();
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isCheckedLogin) {
      return;
    }

    if (loginLoading) {
      return;
    }

    await login();
  };

  useEffect(() => {
    if (customer.state !== 'hasValue') {
      return;
    }

    if (!customer.contents) {
      return;
    }

    navigate('/');
  }, [customer]);

  const onClickOutside = () => {
    setIsFailedLogin(false);
  };

  return (
    <div className={styles['login-outer']} onClick={onClickOutside}>
      <div className={styles['login-container']}>
        <h2 className={styles['login-title']}>{t('login_title')}</h2>
        <div className={styles['login-inner-container']}>
          <div
            className={`${styles['radio-container--top']} ${
              styles[`radio-container--${!isCheckedLogin && 'checked'}`]
            }`}
          >
            <Radio
              name="register"
              title={t('login_register_radio_title')}
              subtitle={t('login_register_radio_subtitle')}
              checked={!isCheckedLogin}
              handleChange={handleChange}
            />
          </div>
          <div
            className={`${styles['radio-container--middle']} ${
              styles[`radio-container--${isCheckedLogin && 'checked'}`]
            }`}
          >
            <Radio
              name="login"
              title={t('login_signin_radio_title')}
              subtitle={t('login_signin_radio_subtitle')}
              checked={isCheckedLogin}
              handleChange={handleChange}
            />

            {isCheckedLogin && (
              <LoginForm user={user} handleChange={handleChangeInput} handleSubmit={handleSubmit} />
            )}
          </div>

          <div className={styles['radio-container--bottom']}>
            <FormButton
              contents={t('login_form_button')}
              onClick={handleClick}
              disabled={loginLoading}
            />

            <p className={styles['login-policy']}>
              <Trans
                i18nKey="login_policy"
                components={{
                  link1: <Link to={'/account/policy/terms'} title="Terms of service" />,
                  link2: <Link to={'/account/policy/privacy'} title="Private Notice" />,
                }}
              />
            </p>
            <p className={styles['login-help']}>
              <Link to="/password-assist">
                <img src="/icons/login-polygon2.svg" alt="forget password" />
                {t('login_forgot_password')}
              </Link>
            </p>
          </div>
        </div>
        {isFailedLogin && (
          <ErrorBox>
            <Trans
              i18nKey={t('login_error_box')}
              components={{
                b: <b className={styles['text--bold']} />,
              }}
            ></Trans>
          </ErrorBox>
        )}
      </div>
    </div>
  );
};

export default LoginContainer;

import { CartItemsResponse } from '../../@types/response-mkm';
import instance from './instance';

export async function getCart() {
  const response = await instance.get<CartItemsResponse>('/cart');

  return response;
}

export async function postCartItem(
  shopifyId: string,
  origin?: 'curation' | 'promotion' | 'search',
  originId?: number,
) {
  const response = await instance.post(
    '/cart/items',
    JSON.stringify({ shopifyId, origin, originId }),
  );

  return response.data.data;
}

export async function deleteCartItem(cartItemsId: number[]) {
  await instance.delete('/cart/items', { data: { cartItemsId } });
}

export async function updateCartItemStatus(cartItems: { id: number; active: boolean }[]) {
  await instance.patch('/cart/items/active', { cartItems });
}

export async function updateCartItem(id: string, quantity: number) {
  await instance.patch(`/cart/items/${encodeURIComponent(id)}`, { quantity });
}

import styles from './styles/ProductDescription.module.scss';

type ProductDescriptionType = {
  descriptionHtml?: string;
};

const ProductDescription = ({ descriptionHtml }: ProductDescriptionType) => {
  const parsedDescriptionHtml = descriptionHtml?.replaceAll(
    '<p class="hide">',
    `<p class=${styles['p__hide']}>`,
  );

  return (
    <>
      <section className={styles['section']}>
        <h3 className={styles['header']}>Description</h3>

        <div
          className={styles['description']}
          dangerouslySetInnerHTML={{
            __html: parsedDescriptionHtml || '',
          }}
        ></div>

        <div className={styles['shipping']}>
          <div className={styles['shipping__header']}>
            <img src="/images/common/int_shipping.png" />
            <h1>International Shipping</h1>
          </div>
          <div className={styles['shipping__item']}>
            <h3>FREE SHIPPING</h3>
            <h6>if you order more than $99</h6>
          </div>
          <div className={styles['shipping__item']}>
            <h3>DIRECTLY FROM KOREA VIA AIR TRANSPORT</h3>
            <h6>fast and secure air dropshipping</h6>
          </div>
          <div className={styles['shipping__item']}>
            <h3>REAL-TIME TRACKING</h3>
            <h6>from order to delivery</h6>
          </div>
          <div className={styles['shipping__description']}>
            <h6>
              *Among the products you ordered, products that are sold out in Korea may be partially
              canceled. However, if you receive free shipping benefits, no additional shipping fee
              will be charged.
            </h6>
            <ul>
              <li>
                If the order does not exceed the free shipping standard of $99, you will be charged
                $20 for overseas shipping.
              </li>
              <li>
                myKmarket will deliver your order within 5 to 7 business days. (Delivery lead time
                may be delayed under certain circumstances such as bad weather or customs clearance)
              </li>
              <li>
                Customs duties may be imposed for each purchase amount at the time of customs
                clearance, and all customs clearance costs must be paid by the customer. (US Duty
                Free Amount: $800)
              </li>
              <li>
                If the package is delivered to the wrong address due to incorrect customer
                information, myKmarket is not responsible for the lost goods and additional shipping
                charges.
              </li>
              <li>
                If the product is returned or additional charges are incurred due to failure to
                receive it within the period, the customer will have to bear the cost.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDescription;

import { ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../input';
import Label from '../../label/Label';
import styles from './styles/LoginForm.module.scss';

type UserType = {
  userId: string;
  password: string;
};

type LoginFormProps = {
  user: UserType;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const LoginForm = ({ user, handleChange, handleSubmit }: LoginFormProps) => {
  const { t } = useTranslation();

  return (
    <form className={styles['login-form']} onSubmit={handleSubmit}>
      <Label label={t('login_signin_form_email')}>
        <Input
          name="userId"
          type="email"
          placeholder={t('login_signin_form_email_placeholder')}
          defaultValue={user.userId}
          onChange={handleChange}
        />
      </Label>

      <Label label={t('login_signin_form_password')}>
        <Input
          name="password"
          type="password"
          placeholder={t('login_signin_form_password_placeholder')}
          defaultValue={user.password}
          onChange={handleChange}
        />
      </Label>
      <button type="submit"></button>
    </form>
  );
};

export default LoginForm;

import { atom, AtomEffect } from 'recoil';
import { Customer, CustomerFromMkm } from '../../@types/customer';
import { getCustomerFromBackend } from '../../api/backend/customer';
import { getCustomer } from '../../api/storefront/customer';

const initialize: (key: string) => AtomEffect<(Customer & CustomerFromMkm) | null> =
  (key: string) =>
  ({ setSelf, trigger }) => {
    const token = localStorage.getItem(key);
    const tokenFromMkm = localStorage.getItem('access-token-mkm');

    if (!token || !tokenFromMkm) {
      setSelf(null);
      return;
    }

    const loadUser = async () => {
      const response = await getCustomer();
      const responseFromMkm = await getCustomerFromBackend();

      setSelf({
        ...response.data.customer,
        userId: responseFromMkm.id,
        profileImageUrl: responseFromMkm.profileImageUrl,
        points: responseFromMkm.points,
        wishlist: responseFromMkm.wishlist,
        reviews: responseFromMkm.reviews,
        coupons: responseFromMkm.coupons,
      });
    };

    if (trigger === 'get') {
      loadUser();
    }
  };

export const customerState = atom<(Customer & CustomerFromMkm) | null>({
  key: 'CustomerInformation',
  effects: [initialize('access-token')],
});

import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import { ChangeEvent, FormEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getRegisterForInviterName, postRegisterForInvitation } from '../../api/backend/customer';
import { customerCreate } from '../../api/storefront/customer';
import styles from './RegisterContainer.module.scss';
import RegisterForm from './atoms/RegisterForm';
import { modalState } from '../../stores/modal/atoms';
import { useRecoilState } from 'recoil';

export type RegisterUserType = {
  firstName: string;
  lastName: string;
  userId: string;
  password: string;
  passwordConfirm: string;
  phone: string;
};

const RegisterContainer = (): ReactElement => {
  const go = useNavigate();
  const [searchParams] = useSearchParams();
  const [isInvited, setIsInvited] = useState<boolean>(false);
  const [inviterName, setInviterName] = useState<string>('');
  const { t } = useTranslation();
  const [user, setUser] = useState<RegisterUserType>({
    firstName: '',
    lastName: '',
    userId: '',
    password: '',
    passwordConfirm: '',
    phone: '',
  });

  const userKey = {
    userId: { ko: '이메일을', en: 'email' },
    firstName: { ko: '이름을', en: 'first name' },
    lastName: { ko: '성을', en: 'last name' },
    password: { ko: '비밀번호를', en: 'password' },
    passwordConfirm: { ko: '비밀번호를', en: 'password' },
    phone: { ko: '핸드폰 번호를', en: 'phone number' },
  };

  const [countryCode, setCountryCode] = useState<string | CountryCode>('US');
  const [modal, setModal] = useRecoilState(modalState);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    setUser((prev) => ({
      ...prev,
      [name]: event.target.validity.valid ? value : prev[name as keyof RegisterUserType],
    }));
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!user.firstName && !user.lastName) {
      setModal({
        modal: true,
        childrenText: t('register_modal_error_name_empty'),
      });
      return;
    }

    if (user.password !== user.passwordConfirm) {
      setModal({
        modal: true,
        childrenText: t('register_modal_error_password'),
      });
      return;
    }

    const keys = Object.keys(user);
    for (let i = 0; i < keys.length; i++) {
      if (!user[keys[i] as keyof RegisterUserType]) {
        const key = userKey[keys[i] as keyof RegisterUserType];
        const lang = localStorage.getItem('language')?.slice(0, 2);
        setModal({
          modal: true,
          childrenText: t(`register_modal_error_empty`, { key: key[lang as keyof typeof key] }),
        });
        return;
      }
    }

    const phone = parsePhoneNumber(user.phone, countryCode as CountryCode);

    if (!phone) {
      setModal({
        modal: true,
        childrenText: t('register_modal_error_phone_empty'),
      });

      return;
    }

    const response = await customerCreate(
      user.userId,
      user.password,
      user.firstName,
      user.lastName,
      phone.number,
    );

    if (response.data?.customerCreate?.customerUserErrors?.[0]?.code == 'TAKEN') {
      if (response.data?.customerCreate?.customerUserErrors?.[0]?.field[1] == 'email') {
        setModal({
          modal: true,
          childrenText: t('register_modal_error_email_duplicated'),
        });
      }
      if (response.data?.customerCreate?.customerUserErrors?.[0]?.field[1] == 'phone') {
        setModal({
          modal: true,
          childrenText: t('register_modal_error_phone_duplicated'),
        });
      }
      return;
    }
    if (response.data?.customerCreate?.customerUserErrors?.[0]?.code == 'INVALID') {
      if (response.data?.customerCreate?.customerUserErrors?.[0]?.field[1] == 'email') {
        setModal({
          modal: true,
          childrenText: t('register_modal_error_email'),
        });
      }
      if (response.data?.customerCreate?.customerUserErrors?.[0]?.field[1] == 'phone') {
        setModal({
          modal: true,
          childrenText: t('register_modal_error_phone'),
        });
      }
      return;
    }

    if (!response.data?.customerCreate?.customer) {
      // TODO: Handle error
      setModal({
        modal: true,
        childrenText: t('register_modal_error'),
      });

      return;
    }

    setModal({
      modal: true,
      childrenText: t('register_modal'),
    });

    await postRegisterForInvitation({
      email: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
      inviteCode: searchParams.get('invite'),
    });

    go('/login');
  };

  const getUserInviterName = useCallback(async (inviteCode: string) => {
    const res = await getRegisterForInviterName(inviteCode);

    if (!res.data) {
      setModal({
        modal: true,
        childrenText: t('register_modal_error'),
      });

      return;
    }

    setIsInvited(true);
    setInviterName(`${res.data.firstName} ${res.data.lastName}`);
  }, []);

  useEffect(() => {
    if (searchParams.get('invite')) {
      getUserInviterName(searchParams.get('invite') ?? '');
    }
  }, [searchParams]);

  return (
    <div className={styles['register-outer']}>
      <div className={styles['register-container']}>
        <h2 className={styles['register-title']}>{t('register_title')}</h2>
        <p className={styles['register-description']}>{t('register_description')}</p>
        {isInvited && (
          <div className={styles['register-invite-discount-image']}>
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundImage: `url('/images/common/signup__invite-discount.png')`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <span>{t('register_invite', { inviterName: inviterName })}</span>
            </div>
          </div>
        )}
        <RegisterForm
          user={user}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export { RegisterContainer };

import { useTranslation } from 'react-i18next';
import { useViewport } from '../../../helper/hooks';
import styles from './styles/PointState.module.scss';

type PointStateProps = {
  currentPoint: number;
};

const PointState = ({ currentPoint }: PointStateProps) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const conversion = Math.floor(currentPoint / 100);
  return (
    <div className={styles['point']}>
      <div className={styles['point__container']}>
        <div className={styles['point__container--row']}>
          <img src="/icons/account__point.svg" alt="포인트 이미지" />
          <div className={styles['title']}>{t('account_point_state_point')}</div>
        </div>
        <div className={styles['point__amount']}>{currentPoint}</div>
      </div>

      <div>
        <img src="/icons/account__swap-arrow.svg" alt="교환 이미지" />
      </div>

      <div className={styles['point__container']}>
        <div className={styles['title']}>
          {isMobile
            ? t('account_point_state_conversion_mobile')
            : t('account_point_state_conversion')}
        </div>
        <div className={styles['point__container--column']}>
          <div className={styles['point__amount']}>{conversion}</div>
          <div className={styles['point__description']}>{`(${t(
            'account_point_state_description',
          )}${conversion.toFixed(2)})`}</div>
        </div>
      </div>
    </div>
  );
};
export default PointState;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router';
import { useRecoilValueLoadable } from 'recoil';
import { HelpFaq, HelpNotification, HelpPartnership, HelpQna, HelpRequest } from '.';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import Tab from '../../../components/tab/Tab';
import { useViewport } from '../../../helper/hooks';
import { customerState } from '../../../stores/customer/atoms';
import styles from './styles/HelpCenter.module.scss';

const HelpCenter = () => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const customer = useRecoilValueLoadable(customerState);

  const tabs = [
    { key: '', name: isMobile ? t('account_help_faq') : t('account_help_faq_all') },
    { key: 'qna', name: t('account_help_qna'), img: '/icons/help__qna.svg' },
    { key: 'request', name: t('account_help_request'), img: '/icons/help__request.svg' },
    {
      key: 'partnership',
      name: t('account_help_partnership'),
      img: '/icons/help__partnership.svg',
    },
    { key: 'notice', name: t('account_help_notification'), img: '/icons/help__notification.svg' },
  ];

  const detectTab = () => {
    const tab = tabs.map((tab) => tab.key).indexOf(pathname.split('/')[3]);
    if (tab > 0) {
      return tab;
    }
    return 0;
  };

  const [clickedTab, setClickedTab] = useState<number>(detectTab());

  useEffect(() => {
    if (!customer.contents) {
      return;
    }

    setClickedTab(detectTab());
  }, [pathname]);

  return (
    <AccountLayout
      mobileNav={{
        type: MobileNavItem.SHOW_ALL,
        title: !customer.contents ? t('account_help_contact') : t('account_help'),
      }}
      pageTitle={!customer.contents ? t('account_help_contact_page') : t('account_help_page')}
      className="no-padding-bottom"
    >
      <div className={styles['help__bg']}>
        {!!customer.contents && (
          <Tab
            data={tabs}
            clickedTab={clickedTab}
            setClickedTab={setClickedTab}
            align="LEFT"
            setRoute={true}
          />
        )}
        <Routes>
          <Route path="/" element={<HelpFaq />} />
          <Route path="/qna" element={<HelpQna />} />
          <Route path="/request/*" element={<HelpRequest />} />
          <Route path="/partnership" element={<HelpPartnership />} />
          <Route path="/notice" element={<HelpNotification />} />
        </Routes>
      </div>
    </AccountLayout>
  );
};

export default HelpCenter;

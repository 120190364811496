import { ProductType } from '../../@types/product';
import { ProductResponse, WishlistResponse } from '../../@types/response-mkm';
import instance from './instance';

export async function getProductByShopifyId(id: string) {
  const response = await instance.get<ProductResponse>(`/product/${id}`);

  return response.data;
}

export async function postProductInWishlist(product: ProductType) {
  const response = await instance.post('/wish', JSON.stringify({ product }));

  return response.data;
}

export async function deleteProductInWishlist(productId: (number | undefined)[]) {
  const response = await instance.delete(`/wish`, {
    data: { products: productId.filter((id) => !!id).map((id) => ({ id })) },
  });

  return response.data;
}

export async function getProductsInWishlist() {
  const response = await instance.get<WishlistResponse>('/wish');

  return response.data;
}

import { useTranslation } from 'react-i18next';
import styles from './styles/HelpMail.module.scss';

const HelpMail = () => {
  const { t } = useTranslation();
  return (
    <div className={styles['container']}>
      <div className={styles['image']}>
        <img src="/icons/help__mail.svg" alt="email image" />
      </div>
      <div>
        <h3 className={styles['title']}>{t('account_help_faq_mail')}</h3>
        <p className={styles['description']}>help@mykmarket.com</p>
      </div>
    </div>
  );
};

export default HelpMail;

import { FormEventHandler, MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { SearchKeyword } from '../../../@types/search';
import { getPopularSearchKeywords } from '../../../api/backend/search';
import { useViewport } from '../../../helper/hooks';
import useSearchHistories from '../../../hooks/useSearchHistories';
import { SearchProps } from '../SearchBar';
import styles from '../SearchBar.module.scss';
import KeywordsInSearch from './KeywordsInSearch';
import SearchPrediction from './SearchPrediction';

const SearchBarSearching = ({
  searchValue,
  setSearchValue,
  setIsSearching,
  handleChange,
  isFocusing,
  handleFocus,
}: SearchProps) => {
  const go = useNavigate();
  const { t } = useTranslation();
  const { addHistory, removeHistory, clearHistories, histories } = useSearchHistories();

  const [populars, setPopulars] = useState<SearchKeyword[]>([]);
  const { width, isMobile } = useViewport();

  const getPopularSearchKeywordsRequest = async () => {
    const {
      data: { keywords },
    } = await getPopularSearchKeywords();

    setPopulars(keywords);
  };

  const handleClearHistory: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    clearHistories();
  };

  const handleRemoveHistory: MouseEventHandler<HTMLImageElement> = (event) => {
    event.preventDefault();
    const value = event.currentTarget.dataset.name;

    if (!value) {
      return;
    }

    removeHistory(value);
  };

  const handleSearchByKeyword: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();

    const keyword = event.currentTarget.dataset.name;

    if (!keyword) {
      return;
    }

    addHistory(keyword);

    go(`/search?s=${keyword}`);
  };

  const handleClearSearchValue: MouseEventHandler<HTMLImageElement> = (event) => {
    event.preventDefault();
    setSearchValue('');
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (searchValue.length < 1 || !handleFocus) {
      return;
    }

    const keyword = searchValue;

    setSearchValue('');
    setIsSearching(false);
    handleFocus(false);

    addHistory(keyword);

    go(`/search?s=${keyword}`);
  };

  useEffect(() => {
    document.body.style.overflow = isMobile ? 'hidden' : 'unset';

    getPopularSearchKeywordsRequest();

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  if (!handleFocus) return <></>;

  return (
    <div className={`${styles['container']} ${styles['container--searching']}`}>
      <div className={`${styles['search__container']} ${styles['search__container--searching']}`}>
        <div
          className={styles['back']}
          onClick={() => {
            setIsSearching(false);
            handleFocus(true);
          }}
        >
          <img src="/icons/category-nav__chevron-left.svg" alt="뒤로가기" />
        </div>

        <div className={`${styles['search']} ${isFocusing ? styles['search--searching'] : ''}`}>
          <img
            className={styles['search__img']}
            src="/icons/search__question.svg"
            alt="검색 이미지"
          />
          <form onSubmit={handleSubmit}>
            <input
              autoFocus
              className={styles['search__input']}
              placeholder={t('search_bar_basic__placeholder')}
              onFocus={() => {
                setIsSearching(true);
                handleFocus(true);
              }}
              onBlur={() => {
                setIsSearching(false);
                handleFocus(false);
              }}
              value={searchValue}
              onChange={handleChange}
            />
            <img
              className={`${styles['search__clear']} ${
                !isFocusing || !searchValue ? styles['search__clear--hidden'] : ''
              }`}
              onMouseDown={handleClearSearchValue}
              src="/icons/search__clear.svg"
              alt="검색어 지우기"
            />
            <button
              className={`${styles['search__button']}
              ${!isFocusing ? styles['search__clear--hidden'] : ''}
            `}
              type="submit"
              onMouseDown={(event) => {
                event.preventDefault();
              }}
            >
              {t('search_bar_search_submit')}
            </button>
          </form>
        </div>
      </div>

      {isFocusing && (
        <div className={styles['search__keywords']}>
          {searchValue.length > 0 ? (
            <SearchPrediction keyword={searchValue} handleSearchByKeyword={handleSearchByKeyword} />
          ) : (
            (isMobile || isFocusing) && (
              <>
                <KeywordsInSearch
                  keywords={histories}
                  handleClearHistory={handleClearHistory}
                  handleRemoveHistory={handleRemoveHistory}
                  handleSearchByKeyword={handleSearchByKeyword}
                  keywordType="HISTORY"
                />

                <KeywordsInSearch
                  keywords={populars}
                  handleClearHistory={handleClearHistory}
                  handleRemoveHistory={handleRemoveHistory}
                  handleSearchByKeyword={handleSearchByKeyword}
                />
              </>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBarSearching;

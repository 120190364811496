import { MouseEventHandler, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Order } from '../../../@types/customer';
import { useViewport } from '../../../helper/hooks';
import { getCapitalizeLetters } from '../../../helper/letter';
import { useOrderStatus } from '../../../hooks/useOrderStatus';
import getLocaleDate from '../../../utils/getDate';
import { Button } from '../../button';
import OrderItemProduct from './OrderItemProduct';
import styles from './styles/OrderItemDetail.module.scss';

const PaymentElement = ({
  title,
  value,
  isDiscount = false,
  isCancelled,
  isRefundAmount,
}: {
  title: string;
  value: string;
  isDiscount?: boolean;
  isCancelled?: boolean;
  isRefundAmount?: boolean;
}) => {
  return (
    <div
      className={`${styles['payment__container']} ${
        styles[isCancelled ? 'payment__container--CANCELLED' : '']
      } ${styles[isRefundAmount ? 'payment__container--refund' : '']}`}
    >
      <h5>{title}</h5>
      <span>
        {isDiscount && '-'}${value ? Number(value).toFixed(2) : (0.0).toFixed(2)}
      </span>
    </div>
  );
};

const OrderItemDetail = ({ order }: { order: Order }): ReactElement => {
  const go = useNavigate();
  const { t } = useTranslation();
  const { shippingAddress: address } = order;

  const { isMobile } = useViewport();

  const [orderText, orderStatus, completedAt] = useOrderStatus(order.orderNumber);

  const buttonInfo: { [key: string]: { name: string; type: string }[] } = {
    PENDING: [{ name: t('order_detail_bottom_button_pending'), type: 'secondary' }],
    READY: [],
    SHIPPED: [{ name: t('order_detail_bottom_button_shipped'), type: 'primary' }],
    SHIPPING: [],
    CANCELLED: [],
  };
  const orderedItems = order.lineItems.edges.filter((item) => item.node.currentQuantity);
  const cancelledItems = order.lineItems.edges.filter(
    (item) => item.node.quantity > item.node.currentQuantity,
  );

  const isCancelled = orderStatus === 'CANCELLED' || cancelledItems.length > 0;
  const refundPrice = cancelledItems.reduce((a, { node: c }) => {
    return a + Number(c.variant.priceV2.amount) * (c.quantity - c.currentQuantity);
  }, 0);

  const onClickBottomButton = (type: keyof typeof buttonInfo) => {
    if (type === 'PENDING') {
      // TODO: CANCEL ORDER
    } else if (type === 'SHIPPED') {
      go('/account/reviews');
    } else {
      return;
    }
  };

  const onClickTrackingOrder: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    if (order.successfulFulfillments.length < 1) {
      // TODO: Cannot track delivery
      return;
    }

    window.open(order.successfulFulfillments[0].trackingInfo[0].url);
  };

  const onClickChangeAddress = () => {
    go(`/account/orders/delivery-info/${encodeURIComponent(order.id)}`);
  };

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['header']}>
          {/* TODO: Fix order date */}
          <div className={styles['header__title']}>{t('order_detail_title')}</div>
          <div className={styles['header__date']}>
            {t('order_detail_date')} | {getLocaleDate(order.processedAt).slice(2)}
          </div>
          <div className={styles['header__no']}>
            <div className={styles['header__number']}>
              <span>{t('order_detail_order_number')}. </span>
              <span>{order.orderNumber}</span>
            </div>
            {!isMobile && (
              <div className={styles['header__status']}>
                <div className={styles[`header__status--${orderStatus}`]}>{orderText}</div>
              </div>
            )}
          </div>
        </div>

        <main className={styles['main']}>
          <div className={styles['information']}>
            <div className={styles['information__container']}>
              <h3 className={styles['payment__title']}>{t('order_detail_payment_info')}</h3>
              <section className={styles['payment']}>
                <PaymentElement
                  title={t('order_detail_payment_info_tax')}
                  value={order.totalTax.amount}
                  isCancelled={isCancelled}
                />
                <PaymentElement
                  title={t('order_detail_payment_info_shipping')}
                  value={order.totalShippingPrice.amount}
                  isCancelled={isCancelled}
                />
                <PaymentElement
                  title={t('order_detail_payment_total')}
                  value={order.currentSubtotalPrice.amount}
                  isCancelled={isCancelled}
                />
                <PaymentElement
                  title={t('order_detail_payment_discount')}
                  value={order.discountApplications.nodes[0]?.value.amount}
                  isDiscount={true}
                  isCancelled={isCancelled}
                />
                {/* TODO: Check payment tool */}
                <PaymentElement
                  title={t('order_detail_payment_tool')}
                  value={order.totalPrice.amount}
                  isCancelled={isCancelled}
                />
                <PaymentElement
                  title={t('order_detail_payment_total_amount')}
                  value={order.totalPrice.amount}
                  isCancelled={isCancelled}
                />
                {isCancelled && (
                  <div className={styles['payment__cancelled']}>
                    <div className={styles['payment__border']} />
                    <PaymentElement
                      title={t('order_detail_payment_refund')}
                      value={refundPrice.toFixed(2)}
                      isRefundAmount={true}
                    />
                    <PaymentElement
                      title={t('order_detail_payment_net_payment')}
                      value={(Number(order.totalPrice.amount) - refundPrice).toFixed(2)}
                    />
                  </div>
                )}
              </section>
            </div>

            {isMobile && (
              <div className={styles['information__buttons']}>
                {orderStatus === 'PENDING' && (
                  <button
                    className={`${styles['information__button']} ${styles['information__button--secondary']}`}
                    onClick={onClickChangeAddress}
                  >
                    {t('account_order_change_address')}
                  </button>
                )}

                {(orderStatus === 'SHIPPING' || orderStatus === 'SHIPPED') && (
                  <button
                    className={`${styles['information__button']} ${styles['information__button--secondary']}`}
                    onClick={onClickTrackingOrder}
                  >
                    {t('order_detail_delivery_tracking')}
                  </button>
                )}
              </div>
            )}

            <div className={styles['information__container']}>
              {!isMobile && (
                <h3 className={styles['payment__title']}>{t('order_detail_delivery_info')}</h3>
              )}
              <section className={styles['delivery']}>
                <h5 className={styles['delivery__name']}>{`${address && address.name}`}</h5>
                <address className={styles['delivery__address']}>
                  {address &&
                    `${address.address2 ? address.address2 : ''} ${address.address1} ${
                      address.city
                    }, ${address.province}, ${address.country} ${address.zip}`}
                </address>
                <div
                  className={`${styles['delivery__address']} ${styles['delivery__email_phone']}`}
                >
                  <div>{order.email || ''}</div>
                  <div>{order.shippingAddress?.phone || ''}</div>
                </div>
                {/* TODO: Check instruction */}
                {/* {!!order.instruction ? (
                  <div className={styles['delivery__instruction']}>{instruction}</div>
                ) : (
                  <div className={styles['delivery__instruction--none']}>
                    There are no shipping instructions with this order. (Optional)
                  </div>
                )} */}
              </section>
            </div>
          </div>

          <div
            className={`${styles['information__products']} ${
              styles[`information__products--${orderStatus}`]
            }`}
          >
            {orderedItems.length > 0 && (
              <div className={styles['delivery__title']}>{t('order_detail_delivery_title')}</div>
            )}
            {orderStatus !== 'CANCELLED' && (
              <div className={styles['information__arrive']}>
                {orderStatus === 'SHIPPED' ? (
                  <>
                    <h5 className={styles['information__arrive__message']}>
                      {t('order_detail_delivery_shipped')}
                    </h5>
                    <p className={styles['information__arrive__date']}>
                      {t('order_detail_delivery_arrived')} {getLocaleDate(completedAt)}
                    </p>
                  </>
                ) : (
                  <h5
                    className={`${styles['information__arrive__message']} ${
                      styles[`information__arrive__message--${orderStatus}`]
                    }`}
                  >
                    {getCapitalizeLetters(orderStatus)}
                  </h5>
                )}
              </div>
            )}

            {!isMobile && (
              <div className={styles['information__buttons']}>
                {orderStatus === 'PENDING' && (
                  <button
                    className={`${styles['information__button']} ${styles['information__button--secondary']}`}
                    onClick={onClickChangeAddress}
                  >
                    {t('account_order_change_address')}
                  </button>
                )}

                {(orderStatus === 'SHIPPING' || orderStatus === 'SHIPPED') && (
                  <button
                    className={`${styles['information__button']} ${styles['information__button--secondary']}`}
                    onClick={onClickTrackingOrder}
                  >
                    {t('order_detail_delivery_tracking')}
                  </button>
                )}
              </div>
            )}
          </div>

          {orderedItems.map(({ node: product }) => (
            <OrderItemProduct key={product.title} product={product} />
          ))}

          {cancelledItems.length > 0 && (
            <div className={styles[`delivery__cancelled__title`]}>
              {t('order_detail_delivery_cancelled_title')}
            </div>
          )}
          {cancelledItems.map(({ node: product }) => (
            <OrderItemProduct key={product.title} product={product} isCancelled />
          ))}
        </main>
      </div>
      <div
        className={`${styles['button__container']} ${styles[`button__container--${orderStatus}`]}`}
      >
        {orderStatus &&
          buttonInfo[orderStatus].map(({ name, type }) => (
            <Button
              key={name}
              className={`${styles['order__button']} ${styles[`order__button--${type}`]}`}
              action={() => onClickBottomButton(orderStatus)}
            >
              {name}
            </Button>
          ))}
      </div>
    </>
  );
};

export default OrderItemDetail;

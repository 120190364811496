import { gql, useQuery } from '@apollo/client';
import {
  CSSProperties,
  ReactElement,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { CurationModuleSortingType } from '../@types/curation';
import { WishProductType } from '../@types/product';
import { ProductByCurationResponse } from '../@types/response';
import { SearchKeyword } from '../@types/search';
import { getPopularSearchKeywords } from '../api/backend/search';
import { postStatisticsSearch } from '../api/backend/statistics';
import { SORT_KEY_MAP, getFilterQuery } from '../api/storefront/products';
import { CategoryPath, CategoryProducts } from '../components/category';
import { useDrawer } from '../components/drawer/Drawer';
import Empty from '../components/empty/Empty';
import { NumberInput } from '../components/input';
import { MainLayout } from '../components/layout';
import Loading from '../components/loading/Loading';
import SearchBar from '../components/search/SearchBar';
import Select from '../components/select/Select';
import { useResponsiveElement } from '../helper/hooks';
import { getCapitalizeLetters } from '../helper/letter';
import { useEffectOnce } from '../hooks/useEffectOnce';
import useSearchHistories from '../hooks/useSearchHistories';
import { statisticsClickCounts } from '../utils/statistics/statisticsClickCounts';
import styles from './styles/Search.module.scss';
import { useRecoilValueLoadable } from 'recoil';
import { customerState } from '../stores/customer/atoms';

const GET_PRODUCTS_BY_KEYWORD = gql`
  query getProductByCuration(
    $first: Int!
    $sortKey: ProductSortKeys!
    $reverse: Boolean!
    $query: String
    $after: String
  ) {
    products(first: $first, sortKey: $sortKey, reverse: $reverse, query: $query, after: $after) {
      edges {
        cursor
        node {
          id
          title
          description
          tags
          createdAt
          updatedAt
          publishedAt
          handle
          availableForSale
          metafield(namespace: "custom", key: "review_rating") {
            value
          }
          featuredImage {
            url
            altText
          }
          priceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          totalInventory
          vendor
          productType

          collections(first: 10) {
            edges {
              node {
                title
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const Search = () => {
  const go = useNavigate();
  const [searchParams] = useSearchParams();
  const { addHistory } = useSearchHistories();
  const { t } = useTranslation();
  const customer = useRecoilValueLoadable(customerState);

  const searchSortKeyMap = {
    BEST_SELLING: t('search_filter_best_selling'),
    NEWEST: t('search_filter_newest'),
    PRICE_LOW_TO_HIGH: t('search_filter_price_low_to_high'),
    PRICE_HIGH_TO_LOW: t('search_filter_price_high_to_low'),
  };

  const [products, setProducts] = useState<WishProductType[]>([]);
  const [sort, setSort] = useState<string>('BEST_SELLING');
  const [populars, setPopulars] = useState<SearchKeyword[]>([]);

  const [after, setAfter] = useState<string | null>(null);
  const first = 12;

  const keyword = searchParams.get('s') || '';
  const tag = searchParams.get('t') || '';

  const queryKeyword = useMemo(() => {
    if (keyword) {
      return `${getFilterQuery({ value1: `${keyword}*` })['Keyword']} OR ${
        getFilterQuery({ value1: `*${keyword}*` })['Tag']
      }`;
    }

    if (tag) {
      return getFilterQuery({ value1: `*${tag}*` })['Tag'];
    }
  }, [keyword, tag]);

  const intersectionBar = useRef<HTMLDivElement | null>(null);

  const { loading, data, fetchMore } = useQuery<ProductByCurationResponse>(
    GET_PRODUCTS_BY_KEYWORD,
    {
      variables: {
        first,
        query: queryKeyword,
        sortKey: SORT_KEY_MAP[sort as CurationModuleSortingType],
        reverse: sort === 'PRICE_HIGH_TO_LOW' || sort === 'NEWEST',
      },
    },
  );

  const handleFetchMore = (after: string | null) => {
    if (!after) {
      return;
    }

    fetchMore({
      variables: {
        first,
        query: queryKeyword,
        after,
        sortKey: SORT_KEY_MAP[sort as CurationModuleSortingType],
        reverse: sort === 'PRICE_HIGH_TO_LOW' || sort === 'NEWEST',
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (previousResult.products) {
          return {
            products: {
              edges: [...previousResult.products.edges, ...fetchMoreResult.products.edges],
              pageInfo: fetchMoreResult.products.pageInfo,
            },
          };
        } else {
          return {
            products: {
              edges: [...fetchMoreResult.products.edges],
              pageInfo: fetchMoreResult.products.pageInfo,
            },
          };
        }
      },
    });
  };

  const intersectionCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting) {
        handleFetchMore(after);
      }
    },
    [after],
  );

  const getPopularSearchKeywordsRequest = async () => {
    const {
      data: { keywords },
    } = await getPopularSearchKeywords();

    setPopulars(keywords);
  };

  const handleClickPopularSearchKeyword = (keyword: string) => {
    addHistory(keyword);

    go(`/search?s=${keyword}`);
  };

  useEffect(() => {
    getPopularSearchKeywordsRequest();
  }, []);

  useEffect(() => {
    if (!intersectionBar.current) {
      return;
    }

    const observer = new IntersectionObserver(intersectionCallback, {
      rootMargin: '10px',
      threshold: 0.5,
    });

    observer.observe(intersectionBar.current);

    return () => observer.disconnect();
  }, [intersectionBar.current, intersectionCallback]);

  useEffect(() => {
    if (loading || !data) {
      return;
    }

    const { edges, pageInfo } = data.products;
    setProducts(edges.map(({ node }) => node as WishProductType));

    if (!pageInfo.hasNextPage) {
      setAfter(null);
      return;
    }

    setAfter(edges[edges.length - 1].cursor);
  }, [loading, data]);

  const [originId, setOriginId] = useState<number | null>(null);

  const getSearchOriginId = useCallback(async () => {
    if (!!keyword) {
      const {
        data: {
          search: { id },
        },
      } = await postStatisticsSearch({
        keyword,
      });
      setOriginId(id);
    }
  }, [keyword]);

  useEffectOnce(() => {
    getSearchOriginId();
  });

  const { open, close, isOpened, render } = useDrawer();

  const onClickMobileFilter = (sortType: CurationModuleSortingType) => {
    setAfter(null);
    setSort(sortType);
    close();
  };
  const onClickEmpty = () => {
    if (customer.contents) {
      go('/account/help-center/request');
      return;
    }
    go('/login');
  };

  const mobileFilterModal = render(
    <>
      <Select.Option
        label={t('search_filter_best_selling')}
        value="BEST_SELLING"
        isSelected={sort === 'BEST_SELLING'}
        onClick={() => onClickMobileFilter('BEST_SELLING')}
      />
      <Select.Option
        label={t('search_filter_newest')}
        value="NEWEST"
        isSelected={sort === 'NEWEST'}
        onClick={() => onClickMobileFilter('NEWEST')}
      />
      <Select.Option
        label={t('search_filter_price_low_to_high')}
        value="PRICE_LOW_TO_HIGH"
        isSelected={sort === 'PRICE_LOW_TO_HIGH'}
        onClick={() => onClickMobileFilter('PRICE_LOW_TO_HIGH')}
      />
      <Select.Option
        label={t('search_filter_price_high_to_low')}
        value="PRICE_HIGH_TO_LOW"
        isSelected={sort === 'PRICE_HIGH_TO_LOW'}
        onClick={() => onClickMobileFilter('PRICE_HIGH_TO_LOW')}
      />
    </>,
  );

  const element = useResponsiveElement({
    MOBILE: (
      <div className={styles['mobile_container']}>
        <SearchBar hasBack={true} />
        <div className={styles['popular']}>
          <h3 className={styles['popular__title']}>{t('search_popular')}</h3>
          <div className={styles['popular__items']}>
            {populars.map((popular) => (
              <div
                key={popular.id}
                className={styles['popular__item']}
                onClick={() => handleClickPopularSearchKeyword(popular.keyword)}
              >
                {popular.keyword}
              </div>
            ))}
          </div>
        </div>

        <div className={styles['result']}>
          <div className={styles['result__header']}>
            <div className={styles['result__header__count']}>
              <Trans
                i18nKey={'search_found'}
                components={{ span: <span /> }}
                values={{ items: products.length }}
              />
            </div>
            <img src="/icons/search__filter.svg" alt="검색 결과 필터링" onClick={open} />
          </div>

          {loading ? (
            <Loading />
          ) : products.length > 0 ? (
            <section className={styles['container']}>
              <ul className={styles['items']}>
                <div className={styles['product-container']}>
                  {products.map((item, idx) => (
                    <ProductItem
                      key={`${encodeURIComponent(item.id)}--${idx}`}
                      item={item}
                      originId={originId!}
                    />
                  ))}
                </div>
              </ul>
            </section>
          ) : (
            <div className={styles['empty_container']}>
              <Empty
                message={t('search_empty')}
                buttonMessage={t('search_empty_button')}
                onClickEmpty={onClickEmpty}
              />
            </div>
          )}
          {isOpened && mobileFilterModal}
        </div>
      </div>
    ),
    DESKTOP: (
      <MainLayout>
        <div className={styles['container']}>
          <div className={styles['popular']}>
            <div>
              <h3 className={styles['popular__title']}>{t('search_popular')}</h3>
              <div className={styles['popular__items']}>
                {populars.map((popular) => (
                  <div
                    key={popular.id}
                    className={styles['popular__item']}
                    onClick={() => handleClickPopularSearchKeyword(popular.keyword)}
                  >
                    {popular.keyword}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles['products']}>
            <div className={styles['select__container']}>
              <Select
                value={sort}
                setValue={setSort}
                placeholder={searchSortKeyMap[sort as CurationModuleSortingType]}
                onSelectClick={() => {
                  setAfter(null);
                }}
              >
                <Select.Option
                  label={t('search_filter_best_selling')}
                  value="BEST_SELLING"
                  isSelected={sort === 'BEST_SELLING'}
                />
                <Select.Option
                  label={t('search_filter_newest')}
                  value="NEWEST"
                  isSelected={sort === 'NEWEST'}
                  onClick={() => onClickMobileFilter('NEWEST')}
                />
                <Select.Option
                  label={t('search_filter_price_low_to_high')}
                  value="PRICE_LOW_TO_HIGH"
                  isSelected={sort === 'PRICE_LOW_TO_HIGH'}
                />
                <Select.Option
                  label={t('search_filter_price_high_to_low')}
                  value="PRICE_HIGH_TO_LOW"
                  isSelected={sort === 'PRICE_HIGH_TO_LOW'}
                />
              </Select>
            </div>

            {loading ? (
              <Loading />
            ) : (
              <CategoryProducts
                products={products}
                categoryPath={<CategoryPath category="All Results" />}
                originId={originId!}
              />
            )}
          </div>
        </div>
      </MainLayout>
    ),
  });

  return (
    <>
      {element}
      <div ref={intersectionBar} style={{ height: '0' }}></div>
    </>
  );
};

export default Search;

const ProductItem = memo(
  ({ item, originId }: { item: WishProductType; originId?: number }): ReactElement => {
    const customStyles: CSSProperties = {
      position: 'unset',
    };

    const navigate = useNavigate();
    const onNavigateProductDetail = (id: number | string) => {
      if (originId) {
        statisticsClickCounts({
          product: item,
          originId,
        });
      }
      navigate(`/product/${encodeURIComponent(id)}`);
    };

    const price = Number(item.priceRange.maxVariantPrice.amount) || 0;
    const originalPrice = Number(item.compareAtPriceRange?.maxVariantPrice.amount) || 0;
    const isDiscount = originalPrice > price;
    const discountRatio = Math.floor(((originalPrice - price) / originalPrice) * 100);

    const flags = item.collections
      ? [
          ...item.collections.edges
            .map(({ node }) => node.title)
            .filter((title) => title.toUpperCase() === 'BEST' || title.toUpperCase() === 'NEW'),
        ]
      : [];

    return (
      <li className={styles['item']}>
        <div className={styles['thumnail']} onClick={() => onNavigateProductDetail(item.id)}>
          <img src={item.featuredImage?.url} alt="상품 썸네일" />
          {item.totalInventory === 0 && <div className={styles['thumnail__soldout']}>Sold Out</div>}

          <div className={`${styles['product-flag']}`}>
            {flags.map((flag, idx) => (
              <div
                className={`${styles['flag']} ${styles[flag.toLowerCase()]}`}
                key={`${flag}--${idx}`}
              >
                <span>{getCapitalizeLetters(flag)}</span>
              </div>
            ))}
            {isDiscount && (
              <div className={`${styles['flag']}`}>
                <span>-{discountRatio}%</span>
              </div>
            )}
          </div>
        </div>

        <div className={styles['description']}>
          <p
            className={styles['description__name']}
            onClick={() => onNavigateProductDetail(item.id)}
          >
            {item.title}
          </p>
          <div className={styles['description__price']}>
            {isDiscount ? (
              <div>
                <span className={styles['current-price']}>${price}</span>
                <small className={styles['description__price__original']}>${originalPrice}</small>
              </div>
            ) : (
              <span className={styles['current-price']}>${price}</span>
            )}
          </div>

          <div className={styles['item__control']}>
            <NumberInput
              item={item}
              origin="search"
              originId={originId}
              customCoverStyles={customStyles}
              customDetailStyles={customStyles}
            />
          </div>
        </div>
      </li>
    );
  },
);

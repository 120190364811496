import {
  FaqResponse,
  NoticeResponse,
  ResponseMkm,
  RequestDetailResponse,
  ReqeustsResponse,
} from '../../@types/response-mkm';
import instance from './instance';

export async function getFaqs() {
  const response = await instance.get<FaqResponse>('/faq');

  return response.data;
}

export async function getNotice(page: number) {
  const response = await instance.get<NoticeResponse>('/notice', { params: { page } });

  return response.data;
}

export async function createRequest(body: FormData) {
  const response = await instance.post<ResponseMkm<null>>('/suggestion', body);

  return response.data;
}

export async function getRequests(page: number) {
  const response = await instance.get<ReqeustsResponse>('/suggestion', { params: { page } });

  return response.data;
}

export async function getRequestById(suggestionId: string) {
  const response = await instance.get<RequestDetailResponse>(`/suggestion/${suggestionId}`);

  return response.data;
}

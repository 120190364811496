import { FormEventHandler, ReactElement, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { postCouponByPoint } from '../../api/backend/coupon';

import { useTranslation } from 'react-i18next';
import { PointDescription, PointRedeem, PointState } from '../../components/account/my-point';
import { AccountLayout } from '../../components/layout';
import { MobileNavItem } from '../../components/layout/BaseLayout';
import { customerState } from '../../stores/customer/atoms';
import styles from './styles/MyPoints.module.scss';
import { modalState } from '../../stores/modal/atoms';

const MyPoints = (): ReactElement => {
  const { t } = useTranslation();
  const customer = useRecoilValueLoadable(customerState);
  const [currentPoint, setCurrentPoint] = useState<number>(0);

  const [point, setPoint] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modal, setModal] = useRecoilState(modalState);

  const handleSubmitRedeemPoint: FormEventHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);

      const response = await postCouponByPoint(point);
      setIsLoading(false);

      if (response.data.isOk) {
        setModal({
          modal: true,
          headerText: t('account_point_redeem_modal_title'),
          childrenText: t('account_point_redeem_modal_description'),
          buttons: [
            {
              text: t('account_point_redeem_modal_button'),
              action: () => {
                window.location.reload();
              },
            },
          ],
        });

        return;
      }

      // FIXME: alert message
      alert('Bad Request');
    },
    [point],
  );
  useEffect(() => {
    if (!customer.contents || customer.state !== 'hasValue') {
      return;
    }

    setCurrentPoint(customer.contents.points);
  }, [customer]);

  return (
    <AccountLayout
      mobileNav={{
        type: MobileNavItem.SHOW_ALL,
        title: t('account_point_title'),
      }}
      pageTitle={t('account_point_page_title')}
      className="no-padding-bottom"
    >
      <div className={styles['container__bg']}>
        <PointState currentPoint={currentPoint} />

        <PointRedeem
          currentPoint={currentPoint}
          point={point}
          setPoint={setPoint}
          handleSubmit={handleSubmitRedeemPoint}
          isLoading={isLoading}
        />

        <PointDescription />
      </div>
    </AccountLayout>
  );
};

export default MyPoints;

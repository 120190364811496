import styled from'./styles/Policy.module.scss';
export const EnRefund = () => {
  return (
    <div className={styled['rte']}>
      <p>
        <strong>Cancellation &amp; Refund Policy</strong>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <ol>
        <li>
          <span>1. Cancellation &amp; Refund Procedure</span>
          <ul>
            <li>
              <span>
                Please apply through the customer center email (help@mykmarket.com) or the chatbot
                on the website for cancellation of the order.
              </span>
            </li>
            <li>
              <span>
                If the order has not been processed in Korea, we will refund the full amount of the
                cancellation without any additional costs.
              </span>
            </li>
            <li>
              <span>
                If the requested order is processed in Korea, it will be processed as a return.
              </span>
            </li>
            <li>
              <span>
                Among the products you ordered, products that are sold out in Korea may be partially
                canceled. However, if you receive free shipping benefits, no additional shipping fee
                will be charged.
              </span>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>1. Return &amp; Exchange Policy</strong>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <ol>
        <li>
          <span>2. Return Process</span>
          <ul>
            <li>
              <span>You can return the product up to 7 days after receiving the product.</span>
            </li>
            <li>
              You must request a return through the{' '}
              <span>customer center email (help@mykmarket.com) or the chatbot on the website </span>
              within 7 days of receiving the product.
            </li>
            <li>
              You must contact the person in charge in advance and proceed with the return, and if
              the product is sent arbitrarily without contacting the person in charge of myKmarket,
              myKmarket is not responsible for loss of the product or shipping costs.
            </li>
          </ul>
        </li>
        <li>
          Returning Cost &amp; Penalties
          <ul>
            <li>
              <span>
                In the case of the seller's negligence (misdelivery, defective products, damaged
                goods, etc.), the cost will be returned free of charge to the customer.
              </span>
            </li>
            <li>
              <span>
                In the case of the buyer's negligence (simple change of mind, impulse purchase, loss
                of necessity, etc.), the return fee must be paid; $20 for overseas shipping and 20%
                for the product you return as for restocking Fee.
              </span>
            </li>
            <li>
              <span>
                The cost and penalties can be deducted from the refund amount or we can request
                additional payment.
              </span>
            </li>
            <li>
              <span>The refund amount may vary depending on the product you purchased.</span>
            </li>
            <li>
              <span>
                The delivery fee from the customer to the refund center must be paid in advance by
                the customer.
              </span>
            </li>
          </ul>
        </li>
        <li>
          3. Return Procedure
          <ul>
            <li>
              The return procedure should be carried out in the following procedure, and myKmarket
              is not responsible for damages caused by not following procedures such as loss of
              products sent randomly.
            </li>
          </ul>
        </li>
      </ol>
      <p>
        <u>In the case of the seller’s negligence</u>
      </p>
      <ul>
        <li>
          Communicate your intention to return via{' '}
          <span>customer center email (help@mykmarket.com) or the chatbot on the website.</span>
        </li>
        <li>The customer center will determine the fault and processes a refund</li>
      </ul>
      <p>
        <u>In the case of the buyer’s negligence</u>
      </p>
      <ul>
        <li>
          Communicate your intention to return via{' '}
          <span>customer center email (help@mykmarket.com) or the chatbot on the website.</span>
        </li>
        <li>
          The customer center will determine the fault and will request customers to send the
          product to the local return center.
        </li>
        <li>
          <span>
            Ship product to the local return center (Return center address: Kay Park, 122 John St,
            Hackensack, NJ HACKENSACK New Jersey 07601 U.S.A.)
          </span>
        </li>
        <li>
          <span>
            After the product is inspected at the local return center, myKmarket will either charge
            the customer for a return fee or deduct from the refund amount
          </span>
        </li>
      </ul>
      <ol>
        <li>
          1. Unacceptable returns
          <ul>
            <li>If it exceeds 7 days after receiving the product</li>
            <li>
              <span>
                In the case where the product is lost or damaged due to the customer's carelessness
                or use
              </span>
            </li>
            <li>
              <span>
                If the product TAG is removed or the brand box is lost or damaged (except if the
                packaging, etc. is damaged to check the contents of the product)
              </span>
            </li>
            <li>
              If the value of the product is significantly reduced due to the use or partial
              consumption of the product (e.g., accessories scratches/fragrances, cosmetics use,
              etc.).
            </li>
            <li>
              In a case where the value of a product has decreased significantly to the point where
              resale is difficult over time.
            </li>
            <li>
              <span>
                In the case where the packaging of products that can be reproduced is damaged,
              </span>
            </li>
            <li>
              <span>
                In the case of failure to receive or delay in delivery due to natural disasters,
                extreme weather conditions, and irresistible circumstances.{' '}
              </span>
            </li>
          </ul>
        </li>
        <li>
          2. Exchange Process
          <ul>
            <li>Due to the nature of overseas delivery products, exchanges are not available.</li>
            <li>
              <span>
                Customers who want to exchange products can proceed and follow the return procedure.{' '}
              </span>
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export const KoRefund = () => {
  return (
    <div className={styled['rte']}>
      <p>
        <strong>취소 및 환불 정책</strong>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <ol>
        <li>
          <span>취소 및 환불 절차</span>
          <ul>
            <li>
              <span>
                주문취소는 고객센터 이메일(help@mykmarket.com) 또는 홈페이지 챗봇을 통해
                신청해주세요.
              </span>
            </li>
            <li>
              <span>
                한국에서 주문이 처리되지 않은 경우 추가 비용 없이 취소 금액 전액을 환불해 드립니다.
              </span>
            </li>
            <li>
              <span>요청하신 주문이 한국에서 처리되면 반품으로 처리됩니다.</span>
            </li>
            <li>
              <span>
                주문하신 상품 중 국내 품절 상품의 경우 일부 취소가 될 수 있습니다. 단, 무료배송
                혜택을 받으시면 추가 배송비가 부과되지 않습니다.
              </span>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>반품 및 교환 정책</strong>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <ol>
        <li>
          <span>반품 절차</span>
          <ul>
            <li>
              <span>상품 수령 후 7일 이내 반품 가능합니다.</span>
            </li>
            <li>
              상품을 받으신 날로부터 7일 이내에
              <span>고객센터 이메일(help@mykmarket.com) 또는 홈페이지 챗봇을</span>
              통해 반품을 요청하셔야 합니다 .
            </li>
            <li>
              사전에 담당자에게 연락하여 반품을 진행하셔야 하며, myKmarket 담당자와의 연락 없이
              임의로 상품을 보내신 경우 상품 분실이나 배송비는 myKmarket에서 책임지지 않습니다.
            </li>
          </ul>
        </li>
        <li>
          반품 비용 및 위약금
          <ul>
            <li>
              <span>
                판매자의 과실(오배송, 상품불량, 상품훼손 등)의 경우에는 그 비용을 고객에게 무상으로
                돌려드립니다.
              </span>
            </li>
            <li>
              <span>
                구매자 과실(단순 변심, 충동구매, 부주의 등)의 경우에는 왕복 배송비를 부담하셔야
                합니다. 재입고 수수료는 해외배송비 $20, 반품시 20%입니다.
              </span>
            </li>
            <li>
              <span>환불 금액에서 비용과 위약금을 공제하거나 추가 지불을 요청할 수 있습니다.</span>
            </li>
            <li>
              <span>구매하신 상품에 따라 환불금액이 상이할 수 있습니다.</span>
            </li>
            <li>
              <span>고객으로부터 환불센터까지의 배송비는 고객이 선불로 결제하셔야 합니다.</span>
            </li>
          </ul>
        </li>
        <li>
          반품 절차
          <ul>
            <li>
              반품 절차는 다음 절차에 따라 진행되어야 하며, 임의로 발송된 상품의 분실 등 절차를
              따르지 않아 발생한 피해에 대해서는 myKmarket에서 책임지지 않습니다.
            </li>
          </ul>
        </li>
      </ol>
      <p>
        <u>판매자의 과실이 있는 경우</u>
      </p>
      <ul>
        <li>
          <span>고객센터 이메일(help@mykmarket.com) 또는 웹사이트의 챗봇을</span>
          통해 재방문 의사를 전달하세요 .<span></span>
        </li>
        <li>고객센터에서 불량여부 판단 후 환불 처리</li>
      </ul>
      <p>
        <u>구매자 과실인 경우</u>
      </p>
      <ul>
        <li>
          <span>고객센터 이메일(help@mykmarket.com) 또는 웹사이트의 챗봇을</span>
          통해 재방문 의사를 전달하세요 .<span></span>
        </li>
        <li>
          고객센터에서 불량 여부를 판단하고 고객에게 해당 지역 반품센터로 제품을 보내달라고 요청할
          것입니다.
        </li>
        <li>
          <span>
            현지 반품 센터로 제품 배송(반품 센터 주소: Kay Park, 122 John St, Hackensack, NJ
            HACKENSACK New Jersey 07601 USA)
          </span>
        </li>
        <li>
          <span>
            현지 반품 센터에서 상품 검수 후 myKmarket에서 고객에게 반품 수수료를 청구하거나 환불
            금액에서 차감합니다.
          </span>
        </li>
      </ul>
      <ol>
        <li>
          허용되지 않는 반품
          <ul>
            <li>상품수령 후 7일이 경과한 경우</li>
            <li>
              <span>고객님의 부주의나 사용으로 인해 상품이 분실 또는 훼손된 경우</span>
            </li>
            <li>
              <span>
                상품 TAG가 제거되었거나 브랜드 박스가 분실, 훼손된 경우(단, 상품의 내용을 확인하기
                위하여 포장 등을 훼손한 경우는 제외)
              </span>
            </li>
            <li>
              상품의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우(예: 악세서리
              스크래치/향기, 화장품 사용 등)
            </li>
            <li>시간의 경과에 의하여 재판매가 곤란할 정도로 상품의 가치가 현저히 감소한 경우</li>
            <li>
              <span>복제가 가능한 상품의 포장을 훼손한 경우</span>
            </li>
            <li>
              <span>
                천재지변, 기상악화, 불가항력적인 사유로 인하여 수령하지 못하거나 배송이 지연된 경우.
              </span>
            </li>
          </ul>
        </li>
        <li>
          교환 프로세스
          <ul>
            <li>해외배송 상품 특성상 교환이 불가합니다.</li>
            <li>
              <span>교환을 원하시는 고객님께서는 반품 절차를 진행하신 후 진행하시면 됩니다.</span>
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};


import { useTranslation } from 'react-i18next';
import Button from './Button';
import FixedBottomButtonContainer from './FixedBottomButtonContainer';
import styles from './styles/OrderButton.module.scss';
import useCart from '../../hooks/useCart';

const OrderButton = ({
  type,
  text,
  action,
  shippingRate = 0,
  isCheckoutLoading,
}: {
  type: 'Checkout' | 'Purchase';
  text: string;
  action: () => void;
  shippingRate?: number;
  isCheckoutLoading?: boolean;
}) => {
  const cart = useCart();
  const totalPrice = cart.getTotalPriceActive;
  const { t } = useTranslation();

  return (
    <FixedBottomButtonContainer>
      <Button
        className={styles['order-button-container']}
        action={action}
        disabled={isCheckoutLoading}
      >
        <div>
          <div className={styles['price-container']}>
            <span>
              $
              {totalPrice == 0
                ? '0.00'
                : shippingRate > 0
                ? (totalPrice + shippingRate).toFixed(2)
                : totalPrice.toFixed(2)}
            </span>
          </div>
          <span>{text}</span>
        </div>
      </Button>
      {/* {type === 'Checkout' && totalPrice < PRICE_FOR_FREE_SHIPPING ? (
        <>
          <div className={styles['guide-message-container']}>
            <p>
            {t('checkout_guide_a')}<b>${Number((PRICE_FOR_FREE_SHIPPING - totalPrice).toFixed(2))}</b>{t('checkout_guide_b')}
            </p>
          </div>
          <div className={styles['triangle']}></div>
        </>
      ) : (
        <></>
      )} */}
    </FixedBottomButtonContainer>
  );
};

export default OrderButton;

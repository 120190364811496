import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, ko } from './lang';

const userLanguage = localStorage.getItem('language') ?? window.navigator.language.slice(0, 2);

// the translations
const resources = {
  ko: {
    translation: ko,
  },
  en: {
    translation: en,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: userLanguage || 'ko',
  fallbackLng: 'ko',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;

import { Fragment, ReactElement, useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { getUsersInviteUrl } from '../../../api/backend/customer';
import useCopyClipboard from '../../../hooks/useCopyClipboard';
import styles from './styles/InvitationsModal.module.scss';

type InvitationsModalProps = {
  close: () => void;
};

const InvitationsModal = ({ close }: InvitationsModalProps): ReactElement => {
  const { t } = useTranslation();
  const [, onCopy] = useCopyClipboard();

  // FIXME: Need to get language setting
  // const [selectedLang, setSelectedLang] = useState<string>('English');
  const [sharedLinkWithHash, setSharedLinkWithHash] = useState<string>('');

  // const langs = ['English', '한국어', '中國語', '日本語', 'Español', 'Tiếng Việt'];

  const links = {
    'Copy link': '/icons/invitations__link.svg',
    Facebook: '/icons/invitations__facebook.svg',
    Email: '/icons/invitations__email.svg',
    Share: '/icons/invitations__share.svg',
  };

  // const handleClickLang: MouseEventHandler<HTMLDivElement> = (event) => {
  //   const { lang } = event.currentTarget.dataset;

  //   setSelectedLang(lang ?? 'English');
  // };

  const getSharedLink = useCallback(async () => {
    const {
      isOk,
      data: { url },
    } = await getUsersInviteUrl();

    if (!isOk) return;

    setSharedLinkWithHash(url);
  }, []);

  // const renderLangs = () =>
  //   langs.map((lang) => (
  //     <div
  //       key={lang}
  //       className={`${styles['modal__langs']} ${
  //         selectedLang === lang ? `${styles['modal__langs--selected']}` : ''
  //       }`}
  //       data-lang={lang}
  //       onClick={handleClickLang}
  //     >
  //       {lang}
  //     </div>
  //   ));

  const onLink = useCallback(
    async (text: keyof typeof links) => {
      switch (text) {
        case 'Copy link':
          onCopy(sharedLinkWithHash);
          break;
        case 'Facebook':
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${sharedLinkWithHash}`);
          break;
        case 'Email':
          if (isMobile) {
            window.location.href = `mailto:?body=${sharedLinkWithHash}`;
          } else {
            window.open(`mailto:?body=${sharedLinkWithHash}`);
          }
          break;
        // case 'Get Image':
        //   console.log('get image clicked');
        //   break;
        case 'Share':
          if (window.navigator.share) {
            await window.navigator
              .share({
                title: 'WebShare on Mobile',
                url: sharedLinkWithHash,
              })
              .then(() => {
                console.log('Thanks for sharing');
              })
              .catch(console.error);
          } else {
            alert('공유하기를 지원하지 않는 환경입니다.');
          }
          break;
      }
    },
    [sharedLinkWithHash],
  );

  const renderLinks = () =>
    Object.entries(links).map(([text, imageUrl]) =>
      text === 'Share' && (!isMobile || !window.navigator.share || !window.navigator.clipboard) ? (
        <Fragment key="empty"></Fragment>
      ) : (
        <div
          key={imageUrl}
          className={styles['link__container']}
          onClick={() => onLink(text as keyof typeof links)}
        >
          <img className={styles['link__image']} src={imageUrl} alt={text} />
          <div className={styles['link__description']}>{text}</div>
        </div>
      ),
    );

  useEffect(() => {
    getSharedLink();
  }, []);

  return (
    <>
      <div className={styles['modal__header']}>
        <h2>Share</h2>
        <img src="/icons/invitations__x.svg" alt="close" onClick={() => close()} />
      </div>
      <div className={styles['modal__description']}>
        <img className={styles['modal__logo']} src="/icons/invitations__logo-main.png" alt="logo" />
        <h3 className={styles['modal__text']}>{t('account_invite_modal_title')}</h3>
        <p className={styles['modal__subtext']}>{t('account_invite_modal_description')}</p>
      </div>

      <div className={styles['modal__langs__container']}>{/* {renderLangs()} */}</div>

      <div className={styles['modal__links__container']}>
        <div className={styles['modal__message']}>
          <Trans
            i18nKey={'account_invite_modal_bottom_description'}
            components={{ span: <span /> }}
          />
          {/* Gifts <span>$10</span> to a friend, gets <span>$10</span> Rewards */}
        </div>
        <div className={styles['modal__links']}>{renderLinks()}</div>
      </div>
    </>
  );
};

export default InvitationsModal;

import { CountryCode } from 'libphonenumber-js';
import {
  CSSProperties,
  Dispatch,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useClickOutside } from '../../helper/hooks';
import styles from './Select.module.scss';

type SelectProps<T> = {
  value: T;
  setValue: Dispatch<SetStateAction<T>>;
  children?: ReactNode;
  fontStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  placeholder?: string;
  onSelectClick?: () => void;
  alreadySelected?: boolean;
};

type OptionProps = {
  label: string;
  value: string;
  isSelected?: boolean;
  onClick?: () => void;
};

const Option = ({ label, value, isSelected, onClick }: OptionProps) => {
  return (
    <div className={styles['select__item']} data-value={value} data-label={label} onClick={onClick}>
      {label}
      {isSelected && (
        <img
          className={styles['select__item--checked']}
          src="/icons/help__check.svg"
          alt="체크 이미지"
        />
      )}
    </div>
  );
};

const Select = ({
  value,
  setValue,
  children,
  fontStyle = {},
  containerStyle = {},
  placeholder = 'Select...',
  onSelectClick,
  alreadySelected = false,
}: SelectProps<string | CountryCode>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [label, setLabel] = useState<string>(placeholder);
  const container = useClickOutside(() => setIsOpen(false));

  const handleClickValue: MouseEventHandler<HTMLDivElement> = (event) => {
    const t = event.target as HTMLDivElement;
    const selectedLabel = t.attributes.getNamedItem('data-label')?.value;
    const selectedValue = t.attributes.getNamedItem('data-value')?.value;

    setLabel((prev) => (selectedLabel ? selectedLabel : prev));
    setValue(selectedValue || '');
  };

  useEffect(() => {
    if (alreadySelected && !!value.trim()) setLabel(value);
  }, [value]);

  return (
    <div
      className={`${styles['container']}
      ${isOpen ? styles['container--opened'] : styles['container--closed']}`}
      ref={container}
      onClick={() => {
        if (onSelectClick) {
          onSelectClick();
        }
        setIsOpen((prev) => !prev);
      }}
      style={containerStyle}
    >
      <div
        className={`${styles['input']} ${
          !!value ? styles['input--selected'] : styles['input--unselected']
        }`}
        style={fontStyle}
      >
        <div className={styles['select__default']}>{label}</div>
        <img
          className={`${styles['select__img']} ${isOpen ? styles['select__img--opened'] : ''}`}
          src="/icons/help__chevron-down.svg"
          alt="아래쪽 화살표 이미지"
        />
      </div>

      {isOpen && (
        <div className={styles['select__items']} onClick={handleClickValue}>
          {children}
        </div>
      )}
    </div>
  );
};

Select.Option = Option;

export default Select;

import { motion } from 'framer-motion';
import {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  ReactElement,
  TouchEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { postRoulette } from '../../api/backend/roulette';
import { SPIN_WHEEL_EXPIRED_TIME } from '../../constants/cookie';
import { getCookieByKey, setCookie } from '../../utils/cookie';
import ModalPortal from '../ModalPortal/ModalPortal';
import { Input } from '../input';
import { FormButton } from '../login/atoms';
import styles from './SpinWheel.module.scss';

const SpinWheelModal = ({ onCloseModal }: { onCloseModal: () => void }): ReactElement => {
  const { t } = useTranslation();

  const [rotate, setRotate] = useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [hasResponse, setHasResponse] = useState<boolean>(false);

  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  const onChangeEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsClicked(true);

    const res = await postRoulette(email);

    if (!res.isOk) {
      alert(t('roulette_alert_fail_text'));
      setIsClicked(false);
      setRotate(1800);
      setHasResponse(true);
      return;
    }

    setRotate(res.data.rotate);
    setHasResponse(true);
  };

  const onClickOutside = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const onTouchOutside = (event: TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div
      className={styles['modal-background']}
      onClick={onClickOutside}
      onTouchStart={onTouchOutside}
    >
      <div className={styles['modal-contents']}>
        <div className={styles['modal-roulette']}>
          <motion.img
            className={styles['modal-roulette-plate']}
            src={`/images/common/roulette-plate--${localStorage.getItem('language')}.png`}
            alt="룰렛 원판"
            onAnimationComplete={() => {
              setIsShowPopup(true);
            }}
            animate={
              isClicked
                ? {
                    rotate: [0, 1800 - rotate],
                  }
                : {
                    rotate: [-10, 10, -10],
                  }
            }
            transition={
              isClicked
                ? {
                    duration: hasResponse ? 4 : 2,
                    repeat: hasResponse ? 0 : Infinity,
                    ease: hasResponse ? 'easeOut' : 'linear',
                  }
                : {
                    duration: 2,
                    repeat: Infinity,
                  }
            }
          />
          <motion.img
            className={styles['modal-roulette-pin']}
            src="/images/common/roulette-pin.png"
            alt="룰렛 핀"
          />
        </div>
        <form onSubmit={onSubmit}>
          <h1>{t('roulette_header_text')}</h1>
          <label htmlFor="roulette-email">{t('roulette_sub_header_text')}</label>
          <Input
            id="roulette-email"
            value={email}
            onChange={onChangeEmail}
            type="email"
            required
            placeholder={t('roulette_input_placeholder')}
            autoFocus
          />
          <div className={styles['modal-form-button']}>
            <FormButton contents={t('roulette_submit_button')} disabled={isClicked} />
            <div className={styles['button-extra-styles']} />
          </div>
          <ul>
            <li>{t('roulette_guide_text_01')}</li>
            <li>{t('roulette_guide_text_02')}</li>
          </ul>
          <p>{t('roulette_guide_sub_text')}</p>
        </form>
        <img
          className={styles['modal__close']}
          src="/icons/roulette__close.svg"
          alt="닫기"
          onClick={onCloseModal}
        />
      </div>
      {isShowPopup && (
        <ModalPortal>
          <div className={styles['modal__outter']} onClick={onClickOutside}>
            <div className={styles['modal-popup__inner']}>
              <header className={styles['modal__header']}>
                <span>{t('modal_default_header')}</span>
                <img
                  src="/icons/cart__x.svg"
                  alt="닫기 이미지"
                  onClick={() => {
                    setIsShowPopup(false);
                  }}
                />
              </header>
              <div className={styles['modal__description']}>
                <Trans
                  i18nKey={t('roulette_inner_popup_description')}
                  components={{
                    br: <br />,
                  }}
                />
              </div>
              <div className={styles['modal__button-container']}>
                <div
                  className={`${styles['modal__button']}`}
                  onClick={() => {
                    setIsShowPopup(false);
                  }}
                >
                  {t('modal_default_button')}
                </div>
              </div>
            </div>
          </div>
        </ModalPortal>
      )}
    </div>
  );
};

const SpinWheel = (): ReactElement => {
  const [isRoulettePopupOpen, setIsRoulettePopupOpen] = useState<boolean>(
    getCookieByKey('roulette-status') !== 'false',
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const onOpenModal = () => {
    setIsModalOpen(true);
  };
  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isModalOpen]);

  return (
    <>
      {isRoulettePopupOpen && (
        <div className={styles['spin-wheel--container']} onClick={onOpenModal}>
          <div className={styles['spin-wheel__inner-container']}>
            <img
              className={styles['spin-wheel--container__wheel']}
              src={'/icons/main__spin-wheel.svg'}
              alt="스핀 휠"
            />
            <span className={styles['spin-wheel--container__text']}>SPIN THE WHEEL!</span>
            <img
              className={styles['modal__close']}
              src="/icons/roulette__close.svg"
              alt="닫기"
              onClick={(event) => {
                event.stopPropagation();
                setIsRoulettePopupOpen(false);
                setCookie('roulette-status', 'false', SPIN_WHEEL_EXPIRED_TIME);
              }}
            />
          </div>
        </div>
      )}
      {isModalOpen && (
        <ModalPortal>
          <SpinWheelModal onCloseModal={onCloseModal} />
        </ModalPortal>
      )}
    </>
  );
};

export default SpinWheel;

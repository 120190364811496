import { useLocation } from 'react-router';
import { MailingAddress } from '../../../@types/customer';
import { AddressBookAdd } from '../../../components/account/address-book';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import { useTranslation } from 'react-i18next';

const DeliveryInfoAdd = () => {
  const { state } = useLocation();
  const { t } = useTranslation();

  const titles = {
    title: state
      ? t('account_profile_delivery_edit_title')
      : t('account_profile_delivery_add_title'),
    pageTitle: state
      ? t('account_profile_delivery_edit_page_title')
      : t('account_profile_delivery_add_page_title'),
  };

  const initAddress: MailingAddress = {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    id: '',
    name: '',
    phone: '',
    province: '',
    zip: '',
  };

  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.ONLY_TITLE, title: titles.title }}
      pageTitle={titles.pageTitle}
      className='delivery-info-add-mobile-layout'
    >
      {state ? (
        <AddressBookAdd
          addressType={'EDIT'}
          selectedShippingInfo={{ ...initAddress, ...state.address }}
        />
      ) : (
        <AddressBookAdd addressType={'NONE'} />
      )}
    </AccountLayout>
  );
};

export default DeliveryInfoAdd;

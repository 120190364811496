import { ReactElement, ReactNode } from 'react';
import Footer from '../footer/Footer';
import MainNavBar from '../navbar/MainNavBar';
import SpinWheel from '../spin-wheel/SpinWheel';
import styles from './styles/MainLayout.module.scss';

const MainLayout = ({
  children,
  hideFooter = false,
}: {
  children: ReactNode;
  hideFooter?: boolean;
}): ReactElement => {
  return (
    <div className={styles['div']}>
      <SpinWheel />
      <MainNavBar />
      {children}
      {!hideFooter && <Footer />}
    </div>
  );
};

export default MainLayout;

import { ChangeEventHandler } from 'react';
import Radio from '../../radio/Radio';
import styles from './styles/CategoryAsideRadio.module.scss';

type RadioItems = {
  [key: string]: string;
};

type CategoryAsideRadioProps = {
  items: RadioItems;
  name: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
};

const CategoryAsideRadio = ({ items, name, handleChange }: CategoryAsideRadioProps) => {
  return (
    <>
      {Object.entries(items).map(([key, value], idx) => (
        <div key={`${key}-${value}--${idx}`} className={styles['category-side-items']}>
          <Radio
            name={name}
            value={key}
            handleChange={handleChange}
            children={<span className={styles['category-side-span']}>{value}</span>}
            defaultChecked={key === 'Unlimited' && true}
          />
        </div>
      ))}
    </>
  );
};

export default CategoryAsideRadio;

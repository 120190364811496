import { Options, Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Grid } from '@splidejs/splide-extension-grid';
import { MutableRefObject, ReactElement, ReactNode } from 'react';
import styles from './SplideSlick.module.scss';

interface Props {
  slickData: {
    elements: ReactNode;
    options?: Options;
  };
  slickRef?: MutableRefObject<Splide | null>;
}

const SplideSlick = ({ slickData, slickRef }: Props): ReactElement => {
  const options: Options = {
    ...slickData.options,
  };

  return (
    <div className={styles['div']}>
      <Splide
        key={slickData.elements?.toString()}
        extensions={{ Grid }}
        options={options}
        ref={slickRef}
      >
        {Array.isArray(slickData.elements)
          ? slickData.elements?.map((element, idx) => (
              <SplideSlide key={`${element}--${idx}`}>{element}</SplideSlide>
            ))
          : slickData.elements}
      </Splide>
    </div>
  );
};

export default SplideSlick;

import { CouponsResponse } from '../../@types/response-mkm';
import instance from './instance';

export async function getCoupons() {
  const response = await instance.get<CouponsResponse>('/coupon');

  return response;
}

export async function postCouponByPoint(amount: number) {
  const response = await instance.post('/coupon/point', JSON.stringify({ amount }));

  return response;
}

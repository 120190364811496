import { ReactNode } from 'react';
import styles from './Overlay.module.scss';

interface Props {
  children: ReactNode;
}

const Overlay = ({ children }: Props) => {
  return <div className={styles['container']}>{children}</div>;
};

export default Overlay;

import { Options, Splide } from '@splidejs/react-splide';
import { MutableRefObject, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useClickOutside, useResponsiveElement } from '../../helper/hooks';
import { categories } from '../../utils/getCategories';
import Dropdown from '../dropdown/Dropdown';
import SplideSlick from '../splide-slick/SplideSlick';
import styles from './styles/CategoryMenu.module.scss';

interface Props {
  subSlickRef?: MutableRefObject<Splide | null>;
}

const CategoryMenu = ({ subSlickRef }: Props): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [hoveredCategoryIdx, setHoveredCategoryIdx] = useState<number>(-1);

  const clickOutSideRef = useClickOutside(() => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (!isHover) {
      setIsOpen(false);
    }

    setIsOpen(true);
  }, [isHover]);

  const onClickCategory = (link: string) => {
    navigate(`/categories?menu=${link}`);
  };

  const onMouseEnterCategory = useCallback((idx: number) => {
    setIsHover(true);
    setHoveredCategoryIdx(idx);
  }, []);

  const onMouseLeaveCategory = useCallback(() => {
    setIsHover(false);
    setHoveredCategoryIdx(-1);
  }, []);

  const mobileCategoryMenu = categories.map((menu, idx) => (
    <div
      key={menu.name}
      className={styles['category-menu']}
      onClick={() => {
        onClickCategory(menu.handle);
        subSlickRef?.current?.go(0);
      }}
      style={{
        maxWidth: localStorage.getItem('language') === 'ko' ? 'unset' : '56px',
      }}
    >
      <div>
        <div
          className={`${
            searchParams.get('menu') === menu.handle || hoveredCategoryIdx === idx
              ? styles['category-menu-active']
              : ''
          }`}
        >
          <img src={menu.image} alt="카테고리 메뉴 이미지" />
        </div>
        <span>{t(`category_name_${menu.handle}`)}</span>
      </div>
    </div>
  ));

  const desktopCategoryMenu = categories.map((menu, idx) => (
    <div
      key={menu.name}
      className={styles['category-menu']}
      onMouseEnter={() => onMouseEnterCategory(idx)}
      onMouseLeave={onMouseLeaveCategory}
      style={{
        maxWidth: localStorage.getItem('language') === 'ko' ? 'unset' : '56px',
      }}
    >
      <div onClick={() => onClickCategory(menu.handle)}>
        <div
          className={`${
            searchParams.get('menu') === menu.handle || hoveredCategoryIdx === idx
              ? styles['category-menu-active']
              : ''
          }`}
        >
          <img src={menu.image} alt="카테고리 메뉴 이미지" />
        </div>
        <span>{t(`category_name_${menu.handle}`)}</span>
      </div>

      {hoveredCategoryIdx === idx && (
        <Dropdown menu={categories[idx].handle} data={categories[idx].inner} isOpen={isOpen} />
      )}
    </div>
  ));

  const elements = useResponsiveElement({
    MOBILE: mobileCategoryMenu,
    DESKTOP: desktopCategoryMenu,
  });

  const options: Options = {
    arrows: false,
    pagination: false,
    autoWidth: true,
    drag: false,
    gap: 32,
    padding: {
      left: 32,
    },
    breakpoints: {
      1139: {
        gap: 30,
      },
      1110: {
        gap: 28,
      },
      1092: {
        gap: 26,
      },
      1060: {
        gap: 24,
      },
      1030: {
        gap: 22,
      },
      1023: {
        padding: {
          left: 16,
          right: 16,
        },
      },
      960: {
        drag: 'free',
        snap: true,
        bounce: false,
        autoWidth: false,
        perPage: 10,
      },
      768: {
        perPage: 9,
      },
      708: {
        perPage: 8,
      },
      648: {
        perPage: 7,
      },
      588: {
        perPage: 6,
      },
      528: {
        perPage: 5,
      },
    },
  };

  const mainSlickRef = useRef<Splide | null>(null);

  useEffect(() => {
    const mainSlickNumber = categories.findIndex(
      (category) => category.handle === searchParams.get('menu'),
    );
    mainSlickRef?.current?.go(mainSlickNumber);
  }, []);

  return (
    <div
      className={styles['div']}
      ref={clickOutSideRef}
      style={{
        height: localStorage.getItem('language') === 'ko' ? '114px' : '122px',
      }}
    >
      <SplideSlick
        slickData={{
          elements,
          options,
        }}
        slickRef={mainSlickRef}
      />
    </div>
  );
};

export default CategoryMenu;

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import BorderSeparator from '../../border/BorderSeparator';
import styles from './CheckoutOverlays.module.scss';
import useCart from '../../../hooks/useCart';
import { CartItemWithProduct } from '../../../@types/cart';

const PurchaseItem = ({ item }: { item: CartItemWithProduct }): ReactElement => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const onClickProduct = () => {
    if (!item.product) {
      return;
    }

    navigate(`/product/${encodeURIComponent(item.product.id)}`);
  };
  return (
    <>
      <article className={styles['purchase-item']}>
        <p onClick={onClickProduct}>{item.product?.title}</p>
        <div>
          <img
            onClick={onClickProduct}
            src={item.product?.featuredImage?.url}
            alt="구매할 상품 썸네일"
          />
          <div>
            <div className={styles['product-price-container']}>
              <span className={styles['current-price']}>
                {Number(item.product?.priceRange.maxVariantPrice.amount) >
                Number(item.product?.priceRange.minVariantPrice.amount)
                  ? `$${item.product?.priceRange.minVariantPrice.amount}`
                  : `$${item.product?.priceRange.maxVariantPrice.amount}`}
              </span>

              <small>{t('checkout_overlay_purchase_quantity', { items: item.quantity })}</small>
            </div>
          </div>
        </div>
      </article>
      <BorderSeparator className={'no-margin-top-and-height-1px'} />
    </>
  );
};

const PurchaseItems = (): ReactElement => {
  const cart = useCart();
  const cartItems = cart.cartItemsWithProduct;
  return (
    <div>
      {cartItems
        .filter((item) => item.active)
        .map((item, idx) => (
          <PurchaseItem key={idx} item={item} />
        ))}
    </div>
  );
};

export default PurchaseItems;

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TRequest } from '../../../@types/help';
import styles from './styles/Suggestion.module.scss';

type Props = {
  request: TRequest;
};

const Request = ({ request }: Props) => {
  const { t } = useTranslation();
  const { createdAt, title, id } = request;
  const date = new Date(createdAt).toLocaleDateString();
  const dateString = `Date. ${date}`;

  const go = useNavigate();

  const handleClick = () => {
    go(`/account/help-center/request/${id}`);
  };

  return (
    <section className={styles['container']} onClick={handleClick}>
      <div className={styles['request__date']}>{dateString}</div>
      <div className={styles['request__content']}>
        <p>{title}</p>
        <img src="/icons/help__right-arrow.svg" alt="오른쪽 방향 표시" />
      </div>
    </section>
  );
};

export default Request;

import { ProductType } from '../../@types/product';
import { postStatisticsClick } from '../../api/backend/statistics';
import { STATISTICS_ORIGIN_MAP } from '../../constants/statistics';

export const statisticsClickCounts = ({
  product,
  originId,
}: {
  product: ProductType;
  originId: number;
}) => {
  const originKey = location.pathname.split('/')[1];
  const origin = STATISTICS_ORIGIN_MAP[originKey];
  const increaseClickCount = async () => {
    await postStatisticsClick({
      shopifyId: product.id,
      origin,
      originId,
    });
  };
  increaseClickCount();
};

import {
  MouseEvent,
  MouseEventHandler,
  TouchEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { categories } from '../../../utils/getCategories';
import styles from './styles/MenuBar.module.scss';

const MenuBar = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const [mouseOverIdx, setMouseOverIdx] = useState<number>(-1);
  const ref = useRef<HTMLDivElement>(null);

  const onClickMenuBar = () => {
    setShow(true);
  };

  const onClickOutside = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.currentTarget === event.target) {
      setShow(false);
    }
  };

  const onTouchOutside = (event: TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const onClickClose: MouseEventHandler = (event) => {
    event.stopPropagation();
    setShow(false);
  };

  const onMouseHandle = useCallback(({ type, idx }: { type: 'OVER' | 'OUT'; idx?: number }) => {
    if (type === 'OUT') {
      setMouseOverIdx(-1);
    } else if (idx !== undefined) {
      setMouseOverIdx(idx);
    }
  }, []);
  const onClickItem = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setShow(false);
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (show) {
      document.body.style.overflowY = 'hidden';
      ref.current.style.height = `${window.innerHeight}px`;
    }

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [ref.current, show]);

  return (
    <>
      <div className={styles['div']} onClick={onClickMenuBar}>
        <img src="/icons/menu-bar.svg" alt="메뉴바1" />
      </div>
      {show && (
        <div
          className={styles['mobile-menu-bg-container']}
          onClick={onClickOutside}
          onTouchStart={onTouchOutside}
        >
          <div
            className={`${styles['mobile-menu-nav-container']} ${show ? styles['show'] : ''} `}
            ref={ref}
          >
            <div className={styles['header']}>
              <h1>{t(`category_name`)}</h1>
              <img src="/icons/account__x__2.svg" alt="" onClick={onClickClose} />
            </div>
            <ul>
              {categories.map((category, idx) => (
                <Link
                  to={{
                    pathname: '/categories',
                    search: `?menu=${category.handle}`,
                  }}
                  key={`${category.name}--${idx}`}
                >
                  <li
                    onMouseOver={() => onMouseHandle({ type: 'OVER', idx })}
                    onMouseOut={() => onMouseHandle({ type: 'OUT' })}
                    onClick={onClickItem}
                  >
                    <div>
                      <img src={category.image} alt="카테고리" />
                      <span>{t(`category_name_${category.handle}`)}</span>
                    </div>
                    <div>
                      <img
                        src={
                          idx === mouseOverIdx
                            ? '/icons/mobile-menu__chevron-right-over.svg'
                            : '/icons/mobile-menu__chevron-right-no-over.svg'
                        }
                        alt="오른쪽 화살표"
                      />
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default MenuBar;

import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WishProductType } from '../../@types/product';
import { ProductsByIdsResponse } from '../../@types/response';
import { getProductsInWishlist } from '../../api/backend/product';
import client from '../../api/client';
import { GET_PRODUCTS_BY_PRODUCT_IDS_QUERY } from '../../api/storefront/products';
import WishListContainer from '../../components/account/wishlist/WishListContainer';
import { AccountLayout } from '../../components/layout';
import { MobileNavItem } from '../../components/layout/BaseLayout';
import styles from './styles/WishList.module.scss';

const WishList = (): ReactElement => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<WishProductType[]>([]);

  const getProductsByIds = async (wishs: WishProductType[]) => {
    const response = await client.query<ProductsByIdsResponse>({
      query: GET_PRODUCTS_BY_PRODUCT_IDS_QUERY,
      variables: { ids: wishs.map((wish) => wish.id) },
    });
    const products = response.data.nodes.map((res) => {
      return { ...res, checked: false, mkmId: wishs.filter((wish) => wish.id === res.id)[0].mkmId };
    });
    setProducts(products as WishProductType[]);
    setIsLoading(false);
    return { response, products };
  };

  const getProductsInWishlistRequest = async () => {
    const result = await getProductsInWishlist();
    const wishs = result.data.wishlist;

    if (!result.isOk) {
      setIsLoading(false);
      return;
    }

    return { wishs };
  };

  useEffect(() => {
    getProductsInWishlistRequest().then((res) => {
      getProductsByIds(res?.wishs || []);
    });
  }, []);

  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.SHOW_ALL, title: t('account_wish') }}
      pageTitle={t('account_wish_page')}
    >
      <div className={styles['wish-container']}>
        <WishListContainer
          items={products}
          isLoading={isLoading}
          setProducts={setProducts}
          products={products}
        />
      </div>
    </AccountLayout>
  );
};

export default WishList;

import { MouseEventHandler, useState } from 'react';
import styles from './Accordion.module.scss';

type AccordionType = {
  title: React.ReactNode;
  description: React.ReactNode;
};

const Accordion = ({ title, description }: AccordionType) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const handleClick: MouseEventHandler = () => {
    setIsOpened((prev) => !prev);
  };

  return (
    <div className={styles['container']} onClick={handleClick}>
      <div className={`${styles['title']} ${isOpened && styles['title--selected']}`}>
        <div>{title}</div>
        <img className={styles['arrow']} src="/icons/help__chevron-down.svg" alt="arrow down" />
      </div>
      <div className={`${styles['description']} ${isOpened && styles['description--selected']}`}>
        {description}
      </div>
    </div>
  );
};

export default Accordion;

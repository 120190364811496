import { useTranslation } from 'react-i18next';
import { PRICE_FOR_FREE_SHIPPING } from '../../constants';
import { useShippingRate, useViewport } from '../../helper/hooks';
import BorderSeparator from '../border/BorderSeparator';
import { Button } from '../button';
import Checkbox from '../checkbox/Checkbox';
import { GuideInfo } from '../checkout/atoms';
import CartItemComponent from './CartItem';
import styles from './styles/CartList.module.scss';
import TGuideInfo from '../checkout/templates/TGuideInfo';
import useCart from '../../hooks/useCart';
import { CartItemWithProduct } from '../../@types/cart';
import Loading from '../loading/Loading';

type CartListProps = {
  onClickCheckoutButton: () => void;
};

const CartList = ({ onClickCheckoutButton }: CartListProps) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const cart = useCart();

  const cartItems = cart.cartItemsWithProduct;
  const totalPrice = cart.getTotalPriceActive;
  const shippingRate = useShippingRate();

  const checkedAll = cart.cartItems.filter((item) => item.active).length === cart.getTotal;
  const checkedCartItems = cartItems.filter(
    (item) => item.active && item.product?.availableForSale,
  );

  const onToggle = async (id: string | number, checked: boolean) => {
    await cart.handleToggle(id as string, checked);
  };

  const onClickDeleteSelection = async () => {
    const ids = cartItems.reduce<string[]>((prev, item) => {
      if (item.active && item.product) {
        prev.push(item.product.id);
        return prev;
      }

      return prev;
    }, []);

    await cart.remove(ids);
  };

  const onSelectAll = async () => {
    const active = !checkedAll;
    await cart.handleToggleAll(active);
  };

  // FIXME: Fix debounce not working (cart rerendering)
  const [availableProducts, unavailableProducts] = cartItems.reduce<
    [CartItemWithProduct[], CartItemWithProduct[]]
  >(
    (prev, curr) => {
      if (curr.product && curr.product.totalInventory > 0) {
        prev[0].push(curr);
        return prev;
      }
      prev[1].push(curr);
      return prev;
    },
    [[], []],
  );

  const onClickClearUnavailableProducts = async () => {
    const ids = cartItems.reduce<string[]>((prev, item) => {
      if (item.product && item.active && item.product.totalInventory < 1) {
        prev.push(item.product.id);
        return prev;
      }

      return prev;
    }, []);

    await cart.remove(ids);
  };

  // const cartListDeliveryContainer = (
  //   <div className={styles['cart-list-delivery-container']}>
  //     <div>
  //       <div>{t('cart_desktop_sum', { sum: checkedCartItems.length })}</div>
  //       <div>
  //         <b className={styles['bold']}>${totalPrice}</b>
  //       </div>
  //     </div>
  //     <div>
  //       <div>{t('cart_desktop_shipping')}</div>
  //       {shippingRate ? (
  //         <div>
  //           <b>{`$${shippingRate}`}</b>
  //         </div>
  //       ) : (
  //         <div>
  //           <span>{t('cart_free_shipping')}</span>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
  // const mobileCartListDeliveryContainer = (
  //   <div className={styles['cart-list-delivery-container']}>
  //     <div className={styles['cart-mobile-total-count-price']}>
  //       <span>{t('cart_desktop_total_sum')}</span>
  //       <b>${(totalPrice + shippingRate).toFixed(2)}</b>
  //     </div>

  //     <div>
  //       <div>{t('cart_desktop_shipping')}</div>
  //       {shippingRate ? (
  //         <div>
  //           <b>{`$${shippingRate}`}</b>
  //         </div>
  //       ) : (
  //         <div>
  //           <span>{t('cart_free_shipping')}</span>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
  const cartDeliveryContainer = (type: 'sum' | 'total') => {
    return (
      <div className={styles['cart_delivery_container']}>
        <div className={styles['cart_delivery_container_upper']}>
          <div>{t('cart_delivery_from')}</div>
          {type == 'sum' ? (
            <div>
              <div>
                {t('cart_desktop_sum', {
                  sum: checkedCartItems.reduce((a, c) => {
                    return a + c.quantity;
                  }, 0),
                })}
              </div>
              <b>${totalPrice == 0 ? '0.00' : totalPrice.toFixed(2)}</b>
            </div>
          ) : (
            <div>
              <div>
                {t('cart_delivery_total', {
                  sum: checkedCartItems.reduce((a, c) => {
                    return a + c.quantity;
                  }, 0),
                })}
              </div>
              <b>${totalPrice == 0 ? 0 : (totalPrice + shippingRate).toFixed(2)}</b>
            </div>
          )}
        </div>
        <div className={styles['cart_delivery_container_lower']}>
          <div>{t('cart_delivery_where')}</div>
          {shippingRate ? (
            <div>
              <div>{t('cart_desktop_shipping')}</div>
              <b>{`$${shippingRate.toFixed(2)}`}</b>
            </div>
          ) : (
            <b className={styles['cart_delivery_free']}>{t('cart_free_shipping')}</b>
          )}
        </div>
      </div>
    );
  };

  if (cart.loading) {
    return <Loading />;
  }

  return (
    <>
      <section className={styles['section']}>
        {isMobile && cartDeliveryContainer('sum')}
        <TGuideInfo />
        {/* {PRICE_FOR_FREE_SHIPPING - totalPrice > 0 && (
          <GuideInfo
            textElement={
              <span className={styles['checkout-guide-info']}>
                {t('cart_guide_a')}
                <b>${Number(PRICE_FOR_FREE_SHIPPING - totalPrice).toFixed(2)}</b>
                {t('cart_guide_b')}
              </span>
            }
            bgColor={'#FDF6B7'}
            link={{ text: t('cart_guide_more'), path: '/' }}
          />
        )} */}
        <div className={styles['cart-desktop-container']}>
          <div className={styles['cart-desktop__left-container']}>
            <div className={styles['cart-list-select-container']}>
              <div>
                <Checkbox
                  id={-1}
                  checked={checkedAll}
                  onToggle={onSelectAll}
                  label={t('cart_list_select')}
                />
              </div>
              <div onClick={onClickDeleteSelection}>
                <span>{t('cart_list_select_delete')}</span>
              </div>
            </div>
            {availableProducts.map((product, idx) => (
              <CartItemComponent
                key={`${product.id}--${idx}`}
                item={product}
                onToggleCart={onToggle}
              />
            ))}
          </div>
          {!isMobile && (
            <div className={styles['cart-desktop__right-container']}>
              <h3>{t('cart_title')}</h3>
              {cartDeliveryContainer('sum')}
              <div className={styles['cart-desktop-total-count-price']}>
                <span>{t('cart_desktop_total_sum')}</span>
                <b>${totalPrice == 0 ? '0.00' : (totalPrice + shippingRate).toFixed(2)}</b>
              </div>
              <div>
                <Button action={onClickCheckoutButton}>
                  <span>{t('cart_checkout_button')}</span>
                </Button>
              </div>
            </div>
          )}
        </div>
      </section>
      <BorderSeparator className="border-horizon-absolute" />
      {unavailableProducts.length > 0 && (
        <div className={styles['cart-desktop-unavailable-container']}>
          <div>
            <header className={styles['cart-desktop-unavailable__header']}>
              <h3>{t('cart_unavailable', { items: unavailableProducts.length })}</h3>
              <span onClick={onClickClearUnavailableProducts}>{t('cart_unavailable_clear')}</span>
            </header>
            {unavailableProducts.map((product, idx) => (
              <div key={`div--${idx}`} className={styles['cart-desktop-unavailable__item']}>
                <CartItemComponent key={`${product.id}--${idx}`} item={product} hasStock={false} />
                {isMobile && <BorderSeparator className={'border-horizon-and-height-1px'} />}
              </div>
            ))}
          </div>
          <div></div>
        </div>
      )}
      {unavailableProducts.length > 0 && <BorderSeparator className={'border-horizon-absolute'} />}
    </>
  );
};
export default CartList;

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import Tab from '../../../components/tab/Tab';
import { EnPrivacy, KoPrivacy } from './PrivacyPolicy';
import { EnRefund, KoRefund } from './RefundPolicy';
import { EnTerms, KoTerms } from './TermsOfService';
import styled from './styles/Policy.module.scss';

const Policy = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [currentLang] = useState<string>(
    () => localStorage.getItem('language') ?? window.navigator.language.slice(0, 2),
  );
  const tabs = [
    { key: '', name: t('policy_shipping_category') },
    { key: 'refund', name: t('policy_refund_category') },
    { key: 'privacy', name: t('policy_privacy_category') },
    { key: 'terms', name: t('policy_terms_category') },
  ];

  const detectTab = () => {
    const tab = tabs.map((tab) => tab.key).indexOf(pathname.split('/')[3]);
    if (tab > 0) {
      return tab;
    }
    return 0;
  };

  const [clickedTab, setClickedTab] = useState<number>(detectTab());

  useEffect(() => {
    setClickedTab(detectTab());
  }, [pathname]);

  const PolicyPage = useCallback(() => {
    if (clickedTab == 1) return currentLang == 'en' ? EnRefund() : KoRefund();
    if (clickedTab == 2) return currentLang == 'en' ? EnPrivacy() : KoPrivacy();
    if (clickedTab == 3) return currentLang == 'en' ? EnTerms() : KoTerms();
    return currentLang == 'en' ? EnShipping() : KoShipping();
  }, [clickedTab]);
  const PolicyTitle = useCallback(() => {
    if (clickedTab == 1) return <h1>{t('policy_refund_title')}</h1>;
    if (clickedTab == 2) return <h1>{t('policy_privacy_title')}</h1>;
    if (clickedTab == 3) return <h1>{t('policy_terms_title')}</h1>;
    return <h1>{t('policy_shipping_title')}</h1>;
  }, [clickedTab]);

  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.SHOW_ALL, title: t('policy_page') }}
      pageTitle={t('policy_page')}
      className="no-padding-bottom"
    >
      <div className={styled['policy__bg']}>
        <Tab
          data={tabs}
          clickedTab={clickedTab}
          setClickedTab={setClickedTab}
          align="LEFT"
          setRoute={true}
        />

        <div className={styled['container']}>
          <div className={styled['inner__container']}>
            <div className={styled['shopify-policy__title']}>
              <PolicyTitle />
            </div>
            <div className={styled['shopify-policy__body']}>
              <PolicyPage />
            </div>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};

export default Policy;

const EnShipping = () => {
  return (
    <div className={styled['rte']}>
      <ol>
        <li>
          <span>1. Delivery Lead Time</span>
          <ul>
            <li>
              <span>
                The delivery lead time is about 5 to 7 days on purchases that have been paid.
                (However, it may be delayed somewhat due to customs clearance issues or extreme
                weather conditions)
              </span>
            </li>
            <li>
              <span>
                All products will be delivered quickly and safely by air transport.
                <br />
              </span>
            </li>
            <li>
              <span>You can check the delivery status through My Page.</span>
            </li>
          </ul>
        </li>
        <li>
          2. Shipping Fee (International Shipping Fee)
          <ul>
            <li>
              If the total payment amount is more than $99, we will deliver your package for free
              (The total payment amount is based on the actual payment amount excluding discounts
              such as coupons and points) If the payment amount does not exceed the free shipping
              standard of $99, you will be charged $20 for overseas shipping.
            </li>
          </ul>
        </li>
      </ol>
      <ol>
        <li>
          3. Customs Duties
          <ul>
            <li>
              Customs duties may be imposed for each purchase amount when customs clearance in the
              United States and all customs clearance costs must be paid by the customer. (US
              duty-free amount is $800)
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};
const KoShipping = () => {
  return (
    <div className={styled['rte']}>
      <ol>
        <li>
          <span>1. 배송 리드 타임</span>
          <ul>
            <li>
              <span>
                배송 소요기간은 결제 완료 상품으로 약 5~7일 소요됩니다. (단, 통관 문제나 기상악화로
                인해 다소 지연될 수 있습니다.)
              </span>
            </li>
            <li>
              <span>
                모든 상품은 항공배송으로 빠르고 안전하게 배송됩니다.
                <br />
              </span>
            </li>
            <li>
              <span>배송현황은 마이페이지에서 확인하실 수 있습니다.</span>
            </li>
          </ul>
        </li>
        <li>
          2. 배송비(국제배송비)
          <ul>
            <li>
              총 결제 금액이 $99 이상일 경우, 무료 배송해 드립니다. (총 결제 금액은 실제 결제 금액
              기준이며, 쿠폰, 포인트 등 할인 제외) 99달러, 해외 배송료 20달러가 부과됩니다.
            </li>
          </ul>
        </li>
      </ol>
      <ol>
        <li>
          3. 관세
          <ul>
            <li>
              미국 통관 시 구매금액별로 관세가 부과될 수 있으며 통관비용은 전액 고객부담입니다.
              (미국 면세 ​​금액은 $800)
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

import styles from './styles/ExternalLink.module.scss';

const ExternalLink = () => {
  return (
    <div className={styles['div']}>
      <img src="/icons/account-nav__external-link.svg" alt="외부 링크" />
    </div>
  );
};

export default ExternalLink;

import { MouseEventHandler } from 'react';
import { MKMPagination } from '../../@types/common';
import styles from './Pagination.module.scss';

interface PaginationProps {
  handleChangePage: (page: number) => void;
  pagination?: MKMPagination;
  pageCount?: number;
}

interface PageItemProps {
  page: number;
  isCurrent: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
}

interface ShortPageProps {
  onClick: MouseEventHandler<HTMLDivElement>;
}

interface PaginationWithShortProps {
  currentPage: number;
  totalPage: number;
  pageCount: number;
  go: (page: number) => void;
}

const ShortPage = ({ onClick }: ShortPageProps) => {
  return (
    <div className={styles['pages__item']} onClick={onClick}>
      ...
    </div>
  );
};

const PageItem = ({ page, isCurrent, onClick }: PageItemProps) => {
  return (
    <div
      className={`${styles['pages__item']}
    ${isCurrent ? styles['pages__item--active'] : ''}`}
      onClick={onClick}
    >
      {page}
    </div>
  );
};

const PaginationWithShort = ({
  currentPage,
  totalPage,
  pageCount,
  go,
}: PaginationWithShortProps) => {
  const showPage = Math.floor(pageCount / 2);
  const hasPrevShort = currentPage - showPage > 3;
  const hasNextShort = currentPage + showPage < totalPage - 2;

  return (
    <>
      {[1, 2].map((value) => (
        <PageItem
          key={value}
          page={value}
          isCurrent={value === currentPage}
          onClick={() => go(value)}
        />
      ))}
      {hasPrevShort && (
        <ShortPage onClick={() => go(currentPage - pageCount > 0 ? currentPage - pageCount : 1)} />
      )}
      {Array.from({ length: pageCount }, (_, idx) => currentPage - showPage + idx).map((page) => {
        if ([1, 2, totalPage - 1, totalPage].includes(page) || page < 1 || page > totalPage) {
          return;
        }
        return (
          <PageItem
            key={page}
            page={page}
            isCurrent={page === currentPage}
            onClick={() => go(page)}
          />
        );
      })}
      {hasNextShort && (
        <ShortPage
          onClick={() =>
            go(currentPage + pageCount < totalPage + 1 ? currentPage + pageCount : totalPage)
          }
        />
      )}

      {[totalPage - 1, totalPage].map((value) => (
        <PageItem
          key={value}
          page={value}
          isCurrent={value === currentPage}
          onClick={() => go(value)}
        />
      ))}
    </>
  );
};

const Pagination = ({ handleChangePage, pagination, pageCount = 3 }: PaginationProps) => {
  if (!pagination) {
    return <></>;
  }

  const { currentPage, totalPage, hasNextPage, hasPrevPage } = pagination;

  const pageNumbers = Array(totalPage).fill(0);

  const hasShort = totalPage > 4;

  const go = (page: number) => {
    if (currentPage === page) {
      return;
    }

    handleChangePage(page);
  };

  const goPreviousPage = () => {
    if (!hasPrevPage) {
      return;
    }
    go(currentPage - 1);
  };

  const goNextPage = () => {
    if (!hasNextPage) {
      return;
    }

    go(currentPage + 1);
  };

  return (
    <div className={styles['container']}>
      {hasPrevPage && (
        <button className={styles['controller']} onClick={goPreviousPage}>
          <img src="/icons/categories__chevron-back.svg" alt="이전 페이지" />
          <div className={styles['controller__term']}>Prev</div>
        </button>
      )}

      <button className={styles['pages']}>
        {hasShort ? (
          <PaginationWithShort
            currentPage={currentPage}
            totalPage={totalPage}
            pageCount={pageCount}
            go={go}
          />
        ) : (
          pageNumbers.map((_, idx) => (
            <PageItem
              key={idx}
              page={idx + 1}
              isCurrent={idx + 1 === currentPage}
              onClick={() => go(idx + 1)}
            />
          ))
        )}
      </button>

      {hasNextPage && (
        <button className={styles['controller']} onClick={goNextPage}>
          <div className={styles['controller__term']}>Next</div>
          <img src="/icons/categories__chevron-next.svg" alt="다음 페이지" />
        </button>
      )}
    </div>
  );
};

export default Pagination;

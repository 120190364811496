import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { ProductType } from '../../../@types/product';
import { useViewport } from '../../../helper/hooks';
import { getCapitalizeLetters } from '../../../helper/letter';
import { Button, CartButton } from '../../button';
import { useDrawer } from '../../drawer/Drawer';
import ProductShareModal from './ProductShareModal';
import styles from './styles/ProductBasicInfo.module.scss';

type ProductBasicInfoType = {
  product: ProductType;
  hasWish: boolean;
  handleSubmitWish: () => Promise<void>;
};

const ProductBasicInfo = ({ product, handleSubmitWish, hasWish }: ProductBasicInfoType) => {
  const { vendor, title, priceRange, compareAtPriceRange } = product;
  const go = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useViewport();

  const price = Number(priceRange.maxVariantPrice.amount) || 0;
  const originalPrice = Number(compareAtPriceRange?.maxVariantPrice.amount) || 0;
  const isDiscount = originalPrice > price;
  const discountRatio = Math.floor(((originalPrice - price) / originalPrice) * 100);

  const { open, close, isOpened, render } = useDrawer();

  const handleClickTag = (tag: string) => {
    console.log('Redirect to tags');
    go(`/search?t=${encodeURI(tag)}`);
  };

  const handleWebShare = async () => {
    if (isMobile && navigator.share) {
      const shareData = {
        title: product.title,
        text: `${product.title}\n${document.URL}`,
        // url: document.URL,
      };

      await navigator.share(shareData);
      return;
    }

    open();
  };

  const shareModal = render(<ProductShareModal title={product.title} close={close} />);

  const flags = product.collections
    ? [
        ...product.collections.edges
          .map(({ node }) => node.title)
          .filter((title) => title.toUpperCase() === 'BEST' || title.toUpperCase() === 'NEW'),
      ]
    : [];

  return (
    <>
      <section className={styles['section']}>
        <div className={styles['product__header']}>
          <div>
            <div className={styles['product__flags']}>
              {flags.map((flag, idx) => (
                <div
                  key={`${flag}-${idx}`}
                  className={`${styles['product__flag']} ${
                    styles[`product__flag--${flag.toLowerCase()}`]
                  }`}
                >
                  {getCapitalizeLetters(flag)}
                </div>
              ))}
            </div>
            <Link className={styles['product__vendor']} to={`/brand/${encodeURIComponent(vendor)}`}>
              {vendor}
            </Link>
          </div>
          <div className={styles['product__control']}>
            <Button action={handleSubmitWish}>
              <img
                src={hasWish ? '/icons/button__bookmark-active.svg' : '/icons/button__bookmark.svg'}
                alt="북마크"
              />
            </Button>
            <Button action={() => handleWebShare()}>
              <img src={'/icons/button__share.svg'} alt="공유" />
            </Button>
          </div>
        </div>

        <h4>{title}</h4>

        <div className={styles['product__description']}>
          {isDiscount ? (
            <>
              <div className={styles['product__price']}>
                <h2>${price}</h2>
                <span>-{discountRatio}%</span>
              </div>
              <h3>${originalPrice}</h3>
            </>
          ) : (
            <div className={styles['product__price']}>
              <h2>${price}</h2>
            </div>
          )}
        </div>

        <div className={styles['product__buttons']}>
          <div className={styles['product__button--wish']} onClick={handleSubmitWish}>
            <img
              src={
                hasWish
                  ? '/icons/button__bookmark-active.svg'
                  : '/icons/button__bookmark__desktop.svg'
              }
              alt="북마크"
            />
            {t('product_wish')}
          </div>
          <div className={styles['product__button']}>
            <CartButton item={product} isSoldout={product.totalInventory < 1} />
          </div>
        </div>

        <div className={styles['product__related__categories']}>
          {product.tags.map((tag, idx) => (
            <div
              key={idx}
              className={styles['product__related__category']}
              onClick={() => handleClickTag(tag)}
            >
              {tag}
            </div>
          ))}
        </div>
      </section>
      {isOpened && shareModal}
    </>
  );
};

export default ProductBasicInfo;

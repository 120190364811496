import { MouseEventHandler, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Order } from '../../../@types/customer';
import { cancelOrder } from '../../../api/backend/order';
import Empty from '../../empty/Empty';
import styles from './styles/OrderListContainer.module.scss';

const OrderListItem = ({ data }: { data: Order }): ReactElement => {
  const go = useNavigate();
  const { t } = useTranslation();

  const { orderNumber, lineItems } = data;

  const status: { [key: string]: string } = {
    PENDING: t('account_order_status_pending'),
    READY: t('account_order_status_ready'),
    SHIPPING: t('account_order_status_shipping'),
    SHIPPED: t('account_order_status_shipped'),
    CANCELLED: t('account_order_status_returns'),
  };

  const handleCancelOrder = async () => {
    if (confirm('Are you sure?')) {
      const orderId = data.id.split('?')[0].split('/')[4];
      await cancelOrder(orderId, data.orderNumber);
      window.location.reload();
    }
  };

  const onClickButton = (type: keyof typeof status) => {
    if (type === 'SHIPPED') {
      go('/account/reviews');
    }
  };
  const onClickChangeAddress = () => {
    go(`/account/orders/delivery-info/${encodeURIComponent(data.id)}`);
  };

  const onClickTrackingOrder: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    if (data.successfulFulfillments[0].trackingInfo.length < 1) {
      // TODO: Cannot track delivery
      return;
    }

    window.open(data.successfulFulfillments[0].trackingInfo[0].url);
  };

  const totalNumber = lineItems.edges.reduce((a, { node: c }) => a + c.currentQuantity, 0);

  return (
    <article className={styles['article']}>
      <header className={styles['header']}>
        <div className={styles['header__no']}>
          <span>{t('account_order_no')}</span>
          <span>{orderNumber}</span>
        </div>

        <div className={styles['header__status']}>
          <div className={styles[`header__status--${data.status}`]}>{status[data.status]}</div>
        </div>
      </header>

      <main className={styles['main']}>
        <Link to={`/account/orders/${encodeURIComponent(data.id)}`}>
          <div
            className={styles['main__container']}
            style={
              data.status !== 'CANCELLED'
                ? {
                    borderBottom: '1px solid #f5f5f5',
                  }
                : {}
            }
          >
            <div className={styles['main__thumnail']}>
              {data.lineItems.edges[0].node.variant && (
                <img
                  src={data.lineItems.edges[0].node.variant.image.originalSrc}
                  alt={lineItems.edges[0].node.title}
                />
              )}
            </div>

            <div className={styles['main__description']}>{lineItems.edges[0].node.title}</div>

            <div className={styles['main__count']}>+{totalNumber}</div>

            <div className={styles['main__arrow']}>
              <img src="/icons/account__order-chevron-next.svg" alt="오른쪽 화살표" />
            </div>
          </div>
        </Link>

        {status[data.status] !== t('account_order_status_ready') && (
          <div className={styles['main__control']}>
            {status[data.status] === t('account_order_status_pending') && (
              <>
                <div className={styles['main__control--unfilled']} onClick={handleCancelOrder}>
                  {t('account_order_cancel')}
                </div>
                <div className={styles['main__control--filled']} onClick={onClickChangeAddress}>
                  {t('account_order_change_address')}
                </div>
              </>
            )}

            {status[data.status] === t('account_order_status_shipping') && (
              <div className={styles['main__control--filled']} onClick={onClickTrackingOrder}>
                {t('account_order_tracking')}
              </div>
            )}

            {status[data.status] === t('account_order_status_shipped') && (
              <>
                <div className={styles['main__control--unfilled']} onClick={onClickTrackingOrder}>
                  {t('account_order_tracking')}
                </div>
                <div
                  className={styles['main__control--filled']}
                  onClick={() => onClickButton('SHIPPED')}
                >
                  {t('account_order_review')}
                </div>
              </>
            )}
            {status[data.status] === t('account_order_status_returns') && (
              <>
                <div
                  className={styles['main__control--filled']}
                  onClick={() => go(`/account/orders/${encodeURIComponent(data.id)}`)}
                >
                  {t('account_order_cancelled')}
                </div>
              </>
            )}
          </div>
        )}
      </main>
    </article>
  );
};

const OrderListContainer = ({ data }: { data: Order[] }): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <section className={styles['section']}>
      {data && data.length > 0 ? (
        data.map((orderData) => <OrderListItem key={orderData.orderNumber} data={orderData} />)
      ) : (
        <Empty
          message={t('account_order_empty')}
          buttonMessage="account_order_empty_button"
          subMessage="account_order_empty_sub_message"
          onClickEmpty={() => {
            navigate('/');
          }}
          className="empty_order"
        />
      )}
    </section>
  );
};

export default OrderListContainer;

import { ReactElement, useMemo } from 'react';
import { CouponDataState, OpenHandler, SelectedCouponState } from '../../../@types/checkout';
import { INITAIL_COUPON } from '../../../@types/coupon';
import { Input } from '../../input';
import CheckoutItem from '../CheckoutItem';
import styles from './CheckoutTemplates.module.scss';
import { useTranslation } from 'react-i18next';

type TCouponsType = CouponDataState &
  Partial<OpenHandler> &
  Pick<SelectedCouponState, 'setSelectedCoupon'>;

const TCoupons = ({
  coupon,
  setCoupon,
  type,
  onClickCouponModal,
  setIsOpenStatusObject,
  setSelectedCoupon,
}: TCouponsType & {
  type?: 'Checkout Desktop';
  onClickCouponModal?: () => void;
}): ReactElement => {
  const { t } = useTranslation();
  const onClickDelete = () => {
    setCoupon(INITAIL_COUPON);
    setSelectedCoupon(INITAIL_COUPON);
  };

  const onClickCouponApply = () => {
    if (setIsOpenStatusObject) {
      setIsOpenStatusObject((prev) => ({
        ...prev,
        couponApply: true,
      }));
    }
  };

  const couponElement = useMemo(() => {
    const value =
      coupon.code === 'Deleted'
        ? '0'
        : coupon.id === -1
        ? coupon.code
        : `-$${coupon.amount?.toFixed(2)}`;

    return (
      <div className={styles['coupon-element']}>
        <span className={coupon.code === 'Deleted' ? styles['deleted-coupon-text'] : ''}>
          {coupon.code === 'Deleted'
            ? t('checkout_coupon_code_deleted')
            : t('checkout_coupon_code')}
        </span>
        <div>
          <div
            className={`${styles['coupon-form']} ${
              coupon.code === 'Deleted' ? styles['deleted-coupon-input'] : ''
            }`}
          >
            <Input
              disabled
              value={value}
              onClick={type === 'Checkout Desktop' ? onClickCouponModal : onClickCouponApply}
            />
            <img onMouseDown={onClickDelete} src="/icons/search__clear.svg" alt="쿠폰 삭제" />
          </div>
          <button onClick={type === 'Checkout Desktop' ? onClickCouponModal : onClickCouponApply}>
            {t('checkout_coupon_button')}
          </button>
        </div>
      </div>
    );
  }, [coupon, coupon.amount]);

  if (coupon.code) {
    return (
      <CheckoutItem
        title={t('checkout_coupon')}
        element={couponElement}
        options={{ chevronShow: true }}
      />
    );
  }

  return (
    <CheckoutItem
      title={t('checkout_coupon')}
      placeholder={t('checkout_coupon_placeholeder')}
      onClickInner={type === 'Checkout Desktop' ? onClickCouponModal : onClickCouponApply}
      options={{ chevronShow: true }}
    />
  );
};

export default TCoupons;

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Button from './Button';
import styles from './styles/WishListButton.module.scss';

const WishListButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className={styles['wishlist__button__container']}
      onClick={() => {
        navigate('/cart');
      }}
    >
      <div className={styles['wishlist__button__img']}>
        <img src={'/icons/shopping-cart.svg'} alt="장바구니" />
      </div>
      <Button className={styles['wishlist__button']}>{t('account_wish_button')}</Button>
    </div>
  );
};

export default WishListButton;

const en = {
  search_bar_basic__placeholder: 'Search for products you want!',
  search_bar_search_submit: 'Search',
  search_bar_search_hitory: 'Search History',
  search_bar_search_hitory_delete: 'Delete All',
  search_bar_search_popular: 'Popular Searches',
  search_bar_search_popular_empty: "There's nothing.",
  login_error_box: `Email or password is incorrect. <br /><b>Please try again.</b>`,
  login_error_box_bold: 'Please try again',
  login_title: 'Sign in',
  login_register_radio_title: 'Create Account',
  login_register_radio_subtitle: 'New to myKmarket?',
  login_signin_radio_title: 'Sign in',
  login_signin_radio_subtitle: 'Already have an account?',
  login_signin_form_email: 'Email',
  login_signin_form_email_placeholder: 'Please enter your email.',
  login_signin_form_password: 'Password',
  login_signin_form_password_placeholder: 'At least 6 characters',
  login_form_button: 'CONTINUE',
  login_policy:
    "By continuing, you agree to myKmarket's <link1>Conditions of Use</link1> and <link2>Privacy Notice</link2>.",
  login_forgot_password: 'Forgot password?',
  password_title: 'Password assistance',
  password_subdescription:
    'Enter the email address or mobile phone number associated with your myKmarket account.',
  password_form_label: 'Mobile number or email',
  password_form_placeholder: 'Mobile number or email',
  password_form_button: 'CONTINUE',
  password_description: 'Has your email or mobile number changed?',
  password_description_subdescription:
    'If you no longer use the email address associated with your myKmarket account, you may contact <b>Customer Service</b> for help restoring access to your account.',
  password_modal: `A password reset notice has been sent to the email you entered.`,
  password_modal_button: 'Confirm',
  password_modal_error: `{{message}}`,
  password_modal_error_null: `Please check your email address`,
  register_title: 'Create Account',
  register_description: 'Please register using the account detail below.',
  register_form_email: 'Email',
  register_form_email_placeholder: 'Please enter your email.',
  register_form_first_name: 'First Name',
  register_form_first_name_placeholder: 'First Name',
  register_form_last_name: 'Last Name',
  register_form_last_name_placeholder: 'Last Name',
  register_form_phone: 'Mobile number',
  register_form_phone_placeholder: "Please enter without '-'.",
  register_form_password: 'Password',
  register_form_password_placeholder: 'At least 6 characters',
  register_form_password_confirm: 'Re-enter password',
  register_form_password_confirm_placeholder: 'At least 6 characters',
  register_form_button: 'CREATE',
  register_descrition:
    "By continuing, you agree to myKmarket's Conditions of Use and Privacy Notice.",
  register_link_help: ' Need help?',
  register_link_return: 'Return to Store',
  register_invite: `From your friend
  {{inviterName}}`,
  register_modal: 'Your membership has been completed.',
  register_modal_error: 'There was an error creating your account. (Unknown error)',
  register_modal_error_password: 'Password is incorrect.',
  register_modal_error_empty: 'Please enter the correct {{key}}.',
  register_modal_error_name_empty: 'Please enter the correct fullname.',
  register_modal_error_phone: 'Please enter a valid mobile phone number.',
  register_modal_error_phone_empty: 'Please enter a valid mobile phone number.',
  register_modal_error_phone_duplicated: 'This phone number is already registered.',
  register_modal_error_email: 'Please enter a valid email.',
  register_modal_error_email_duplicated: 'This email is already registered.',
  register_modal_error_invite: 'Invitation code is incorrect.',
  account_title: 'ACCOUNT',
  account_user_info_point: 'My Points',
  account_user_info_refer: 'Invite Friends, ',
  account_user_info_refer_span: 'Get $20',
  account_user_info_my_orders: 'My Orders',
  account_user_info_my_orders_pending: 'Pending',
  account_user_info_my_orders_shipping: 'Shipping',
  account_user_info_my_orders_shipped: 'Shipped',
  account_user_info_my_orders_return: 'Cancelled',
  account_user_info_wish: 'Wish List',
  account_user_info_reward: 'Reward Points',
  account_user_info_coupon: 'Coupons',
  account_user_info_review: 'Post a Review',
  account_user_info_help: 'Help Center',
  account_user_info_help_sub: 'Contact us, FAQ, etc.',
  account_user_info_setting: 'Settings',
  account_user_info_setting_sub: 'Addresses, payment, notifications, etc.',
  account_user_info_language: 'Language',
  account_user_info_language_sub: 'English, Korean',
  account_user_info_info: 'Information',
  account_user_info_policy: 'Policy',
  account_logout: 'Log Out',
  account_aside_point: 'My Points',
  account_aside_refer: 'Invite Friends, ',
  account_aside_refer_span: 'Get $20',
  account_aside_my_orders: 'My Orders',
  account_aside_wish: 'Wish List',
  account_aside_coupon: 'Coupons',
  account_aside_review: 'Post a Review',
  account_aside_contact: 'Contact Us',
  account_aside_help: 'Help Center',
  account_aside_setting: 'Settings',
  account_aside_language: 'Language',
  account_aside_info: 'Information',
  account_aside_policy: 'Policy',
  account_aside_logout: 'Log Out',
  account_aside_login: 'Log In',
  account_profile_title: 'ACCOUNT PROFILE',
  account_profile_page_title: 'Account Profile',
  account_profile_edit_title: 'EDIT PROFILE',
  account_profile_edit_page_title: 'Edit Profile',
  account_profile_edit_button: 'Edit Profile',
  account_profile_change_photo: 'Change Profile Photo',
  account_profile_name: 'Name',
  account_profile_phone: 'Phone',
  account_profile_email: 'Email',
  account_profile_address_book: 'Address Book',
  account_profile_change_password: 'Change Password',
  account_profile_change_name_title: 'CHANGE NAME',
  account_profile_change_name_page_title: 'Change Name',
  account_profile_change_name_label: 'Name',
  account_profile_change_name_first_placeholder: 'First Name',
  account_profile_change_name_last_placeholder: 'Last Name',
  account_profile_change_name_button: 'SAVE',
  account_profile_change_name_modal: 'You have successfully renamed it was renamed.',
  account_profile_change_name_modal_button: 'Confirm',
  account_profile_change_name_modal_alert: 'Please enter your name correctly.',
  account_profile_change_name_modal_alert_button: 'Confirm',
  account_profile_change_name_modal_error: 'Please enter your name correctly.',
  account_profile_change_name_modal_error_button: 'Confirm',
  account_profile_change_phone_title: 'CHANGE PHONE NUMBER',
  account_profile_change_phone_page_title: 'Change Phone Number',
  account_profile_change_phone_placeholder: "Please enter without '-'.",
  account_profile_change_phone_button: 'SAVE',
  account_profile_change_phone_done: 'You have successfully changed your phone number.',
  account_profile_change_phone_done_button: 'SAVE',
  account_profile_change_phone_error: 'Please enter your phone number correctly.',
  account_profile_change_phone_error_button: 'SAVE',
  account_profile_change_phone_error_empty: 'Please enter your phone number correctly.',
  account_profile_change_phone_error_empty_button: 'SAVE',
  account_profile_delivery_title: 'DELIVERY INFO',
  account_profile_delivery_page_title: 'Delivery Info',
  account_profile_delivery_address_title: 'Select from My Addresses',
  account_profile_delivery_address_add: 'Add a New Address',
  account_profile_delivery_address_button: 'SAVE',
  account_profile_delivery_address_modal: 'Please check your address',
  account_profile_delivery_address_modal_button: 'Confirm',
  account_profile_delivery_address_empty: 'There is no registered delivery address.',
  account_profile_delivery_address_empty_button: 'Add delivery address',
  account_profile_delivery_address_error: `Please enter the correct address.
  If the address is incorrect, it may be returned.`,
  account_profile_delivery_address_error_firstName: 'firstName',
  account_profile_delivery_address_error_lastName: 'lastName',
  account_profile_delivery_address_error_address1: 'address1',
  account_profile_delivery_address_error_city: 'city',
  account_profile_delivery_address_error_country: 'country',
  account_profile_delivery_address_error_phone: 'phone',
  account_profile_delivery_address_error_province: 'province',
  account_profile_delivery_address_error_zip: 'zip',
  account_profile_delivery_add_title: 'ADD NEW ADDRESS',
  account_profile_delivery_add_page_title: 'Add New Address',
  account_profile_delivery_edit_title: 'EDIT NEW ADDRESS',
  account_profile_delivery_edit_page_title: 'Edit New Address',
  account_profile_delivery_add_name: 'Recipient Name',
  account_profile_delivery_add_first_name: 'First Name',
  account_profile_delivery_add_last_name: 'Last Name',
  account_profile_delivery_add_description_alert: 'Please enter your full & exact name.',
  account_profile_delivery_add_description:
    'Delivery may get stuck in customs if you do not enter the correct name.',
  account_profile_delivery_add_contact: 'Contact Info',
  account_profile_delivery_add_phone: 'Phone Number',
  account_profile_delivery_add_address: 'Delivery Address',
  account_profile_delivery_add_country: 'Country/region',
  account_profile_delivery_add_street: 'Street Address',
  account_profile_delivery_add_unit: 'Unit of Apartment # (Optional)',
  account_profile_delivery_add_city: 'City',
  account_profile_delivery_add_state: 'State',
  account_profile_delivery_add_zip: 'Zip code',
  account_profile_delivery_add_save: 'SAVE',
  account_profile_delivery_add_cancel: 'CANCEL',
  account_profile_change_password_title: 'CHANGE PASSWORD',
  account_profile_change_password_page_title: 'Change Password',
  account_profile_change_password_new: 'New Password',
  account_profile_change_password_new_placeholder: 'At least 6 characters',
  account_profile_change_password_re: 'Re-enter New Password',
  account_profile_change_password_re_placeholder: 'At least 6 characters',
  account_profile_change_password_button: 'SAVE',
  account_profile_change_password_modal: 'Password changed successfully.',
  account_profile_change_password_modal_button: 'Confirm',
  account_profile_change_password_modal_error: 'The new password does not match.',
  account_order_title: 'MY ORDERS',
  account_order_page_title: 'My Orders',
  account_order_tab: 'All',
  account_order_tab_pending: 'Pending',
  account_order_tab_ready: 'Ready to ship',
  account_order_tab_shipping: 'Shipping',
  account_order_tab_shipped: 'Shipped',
  account_order_tab_returns: 'Cancelled',
  account_order_empty: 'No Order History',
  account_order_empty_button: 'START SHOPPING',
  account_order_empty_sub_message: 'Put your items in your cart and get free shipping!',
  account_order_no: 'Order No.',
  account_order_status_pending: 'Pending',
  account_order_status_ready: 'Ready to ship',
  account_order_status_shipping: 'Shipping',
  account_order_status_shipped: 'Shipped',
  account_order_status_returns: 'Cancelled',
  account_order_cancel: 'Cancel order',
  account_order_change_address: 'Change delivery address',
  account_order_tracking: 'Delivery tracking',
  account_order_review: 'Write a review',
  account_order_cancelled: 'Cancelled Order Detail',
  account_orders_detail_title: 'ORDER DETAILS',
  account_orders_detail_page: 'Order Details',
  account_orders_modal_address: 'Your address has been changed.',
  account_orders_modal_address_error: 'Please try again.',
  account_orders_modal_address_fail: 'Address changes can only be made in the ‘Pending’ state.',
  account_orders_modal_cancel: 'Are you sure you want to cancel this order?',
  account_orders_modal_cancel_button: 'continue',
  account_orders_modal_cancel_toast: 'Your order has been cancelled.',
  account_point_title: 'REDEEM POINTS',
  account_point_page_title: 'Redeem Points',
  account_point_state_point: 'My Point',
  account_point_state_conversion: 'Coupon Conversion',
  account_point_state_conversion_mobile: 'Coupon',
  account_point_state_description: 'equal to $',
  account_point_redeem: 'Order discount',
  account_point_redeem_subtitle: '100 Points = $1',
  account_point_redeem_description: ' Points for $',
  account_point_redeem_description2: ' off',
  account_point_redeem_button: 'redeem',
  account_point_redeem_description_title: 'How to Earn Free Benefits',
  account_point_redeem_description_item1: 'Refer friends and claim $10',
  account_point_redeem_description_item2: 'Post a review and earn $1',
  account_point_redeem_description_item3: 'Earn 1 Point for every $1 spent',
  account_point_redeem_description_alert: '*100 points converts to $1',
  account_point_redeem_modal_title: 'Notice',
  account_point_redeem_modal_description:
    'Coupon conversion is complete.\nPlease check in my coupon box.',
  account_point_redeem_modal_button: 'confirm',
  account_invite: 'INVITE FRIEND',
  account_invite_page: 'Invite Friend',
  account_invite_description:
    'Introduce myKmarket to your friend and get some reserves with your friend. 1000 points ($10) will be paid to each friend after purchasing from myKmarket and the delivery is completed. Introduce myKmarket to another friend and accumulate unlimited reserves one by one. (Based on 100 points = $1 and points will be paid after delivery.)',
  account_invite_button: 'Invite a friend!',
  account_invite_invited: 'Friends invited',
  account_invite_earned: 'Rewards earned',
  account_invite_modal_title: 'Receive {$10} rewards from the following {1} orders.',
  account_invite_modal_description: 'Shop Asian groceries on myKmarket!',
  account_invite_modal_bottom_description:
    'Gifts <span>$10</span> to a friend, gets <span>$10</span> Rewards',
  account_wish: 'WISH LIST',
  account_wish_page: 'Wish List',
  account_wish_tab: 'My Wish List',
  account_wish_button: 'GO TO CART',
  account_wish_empty: 'There is no wish list product.',
  account_wish_empty_button: 'START SHOPPING',
  account_wish_soldout: 'Sold Out',
  account_coupon: 'COUPONS',
  account_coupon_page: 'Coupons',
  account_coupon_tab1: 'AVAILABLE',
  account_coupon_tab2: 'USED',
  account_coupon_tab3: 'EXPIRED',
  account_coupon_empty_message: "You don't have any coupons.",
  account_review: 'POST REVIEW',
  account_review_page: 'Post Review',
  account_review_tab1: 'To Reviews',
  account_review_tab2: 'Completed Reviews',
  account_review_empty_write_message: 'No orders available to write a review.',
  account_review_empty_edit_message: 'No reviews have been made. ',
  account_review_write: 'Be The First To Write A Review',
  account_review_write_button: 'Write a detailed review',
  account_review_write_deadline: 'Deadline: ',
  account_review_written_button: 'Edit',
  account_review_edit_write: 'WRITE A REVIEW',
  account_review_edit_edit: 'EDIT A REVIEW',
  account_review_edit_page_write: 'Write A Review',
  account_review_edit_page_edit: 'Edit A Review',
  account_review_edit_header: 'Please rate this product in detail.',
  account_review_edit_text_title: 'Details',
  account_review_edit_text_placeholder:
    'Please leave an honest review of the product to help other customers. (Please contact the customer service center for delivery, packaging, question response, product price, etc. that are not related to product quality.)',
  account_review_edit_pictures: 'Attach pictures',
  account_review_edit_files: 'Attach file',
  account_review_edit_edit_button: 'COMPLETED',
  account_review_edit_write_button: 'COMPLETED',
  account_help: 'HELP CENTER',
  account_help_page: 'Help Center',
  account_help_contact: 'CONTACT US',
  account_help_contact_page: 'Contact Us',
  account_help_faq: 'FAQ',
  account_help_faq_all: 'ALL',
  account_help_qna: 'Q&A',
  account_help_request: 'Request',
  account_help_partnership: 'Partnership',
  account_help_notification: 'Notifications',
  account_help_faq_header: 'Do you need help?',
  account_help_faq_mail: 'Customer Center',
  account_help_faq_title: 'FAQ',
  account_help_faq_empty: 'No FAQ',
  account_help_request_message:
    'If the item you want to purchase is unavailable, please send a request to mykmarket.',
  account_help_request_empty: 'No Request History',
  account_help_request_button: 'NEW REQUEST',
  account_help_request_title: 'REQUEST',
  account_help_request_new_title: 'New Request',
  account_help_request_new_title_placeholder:
    "Please enter the 'brand name' of the product you are looking for.",
  account_help_request_new_detail_placeholder: 'Please leave your inquiry details.',
  account_help_request_new_button: 'send',
  account_help_partnership_title: 'PARTNERSHIP',
  account_help_partnership_company: 'Company Name',
  account_help_partnership_company_placeholder: 'Please enter the company name.',
  account_help_partnership_email: 'Return e-mail',
  account_help_partnership_email_placeholder:
    'Please enter the email address to receive an answer.',
  account_help_partnership_detail: 'Details',
  account_help_partnership_detail_placeholder: 'Please enter detailed suggestions.',
  account_help_partnership_button: 'SEND',
  account_help_partnership_modal: 'Partnership proposal is complete.',
  account_help_partnership_modal_button: 'Confirm',
  account_help_partnership_modal_error: 'Please check your proposal information.',
  account_help_partnership_modal_error_button: 'Confirm',
  account_help_notification_title: 'NOTIFICATIONS',
  account_help_notification_tag: 'Notice',
  account_help_notification_empty: 'No Notifications',
  account_notification: 'PUSH NOTIFICATIONS',
  account_notification_page: 'Push Notifications',
  account_notification_sales_title: 'Sales & Promotion',
  account_notification_sales_description: 'Information on events and promotions.',
  account_notification_sales_toggle: 'New Arrivals, Best Selling, Limited Time Deals',
  account_notification_personalized_title: 'Personalized notifications',
  account_notification_personalized_description:
    'Introducing coupons, restock notifications, and community messages.',
  account_notification_personalized_toggle: 'New Coupon, Restock, Community Message',
  account_notification_order_title: 'Order and system notifications',
  account_notification_order_description:
    '*For your convenience, you can’t disable for these notifications.',
  account_language: 'LANGUAGE',
  account_language_page: 'Language',
  account_language_en: 'English',
  account_language_ko: 'Korean',
  account_language_button: 'SAVE',
  account_information: 'INFORMATION',
  account_information_page: 'Information',
  account_information_tab1: 'About Our Service',
  account_information_tab2: 'Customer Benefits',
  main_render_header_more: 'See all',
  category_aside_filters: 'Filters',
  category_aside_for_sale: 'For Sale',
  category_aside_price: 'Price',
  category_aside_price_unlimited: 'Unlimited',
  category_aside_price_under5: 'Under $5',
  category_aside_price_under10: '$5 - $10',
  category_aside_price_under15: '$10 - $15',
  category_aside_price_under25: '$15 - $25',
  category_aside_price_over25: '$25 - Above',
  category_aside_price_custom: 'Enter Price',
  category_aside_price_custom_min: 'Min',
  category_aside_price_custom_min_placeholder: 'Min Price',
  category_aside_price_custom_max: 'Max',
  category_aside_price_custom_max_placeholder: 'Max Price',
  category_aside_price_custom_apply: 'Apply',
  category_aside_price_custom_modal: 'Please check the price range.',
  category_aside_price_custom_modal_button: 'Confirm',
  category_name: 'Category',
  category_name_All: 'All',
  category_name_foods: 'Foods',
  'category_name_nuts-dried-fruits': 'Nuts/Dried Fruits',
  'category_name_rice-mixed-grains': 'Rice/Mixed Grains',
  'category_name_dried-seafood': 'Dried Seafood',
  'category_name_coffee-tea': 'Coffee/Tea',
  'category_name_snacks-cereals': 'Snacks/Cereals',
  'category_name_candy-jelly': 'Candy/Jelly',
  category_name_ramen: 'Ramen',
  'category_name_noodles-can-processed-food': 'Noodles/Can/Processed Food',
  'category_name_sauce-oil-seasoning': 'Sauce/Oil/Seasoning',
  'category_name_baby-kids-food': 'Baby & Kids Food',
  category_name_beauty: 'Beauty',
  'category_name_skin-care': 'Skin Care',
  'category_name_shave-hair-removal': 'Shave/Hair Removal',
  'category_name_cleansing-peeling': 'Cleansing/Peeling',
  category_name_makeup: 'Makeup',
  category_name_fragrances: 'Fragrances',
  'category_name_mens-care': 'Mens Care',
  'category_name_hair-care-body-care': 'Hair Care/Body Care',
  'category_name_foot-hand-nail-care': 'Foot, Hand & Nail Care',
  'category_name_tools-accessories': 'Tools & Accessories',
  'category_name_kids-beauty': 'Kids Beauty',
  'category_name_vitamins-minerals': 'Vitamins/Minerals',
  category_name_supplements: 'Supplements',
  'category_name_ginseng-red-ginseng': 'Ginseng/Red Ginseng',
  'category_name_concentrated-juice-drinks': 'Concentrated Juice/Drinks',
  'category_name_honey-propolis': 'Honey/Propolis',
  'category_name_powder-pills': 'Powder/Pills',
  'category_name_weight-management': 'Weight Management',
  'category_name_medical-supplies-equipments': 'Health Equiptments',
  'category_name_health-supplements': 'Health Supplements',
  category_name_baby: 'Baby',
  'category_name_baby-fashion': 'Baby Fashion',
  category_name_nursing: 'Nursing',
  'category_name_baby-carrier-outdoor': 'Baby Carrier/Outdoor',
  'category_name_baby-bedding': 'Baby Bedding',
  'category_name_baby-safety': 'Baby Safety',
  'category_name_baby-hair-care-body-care': 'Baby Hair Care/Body Care',
  'category_name_pregnancy-maternity': 'Pregnancy/Maternity',
  'category_name_baby-tableware': 'Baby Tableware',
  category_name_home: 'Home',
  category_name_laundry: 'Laundry',
  'category_name_household-cleaning-air-fresheners': 'Household Cleaning/Air Fresheners',
  'category_name_bathroom-supplies': 'Bathroom Supplies',
  'category_name_home-storage-organization': 'Home Storage/Organization',
  'category_name_household-supplies': 'Household Supplies',
  category_name_kitchen: 'Kitchen',
  category_name_cookware: 'Cookware',
  'category_name_cutlery-knife-accessories': 'Cutlery/Knife Accessories',
  'category_name_utensils-gadgets': 'Utensils/Gadgets',
  category_name_dinnerware: 'Dinnerware',
  category_name_flatware: 'Flatware',
  'category_name_glassware-drinkware': 'Glassware/Drinkware',
  'category_name_kitchen-storage-organization': 'Kitchen Storage/Organization',
  'category_name_food-containers': 'Food Containers',
  'category_name_kitchen-accessories': 'Kitchen Accessories',
  'category_name_thermocoolers-thermoses': 'Thermocoolers/Thermoses',
  'category_name_baking-supplies': 'Baking Supplies',
  'category_name_korean-traditional-supplies': 'Korean Traditional Supplies',
  'category_name_stationary-office': 'Stationery & Office',
  'category_name_stationery-office': 'Stationery & Office',
  'category_name_office-supplies': 'Office Supplies',
  'category_name_arts-crafts': 'Arts & Crafts',
  'category_name_character-stationery': 'Character Stationery',
  'category_name_party-decorations': 'Party Decorations',
  'category_name_gift-wrapping': 'Gift Wrapping',
  'category_name_cards-letters-envelopes': 'Cards/Letters/Envelopes',
  category_name_albums: 'Albums',
  category_name_toys: 'Toys',
  'category_name_character-toys': 'Character Toys',
  'category_name_baby-toddler-toys': 'Baby & Toddler Toys',
  'category_name_robots-electronic-toys': 'Robots/Electronic Toys',
  'category_name_sports-outdoor-play': 'Sports / Outdoor Play',
  'category_name_learning-education': 'Learning/Education',
  'category_name_games-puzzles': 'Games/Puzzles',
  'category_name_remote-control-play': 'Remote Control & Play',
  'category_name_action-figures-statues': 'Action Figures/Statues',
  'category_name_musical-instraments-equipments': 'Musical Instraments/Equipments',
  category_name_books: 'Books',
  'category_name_childrens-books': "Children's Books",
  'category_name_novel-essay-poet': 'Novel/Essay/Poet',
  'category_name_cookbooks-food-wine': 'Cookbooks/Food&Wine',
  'category_name_health-fitness-hobby': 'Health/Fitness/Hobby',
  'category_name_comics-graphic-novels': 'Comics/Graphic Novels',
  'category_name_politics-social-sciences': 'Politics/Social Sciences',
  category_name_history: 'History',
  category_name_humanities: 'Humanities',
  'category_name_computers-technology': 'Computers/Technology',
  'category_name_cd-lp': 'CD/LP',
  'category_name_sports-outdoors': 'Sports/ Outdoors',
  category_name_camping: 'Camping',
  'category_name_exercise-fitness': 'Exercise/Fitness',
  'category_name_water-sports': 'Water Sports',
  category_name_golf: 'Golf',
  category_name_bikes: 'Bikes',
  category_name_fishing: 'Fishing',
  'category_name_sports-goods-accessories': 'Sports Goods & Accessories',
  'category_name_racquet-sports': 'Racquet Sports',
  'category_name_boxing-martial-arts': 'Boxing/Martial Arts',
  category_name_automotive: 'Automotive',
  'category_name_interior-accessories': 'Interior Accessories',
  'category_name_exterior-accessories': 'Exterior Accessories',
  'category_name_car-care-cleaning-supplies-fluids': 'Car Care/Cleaning Supplies/Fluids',
  'category_name_tools-equipments-safety': 'Tools/Equipments/Safety',
  category_name_fashion: 'Fashion',
  'category_name_womens-fashion': "Women's Fashion",
  'category_name_mens-fashion': "Men's Fashion",
  category_name_pets: 'Pets',
  'category_name_dog-treats': 'Dog Treats',
  'category_name_dog-supplies': 'Dog Supplies',
  'category_name_cat-treats': 'Cat Treats',
  'category_name_cat-supplies': 'Cat Supplies',
  category_empty: 'No Result',
  cart_list_select: 'Select All',
  cart_list_select_delete: 'Delete Selection',
  cart_guide_a: ' ',
  cart_guide_b: ' Free Shipping on Addition',
  cart_guide_more: 'Shop More',
  cart_item_remove: 'Remove',
  cart_title: 'My Cart',
  cart_desktop_sum: '{{sum}} items total',
  cart_desktop_shipping: 'Shipping fee',
  cart_desktop_shipping_description: 'calculated at next step',
  cart_desktop_total_sum: 'Total',
  cart_unavailable: 'Unavailable Product ({{items}} item)',
  cart_unavailable_clear: 'Delete All',
  cart_checkout_button: 'CHECKOUT',
  cart_checkout_button_mobile: 'Checkout({{items}} item)',
  cart_free_shipping: 'Free Shipping',
  cart_delivery_total: '{{sum}} items total',
  cart_delivery_from: 'Delivery from',
  cart_delivery_where: 'Republic of Korea',
  cart_toast_a: 'Add',
  cart_toast_b: 'more to cart to reach ${{price}}',
  cart_item_empty_soldout: 'Sold Out',
  cart_item_empty_notify: 'Notify Me',
  cart_item_empty_remove: 'Delete',
  cart_checkout_modal: 'Please select at least one product.',
  cart_checkout_modal_button: 'Confirm',
  product_wish: 'WISH LIST',
  product_cart_add: 'ADD TO CART',
  product_cart_in: 'IN CART',
  product_cart_soldout: 'NOTIFY ME',
  product_review: 'REVIEWS',
  product_review_write: 'Be The First To Write A Review',
  product_detail: 'DETAILS',
  product_detail_share_modal_description: 'Shop Asian groceries on myKmarket!',
  product_detail_empty: 'Please try again.',
  product_notify: 'Notify me',
  product_notify_modal: `Restock notification has been requested. We will let you know by email 
  when product is restocked.`,
  product_notify_modal_button: 'Confirm',
  checkout: 'CHECKOUT',
  checkout_page: 'Checkout',
  checkout_shipping: 'Delivery Info',
  checkout_shipping_placeholder: 'Please enter your delivery address and contact information.',
  checkout_shipping_address_description:
    'There are no shipping instructions with this order. (Optional)',
  checkout_shipping_address_edit: 'Edit',
  checkout_guide_free: "You've got free delivery on this order",
  checkout_guide_a: ' ',
  checkout_guide_b: ' Free Shipping on Addition',
  checkout_guide_more: 'Shop More',
  checkout_carrier: 'Carrier Information',
  checkout_carrier_main: 'Carrier Information',
  checkout_carrier_sub: 'Carrier Information',
  checkout_carrier_free: 'Free',
  checkout_carrier_next: 'next step',
  checkout_carrier_alert: 'Please check again that address is in english\nfor safe delivery.',
  checkout_coupon: 'Coupons',
  checkout_coupon_placeholeder: 'Please select coupon or enter discount code.',
  checkout_coupon_code: 'Applied coupon',
  checkout_coupon_code_deleted: 'Please select or enter coupon code',
  checkout_coupon_delete_button: 'Delete coupon',
  checkout_coupon_button: 'Apply',
  checkout_coupon_apply_title: 'COUPONS',
  checkout_coupon_apply_code: 'Coupon Code',
  checkout_coupon_apply_placeholder: 'Please enter discount code.',
  checkout_coupon_apply_or: 'or',
  checkout_coupon_apply_select: 'Select Coupon',
  checkout_coupon_apply_save: 'SAVE',
  checkout_coupon_modal_save: 'SAVE',
  checkout_coupon_modal_cancel: 'Cancel',
  checkout_coupon_modal_add: 'Add New Card',
  checkout_coupon_modal_add_save: 'SAVE',
  checkout_coupon_modal_add_cancel: 'Cancel',
  checkout_item: 'Total ({{items}} items)',
  checkout_purcahse: 'Order Summary',
  checkout_purcahse_payment: 'Payment Info',
  checkout_purcahse_subtotal: 'Total ({{items}} item)',
  checkout_purcahse_taxes: 'Taxes',
  checkout_purcahse_shipping: 'Shipping',
  checkout_purcahse_total: 'SubTotal',
  checkout_purcahse_point: 'Coupon',
  checkout_purcahse_amount: 'Order Total',
  checkout_purcahse_button: 'PLACE ORDER',
  checkout_overlay_delivery: 'DELIVERY INFO',
  checkout_overlay_delivery_add: 'DELIVERY INFO',
  checkout_overlay_payment: 'PAYMENT METHOD',
  checkout_overlay_card: 'ADD NEW CARD',
  checkout_overlay_coupon: 'COUPONS',
  checkout_overlay_purchase: 'PURCHASE ITEMS',
  checkout_overlay_purchase_quantity: '{{items}} items',
  checkout_modal: '',
  checkout_modal_button: 'Confirm',
  checkout_modal_alert:
    'Tax may be charged for purchases over $800. Would you like to continue purchase?',
  checkout_modal_alert_button: 'Confirm',
  checkout_modal_error: 'There was a problem with the payment processing. Please try again.',
  checkout_modal_error_button: 'Confirm',
  checkout_modal_error_zip: 'Please enter a valid zip code.',
  checkout_modal_error_city: 'Please enter a valid city name.',
  checkout_modal_error_state: 'Please select a state.',
  checkout_modal_error_zip_button: 'Confirm',
  checkout_modal_error_select: 'Please enter the delivery address.',
  checkout_modal_error_select_button: 'Confirm',

  common_empty_message: 'Recommend myKmarket popular products!',
  common_empty_button: 'Go to Best',

  footer_welcome: 'Welcome to mykmarket!',
  footer_description: 'If you have any questions, please contact the customer center below',
  footer_customer_center: 'Customer Center',
  footer_information: 'Information',
  footer_information_about: 'About Our Service',
  footer_information_benefit: 'Customer Benefits',
  footer_information_contact: 'Contact Us',
  footer_policy: 'Policies',
  footer_policy_shipping: 'Shipping Policy',
  footer_policy_refund: 'Refund Policy',
  footer_policy_privacy: 'Privacy Policy',
  footer_policy_terms: 'Terms of Service',
  footer_rights: 'ⓒ2021 Brandless Co., Ltd. All rights reserved.',
  footer_rights_description: 'Fulfilled By MXN.',

  policy_page: 'Policy',
  policy_shipping: 'SHIPPING POLICY',
  policy_shipping_title: 'Shipping Policy',
  policy_shipping_category: 'Shipping Policy',
  policy_refund: 'REFUND POLICY',
  policy_refund_title: 'Refund Policy',
  policy_refund_category: 'Return & Refund Policy',
  policy_privacy: 'PRIVACY POLICY',
  policy_privacy_title: 'Privacy Policy',
  policy_privacy_category: 'Privacy Policy',
  policy_terms: 'TERMS OF SERVICE',
  policy_terms_title: 'Terms Of Service',
  policy_terms_category: 'Terms Of Service',

  search_popular: 'Popular shopping ideas',
  search_found: '<span>{{items}}</span> result found',
  search_empty: `If the item you want to purchase is unavailable, please send a request to mykmarket.`,
  search_empty_button: 'START REQUEST',
  search_filter_best_selling: 'Best Selling',
  search_filter_newest: 'Newest',
  search_filter_price_low_to_high: 'Price: Low to High',
  search_filter_price_high_to_low: 'Price: High to Low',

  order_detail_payment_info: 'Payment Info',
  order_detail_payment_info_tax: 'Taxes',
  order_detail_payment_info_shipping: 'Shipping',
  order_detail_payment_total: 'Subtotal',
  order_detail_payment_discount: 'Coupon',
  order_detail_payment_tool: 'Payment tool',
  order_detail_payment_total_amount: 'Order Total',
  order_detail_payment_refund: 'Refund',
  order_detail_payment_net_payment: 'Net payment',
  order_detail_delivery_info: 'Delivery Info',
  order_detail_items: 'Order items',
  order_detail_title: 'Order Details',
  order_detail_date: 'Order',
  order_detail_order_number: 'Order No.',
  order_detail_bottom_button_pending: 'CANCEL ORDER',
  order_detail_bottom_button_shipped: 'Write a Review',
  order_detail_delivery_title: 'Order Items',
  order_detail_delivery_cancelled_title: 'Cancelled',
  order_detail_delivery_shipped: 'Delivery completed',
  order_detail_delivery_arrived: 'Arrived',
  order_detail_delivery_tracking: 'Delivery tracking',
  order_detail_item_qty: 'QTY {{quantity}}',
  modal_default_button: 'Confirm',
  modal_default_cancel_button: 'Cancel',
  modal_default_header: 'Notice',

  alert_copied: 'Copied successfully.',

  roulette_header_text: 'Want to unlock today’s exclusive discount?',
  roulette_sub_header_text: 'Enter your email to reveal your special offer!',
  roulette_guide_text_01: 'You can spin the wheel only once.',
  roulette_guide_text_02: 'Single-use coupon.',
  roulette_input_placeholder: 'Enter your Email',
  roulette_submit_button: 'SPIN THE WHEEL!',
  roulette_guide_sub_text:
    'By submitting your email address you agree to the Terms of Use and Sale and to receive marketing emails about products and services we think may be of interest to you. You can opt-out at any time.',
  roulette_inner_popup_description: 'Congratulations you won!<br/>Please check your mailbox.',
  roulette_alert_fail_text: 'This email has been used.',
};

export default en;

/*
const { t } = useTranslation();
t('account_profile_order_tab')
{t('account_profile_order_tab')}
{t('checkout_item', {items: checkedCartItem.length})}
cart_desktop_sum: '{{sum}} items total',
 */

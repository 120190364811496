export type Part = {
  id: number;
  name: string;
  handle: string;
};

export type Collection = {
  name: string;
  handle: string;
  image: string;
  inner: Part[];
};

export const categories: Collection[] = [
  {
    name: 'Foods',
    handle: 'foods',
    image: '/images/common/category01.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'foods',
      },
      {
        id: 2,
        name: 'Nuts/Dried Fruits',
        handle: 'nuts-dried-fruits',
      },
      {
        id: 3,
        name: 'Rice/Mixed Grains',
        handle: 'rice-mixed-grains',
      },
      {
        id: 4,
        name: 'Dried Seafood',
        handle: 'dried-seafood',
      },
      {
        id: 5,
        name: 'Coffee/Tea',
        handle: 'coffee-tea',
      },
      {
        id: 6,
        name: 'Snacks/Cereals',
        handle: 'snacks-cereals',
      },
      {
        id: 7,
        name: 'Candy/Jelly',
        handle: 'candy-jelly',
      },
      {
        id: 8,
        name: 'Ramen',
        handle: 'ramen',
      },
      {
        id: 9,
        name: 'Noodles/Can/Processed Food',
        handle: 'noodles-can-processed-food',
      },
      {
        id: 10,
        name: 'Sauce/Oil/Seasoning',
        handle: 'sauce-oil-seasoning',
      },
      {
        id: 11,
        name: 'Baby & Kids Food',
        handle: 'baby-kids-food',
      },
    ],
  },
  {
    name: 'Beauty',
    handle: 'beauty',
    image: '/images/common/category02.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'beauty',
      },
      {
        id: 2,
        name: 'Skin Care',
        handle: 'skin-care',
      },
      {
        id: 3,
        name: 'Shave/Hair Removal',
        handle: 'shave-hair-removal',
      },
      {
        id: 4,
        name: 'Cleansing/Peeling',
        handle: 'cleansing-peeling',
      },
      { id: 5, name: 'Makeup', handle: 'makeup' },
      { id: 6, name: 'Fragrances', handle: 'fragrances' },
      { id: 7, name: 'Mens Care', handle: 'mens-care' },
      {
        id: 8,
        name: 'Hair Care/Body Care',
        handle: 'hair-care-body-care',
      },
      {
        id: 9,
        name: 'Foot, Hand & Nail Care',
        handle: 'foot-hand-nail-care',
      },
      {
        id: 10,
        name: 'Tools & Accessories',
        handle: 'tools-accessories',
      },
      {
        id: 11,
        name: 'Kids Beauty',
        handle: 'kids-beauty',
      },
    ],
  },
  {
    name: 'Health Supplements',
    handle: 'health-supplements',
    image: '/images/common/category03.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'health-supplements',
      },
      {
        id: 2,
        name: 'Vitamins/Minerals',
        handle: 'vitamins-minerals',
      },
      { id: 3, name: 'Supplements', handle: 'supplements' },
      { id: 4, name: 'Ginseng/Red Ginseng', handle: 'ginseng-red-ginseng' },
      { id: 5, name: 'Concentrated Juice/Drinks', handle: 'concentrated-juice-drinks' },
      { id: 6, name: 'Honey/Propolis', handle: 'honey-propolis' },
      { id: 7, name: 'Powder/Pills', handle: 'powder-pills' },
      { id: 8, name: 'Weight Management', handle: 'weight-management' },
      { id: 9, name: 'Health Equiptments', handle: 'medical-supplies-equipments' },
    ],
  },
  {
    name: 'Baby',
    handle: 'baby',
    image: '/images/common/category04.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'baby',
      },
      {
        id: 2,
        name: 'Baby Fashion',
        handle: 'baby-fashion',
      },
      {
        id: 3,
        name: 'Nursing',
        handle: 'nursing',
      },
      { id: 4, name: 'Baby Carrier/Outdoor', handle: 'baby-carrier-outdoor' },
      { id: 5, name: 'Baby Bedding', handle: 'baby-bedding' },
      { id: 6, name: 'Baby Safety', handle: 'baby-safety' },
      { id: 7, name: 'Baby Hair Care/Body Care', handle: 'baby-hair-care-body-care' },
      { id: 8, name: 'Pregnancy/Maternity', handle: 'pregnancy-maternity' },
      { id: 9, name: 'Baby Tableware', handle: 'baby-tableware' },
    ],
  },
  {
    name: 'Home',
    handle: 'home',
    image: '/images/common/category05.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'home',
      },
      { id: 2, name: 'Laundry', handle: 'laundry' },
      {
        id: 3,
        name: 'Household Cleaning/Air Fresheners',
        handle: 'household-cleaning-air-fresheners',
      },
      { id: 4, name: 'Bathroom Supplies', handle: 'bathroom-supplies' },
      { id: 5, name: 'Home Storage/Organization', handle: 'home-storage-organization' },
      { id: 6, name: 'Household Supplies', handle: 'household-supplies' },
    ],
  },
  {
    name: 'Kitchen',
    handle: 'kitchen',
    image: '/images/common/category06.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'kitchen',
      },
      { id: 2, name: 'Cookware', handle: 'cookware' },
      { id: 3, name: 'Cutlery/Knife Accessories', handle: 'cutlery-knife-accessories' },
      { id: 4, name: 'Utensils/Gadgets', handle: 'utensils-gadgets' },
      { id: 5, name: 'Dinnerware', handle: 'dinnerware' },
      { id: 6, name: 'Flatware', handle: 'flatware' },
      { id: 7, name: 'Glassware/Drinkware', handle: 'glassware-drinkware' },
      { id: 8, name: 'Kitchen Storage/Organization', handle: 'kitchen-storage-organization' },
      { id: 9, name: 'Food Containers', handle: 'food-containers' },
      { id: 10, name: 'Kitchen Accessories', handle: 'kitchen-accessories' },
      { id: 11, name: 'Thermocoolers/Thermoses', handle: 'thermocoolers-thermoses' },
      { id: 12, name: 'Baking Supplies', handle: 'baking-supplies' },
      { id: 13, name: 'Korean Traditional Supplies', handle: 'korean-traditional-supplies' },
    ],
  },
  {
    name: 'Stationary & Office',
    handle: 'stationary-office',
    image: '/images/common/category07.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'stationary-office',
      },
      { id: 2, name: 'Office Supplies', handle: 'office-supplies' },
      { id: 3, name: 'Arts & Crafts', handle: 'arts-crafts' },
      { id: 4, name: 'Character Stationery', handle: 'character-stationery' },
      { id: 5, name: 'Party Decorations', handle: 'party-decorations' },
      { id: 6, name: 'Gift Wrapping', handle: 'gift-wrapping' },
      { id: 7, name: 'Cards/Letters/Envelopes', handle: 'cards-letters-envelopes' },
      { id: 8, name: 'Albums', handle: 'albums' },
    ],
  },
  {
    name: 'Toys',
    handle: 'toys',
    image: '/images/common/category08.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'toys',
      },
      { id: 2, name: 'Character Toys', handle: 'character-toys' },
      { id: 3, name: 'Baby & Toddler Toys', handle: 'baby-toddler-toys' },
      { id: 4, name: 'Robots/Electronic Toys', handle: 'robots-electronic-toys' },
      { id: 5, name: 'Sports / Outdoor Play', handle: 'sports-outdoor-play' },
      { id: 6, name: 'Learning/Education', handle: 'learning-education' },
      { id: 7, name: 'Games/Puzzles', handle: 'games-puzzles' },
      { id: 8, name: 'Remote Control & Play', handle: 'remote-control-play' },
      { id: 9, name: 'Action Figures/Statues', handle: 'action-figures-statues' },
      { id: 10, name: 'Musical Instraments/Equipments', handle: 'musical-instraments-equipments' },
    ],
  },
  {
    name: 'Books',
    handle: 'books',
    image: '/images/common/category09.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'books',
      },
      { id: 2, name: "Children's Books", handle: 'childrens-books' },
      { id: 3, name: 'Novel/Essay/Poet', handle: 'novel-essay-poet' },
      { id: 4, name: 'Cookbooks/Food&Wine', handle: 'cookbooks-food-wine' },
      { id: 5, name: 'Health/Fitness/Hobby', handle: 'health-fitness-hobby' },
      { id: 6, name: 'Comics/Graphic Novels', handle: 'comics-graphic-novels' },
      { id: 7, name: 'Politics/Social Sciences', handle: 'politics-social-sciences' },
      { id: 8, name: 'History', handle: 'history' },
      { id: 9, name: 'Humanities', handle: 'humanities' },
      { id: 10, name: 'Computers/Technology', handle: 'computers-technology' },
      { id: 11, name: 'CD/LP', handle: 'cd-lp' },
    ],
  },
  {
    name: 'Sports/ Outdoors',
    handle: 'sports-outdoors',
    image: '/images/common/category10.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'sports-outdoors',
      },
      { id: 2, name: 'Camping', handle: 'camping' },
      { id: 3, name: 'Exercise/Fitness', handle: 'exercise-fitness' },
      { id: 4, name: 'Water Sports', handle: 'water-sports' },
      { id: 5, name: 'Golf', handle: 'golf' },
      { id: 6, name: 'Bikes', handle: 'bikes' },
      { id: 7, name: 'Fishing', handle: 'fishing' },
      { id: 8, name: 'Sports Goods & Accessories', handle: 'sports-goods-accessories' },
      { id: 9, name: 'Racquet Sports', handle: 'racquet-sports' },
      { id: 10, name: 'Boxing/Martial Arts', handle: 'boxing-martial-arts' },
    ],
  },
  {
    name: 'Automotive',
    handle: 'automotive',
    image: '/images/common/category11.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'automotive',
      },
      { id: 2, name: 'Interior Accessories', handle: 'interior-accessories' },
      { id: 3, name: 'Exterior Accessories', handle: 'exterior-accessories' },
      {
        id: 4,
        name: 'Car Care/Cleaning Supplies/Fluids',
        handle: 'car-care-cleaning-supplies-fluids',
      },
      { id: 5, name: 'Tools/Equipments/Safety', handle: 'tools-equipments-safety' },
    ],
  },
  {
    name: 'Fashion',
    handle: 'fashion',
    image: '/images/common/category12.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'fashion',
      },
      {
        id: 2,
        name: "Women's Fashion",
        handle: 'womens-fashion',
      },
      { id: 3, name: "Men's Fashion", handle: 'mens-fashion' },
    ],
  },
  {
    name: 'Pets',
    handle: 'pets',
    image: '/images/common/category13.svg',
    inner: [
      {
        id: 1,
        name: 'All',
        handle: 'pets',
      },
      { id: 2, name: 'Dog Treats', handle: 'dog-treats' },
      { id: 3, name: 'Dog Supplies', handle: 'dog-supplies' },
      { id: 4, name: 'Cat Treats', handle: 'cat-treats' },
      { id: 5, name: 'Cat Supplies', handle: 'cat-supplies' },
    ],
  },
];

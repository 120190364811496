import { ApolloQueryResult, gql } from '@apollo/client';
import { Order } from '../../@types/customer';
import { OrderListResponse } from '../../@types/response';
import client from '../client';

export async function getOrder(
  id?: string,
): Promise<{ response: ApolloQueryResult<OrderListResponse>; order: Order }> {
  const query = gql`
    query getOrder($id: ID!) {
      node(id: $id) {
        id
        ... on Order {
          id
          orderNumber
          email
          phone
          name
          canceledAt
          shippingAddress {
            address1
            address2
            city
            country
            name
            phone
            province
            zip
          }
          customAttributes {
            key
            value
          }
          currentSubtotalPrice {
            amount
          }
          currentTotalDuties {
            amount
          }
          currentTotalPrice {
            amount
          }
          currentTotalTax {
            amount
          }
          customerLocale
          customerUrl
          edited
          successfulFulfillments {
            trackingInfo {
              url
            }
          }
          originalTotalDuties {
            amount
          }
          originalTotalPrice {
            amount
          }
          statusUrl
          totalPrice {
            amount
          }
          totalRefunded {
            amount
          }
          totalShippingPrice {
            amount
          }
          totalTax {
            amount
          }
          subtotalPrice {
            amount
          }
          discountApplications(first: 10) {
            nodes {
              allocationMethod
              targetSelection
              targetType
              value {
                ... on MoneyV2 {
                  amount
                }
              }
            }
          }
          processedAt
          fulfillmentStatus
          financialStatus
          metafield(namespace: "custom", key: "review_rating") {
            value
          }
          lineItems(first: 250) {
            edges {
              node {
                title
                quantity
                currentQuantity
                originalTotalPrice {
                  amount
                }
                variant {
                  id
                  title
                  priceV2 {
                    amount
                  }
                  compareAtPriceV2 {
                    amount
                  }
                  image {
                    id
                    originalSrc
                    altText
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const response = await client.query({
    query,
    variables: {
      id,
    },
  });

  const order = response.data.node;

  return { response, order };
}

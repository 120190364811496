import { ApolloProvider } from '@apollo/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import App from './App';
import client from './api/client';
import Toast from './components/toast/Toast';
import { ViewportProvider } from './context';
import './locales/index';
import { queryClient } from './react-query/queryClient';
import './styles/globals.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <ViewportProvider>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <RecoilRoot>
            <BrowserRouter>
              <App />
              <Toast />
            </BrowserRouter>
          </RecoilRoot>
        </ApolloProvider>
      </QueryClientProvider>
    </ViewportProvider>
  </StrictMode>,
);

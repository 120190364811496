import { ReactElement } from 'react';
import { Coupon } from '../../../../@types/coupon';
import styles from './CouponItem.module.scss';
import getDatetime from '../../../../utils/getDatetime';

type Props = {
  data: Coupon;
  selected?: boolean;
};

const CouponItem = ({ data, selected = false }: Props): ReactElement => {
  const enabled = data.status === 'AVAILABLE';

  const couponColor: { [key: string]: string } = {
    fixed_amount: data.amount < 6 ? 'primary' : data.amount < 11 ? 'secondary' : 'third',
    percentage: data.amount === 5 ? 'primary' : data.amount === 10 ? 'secondary' : 'third',
    shipping_line: 'primary',
  };

  return (
    <article
      className={`${styles['container']} ${enabled ? '' : styles['container--disabled']} ${
        selected ? styles['container--selected'] : ''
      }`}
    >
      {selected && <div className={styles['container__border--selected']}></div>}
      <div
        className={`${styles['coupon__infomation']} ${
          selected ? styles['coupon__information--selected'] : ''
        }`}
      >
        <h2 className={!enabled ? styles['disabled'] : styles['coupon__title']}>${data.amount}</h2>
        <h3 className={!enabled ? styles['disabled'] : styles['coupon__description']}>
          {`$${data.amount} off $${data.standardPrice} ${data.origin
            .toLowerCase()
            .replace(/^[a-z]/, (c) => c.toUpperCase())} Coupon`}
        </h3>
      </div>

      <div
        className={`${styles['coupon__metadata']} ${
          enabled ? styles[`${data.type}--${couponColor[data.type]}`] : styles['disabled']
        }`}
      >
        <h4>Expires on: {getDatetime(data.expiredAt)}</h4>
        <h4>Code: {data.code}</h4>

        {!enabled && <div className={styles['coupon__tag']}>{data.status}</div>}
      </div>
    </article>
  );
};

export default CouponItem;

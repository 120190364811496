import {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { SearchBarBasic, SearchBarSearching, SearchBarIcon } from './atoms';

export type SearchProps = {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  setIsSearching: Dispatch<SetStateAction<boolean>>;
  handleSubmit?: FormEventHandler<HTMLFormElement>;
  isFocusing?: boolean;
  handleFocus?: (isFocus: boolean) => void;
  hasBack?: boolean;
};

type SearchBarProps = {
  hasBack?: boolean;
  hideBar?: boolean;
};

const SearchBar = ({ hasBack = false, hideBar = false }: SearchBarProps) => {
  const [isFocusing, setIsFocusing] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const { search } = useLocation();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    // TODO: Show Preview,
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleFocus = (isFocus: boolean) => {
    setIsFocusing(isFocus);
  };

  useEffect(() => {
    const s = new URLSearchParams(search).get('s');

    if (!s) {
      return;
    }

    setSearchValue(s);
  }, [search]);

  return (
    <>
      {isSearching ? (
        <SearchBarSearching
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleChange={handleChange}
          setIsSearching={setIsSearching}
          isFocusing={isFocusing}
          handleFocus={handleFocus}
        />
      ) : hideBar ? (
        <SearchBarIcon
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleChange={handleChange}
          setIsSearching={setIsSearching}
        />
      ) : (
        <SearchBarBasic
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleChange={handleChange}
          setIsSearching={setIsSearching}
          hasBack={hasBack}
        />
      )}
    </>
  );
};

export default SearchBar;

import { ReactElement } from 'react';
import { BaseLayout } from '../components/layout';
import { MobileNavItem } from '../components/layout/BaseLayout';
import { RegisterContainer } from '../components/register/RegisterContainer';

const Register = (): ReactElement => {
  return (
    <BaseLayout
      className="login"
      mobileNav={{ type: MobileNavItem.ONLY_LOGO }}
      desktopNav="ONLY_TITLE"
    >
      <RegisterContainer />
    </BaseLayout>
  );
};

export default Register;

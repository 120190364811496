import {
  CSSProperties,
  Dispatch,
  memo,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { WishProductType } from '../../../@types/product';
import { deleteProductInWishlist } from '../../../api/backend/product';
import { useViewport } from '../../../helper/hooks';
import { getCapitalizeLetters } from '../../../helper/letter';
import { statisticsClickCounts } from '../../../utils/statistics/statisticsClickCounts';
import WishListButton from '../../button/WishListButton';
import Checkbox from '../../checkbox/Checkbox';
import Empty from '../../empty/Empty';
import { NumberInput } from '../../input';
import Loading from '../../loading/Loading';
import styles from './WishListContainer.module.scss';

const WishListItem = memo(
  ({
    item,
    originId,
    onToggleItem,
  }: {
    item: WishProductType;
    originId?: number;
    onToggleItem?: (id: string | number, checked: boolean) => void;
  }): ReactElement => {
    const customStyles: CSSProperties = {
      position: 'unset',
    };
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onNavigateProductDetail = (id: number | string) => {
      if (originId) {
        statisticsClickCounts({
          product: item,
          originId,
        });
      }
      navigate(`/product/${encodeURIComponent(id)}`);
    };

    const price = Number(item.priceRange.maxVariantPrice.amount) || 0;
    const originalPrice = Number(item.compareAtPriceRange?.maxVariantPrice.amount) || 0;
    const isDiscount = originalPrice > price;
    const discountRatio = Math.floor(((originalPrice - price) / originalPrice) * 100);

    const flags = item.collections
      ? [
          ...item.collections.edges
            .map(({ node }) => node.title)
            .filter((title) => title.toUpperCase() === 'BEST' || title.toUpperCase() === 'NEW'),
        ]
      : [];

    return (
      <li className={styles['item']}>
        <div className={styles['fixed__container']}>
          <Checkbox
            id={item.id}
            checked={item.checked}
            onToggle={(id: string | number, checked: boolean) => {
              if (!onToggleItem) {
                return;
              }
              onToggleItem(id, checked);
            }}
          />
        </div>
        <div className={styles['thumnail']} onClick={() => onNavigateProductDetail(item.id)}>
          <img src={item.featuredImage?.url} alt="상품 썸네일" />
          {item.totalInventory === 0 && (
            <div className={styles['thumnail__soldout']}>
              <span>{t('account_wish_soldout')}</span>
            </div>
          )}
          <div className={styles['product-flag']}>
            {flags.map((flag, idx) => (
              <div
                className={`${styles['flag']} ${styles[flag.toLowerCase()]}`}
                key={`${flag}--${idx}`}
              >
                <span>{getCapitalizeLetters(flag)}</span>
              </div>
            ))}
            {isDiscount && (
              <div className={`${styles['flag']}`}>
                <span>-{discountRatio}%</span>
              </div>
            )}
          </div>
        </div>

        <div className={styles['description']}>
          <p
            className={styles['description__name']}
            onClick={() => onNavigateProductDetail(item.id)}
          >
            {item.title}
          </p>
          <div className={styles['description__price']}>
            {isDiscount ? (
              <div>
                <span className={styles['current-price']}>${price}</span>
                <small className={styles['description__price__original']}>${originalPrice}</small>
              </div>
            ) : (
              <span className={styles['current-price']}>${price}</span>
            )}
          </div>

          {/* TODO: Check history, discountRaito */}
          {/* {item.purchaseHistory && (
          <span className={styles['description__history']}>{item.purchaseHistory} purchases</span>

          {/* TODO: Check star rating */}
          {/* {item.starRating && (
          <div className={styles['description__star']}>
            <img src="/icons/star-rating.svg" alt="별 모양" />
            <span>{item.starRating}</span>
          </div>
        )} */}

          <div className={styles['item__control']}>
            <NumberInput
              item={item}
              customCoverStyles={customStyles}
              customDetailStyles={customStyles}
            />
          </div>
        </div>
      </li>
    );
  },
);

const WishListContainer = ({
  items,
  isLoading = false,
  originId,
  setProducts,
  products,
}: {
  items: WishProductType[];
  isLoading?: boolean;
  originId?: number;
  setProducts?: Dispatch<SetStateAction<WishProductType[]>>;
  products?: WishProductType[];
}): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const { isMobile } = useViewport();

  const onToggle = (id: string | number, checked: boolean) => {
    setProducts!((items) => items.map((item) => (item.id === id ? { ...item, checked } : item)));
  };
  const onClickDeleteSelection = async () => {
    const selected = items.filter((item) => item.checked).map((item) => item.mkmId);

    const res = await deleteProductInWishlist(selected);
    location.reload();
    setCheckedAll(false);
  };
  const onSelectAll = () => {
    setCheckedAll((checkedAll) => !checkedAll);
    if (!checkedAll) {
      setProducts!((items) => items.map((item) => ({ ...item, checked: true })));
    } else {
      setProducts!((items) => items.map((item) => ({ ...item, checked: false })));
    }
  };
  
  useEffect(() => {
    if (
      products!.filter((item) => item.checked).length == products!.length &&
      products!.length > 0
    ) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [products]);

  return (
    <section className={styles['container']}>
      <ul className={styles['items']}>
        {isLoading ? (
          <Loading />
        ) : items.length > 0 ? (
          <div className={styles['wish-list-container']}>
            <div className={styles['wish-list-select-container']}>
              <div>
                <Checkbox
                  id={-1}
                  checked={checkedAll}
                  onToggle={onSelectAll}
                  label={t('cart_list_select')}
                />
              </div>
              <div
                className={styles['wish-list-delect-container']}
                onClick={onClickDeleteSelection}
              >
                <span>{t('cart_list_select_delete')}</span>
              </div>
            </div>
            {items.map((item, idx) => (
              <WishListItem
                key={`${item.id}--${idx}`}
                item={item}
                originId={originId}
                onToggleItem={onToggle}
              />
            ))}
            {Array.from({ length: 4 }, (_, i) => (
              <li key={`li--${i}`} />
            ))}
            {isMobile && <WishListButton />}
          </div>
        ) : (
          <div className={styles['empty_container']}>
            <Empty
              message={t('account_wish_empty')}
              subMessage="common_empty_message"
              buttonMessage="account_wish_empty_button"
              onClickEmpty={() => {
                navigate('/');
              }}
            />
          </div>
        )}
      </ul>
    </section>
  );
};
export default memo(WishListContainer);

import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CurationModule, CurationProductType } from '../@types/curation';
import { getModularization } from '../api/backend/modularization';

export const useModularizationMoreProducts = (
  type: 'productDetail' | 'cart' | 'checkoutCompleted',
) => {
  const go = useNavigate();
  const { title } = useParams();

  const [curationModuleItem, setCurationModuleItem] = useState<CurationModule>();

  const getCurationModuleItem = useCallback(async () => {
    const res = await getModularization(type);
    if (res.isOk && res.data.modularization) {
      const { products } = res.data.modularization;
      const targetCurationModuleItem = products.find((product) => product.title === title);
      if (!targetCurationModuleItem) {
        go('/');
      }
      setCurationModuleItem(targetCurationModuleItem);
      return;
    }
    go('/');
  }, []);

  const preprocessedModuleItem = {
    ...curationModuleItem,
    id: curationModuleItem?.id ?? -1,
    curationItemType: curationModuleItem?.curationItemType ?? 'PRODUCT',
    amount: 0,
    type: {
      text: '2xN' as CurationProductType,
      row: Number.MAX_SAFE_INTEGER,
      column: 2,
    },
  };

  useEffect(() => {
    getCurationModuleItem();
  }, []);

  return { preprocessedModuleItem, title };
};

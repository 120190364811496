import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Customer, CustomerFromMkm } from '../../../@types/customer';
import { logout } from '../../../utils/logout';
import UserInfo from '../UserInfo';
import styles from './Aside.module.scss';

type AsideProps = {
  customer: (Customer & CustomerFromMkm) | null;
};

const Aside = ({ customer }: AsideProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const current = pathname.split('/')[2];
  const login = () => {
    window.location.replace('/login');
  };
  return (
    <aside className={styles['aside']}>
      {!!customer && (
        <div>
          <div className={styles['aside__container']}>
            <UserInfo
              link={'/account/profile'}
              mainText={customer?.displayName}
              image={customer?.profileImageUrl || '/icons/account__profile-default.svg'}
              className={styles['aside__profile']}
              hasArrow={false}
            />
          </div>

          <div className={styles['aside__container']}>
            <UserInfo
              link="/account/my-points"
              mainText={t('account_aside_point')}
              image="/icons/account__point.svg"
              isSelected={current === 'my-points'}
              hasArrow={false}
            />
            <UserInfo
              link="/account/invitations"
              mainText={
                <div>
                  <Trans i18nKey={t('account_aside_refer')} components={{ span: <span></span> }} />
                  <span>{t('account_aside_refer_span')}</span>
                </div>
              }
              image="/icons/account__gift.svg"
              isSelected={current === 'invitations'}
              hasArrow={false}
            />
          </div>

          <div className={styles['aside__container']}>
            <UserInfo
              link="/account/orders"
              mainText={t('account_aside_my_orders')}
              image="/icons/account__orderlist.svg"
              isSelected={!current || current === 'orders'}
              hasArrow={false}
            />
            <UserInfo
              link="/account/wishlist"
              mainText={t('account_aside_wish')}
              image={'/icons/account__wish-list.svg'}
              isSelected={current === 'wishlist'}
              hasArrow={false}
            />
            <UserInfo
              link="/account/coupons"
              mainText={t('account_aside_coupon')}
              image={'/icons/account__coupons.svg'}
              isSelected={current === 'coupons'}
              hasArrow={false}
            />
            <UserInfo
              link="/account/reviews"
              mainText={t('account_aside_review')}
              image={'/icons/account__post-review.svg'}
              isSelected={current === 'reviews'}
              hasArrow={false}
            />
          </div>

          <div className={styles['aside__container']}>
            <UserInfo
              link="/account/help-center"
              mainText={t('account_aside_help')}
              image={'/icons/account__help-center.svg'}
              isSelected={current === 'help-center'}
              hasArrow={false}
            />
            <UserInfo
              link="/account/notifications"
              mainText={t('account_aside_setting')}
              image={'/icons/account__settings.svg'}
              isSelected={current === 'notifications'}
              hasArrow={false}
            />
            <UserInfo
              link="/account/language"
              mainText={t('account_aside_language')}
              image={'/icons/account__language.svg'}
              isSelected={current === 'language'}
              hasArrow={false}
            />
          </div>
        </div>
      )}
      {!customer && (
        <UserInfo
          link="/account/help-center/partnership"
          mainText={t('account_aside_contact')}
          image={'/icons/account__help-center.svg'}
          isSelected={current === 'help-center'}
          hasArrow={false}
        />
      )}

      <div className={styles['aside__container']}>
        <UserInfo
          link="/account/information"
          mainText={t('account_aside_info')}
          image={'/icons/account__information.svg'}
          isSelected={current === 'information'}
          hasArrow={false}
        />
        <UserInfo
          link="/account/policy"
          mainText={t('account_aside_policy')}
          image={'/icons/account__carbon_policy.svg'}
          isSelected={current === 'policy'}
          hasArrow={false}
        />
      </div>

      {!!customer ? (
        <div onClick={() => logout()}>
          <UserInfo
            link="#"
            mainText={t('account_aside_logout')}
            image={'/icons/account__logout.svg'}
            hasArrow={false}
          />
        </div>
      ) : (
        <div onClick={() => login()}>
          <UserInfo
            link="#"
            mainText={t('account_aside_login')}
            image={'/icons/account__logout.svg'}
            hasArrow={false}
          />
        </div>
      )}
    </aside>
  );
};

export default Aside;

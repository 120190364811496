import { useQuery } from '@apollo/client';
import {
  Dispatch,
  memo,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { CurationModule, CurationModuleSortingType } from '../../@types/curation';
import { ProductType } from '../../@types/product';
import { ProductByCurationResponse, ProductsByHandleResponse } from '../../@types/response';
import {
  GET_PRODUCTS_BY_CURATION_QUERY,
  GET_PRODUCTS_BY_PRODUCT_IDS_QUERY,
  getFilterQuery,
  SORT_KEY_MAP,
} from '../../api/storefront/products';
import { useViewport } from '../../helper/hooks';

import BorderSeparator from '../border/BorderSeparator';
import Loading from '../loading/Loading';
import Product from './Product';
import ProductContainerSlick from './ProductContainerSlick';
import styles from './styles/ProductContainer.module.scss';

const ProductContainer = ({
  item,
  options,
  origin,
  originId,
  productCounts,
  setProductCounts,
}: {
  item: CurationModule;
  options?: {
    borderShow: boolean;
    infiniteScrollMode: boolean;
    headerType?: 'DEFAULT' | 'ONLY_TITLE';
    originName?: 'main' | 'cart' | 'product' | 'checkout-completed';
  };
  origin?: 'curation' | 'promotion' | 'search';
  originId?: number;
  productCounts?: number;
  setProductCounts?: Dispatch<SetStateAction<number>>;
}): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();

  const { isMobile, width } = useViewport();

  const {
    type: { row, column },
    title,
    amount,
    sorting,
    filters,
  } = item;

  const renderHeader = (type: 'DEFAULT' | 'ONLY_TITLE' = 'DEFAULT') => {
    switch (type) {
      case 'DEFAULT':
        return (
          <div className={styles['product-category-title']}>
            <h2>{title}</h2>
            <Link
              onClick={() => window.scroll(0, 0)}
              to={`/module/${options?.originName}/${encodeURIComponent(title ?? '')}`}
            >
              <span>{t('main_render_header_more')}</span>
              <img src="/icons/category__chevron-right.svg" alt="오른쪽 화살표" />
            </Link>
          </div>
        );
      case 'ONLY_TITLE':
        return (
          <div className={styles['product-category-title']}>
            <h2>{title}</h2>
          </div>
        );
    }
  };

  const render = (children: ReactNode) => (
    <>
      <div
        className={styles['div']}
        style={{
          minWidth: 'unset',
          width:
            width >= 1024 && width < 1280 && !location.pathname.startsWith('/promotion')
              ? '90%'
              : width >= 1280 && location.pathname === '/'
              ? '97%'
              : '100%',
        }}
      >
        <section className={styles['section']}>
          {renderHeader(options?.headerType)}
          {children}
        </section>
      </div>
      {options?.borderShow ? (
        <BorderSeparator
          className={
            isMobile ? 'border-horizon' : 'border-horizon-and-height-1px'
            // options?.originName === 'product' ? 'border-horizon-and-height-1px' : 'border-horizon'
          }
        />
      ) : (
        <></>
      )}
    </>
  );

  const [products, setProducts] = useState<ProductType[]>([]);
  const intersectionBar = useRef<HTMLDivElement | null>(null);
  const [after, setAfter] = useState<string | null>(null);

  const isFiltersExist = filters && filters.length > 0;

  const isFilterIdsOnly = isFiltersExist && filters[0].filterType === 'Ids';

  const INFINITE_SCROLL_DEFAULT_AMOUNT = 12;

  const CURATION_MODULE_INFINITE_ROWS = Number.MAX_SAFE_INTEGER;

  const isInfiniteModeAmount =
    row === CURATION_MODULE_INFINITE_ROWS &&
    (amount === 0 || (!!amount && INFINITE_SCROLL_DEFAULT_AMOUNT < amount));

  const defaultAmount = isInfiniteModeAmount ? INFINITE_SCROLL_DEFAULT_AMOUNT : amount;

  const [receivedDataCount, setReceivedDataCount] = useState<number>(defaultAmount ?? 0);

  const { loading, data, fetchMore } = useQuery(
    isFilterIdsOnly ? GET_PRODUCTS_BY_PRODUCT_IDS_QUERY : GET_PRODUCTS_BY_CURATION_QUERY,
    {
      variables: {
        first: defaultAmount,
        sortKey: SORT_KEY_MAP[sorting as CurationModuleSortingType],
        reverse: sorting === 'PRICE_HIGH_TO_LOW' || sorting === 'NEWEST',
        query:
          isFiltersExist && !isFilterIdsOnly
            ? filters
                .map(
                  (filter) =>
                    getFilterQuery({
                      value1: filter.value1,
                      value2: filter.value2,
                    })[filter.filterType],
                )
                .join(' AND ')
            : null,
        ids:
          isFiltersExist && isFilterIdsOnly
            ? getFilterQuery({
                value1: filters[0].value1,
              })[filters[0].filterType]
            : null,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const handleFetchMore = (after: string | null) => {
    if (!after) {
      return;
    }

    if (!amount || amount === receivedDataCount) {
      if (amount !== 0) {
        return;
      }
    }

    const variantAmount =
      amount - receivedDataCount > INFINITE_SCROLL_DEFAULT_AMOUNT || amount === 0
        ? INFINITE_SCROLL_DEFAULT_AMOUNT
        : amount - receivedDataCount;

    if (variantAmount < 0) {
      return;
    }

    setReceivedDataCount((prev) => prev + variantAmount);

    fetchMore({
      variables: {
        first: variantAmount,
        sortKey: SORT_KEY_MAP[sorting as CurationModuleSortingType],
        reverse: sorting === 'PRICE_HIGH_TO_LOW' || sorting === 'NEWEST',
        query:
          isFiltersExist && !isFilterIdsOnly
            ? filters
                .map(
                  (filter) =>
                    getFilterQuery({
                      value1: filter.value1,
                      value2: filter.value2,
                    })[filter.filterType],
                )
                .join(' AND ')
            : null,
        after,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if ('collectionByHandle' in previousResult) {
          return {
            collectionByHandle: {
              products: {
                edges: [
                  ...previousResult.collectionByHandle.products.edges,
                  ...fetchMoreResult.collectionByHandle.products.edges,
                ],
                pageInfo: fetchMoreResult.collectionByHandle.products.pageInfo,
              },
            },
          };
        } else {
          return {
            products: {
              edges: [...previousResult.products.edges, ...fetchMoreResult.products.edges],
              pageInfo: fetchMoreResult.products.pageInfo,
            },
          };
        }
      },
    });
  };

  const intersectionCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting) {
        handleFetchMore(after);
      }
    },
    [after],
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    if (isFiltersExist && isFilterIdsOnly) {
      setProducts(data.nodes.map((node: ProductType) => node));
      return;
    }

    if ('collectionByHandle' in data) {
      const { edges, pageInfo } = (data as ProductsByHandleResponse).collectionByHandle.products;

      setProducts(edges.map(({ node }) => node));

      if (productCounts && setProductCounts) {
        setProductCounts(edges.length);
      }

      if (!pageInfo.hasNextPage) {
        setAfter(null);
        return;
      }
      setAfter(edges[edges.length - 1].cursor);
    } else {
      const { edges, pageInfo } = (data as ProductByCurationResponse).products;

      setProducts(edges.map(({ node }) => node));

      if (productCounts && setProductCounts) {
        setProductCounts(edges.length);
      }

      if (!pageInfo.hasNextPage) {
        setAfter(null);
        return;
      }
      setAfter(edges[edges.length - 1].cursor);
    }
  }, [data, filters]);

  useEffect(() => {
    if (!intersectionBar.current) {
      return;
    }

    const observer = new IntersectionObserver(intersectionCallback, {
      rootMargin: '10px',
      threshold: 0.5,
    });

    observer.observe(intersectionBar.current);

    return () => observer.disconnect();
  }, [intersectionBar.current, intersectionCallback]);

  const productElements = useMemo(
    () =>
      products.map(
        (item: ProductType, idx) =>
          item?.id && (
            <Product
              key={`${item.id}--${idx}`}
              item={item}
              origin={origin}
              originId={originId}
              align={options?.infiniteScrollMode ? 'CENTER' : 'DEFAULT'}
            />
          ),
      ),
    [products],
  );

  return (
    <>
      {options?.infiniteScrollMode
        ? render(
            <>
              <div className={styles['product--infinite-scroll']}>
                {productElements}
                {Array.from({ length: 12 }, (_, i) => (
                  <article key={`article--${i}`} />
                ))}
              </div>
              {loading ? <Loading /> : <div ref={intersectionBar} style={{ height: '56px' }}></div>}
            </>,
          )
        : render(<ProductContainerSlick rows={row} productElements={productElements} />)}
    </>
  );
};

export default memo(ProductContainer);

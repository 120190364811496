import { ChangeEventHandler, FormEventHandler, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { createRequest } from '../../../api/backend/help';
import { FormButton, Input } from '../../../components/login/atoms';
import Textarea from '../../../components/textarea/Textarea';
import Upload from '../../../components/upload/Upload';
import styles from './styles/HelpRequestForm.module.scss';

export const HelpRequestForm = () => {
  const go = useNavigate();
  const { t } = useTranslation();
  const [images, setImages] = useState<File[]>([]);
  const [form, setForm] = useState({ title: '', content: '' });
  const [enabled, setEnabled] = useState<boolean>(false);

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const { name, value } = event.currentTarget;

    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const uploadImagesElement = useMemo(
    () => (
      <div className={styles['request__upload']}>
        <Upload files={images} setFiles={setImages} />
      </div>
    ),
    [images],
  );

  const handleSubmit: FormEventHandler = async (event) => {
    event.preventDefault();

    const { title, content } = form;

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    images.forEach((image) => formData.append('images', image));

    const response = await createRequest(formData);

    if (!response.isOk) {
      // TODO: Handle Error
      return;
    }

    go('/account/help-center/request');
    window.location.reload();
  };

  useEffect(() => {
    setEnabled(!!form.title && !!form.content);
  }, [form]);

  return (
    <form className={styles['request__form']} onSubmit={handleSubmit}>
      <div className={styles['container']}>
        <h2 className={styles['request__title']}>{t('account_help_request_new_title')}</h2>
        <Input
          placeholder={t('account_help_request_new_title_placeholder')}
          type="text"
          name="title"
          defaultValue={form.title}
          onChange={handleChange}
        />

        <Textarea
          name="content"
          placeholder={t('account_help_request_new_detail_placeholder')}
          defaultValue={form.content}
          onChange={handleChange}
          style={{ marginBottom: '24px' }}
        />
        {uploadImagesElement}
      </div>
      <div className={styles['request__button']}>
        <FormButton contents={t('account_help_request_new_button')} disabled={!enabled} />
      </div>
    </form>
  );
};

export default HelpRequestForm;

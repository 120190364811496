import { useCallback, useEffect, useState } from 'react';
import { CurationModule } from '../@types/curation';
import { getModularization } from '../api/backend/modularization';

interface Props {
  type: 'productDetail' | 'cart' | 'checkoutCompleted';
}

export const useModularization = ({ type }: Props) => {
  const [moduleItems, setModuleItems] = useState<CurationModule[]>([]);

  const getModuleItems = useCallback(async (type: Props['type']) => {
    const res = await getModularization(type);
    if (res.isOk && res.data.modularization.products) {
      const { products } = res.data.modularization;
      setModuleItems(products);
    }
  }, []);

  useEffect(() => {
    getModuleItems(type);
  }, [type]);

  return { moduleItems, setModuleItems };
};

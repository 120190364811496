import { MouseEventHandler, ReactElement, ReactNode } from 'react';
import styles from './styles/Button.module.scss';

interface ButtonProps {
  children: ReactNode;
  action?: MouseEventHandler;
  className?: string;
  disabled?: boolean;
}

const Button = ({ children, action, className, disabled }: ButtonProps): ReactElement => {
  return (
    <div
      onClick={disabled ? (event) => event.preventDefault() : action}
      className={`${disabled ? styles['disabled'] : ''} ${styles['button-container']} ${
        className ? className : ''
      }`}
    >
      {children}
    </div>
  );
};
export default Button;

import styled from './styles/Policy.module.scss';
export const EnTerms = () => {
  return (
    <div className={styled['rte']}>
      <strong>OVERVIEW</strong> <br /> This website is operated by my Kmarket. Throughout the site,
      the terms “we”, “us” and “our” refer to my Kmarket. my Kmarket offers this website, including
      all information, tools and services available from this site to you, the user, conditioned
      upon your acceptance of all terms, conditions, policies and notices stated here. <br /> <br />{' '}
      By visiting our site and/ or purchasing something from us, you engage in our “Service” and
      agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”),
      including those additional terms and conditions and policies referenced herein and/or
      available by hyperlink. These Terms of Service apply to all users of the site, including
      without limitation users who are browsers, vendors, customers, merchants, and/ or contributors
      of content. <br /> <br /> Please read these Terms of Service carefully before accessing or
      using our website. By accessing or using any part of the site, you agree to be bound by these
      Terms of Service. If you do not agree to all the terms and conditions of this agreement, then
      you may not access the website or use any services. If these Terms of Service are considered
      an offer, acceptance is expressly limited to these Terms of Service. <br /> <br /> Any new
      features or tools which are added to the current store shall also be subject to the Terms of
      Service. You can review the most current version of the Terms of Service at any time on this
      page. We reserve the right to update, change or replace any part of these Terms of Service by
      posting updates and/or changes to our website. It is your responsibility to check this page
      periodically for changes. Your continued use of or access to the website following the posting
      of any changes constitutes acceptance of those changes. <br /> <br /> Our store is hosted on
      Shopify Inc. They provide us with the online e-commerce platform that allows us to sell our
      products and services to you. <br /> <br /> <strong>SECTION 1 - ONLINE STORE TERMS</strong>{' '}
      <br /> By agreeing to these Terms of Service, you represent that you are at least the age of
      majority in your state or province of residence, or that you are the age of majority in your
      state or province of residence and you have given us your consent to allow any of your minor
      dependents to use this site. <br /> You may not use our products for any illegal or
      unauthorized purpose nor may you, in the use of the Service, violate any laws in your
      jurisdiction (including but not limited to copyright laws). <br /> You must not transmit any
      worms or viruses or any code of a destructive nature. <br /> A breach or violation of any of
      the Terms will result in an immediate termination of your Services. <br /> <br />{' '}
      <strong>SECTION 2 - GENERAL CONDITIONS</strong> <br /> We reserve the right to refuse service
      to anyone for any reason at any time. <br /> You understand that your content (not including
      credit card information), may be transferred unencrypted and involve (a) transmissions over
      various networks; and (b) changes to conform and adapt to technical requirements of connecting
      networks or devices. Credit card information is always encrypted during transfer over
      networks. <br /> You agree not to reproduce, duplicate, copy, sell, resell or exploit any
      portion of the Service, use of the Service, or access to the Service or any contact on the
      website through which the service is provided, without express written permission by us.{' '}
      <br /> The headings used in this agreement are included for convenience only and will not
      limit or otherwise affect these Terms. <br /> <br />{' '}
      <strong>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</strong> <br /> We
      are not responsible if information made available on this site is not accurate, complete or
      current. The material on this site is provided for general information only and should not be
      relied upon or used as the sole basis for making decisions without consulting primary, more
      accurate, more complete or more timely sources of information. Any reliance on the material on
      this site is at your own risk. <br /> This site may contain certain historical information.
      Historical information, necessarily, is not current and is provided for your reference only.
      We reserve the right to modify the contents of this site at any time, but we have no
      obligation to update any information on our site. You agree that it is your responsibility to
      monitor changes to our site. <br /> <br />{' '}
      <strong>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</strong> <br /> Prices for our
      products are subject to change without notice. <br /> We reserve the right at any time to
      modify or discontinue the Service (or any part or content thereof) without notice at any time.{' '}
      <br /> We shall not be liable to you or to any third-party for any modification, price change,
      suspension or discontinuance of the Service. <br /> <br />{' '}
      <strong>SECTION 5 - PRODUCTS OR SERVICES (if applicable)</strong> <br /> Certain products or
      services may be available exclusively online through the website. These products or services
      may have limited quantities and are subject to return or exchange only according to our Return
      Policy. <br /> We have made every effort to display as accurately as possible the colors and
      images of our products that appear at the store. We cannot guarantee that your computer
      monitor's display of any color will be accurate. <br /> We reserve the right, but are not
      obligated, to limit the sales of our products or Services to any person, geographic region or
      jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to
      limit the quantities of any products or services that we offer. All descriptions of products
      or product pricing are subject to change at anytime without notice, at the sole discretion of
      us. We reserve the right to discontinue any product at any time. Any offer for any product or
      service made on this site is void where prohibited. <br /> We do not warrant that the quality
      of any products, services, information, or other material purchased or obtained by you will
      meet your expectations, or that any errors in the Service will be corrected. <br /> <br />{' '}
      <strong>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</strong> <br /> We reserve the
      right to refuse any order you place with us. We may, in our sole discretion, limit or cancel
      quantities purchased per person, per household or per order. These restrictions may include
      orders placed by or under the same customer account, the same credit card, and/or orders that
      use the same billing and/or shipping address. In the event that we make a change to or cancel
      an order, we may attempt to notify you by contacting the e‑mail and/or billing address/phone
      number provided at the time the order was made. We reserve the right to limit or prohibit
      orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.{' '}
      <br /> <br /> You agree to provide current, complete and accurate purchase and account
      information for all purchases made at our store. You agree to promptly update your account and
      other information, including your email address and credit card numbers and expiration dates,
      so that we can complete your transactions and contact you as needed. <br /> <br /> For more
      detail, please review our Returns Policy. <br /> <br />{' '}
      <strong>SECTION 7 - OPTIONAL TOOLS</strong> <br /> We may provide you with access to
      third-party tools over which we neither monitor nor have any control nor input. <br /> You
      acknowledge and agree that we provide access to such tools ”as is” and “as available” without
      any warranties, representations or conditions of any kind and without any endorsement. We
      shall have no liability whatsoever arising from or relating to your use of optional
      third-party tools. <br /> Any use by you of optional tools offered through the site is
      entirely at your own risk and discretion and you should ensure that you are familiar with and
      approve of the terms on which tools are provided by the relevant third-party provider(s).{' '}
      <br /> We may also, in the future, offer new services and/or features through the website
      (including, the release of new tools and resources). Such new features and/or services shall
      also be subject to these Terms of Service. <br /> <br />{' '}
      <strong>SECTION 8 - THIRD-PARTY LINKS</strong> <br /> Certain content, products and services
      available via our Service may include materials from third-parties. <br /> Third-party links
      on this site may direct you to third-party websites that are not affiliated with us. We are
      not responsible for examining or evaluating the content or accuracy and we do not warrant and
      will not have any liability or responsibility for any third-party materials or websites, or
      for any other materials, products, or services of third-parties. <br /> We are not liable for
      any harm or damages related to the purchase or use of goods, services, resources, content, or
      any other transactions made in connection with any third-party websites. Please review
      carefully the third-party's policies and practices and make sure you understand them before
      you engage in any transaction. Complaints, claims, concerns, or questions regarding
      third-party products should be directed to the third-party. <br /> <br />{' '}
      <strong>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</strong> <br /> If, at our
      request, you send certain specific submissions (for example contest entries) or without a
      request from us you send creative ideas, suggestions, proposals, plans, or other materials,
      whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree
      that we may, at any time, without restriction, edit, copy, publish, distribute, translate and
      otherwise use in any medium any comments that you forward to us. We are and shall be under no
      obligation (1) to maintain any comments in confidence; (2) to pay compensation for any
      comments; or (3) to respond to any comments. <br /> We may, but have no obligation to,
      monitor, edit or remove content that we determine in our sole discretion are unlawful,
      offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable
      or violates any party’s intellectual property or these Terms of Service. <br /> You agree that
      your comments will not violate any right of any third-party, including copyright, trademark,
      privacy, personality or other personal or proprietary right. You further agree that your
      comments will not contain libelous or otherwise unlawful, abusive or obscene material, or
      contain any computer virus or other malware that could in any way affect the operation of the
      Service or any related website. You may not use a false e‑mail address, pretend to be someone
      other than yourself, or otherwise mislead us or third-parties as to the origin of any
      comments. You are solely responsible for any comments you make and their accuracy. We take no
      responsibility and assume no liability for any comments posted by you or any third-party.{' '}
      <br /> <br /> <strong>SECTION 10 - PERSONAL INFORMATION</strong> <br /> Your submission of
      personal information through the store is governed by our Privacy Policy. To view our Privacy
      Policy. <br /> <br /> <strong>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</strong> <br />{' '}
      Occasionally there may be information on our site or in the Service that contains
      typographical errors, inaccuracies or omissions that may relate to product descriptions,
      pricing, promotions, offers, product shipping charges, transit times and availability. We
      reserve the right to correct any errors, inaccuracies or omissions, and to change or update
      information or cancel orders if any information in the Service or on any related website is
      inaccurate at any time without prior notice (including after you have submitted your order).{' '}
      <br /> We undertake no obligation to update, amend or clarify information in the Service or on
      any related website, including without limitation, pricing information, except as required by
      law. No specified update or refresh date applied in the Service or on any related website,
      should be taken to indicate that all information in the Service or on any related website has
      been modified or updated. <br /> <br /> <strong>SECTION 12 - PROHIBITED USES</strong> <br />{' '}
      In addition to other prohibitions as set forth in the Terms of Service, you are prohibited
      from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to
      perform or participate in any unlawful acts; (c) to violate any international, federal,
      provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or
      violate our intellectual property rights or the intellectual property rights of others; (e) to
      harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on
      gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;
      (f) to submit false or misleading information; (g) to upload or transmit viruses or any other
      type of malicious code that will or may be used in any way that will affect the functionality
      or operation of the Service or of any related website, other websites, or the Internet; (h) to
      collect or track the personal information of others; (i) to spam, phish, pharm, pretext,
      spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or
      circumvent the security features of the Service or any related website, other websites, or the
      Internet. We reserve the right to terminate your use of the Service or any related website for
      violating any of the prohibited uses. <br /> <br />{' '}
      <strong>SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</strong> <br /> We do
      not guarantee, represent or warrant that your use of our service will be uninterrupted,
      timely, secure or error-free. <br /> We do not warrant that the results that may be obtained
      from the use of the service will be accurate or reliable. <br /> You agree that from time to
      time we may remove the service for indefinite periods of time or cancel the service at any
      time, without notice to you. <br /> You expressly agree that your use of, or inability to use,
      the service is at your sole risk. The service and all products and services delivered to you
      through the service are (except as expressly stated by us) provided 'as is' and 'as available'
      for your use, without any representation, warranties or conditions of any kind, either express
      or implied, including all implied warranties or conditions of merchantability, merchantable
      quality, fitness for a particular purpose, durability, title, and non-infringement. <br /> In
      no case shall my Kmarket, our directors, officers, employees, affiliates, agents, contractors,
      interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or
      any direct, indirect, incidental, punitive, special, or consequential damages of any kind,
      including, without limitation lost profits, lost revenue, lost savings, loss of data,
      replacement costs, or any similar damages, whether based in contract, tort (including
      negligence), strict liability or otherwise, arising from your use of any of the service or any
      products procured using the service, or for any other claim related in any way to your use of
      the service or any product, including, but not limited to, any errors or omissions in any
      content, or any loss or damage of any kind incurred as a result of the use of the service or
      any content (or product) posted, transmitted, or otherwise made available via the service,
      even if advised of their possibility. Because some states or jurisdictions do not allow the
      exclusion or the limitation of liability for consequential or incidental damages, in such
      states or jurisdictions, our liability shall be limited to the maximum extent permitted by
      law. <br /> <br /> <strong>SECTION 14 - INDEMNIFICATION</strong> <br /> You agree to
      indemnify, defend and hold harmless my Kmarket and our parent, subsidiaries, affiliates,
      partners, officers, directors, agents, contractors, licensors, service providers,
      subcontractors, suppliers, interns and employees, harmless from any claim or demand, including
      reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of
      these Terms of Service or the documents they incorporate by reference, or your violation of
      any law or the rights of a third-party. <br /> <br />{' '}
      <strong>SECTION 15 - SEVERABILITY</strong> <br /> In the event that any provision of these
      Terms of Service is determined to be unlawful, void or unenforceable, such provision shall
      nonetheless be enforceable to the fullest extent permitted by applicable law, and the
      unenforceable portion shall be deemed to be severed from these Terms of Service, such
      determination shall not affect the validity and enforceability of any other remaining
      provisions. <br /> <br /> <strong>SECTION 16 - TERMINATION</strong> <br /> The obligations and
      liabilities of the parties incurred prior to the termination date shall survive the
      termination of this agreement for all purposes. <br /> These Terms of Service are effective
      unless and until terminated by either you or us. You may terminate these Terms of Service at
      any time by notifying us that you no longer wish to use our Services, or when you cease using
      our site. <br /> If in our sole judgment you fail, or we suspect that you have failed, to
      comply with any term or provision of these Terms of Service, we also may terminate this
      agreement at any time without notice and you will remain liable for all amounts due up to and
      including the date of termination; and/or accordingly may deny you access to our Services (or
      any part thereof). <br /> <br /> <strong>SECTION 17 - ENTIRE AGREEMENT</strong> <br /> The
      failure of us to exercise or enforce any right or provision of these Terms of Service shall
      not constitute a waiver of such right or provision. <br /> These Terms of Service and any
      policies or operating rules posted by us on this site or in respect to The Service constitutes
      the entire agreement and understanding between you and us and govern your use of the Service,
      superseding any prior or contemporaneous agreements, communications and proposals, whether
      oral or written, between you and us (including, but not limited to, any prior versions of the
      Terms of Service). <br /> Any ambiguities in the interpretation of these Terms of Service
      shall not be construed against the drafting party. <br /> <br />{' '}
      <strong>SECTION 18 - GOVERNING LAW</strong> <br /> These Terms of Service and any separate
      agreements whereby we provide you Services shall be governed by and construed in accordance
      with the laws of South Korea. <br /> <br />{' '}
      <strong>SECTION 19 - CHANGES TO TERMS OF SERVICE</strong> <br /> You can review the most
      current version of the Terms of Service at any time at this page. <br /> We reserve the right,
      at our sole discretion, to update, change or replace any part of these Terms of Service by
      posting updates and changes to our website. It is your responsibility to check our website
      periodically for changes. Your continued use of or access to our website or the Service
      following the posting of any changes to these Terms of Service constitutes acceptance of those
      changes. <br /> <br /> <strong>SECTION 20 - CONTACT INFORMATION</strong> <br /> Questions
      about the Terms of Service should be sent to us at help@brandless.kr.
    </div>
  );
};
export const KoTerms = () => {
  return (
    <div className={styled['rte']}>
      <strong>개요</strong> <br /> 이 웹사이트는 my Kmarket에서 운영합니다. 사이트 전체에서 "우리",
      "저희" 및 "우리의"라는 용어는 my Kmarket을 지칭합니다. my Kmarket은 여기에 명시된 모든 조건,
      정책 및 통지를 수락하는 조건으로 이 사이트에서 사용자에게 제공되는 모든 정보, 도구 및 서비스를
      포함하여 이 웹사이트를 제공합니다. <br /> <br />
      당사 사이트를 방문하거나 당사로부터 무언가를 구매함으로써 귀하는 당사의 "서비스"에 참여하고
      추가 이용 약관 및 정책을 포함하여 다음 이용 약관("서비스 약관", "약관")에 구속된다는 데
      동의합니다. 여기에서 참조 및/또는 하이퍼링크로 사용할 수 있습니다. 이 서비스 약관은 브라우저,
      공급업체, 고객, 판매자 및/또는 콘텐츠 기여자인 사용자를 포함하되 이에 국한되지 않는 사이트의
      모든 사용자에게 적용됩니다.
      <br /> <br />
      당사 웹사이트에 액세스하거나 사용하기 전에 이 서비스 약관을 주의 깊게 읽으십시오. 사이트의
      일부를 액세스하거나 사용함으로써 귀하는 본 서비스 약관에 구속된다는 데 동의합니다. 본 계약의
      모든 조건에 동의하지 않으면 웹 사이트에 액세스하거나 서비스를 사용할 수 없습니다. 본 서비스
      약관이 제안으로 간주되는 경우 수락은 명시적으로 본 서비스 약관으로 제한됩니다.
      <br /> <br />
      현재 스토어에 추가된 새로운 기능이나 도구도 서비스 약관의 적용을 받습니다. 이 페이지에서
      언제든지 서비스 약관의 최신 버전을 검토할 수 있습니다. 당사는 당사 웹사이트에 업데이트 및/또는
      변경 사항을 게시하여 본 서비스 약관의 일부를 업데이트, 변경 또는 교체할 권리를 보유합니다. 이
      페이지에서 주기적으로 변경 사항을 확인하는 것은 귀하의 책임입니다. 변경 사항을 게시한 후에도
      웹 사이트를 계속 사용하거나 액세스하면 해당 변경 사항을 수락하는 것으로 간주됩니다. <br />{' '}
      <br />
      저희 매장은 Shopify Inc.에서 호스팅됩니다. Shopify는 저희 제품과 서비스를 귀하에게 판매할 수
      있는 온라인 전자 상거래 플랫폼을 제공합니다. <br /> <br />{' '}
      <strong>섹션 1 - 온라인 스토어 약관</strong> <br />본 서비스 약관에 동의함으로써 귀하는 귀하가
      거주하는 주 또는 도에서 성년이거나 거주하는 주 또는 도에서 성년이며 다음 사항에 동의했음을
      나타냅니다. 귀하의 미성년 부양 가족이 이 사이트를 사용할 수 있도록 허용하십시오. <br />
      귀하는 불법적이거나 승인되지 않은 목적으로 당사 제품을 사용할 수 없으며 서비스 사용 시
      관할권의 법률(저작권법을 포함하되 이에 국한되지 않음)을 위반할 수 없습니다. <br />
      웜이나 바이러스 또는 파괴적인 성격의 코드를 전송해서는 안 됩니다. <br />
      약관을 위반하거나 위반하면 귀하의 서비스가 즉시 종료됩니다. <br /> <br />{' '}
      <strong>섹션 2 - 일반 조건</strong> <br />
      당사는 언제든지 어떤 이유로든 누구에게나 서비스를 거부할 권리가 있습니다. <br />
      귀하는 귀하의 콘텐츠(신용 카드 정보 제외)가 암호화되지 않은 상태로 전송될 수 있으며 (a) 다양한
      네트워크를 통한 전송; (b) 연결 네트워크 또는 장치의 기술적 요구 사항을 준수하고 적응하기 위한
      변경. 신용 카드 정보는 네트워크를 통한 전송 중에 항상 암호화됩니다. <br />
      귀하는 당사의 명시적인 서면 허가 없이 서비스의 일부, 서비스 사용 또는 서비스에 대한 액세스
      또는 서비스가 제공되는 웹 사이트의 모든 연락처를 재생산, 복제, 복사, 판매, 재판매 또는
      악용하지 않는다는 데 동의합니다. . <br />본 계약에 사용된 제목은 편의상 포함된 것이며 본
      약관을 제한하거나 달리 영향을 미치지 않습니다.
      <br /> <br /> <strong>섹션 3 - 정보의 정확성, 완전성 및 적시성</strong> <br /> 당사는 이
      사이트에서 제공되는 정보가 정확하거나 완전하지 않거나 최신 정보가 아닌 경우 책임을 지지
      않습니다. 이 사이트의 자료는 일반 정보용으로만 제공되며 일차적, 보다 정확하고 보다 완전하거나
      보다 시의적절한 정보 출처를 참조하지 않고 결정을 내리기 위한 유일한 근거로 의존하거나
      사용해서는 안 됩니다. 이 사이트의 자료에 대한 의존은 귀하의 책임입니다.
      <br />이 사이트에는 특정 역사적 정보가 포함될 수 있습니다. 반드시 과거 정보는 최신 정보가
      아니며 참조용으로만 제공됩니다. 당사는 언제든지 이 사이트의 내용을 수정할 수 있는 권리를
      보유하지만 당사 사이트의 정보를 업데이트할 의무는 없습니다. 귀하는 당사 사이트의 변경 사항을
      모니터링하는 것이 귀하의 책임이라는 데 동의합니다. <br /> <br />{' '}
      <strong>섹션 4 - 서비스 및 가격 수정</strong> <br /> 당사 제품의 가격은 예고 없이 변경될 수
      있습니다. <br />
      당사는 언제든지 통지 없이 서비스(또는 그 일부 또는 콘텐츠)를 수정하거나 중단할 수 있는 권리를
      보유합니다. <br />
      당사는 서비스의 수정, 가격 변경, 정지 또는 중단에 대해 귀하 또는 제3자에게 책임을 지지
      않습니다.
      <br /> <br /> <strong>섹션 5 - 제품 또는 서비스(해당되는 경우)</strong> <br /> 특정 제품 또는
      서비스는 웹사이트를 통해 온라인으로만 제공될 수 있습니다. 이러한 제품 또는 서비스는 수량이
      제한되어 있을 수 있으며 반품 정책에 따라서만 반품 또는 교환될 수 있습니다. <br />
      우리는 매장에 나타나는 제품의 색상과 이미지를 최대한 정확하게 표시하기 위해 모든 노력을
      기울였습니다. 귀하의 컴퓨터 모니터에 표시되는 색상이 정확하다고 보장할 수 없습니다.
      <br />
      당사는 당사 제품 또는 서비스의 판매를 개인, 지역 또는 관할권으로 제한할 권리를 보유하지만
      의무는 아닙니다. 당사는 이 권리를 사례별로 행사할 수 있습니다. 당사는 당사가 제공하는 제품
      또는 서비스의 수량을 제한할 권리가 있습니다. 제품 또는 제품 가격에 대한 모든 설명은 당사의
      단독 재량에 따라 예고 없이 언제든지 변경될 수 있습니다. 우리는 언제든지 제품을 중단할 권리가
      있습니다. 이 사이트에서 제공되는 모든 제품 또는 서비스에 대한 제안은 금지된 경우 무효입니다.{' '}
      <br />
      당사는 귀하가 구매하거나 획득한 제품, 서비스, 정보 또는 기타 자료의 품질이 귀하의 기대를
      충족하거나 서비스의 오류가 수정될 것이라고 보증하지 않습니다. <br /> <br />{' '}
      <strong>섹션 6 - 청구 및 계정 정보의 정확성</strong> <br />
      당사는 귀하의 주문을 거부할 권리가 있습니다. 당사는 단독 재량에 따라 1인당, 가구당 또는 주문당
      구매 수량을 제한하거나 취소할 수 있습니다. 이러한 제한에는 동일한 고객 계정, 동일한 신용 카드
      및/또는 동일한 청구 및/또는 배송 주소를 사용하는 주문에 의해 또는 동일한 고객 계정으로
      이루어진 주문이 포함될 수 있습니다. 주문을 변경하거나 취소하는 경우 주문 당시 제공된 이메일
      및/또는 청구서 수신 주소/전화 번호로 연락하여 통지를 시도할 수 있습니다. 당사는 단독 판단에
      따라 딜러, 리셀러 또는 유통업체가 주문한 것으로 보이는 주문을 제한하거나 금지할 권리가
      있습니다.
      <br /> <br />
      귀하는 당사 매장에서 이루어진 모든 구매에 대해 최신의 완전하고 정확한 구매 및 계정 정보를
      제공하는 데 동의합니다. 귀하는 당사가 귀하의 거래를 완료하고 필요에 따라 귀하에게 연락할 수
      있도록 귀하의 이메일 주소, 신용 카드 번호 및 만료일을 포함한 귀하의 계정 및 기타 정보를 즉시
      업데이트하는 데 동의합니다. <br /> <br />
      자세한 내용은 반품 정책을 검토하십시오. <br /> <br />{' '}
      <strong>섹션 7 - 선택적 도구 당사는</strong> <br /> 당사가 모니터링하거나 통제하거나 입력할 수
      없는 제3자 도구에 대한 액세스를 귀하에게 제공할 수 있습니다.
      <br />
      귀하는 당사가 어떠한 종류의 보증, 진술 또는 조건 없이 그리고 승인 없이 "있는 그대로" 및 "사용
      가능한" 도구에 대한 액세스를 제공한다는 것을 인정하고 동의합니다. 당사는 선택적 제3자 도구의
      사용으로 인해 발생하거나 이와 관련하여 어떠한 책임도 지지 않습니다. <br />
      사이트를 통해 제공되는 선택적 도구의 사용은 전적으로 귀하의 위험과 재량에 달려 있으며 귀하는
      관련 제3자 공급자가 제공하는 도구의 조건을 숙지하고 승인해야 합니다. <br />
      또한 향후 웹 사이트를 통해 새로운 서비스 및/또는 기능을 제공할 수도 있습니다(새로운 도구 및
      리소스의 릴리스 포함). 이러한 새로운 기능 및/또는 서비스에도 본 서비스 약관이 적용됩니다.{' '}
      <br /> <br /> <strong>섹션 8 - 제3자 링크</strong> <br />
      당사 서비스를 통해 제공되는 특정 콘텐츠, 제품 및 서비스에는 제3자의 자료가 포함될 수 있습니다.{' '}
      <br />이 사이트의 제3자 링크는 귀하를 당사와 관련이 없는 제3자 웹사이트로 안내할 수 있습니다.
      당사는 콘텐츠 또는 정확성을 검사하거나 평가할 책임이 없으며 제3자 자료나 웹 사이트 또는
      제3자의 기타 자료, 제품 또는 서비스에 대해 보증하지 않으며 어떠한 책임이나 책임도 지지
      않습니다.
      <br />
      당사는 상품, 서비스, 리소스, 콘텐츠의 구매 또는 사용 또는 제3자 웹사이트와 관련된 기타 거래와
      관련된 피해나 손해에 대해 책임을 지지 않습니다. 제3자의 정책 및 관행을 주의 깊게 검토하고
      거래에 참여하기 전에 이를 이해했는지 확인하십시오. 제3자 제품에 관한 불만, 주장, 우려 또는
      질문은 제3자에게 전달해야 합니다. <br /> <br />{' '}
      <strong>섹션 9 - 사용자 의견, 피드백 및 기타 제출</strong> <br />
      당사의 요청에 따라 특정 제출물(예: 콘테스트 참가작)을 보내거나 당사의 요청 없이 온라인,
      이메일, 우편 또는 기타 방법으로 창의적인 아이디어, 제안, 제안, 계획 또는 기타 자료를 보내는
      경우 (총칭하여 '의견'), 귀하는 당사가 귀하가 당사에 전달하는 모든 의견을 언제든지 제한 없이
      편집, 복사, 게시, 배포, 번역 및 모든 매체에서 사용할 수 있다는 데 동의합니다. 우리는 (1)
      의견을 비밀로 유지할 의무가 없습니다. (2) 의견에 대한 보상을 지불합니다. 또는 (3) 의견에
      응답합니다.
      <br />
      당사는 단독 재량에 따라 불법, 공격, 위협, 비방, 명예 훼손, 음란물, 외설적 또는 달리 불쾌하거나
      당사자의 지적 재산권 또는 본 서비스 약관을 위반한다고 판단되는 콘텐츠를 모니터링, 편집 또는
      제거할 수 있지만 의무는 없습니다. .<br />
      귀하는 귀하의 의견이 저작권, 상표권, 개인 정보 보호, 성격 또는 기타 개인 또는 소유권을
      포함하여 제3자의 권리를 침해하지 않는다는 데 동의합니다. 또한 귀하는 귀하의 의견에
      비방적이거나 불법적이거나 모욕적이거나 외설적인 자료가 포함되지 않거나 서비스 또는 관련 웹
      사이트의 운영에 어떤 식으로든 영향을 미칠 수 있는 컴퓨터 바이러스 또는 기타 맬웨어가 포함되지
      않는다는 데 동의합니다. 허위 이메일 주소를 사용하거나 자신이 아닌 다른 사람인 것처럼
      가장하거나 의견의 출처에 대해 당사 또는 제3자를 오도할 수 없습니다. 귀하가 작성한 의견과 그
      정확성에 대한 책임은 전적으로 귀하에게 있습니다. 당사는 귀하 또는 제3자가 게시한 댓글에 대해
      어떠한 책임도 지지 않습니다. <br /> <br /> <strong>섹션 10 - 개인 정보</strong> <br />
      스토어를 통한 귀하의 개인 정보 제출은 당사의 개인 정보 보호 정책에 따릅니다. 개인 정보 보호
      정책을 보려면. <br /> <br /> <strong>섹션 11 - 오류, 부정확성 및 누락</strong> <br /> 때때로
      당사 사이트 또는 서비스에 제품 설명, 가격 책정, 프로모션, 제안, 제품 배송료, 운송 시간 및
      가용성과 관련될 수 있는 인쇄상의 오류, 부정확성 또는 누락이 포함된 정보가 있을 수 있습니다.
      당사는 오류, 부정확성 또는 누락을 수정하고 서비스 또는 관련 웹사이트의 정보가 사전 통지 없이
      부정확한 경우 정보를 변경 또는 업데이트하거나 주문을 취소할 수 있는 권리를 보유합니다(귀하가
      주문을 제출한 후 포함). .<br />
      당사는 법률에서 요구하는 경우를 제외하고 가격 정보를 포함하되 이에 국한되지 않는 서비스 또는
      관련 웹사이트의 정보를 업데이트, 수정 또는 명확히 할 의무가 없습니다. 서비스 또는 관련
      웹사이트에 적용되는 지정된 업데이트 또는 새로 고침 날짜는 서비스 또는 관련 웹사이트의 모든
      정보가 수정 또는 업데이트되었음을 ​​나타내는 것으로 간주되어서는 안 됩니다. <br /> <br />{' '}
      <strong>섹션 12 - 금지된 사용</strong> <br />
      서비스 약관에 명시된 다른 금지 사항 외에도 귀하는 사이트 또는 그 콘텐츠를 다음과 같이 사용하는
      것이 금지됩니다. (a) 불법적인 목적으로; (b) 불법 행위를 수행하거나 참여하도록 타인을 권유하는
      행위 (c) 국제, 연방, 지방 또는 주의 규정, 규칙, 법률 또는 지역 조례를 위반하는 행위 (d) 당사의
      지적 재산권 또는 타인의 지적 재산권을 침해하거나 위반하는 행위 (e) 성별, 성적 취향, 종교,
      민족, 인종, 연령, 출신 국가 또는 장애를 이유로 괴롭히거나, 학대하거나, 모욕하거나, 해를
      입히거나, 비방하거나, 비방하거나, 폄하하거나, 위협하거나, 차별하는 행위 (f) 허위 또는 오해의
      소지가 있는 정보를 제출하는 행위 (g) 서비스나 관련 웹사이트, 다른 웹사이트 또는 인터넷의
      기능이나 작동에 영향을 미치거나 영향을 미칠 수 있는 바이러스 또는 기타 유형의 악성 코드를
      업로드하거나 전송하는 행위 (h) 타인의 개인 정보를 수집하거나 추적하기 위해 (i) 스팸, 피싱, 약,
      구실, 거미, 크롤링 또는 긁기; (j) 외설적이거나 부도덕한 목적을 위해; 또는 (k) 서비스 또는 관련
      웹사이트, 기타 웹사이트 또는 인터넷의 보안 기능을 방해하거나 회피합니다. 당사는 금지된 사용을
      위반하는 경우 귀하의 서비스 또는 관련 웹사이트 사용을 종료할 권리를 보유합니다. (j)
      외설적이거나 부도덕한 목적을 위해; 또는 (k) 서비스 또는 관련 웹사이트, 기타 웹사이트 또는
      인터넷의 보안 기능을 방해하거나 회피합니다. 당사는 금지된 사용을 위반하는 경우 귀하의 서비스
      또는 관련 웹사이트 사용을 종료할 권리를 보유합니다. (j) 외설적이거나 부도덕한 목적을 위해;
      또는 (k) 서비스 또는 관련 웹사이트, 기타 웹사이트 또는 인터넷의 보안 기능을 방해하거나
      회피합니다. 당사는 금지된 사용을 위반하는 경우 귀하의 서비스 또는 관련 웹사이트 사용을 종료할
      권리를 보유합니다.
      <br /> <br /> <strong>섹션 13 - 보증 부인; 책임의 제한 당사</strong> <br /> 는 귀하의 당사
      서비스 사용이 중단되지 않거나 시기적절하거나 안전하거나 오류가 없음을 보증, 진술 또는 보증하지
      않습니다. <br />
      당사는 서비스 이용으로 인해 얻을 수 있는 결과가 정확하거나 신뢰할 수 있음을 보증하지 않습니다.{' '}
      <br />
      귀하는 때때로 당사가 귀하에게 통지하지 않고 무기한으로 서비스를 제거하거나 서비스를 취소할 수
      있음에 동의합니다.
      <br />
      귀하는 귀하의 서비스 사용 또는 사용 불능이 전적으로 귀하의 책임이라는 데 명시적으로
      동의합니다. 서비스 및 서비스를 통해 귀하에게 제공되는 모든 제품 및 서비스는 명시적이든
      명시적이든 어떠한 종류의 진술, 보증 또는 조건 없이 귀하가 사용할 수 있도록 '있는 그대로'
      그리고 '이용 가능한 대로' 제공됩니다(당사가 명시적으로 언급한 경우 제외). 상품성, 상품성 품질,
      특정 목적에의 적합성, 내구성, 소유권 및 비침해성에 대한 모든 묵시적 보증 또는 조건을 포함하여
      묵시적입니다.
      <br />
      가능성에 대해 조언을 받은 경우에도 서비스를 통해 전송되거나 다른 방식으로 제공됩니다. 일부 주
      또는 관할권에서는 결과적 또는 우발적 손해에 대한 책임의 배제 또는 제한을 허용하지 않기 때문에
      그러한 주 또는 관할권에서 당사의 책임은 법이 허용하는 최대 범위로 제한됩니다.
      <br /> <br /> <strong>섹션 14 - 면책</strong> <br /> 귀하는 my Kmarket과 모회사, 자회사,
      계열사, 파트너, 임원, 이사, 대리인, 계약자, 라이센스 제공자, 서비스 제공자, 하도급자,
      공급업체, 인턴 및 직원을 모든 청구로부터 면책, 방어 및 면책하는 데 동의합니다. 귀하가 본
      서비스 약관 또는 참조로 통합한 문서를 위반하거나 법률 또는 제3자의 권리를 위반하여 발생하는
      제3자의 요구(합리적인 변호사 비용 포함) . <br /> <br /> <strong>섹션 15 - 분리 가능성</strong>{' '}
      <br />본 서비스 약관의 조항이 불법, 무효 또는 시행 불가능한 것으로 결정되는 경우에도 해당
      조항은 해당 법률이 허용하는 최대 범위까지 시행 가능하며 시행 불가능한 부분은 본 약관에서
      분리된 것으로 간주됩니다. 그러한 결정은 다른 나머지 조항의 유효성 및 집행 가능성에 영향을
      미치지 않습니다. <br /> <br /> <strong>섹션 16 -</strong> <br /> 해지 해지 날짜 이전에 발생한
      당사자의 의무 및 책임은 모든 목적을 위해 본 계약이 해지된 후에도 존속합니다.
      <br />본 서비스 약관은 귀하 또는 당사가 종료하지 않는 한 유효합니다. 귀하는 더 이상 당사
      서비스를 사용하지 않겠다고 통보하거나 당사 사이트 사용을 중단할 때 언제든지 본 서비스 약관을
      해지할 수 있습니다. <br />
      당사의 단독 판단에 따라 귀하가 본 서비스 약관의 조건 또는 조항을 준수하지 못하거나 실패한
      것으로 의심되는 경우 당사는 통지 없이 언제든지 본 계약을 해지할 수 있으며 귀하는 기한이 지난
      모든 금액에 대한 책임을 지게 됩니다. 해지일까지 포함 및/또는 그에 따라 당사 서비스(또는 그
      일부)에 대한 귀하의 액세스를 거부할 수 있습니다. <br /> <br />{' '}
      <strong>섹션 17 - 전체 계약</strong> <br /> 당사가 본 서비스 약관의 권리 또는 조항을
      행사하거나 시행하지 못한다고 해서 해당 권리 또는 조항의 포기로 간주되지 않습니다.
      <br />본 서비스 약관 및 당사가 이 사이트에 또는 서비스와 관련하여 게시한 모든 정책 또는 운영
      규칙은 귀하와 당사 간의 전체 계약 및 이해를 구성하며 이전 또는 현재의 모든 계약, 통신 및
      제안을 대체하여 귀하의 서비스 사용을 규율합니다. , 귀하와 당사 간의 구두 또는 서면 여부(서비스
      약관의 이전 버전을 포함하되 이에 국한되지 않음). <br />본 서비스 약관 해석의 모호성은 초안
      작성 당사자에게 불리하게 해석되지 않습니다. <br /> <br /> <strong>
        섹션 18 - 준거법
      </strong>{' '}
      <br /> 본 서비스 약관 및 당사가 귀하에게 서비스를 제공하는 별도의 계약은 대한민국 법률에 따라
      규율되고 해석됩니다. <br /> <br /> <strong>섹션 19 - 서비스 약관 변경</strong> <br />이
      페이지에서 언제든지 서비스 약관의 최신 버전을 검토할 수 있습니다. <br />
      당사는 단독 재량에 따라 당사 웹사이트에 업데이트 및 변경 사항을 게시하여 본 서비스 약관의
      일부를 업데이트, 변경 또는 대체할 권리를 보유합니다. 주기적으로 웹사이트에서 변경 사항을
      확인하는 것은 귀하의 책임입니다. 본 서비스 약관의 변경 사항이 게시된 후 당사 웹 사이트 또는
      서비스를 계속 사용하거나 액세스하는 것은 해당 변경 사항을 수락하는 것으로 간주됩니다. <br />{' '}
      <br /> <strong>섹션 20 - 연락처 정보</strong> <br /> 서비스 약관에 대한 질문은
      help@brandless.kr로 보내주십시오.
    </div>
  );
};

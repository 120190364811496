import { ChangeEventHandler, Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { PriceRangeOptions, SelectedOptions } from '../../pages/Categories';
import { modalState } from '../../stores/modal/atoms';
import { Part } from '../../utils/getCategories';
import { Button } from '../button';
import ToggleSwitch from '../toggle-switch/ToggleSwitch';
import { CategoryAsideRadio } from './atoms/';
import styles from './styles/CategoryAside.module.scss';

type CategoryAsideProps = {
  categoryName?: string | null;
  categoryHandle?: string;
  subCategoryHandle?: string | null;
  categoryItems?: Part[];
  selectedOptions: SelectedOptions;
  setSelectedOptions: Dispatch<SetStateAction<SelectedOptions>>;
  customPrice: PriceRangeOptions;
  setCustomPrice: Dispatch<SetStateAction<PriceRangeOptions>>;
};

const CategoryAside = ({
  categoryName,
  categoryHandle,
  subCategoryHandle,
  categoryItems,
  selectedOptions,
  setSelectedOptions,
  customPrice,
  setCustomPrice,
}: CategoryAsideProps) => {
  const { t } = useTranslation();
  const priceOptions = {
    Unlimited: t('category_aside_price_unlimited'),
    'Under $5': t('category_aside_price_under5'),
    '$5 - $10': t('category_aside_price_under10'),
    '$10 - $15': t('category_aside_price_under15'),
    '$15 - $25': t('category_aside_price_under25'),
    '$25 - Above': t('category_aside_price_over25'),
    custom: t('category_aside_price_custom'),
  };

  const countryOptions = {
    unlimited: 'Unlimited',
    'south-korea': 'South Korea',
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, name } = e.target;
    setSelectedOptions({
      ...selectedOptions,
      [name]: value,
    });
  };
  const handleToggle = (name: string) => {
    setSelectedOptions({
      ...selectedOptions,
      [name]: !selectedOptions?.forSale,
    });
  };

  return (
    <div className={styles['category__side']}>
      <div className={styles['category__side__menu']}>
        <div className={styles['category__side__type']}>{categoryName}</div>
        {categoryItems?.map(({ id, name, handle }) => (
          <div
            className={`${styles['category__side__item']} ${
              handle === subCategoryHandle || (name === 'All' && !subCategoryHandle)
                ? styles['category__side__strong']
                : ''
            }`}
            key={id}
          >
            <Link
              to={`/categories?menu=${categoryHandle}${
                categoryHandle !== handle ? `&submenu=${handle}` : ``
              }`}
            >
              {t(`category_name_${name === 'All' ? name : handle}`)}
            </Link>
          </div>
        ))}
      </div>

      <div className={styles['category__side__price']}>
        <div className={styles['category__side__head']}>{t('category_aside_price')}</div>
        <CategoryAsideRadio items={priceOptions} name="priceOption" handleChange={handleChange} />
        <CustomRangeInput
          customPrice={customPrice}
          selectedOptions={selectedOptions}
          setCustomPrice={setCustomPrice}
          setSelectedOptions={setSelectedOptions}
        />
      </div>
    </div>
  );
};

export default CategoryAside;

interface ICustomRangeInput {
  customPrice: PriceRangeOptions;
  selectedOptions: SelectedOptions;
  setCustomPrice: Dispatch<SetStateAction<PriceRangeOptions>>;
  setSelectedOptions: Dispatch<SetStateAction<SelectedOptions>>;
}

const CustomRangeInput = ({
  customPrice,
  selectedOptions,
  setCustomPrice,
  setSelectedOptions,
}: ICustomRangeInput) => {
  const { t } = useTranslation();
  const [modal, setModal] = useRecoilState(modalState);
  const onClickApply = () => {
    if (typeof priceRange.max == 'undefined' || priceRange.min <= priceRange.max!) {
      setCustomPrice(priceRange);
      setSelectedOptions({
        ...selectedOptions,
        priceOption: 'custom',
      });
    } else {
      // to do : 범위 카테고리 다국어 필요
      setModal({
        modal: true,
        childrenText: t('category_aside_price_custom_modal'),
        buttons: [
          {
            text: t('category_aside_price_custom_modal_button'),
          },
        ],
      });
      return;
    }
  };
  const [priceRange, setPriceRange] = useState<PriceRangeOptions>(customPrice);

  return (
    <div className={styles['custom_range_container']}>
      <div className={styles['custom_range_input']}>
        <RangeInputUnit
          type="min"
          selectedOptions={selectedOptions}
          priceRange={priceRange}
          setPriceRange={setPriceRange}
        />
        <span>~</span>
        <RangeInputUnit
          type="max"
          selectedOptions={selectedOptions}
          priceRange={priceRange}
          setPriceRange={setPriceRange}
        />
      </div>
      <Button action={onClickApply} className={styles['custom_range__button']}>
        <span>{t('category_aside_price_custom_apply')}</span>
      </Button>
    </div>
  );
};

interface IRangeInputUnit {
  type: 'min' | 'max';
  selectedOptions: SelectedOptions;
  priceRange: PriceRangeOptions;
  setPriceRange: Dispatch<SetStateAction<PriceRangeOptions>>;
}

const RangeInputUnit = ({ type, selectedOptions, priceRange, setPriceRange }: IRangeInputUnit) => {
  const { t } = useTranslation();
  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setPriceRange((prev) => ({
      ...prev,
      [type]: Number(value),
    }));
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!priceRange[type] && (event.key === '0' || event.key === '.')) event.preventDefault();
    if (event.key === '-' || event.key === '+') event.preventDefault();
  };
  return (
    <div className={styles['range_input_unit']}>
      <div className={styles['range_input_unit_type']}>
        {type == 'min'
          ? t('category_aside_price_custom_min')
          : t('category_aside_price_custom_max')}
      </div>
      <div className={styles['range_input_unit_input']}>
        <input
          placeholder={
            type == 'min'
              ? t('category_aside_price_custom_min_placeholder')
              : t('category_aside_price_custom_max_placeholder')
          }
          min={0}
          name={type}
          type="number"
          disabled={selectedOptions.priceOption != 'custom'}
          onKeyDown={(e) => handleKeyDown(e)}
          defaultValue={
            selectedOptions.priceOption == 'custom' && priceRange[type] != 0 ? priceRange[type] : ''
          }
          onChange={handleChangeInput}
        />
      </div>
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import { FormButton } from '../login/atoms';
import styles from './Empty.module.scss';

interface Props {
  message: string;
  subMessage?: string;
  isProduct?: boolean;
  buttonMessage?: string;
  onClickEmpty?: () => void;
  className?: string;
}

const Empty = ({
  message,
  buttonMessage,
  onClickEmpty,
  subMessage,
  isProduct = true,
  className,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles['container']} ${className ? styles[`${className}`] : ''}`}>
      <div className={styles['icon']}>
        <img src="/icons/icon__empty.svg" alt="" />
      </div>
      <div className={styles['description']}>{message}</div>
      {isProduct && (
        <>
          {subMessage && <div className={styles['description--sub']}>{t(subMessage)}</div>}
          <div className={styles['button']}>
            <FormButton
              contents={buttonMessage ? t(buttonMessage) : t('common_empty_button')}
              onClick={onClickEmpty ? onClickEmpty : () => console.log('Redirect to ?')}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Empty;

import { ReactNode } from 'react';
import { atom } from 'recoil';

export interface ToastState {
  content: ReactNode;
}

export const toastState = atom<ToastState | null>({
  key: 'toastState',
  default: null,
});

import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useRecoilValueLoadable } from 'recoil';
import { MkmProduct, ProductImage, ProductType } from '../@types/product';
import { WrittenReviewType } from '../@types/review';
import {
  deleteProductInWishlist,
  getProductByShopifyId,
  postProductInWishlist,
} from '../api/backend/product';
import { getProductById } from '../api/storefront/products';
import { CartButton } from '../components/button';
import { CategoryPath } from '../components/category';
import { BaseLayout } from '../components/layout';
import { MobileNavItem } from '../components/layout/BaseLayout';
import { ProductContainer } from '../components/products';
import {
  ProductBasicInfo,
  ProductDescription,
  ProductReviews,
  ProductThumbnail,
} from '../components/products/atoms';
import { useModularization } from '../hooks/useModularization';
import { customerState } from '../stores/customer/atoms';
import styles from './styles/ProductDetail.module.scss';
import Loading from '../components/loading/Loading';
import Empty from '../components/empty/Empty';

export const PageDivider = () => {
  return <div className={styles['divider']}></div>;
};

const ProductDetail = (): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const go = useNavigate();
  const customer = useRecoilValueLoadable(customerState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [product, setProduct] = useState<ProductType>();

  const [mkmProduct, setMkmProduct] = useState<MkmProduct>();
  const [hasWish, setHasWish] = useState<boolean>(false);
  const [reviews, setReviews] = useState<WrittenReviewType[]>([]);
  const [productImages, setProductImages] = useState<ProductImage[]>();

  const getProductByIdRequest = useCallback(async () => {
    const { response, product } = await getProductById(id);
    const productImages = product?.images?.edges.map(({ node }) => node);
    const mkmResponse = await getProductByShopifyId(encodeURIComponent(product.id));

    setProduct(product);
    setProductImages(productImages);
    setHasWish(mkmResponse?.data?.hasWish ?? false);
    setReviews(mkmResponse?.data?.reviews);
    setMkmProduct(mkmResponse?.data);
    setIsLoading(response.loading);
  }, [id]);

  const handleSubmitWish = async () => {
    if (!customer.contents) {
      go('/login');
    }

    if (!product) {
      return;
    }

    if (hasWish) {
      setHasWish((prev) => !prev);
      await deleteProductInWishlist([mkmProduct?.mkmId]);
      return;
    }

    setHasWish((prev) => !prev);
    await postProductInWishlist(product);
  };

  useEffect(() => {
    getProductByIdRequest();
  }, [id]);

  const { moduleItems } = useModularization({ type: 'productDetail' });
  return (
    <BaseLayout mobileNav={{ type: MobileNavItem.CATEGORY_ALL }}>
      {isLoading ? (
        <div className={styles['loading']}>
          <Loading />
        </div>
      ) : product ? (
        <div className={styles['product__detail__container']}>
          <div className={styles['product__detail__path']}>
            <CategoryPath
              category={t(
                `category_name_${product.productType.replace(/ & | |\//g, '-').toLowerCase()}`,
              )}
            />
          </div>
          <div className={styles['product__detail']}>
            {productImages && productImages?.length > 0 && (
              <ProductThumbnail
                images={{
                  mobileImages: productImages,
                  desktopImages: productImages,
                }}
                isSoldout={product.totalInventory < 1}
              />
            )}
            <ProductBasicInfo
              product={product}
              handleSubmitWish={handleSubmitWish}
              hasWish={hasWish}
            />
          </div>
          <PageDivider />
          <div className={styles['product__container']}>
            <ProductReviews
              reviews={reviews}
              title={product.title}
              hasPurchaseHistory={mkmProduct?.isPurchased ?? false}
              options={{ borderShow: true }}
              productId={product.id}
            />
          </div>
          <PageDivider />
          {moduleItems?.map((moduleItem) => (
            <ProductContainer
              key={moduleItem.id}
              item={moduleItem}
              options={{
                borderShow: true,
                infiniteScrollMode: moduleItem.type.text === '2xN',
                originName: 'product',
              }}
            />
          ))}

          <ProductDescription descriptionHtml={product.descriptionHtml} />

          <div className={styles['product__cart']}>
            <CartButton
              item={product}
              handleSubmitWish={handleSubmitWish}
              hasWish={hasWish}
              isSoldout={product.totalInventory < 1}
            />
          </div>
        </div>
      ) : (
        <div className={styles['empty']}>
          <Empty message={t('product_detail_empty')} />
        </div>
      )}
    </BaseLayout>
  );
};

export default ProductDetail;

import { ChangeEvent, InputHTMLAttributes, ReactElement } from 'react';
import styles from './Radio.module.scss';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  title?: string;
  subtitle?: string;
  value?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  option?: {
    direction: 'row' | 'column';
  };
}

const Radio = ({
  checked,
  name,
  title,
  subtitle,
  value,
  children,
  handleChange,
  option = {
    direction: 'row',
  },
  ...props
}: Props): ReactElement => {
  return (
    <label className={styles['radio-label']}>
      <input
        type="radio"
        className={styles['radio-input']}
        name={name}
        checked={checked}
        value={value ?? name}
        onChange={handleChange}
        {...props}
      />
      <div
        className={
          styles[`radio-text-container${option && option.direction === 'column' && '--column'}`]
        }
      >
        {title && <span className={styles['radio-title']}>{title}</span>}
        {subtitle && (
          <span
            className={`${styles['radio-subtitle']} ${
              option.direction === 'column'
                ? styles['radio-subtitle--m']
                : styles['radio-subtitle--s']
            }`}
          >
            {subtitle}
          </span>
        )}
        {children && children}
      </div>
    </label>
  );
};

export default Radio;

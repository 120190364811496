import { Dispatch, MouseEventHandler, ReactElement, SetStateAction } from 'react';
import { useNavigate } from 'react-router';
import { useResponsiveElement } from '../../helper/hooks';
import styles from './Tab.module.scss';

export type TabData = {
  name: string;
  img?: string;
  key?: string;
};

type TabProps<T> = {
  data: TabData[];
  clickedTab: number;
  setClickedTab?: Dispatch<SetStateAction<number>>;
  setRoute?: boolean;
  img?: T[];
  align?: 'LEFT' | 'CENTER';
};

type TabItemProps = {
  data: string;
  isSelected: boolean;
  handleClick: MouseEventHandler<HTMLDivElement>;
  img?: string;
};

export const MobileTabItem = ({ data, isSelected, handleClick }: TabItemProps) => {
  return (
    <div
      className={`${styles['tab__item']} ${isSelected ? styles['tab__item--active'] : ''}`}
      onClick={handleClick}
    >
      {data as string}
    </div>
  );
};

export const DesktopTabItem = ({ data, isSelected, handleClick, img }: TabItemProps) => {
  return (
    <div
      className={`${styles['tab__item--desktop']} ${
        isSelected ? styles['tab__item--active--desktop'] : ''
      }`}
      onClick={handleClick}
    >
      {img && <img src={img} alt="탭 이미지" />}
      {data}
    </div>
  );
};

const Tab = <T extends string>({
  data,
  clickedTab,
  setClickedTab,
  setRoute,
  align = 'CENTER',
}: TabProps<T>): ReactElement => {
  const go = useNavigate();
  const TabItem = useResponsiveElement({
    MOBILE: data.map(({ name }, idx) => (
      <MobileTabItem
        key={`${name}-${idx}`}
        data={name}
        isSelected={idx === clickedTab}
        handleClick={
          setRoute
            ? () => {
                setClickedTab!(idx);
                go(data[idx].key!);
              }
            : () => setClickedTab!(idx)
        }
      />
    )),
    DESKTOP: data.map(({ name, img }, idx) => (
      <DesktopTabItem
        key={`${name}-${idx}`}
        data={name}
        isSelected={idx === clickedTab}
        handleClick={
          setRoute
            ? () => {
                go(data[idx].key!);
              }
            : () => setClickedTab!(idx)
        }
        img={img}
      />
    )),
  });

  return (
    <div
      className={`${styles['container']} ${align === 'CENTER' ? styles['container--center'] : ''}`}
    >
      {TabItem}
    </div>
  );
};

export default Tab;

import { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import styles from './styles/Input.module.scss';

interface Props {
  hasError?: boolean;
  errorMessage?: string;
}

const Input = ({
  name,
  type,
  placeholder,
  defaultValue,
  onChange,
  onClick,
  hasError = false,
  errorMessage,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & Props) => {
  const [inputType, setInputType] = useState<string>(type ?? 'text');
  const [passwordShow, setPasswordShow] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const onClickPasswordImage = () => {
    setPasswordShow((show) => !show);
  };

  useEffect(() => {
    if (type === 'password') {
      if (passwordShow) {
        setInputType('text');
      } else {
        setInputType('password');
      }
    }
  }, [passwordShow, type]);

  return (
    <>
      <div className={styles['form-input-container']} onClick={onClick}>
        <div className={styles['form-input-inner']}>
          <input
            ref={inputRef}
            name={name}
            className={`${styles['form-input-input']} ${
              hasError && styles['form-input-input--danger']
            }`}
            type={inputType}
            placeholder=" "
            defaultValue={defaultValue}
            onChange={onChange}
            autoComplete="off"
            {...props}
          />
          <span
            onClick={() => {
              inputRef.current?.focus();
            }}
          >
            {placeholder}
          </span>
        </div>
        {type === 'password' && passwordShow && (
          <img
            onClick={onClickPasswordImage}
            className={styles['form-input--password-image']}
            src={'/icons/input-password--show.svg'}
            alt="비밀번호 숨기기"
          />
        )}
        {type === 'password' && !passwordShow && (
          <img
            onClick={onClickPasswordImage}
            className={styles['form-input--password-image']}
            src={'/icons/input-password--hide.svg'}
            alt="비밀번호 숨기기"
          />
        )}
        {hasError && (
          <div className={styles['input__error']}>
            <div className={styles['input__error__image']}>
              <img src="/icons/input-danger.svg" alt="" />
            </div>
            <div>{errorMessage}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default Input;

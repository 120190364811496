import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useResponsiveElement } from '../../helper/hooks';
import styles from './Footer.module.scss';
import { useViewport } from '../../helper/hooks';

const Footer = (): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isMobile } = useViewport();
  const bottomButtonExistForPaths = [
    '/product',
    '/account/profile/delivery-info',
    '/account/wishlist',
    '/account/language',
    '/account/help-center/request',
    '/cart',
    '/checkout-completed',
    '/checkout',
  ];
  const removeBottomButtonExistForPaths = [
    '/account/profile/delivery-info-add',
    '/account/help-center/request/',
  ];
  const isBottomButtonExist =
    !!bottomButtonExistForPaths.find((path) => location.pathname.startsWith(path)) &&
    !removeBottomButtonExistForPaths.find((path) => location.pathname.includes(path));

  const removeTopPaddingForPaths = [
    '/checkout',
    '/account/language',
    '/account/information',
    '/account/policy',
    '/orders/gid',
  ];
  const isremoveTopPadding = removeTopPaddingForPaths.find((path) =>
    location.pathname.startsWith(path),
  );
  const mobileFooter = (
    <div
      className={`${styles['mobile-footer']} ${
        isBottomButtonExist && styles['mobile-footer-bottom-pading']
      }`}
    >
      <div>
        <h5>{t('footer_welcome')}</h5>
        <p>{t('footer_description')}</p>
        <h5>{t('footer_customer_center')}</h5>
        <h3>help@mykmarket.com</h3>
        <div>
          <div>
            <h4>{t('footer_information')}</h4>
            <div></div>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to="/account/information"
            >
              {t('footer_information_about')}
            </Link>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to="/account/information/benefit"
            >
              {t('footer_information_benefit')}
            </Link>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to="/account/help-center/partnership"
            >
              {t('footer_information_contact')}
            </Link>
          </div>
          <div>
            <h4>{t('footer_policy')}</h4>
            <div></div>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to="/account/policy"
            >
              {t('footer_policy_shipping')}
            </Link>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to="/account/policy/refund"
            >
              {t('footer_policy_refund')}
            </Link>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to="/account/policy/privacy"
            >
              {t('footer_policy_privacy')}
            </Link>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to="/account/policy/terms"
            >
              {t('footer_policy_terms')}
            </Link>
          </div>
        </div>
        <h5>{t('footer_rights')}</h5>
        <h5>{t('footer_rights_description')}</h5>
      </div>
    </div>
  );

  const desktopFooter = (
    <div className={styles['desktop-footer']}>
      <div>
        <div>
          <h3>{t('footer_welcome')}</h3>
          <p>{t('footer_description')}</p>
        </div>
        <div>
          <h4>{t('footer_information')}</h4>
          <div></div>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to="/account/information"
          >
            {t('footer_information_about')}
          </Link>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to="/account/information/benefit"
          >
            {t('footer_information_benefit')}
          </Link>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to="/account/help-center/partnership"
          >
            {t('footer_information_contact')}
          </Link>
        </div>
        <div>
          <h4>{t('footer_policy')}</h4>
          <div></div>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to="/account/policy"
          >
            {t('footer_policy_shipping')}
          </Link>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to="/account/policy/refund"
          >
            {t('footer_policy_refund')}
          </Link>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to="/account/policy/privacy"
          >
            {t('footer_policy_privacy')}
          </Link>
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to="/account/policy/terms"
          >
            {t('footer_policy_terms')}
          </Link>
        </div>
        <div>
          <h5>{t('footer_customer_center')}</h5>
          <h4>help@mykmarket.com</h4>
        </div>
      </div>
      <div>
        <span>{t('footer_rights')}</span>
        <span>{t('footer_rights_description')}</span>
      </div>
    </div>
  );

  const element = useResponsiveElement({
    MOBILE: mobileFooter,
    DESKTOP: desktopFooter,
  });

  return (
    <footer
      className={`${styles['footer']} ${
        isMobile && !!isremoveTopPadding && styles['footer-remove-top-padding']
      }`}
    >
      {element}
    </footer>
  );
};

export default Footer;

import { ReactElement } from 'react';
import { useResponsiveElement } from '../../helper/hooks';
import SearchBar from '../search/SearchBar';
import { Cart, Logo, MenuBar, MultiLanguage, User } from './atoms';
import DesktopNavBar from './desktop/DesktopNavBar';
import styles from './styles/MainNavBar.module.scss';

const MainNavBar = (): ReactElement => {
  const mobileNav = (
    <div className={styles['nav__container']}>
      <div className={styles['nav__items']}>
        <div>
          <MenuBar />
          <MultiLanguage />
        </div>

        <div className={styles['nav__logo']}>
          <Logo imgUrl="/images/mobile/logo.svg" />
        </div>

        <div>
          <User />
          <Cart />
        </div>
      </div>
      <div className={styles['nav__search']}>
        <SearchBar />
      </div>
    </div>
  );
  const desktopNav = DesktopNavBar('CATEGORY');
  const element = useResponsiveElement({
    MOBILE: mobileNav,
    DESKTOP: desktopNav,
  });
  return <nav className={styles['nav']}>{element}</nav>;
};

export default MainNavBar;

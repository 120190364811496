import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { OrderButton } from '../components/button';
import { CartList } from '../components/cart';
import { BaseLayout } from '../components/layout';
import { MobileNavItem } from '../components/layout/BaseLayout';
import { ProductContainer } from '../components/products';
import { useShippingRate, useViewport } from '../helper/hooks';
import useCart from '../hooks/useCart';
import { useModularization } from '../hooks/useModularization';
import { modalState } from '../stores/modal/atoms';
import styles from './styles/Cart.module.scss';

const Cart = (): ReactElement => {
  const { t } = useTranslation();
  const cart = useCart();
  const totalPrice = cart.getTotalPriceActive;
  const navigate = useNavigate();
  const [modal, setModal] = useRecoilState(modalState);
  const { isMobile } = useViewport();

  const shippingRate = useShippingRate();
  const onClickCheckoutButton = () => {
    if (totalPrice <= 0) {
      setModal({
        modal: true,
        childrenText: t('cart_checkout_modal'),
        buttons: [
          {
            text: t('cart_checkout_modal_button'),
          },
        ],
      });
      return;
    }

    const { cartItems } = cart;

    const notCorrectMinMaxCartItems = cartItems.filter(
      ({ product: { min, max }, quantity }) =>
        min !== undefined && max !== undefined && !(quantity >= min && quantity <= max),
    );

    if (notCorrectMinMaxCartItems.length > 0) {
      const {
        product: { title, min, max },
      } = notCorrectMinMaxCartItems[0];
      setModal({
        modal: true,
        childrenText: `${title} 제품의 최소 구매 수량은 ${min}개, 최대 구매 수량은 ${max}개 입니다.`,
        buttons: [
          {
            text: t('cart_checkout_modal_button'),
          },
        ],
      });
      return;
    }

    navigate('/checkout');
  };

  const { moduleItems } = useModularization({ type: 'cart' });

  const cartMainElements = (
    <div>
      <CartList onClickCheckoutButton={onClickCheckoutButton} />
      {moduleItems?.map((moduleItem) => (
        <ProductContainer
          key={moduleItem.id}
          item={moduleItem}
          options={{
            borderShow: false,
            infiniteScrollMode: moduleItem.type.text === '2xN',
            originName: 'cart',
          }}
        />
      ))}
    </div>
  );

  return (
    <BaseLayout mobileNav={{ type: MobileNavItem.HOME_SEARCH, title: t('cart_title') }}>
      {isMobile ? (
        <div className={styles['cart-main-mobile']}>{cartMainElements}</div>
      ) : (
        <div className={styles['cart-main-desktop']}>{cartMainElements}</div>
      )}
      {isMobile && (
        <div className={styles['cart-order-button-container']}>
          <OrderButton
            type="Checkout"
            text={t('cart_checkout_button')}
            action={onClickCheckoutButton}
            shippingRate={shippingRate}
          />
        </div>
      )}
    </BaseLayout>
  );
};

export default Cart;

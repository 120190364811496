import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import Tab from '../../../components/tab/Tab';
import { useViewport } from '../../../helper/hooks';
import styles from './styles/Information.module.scss';

const Information = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isMobile } = useViewport();
  const [currentLang] = useState<string>(
    () => localStorage.getItem('language') ?? window.navigator.language.slice(0, 2),
  );

  const tabs = [
    { key: '', name: t('account_information_tab1') },
    { key: 'benefit', name: t('account_information_tab2') },
  ];

  const detectTab = () => {
    const tab = tabs.map((tab) => tab.key).indexOf(pathname.split('/')[3]);
    if (tab > 0) {
      return tab;
    }
    return 0;
  };

  const [clickedTab, setClickedTab] = useState<number>(detectTab());

  useEffect(() => {
    setClickedTab(detectTab());
  }, [pathname]);

  const InfoPage = () => {
    return (
      <img
        src={
          currentLang == 'en'
            ? '/images/common/about_pc_en_20230221.webp'
            : '/images/common/about_pc_kr_20230221.webp'
        }
        alt="about our service"
      />
    );
  };

  const BenefitPage = () => {
    return (
      <img
        src={
          currentLang == 'en'
            ? '/images/common/benefit_pc_en_20221118.webp'
            : '/images/common/benefit_pc_kr_20221118.webp'
        }
        alt="Customer Benefits"
      />
    );
  };

  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.SHOW_ALL, title: t('account_information') }}
      pageTitle={t('account_information_page')}
      className="no-padding-bottom"
    >
      <div className={styles['information__bg']}>
        <Tab
          data={tabs}
          clickedTab={clickedTab}
          setClickedTab={setClickedTab}
          align="LEFT"
          setRoute={true}
        />

        <div className={styles['container']}>
          <Routes>
            <Route path="/" element={<InfoPage />} />
            <Route path="/benefit" element={<BenefitPage />} />
          </Routes>
        </div>
      </div>
    </AccountLayout>
  );
};

export default Information;

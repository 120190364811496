import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button } from '../../button';
import styles from './styles/GuideInfo.module.scss';

type GuideInfoType = {
  textElement: ReactNode;
  bgColor: string;
  link?: {
    text: string;
    path: string;
    color?: string;
  };
};

const GuideInfo = ({ textElement, bgColor, link }: GuideInfoType) => {
  const navigate = useNavigate();
  return (
    <div style={{ backgroundColor: bgColor }} className={styles['guide-info-container']}>
      <div>
        {textElement}
        {link && (
          <Button className={styles['link-container']} action={() => navigate(link?.path)}>
            <span style={{ color: link.color }}>{link.text}</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default GuideInfo;

import { useNavigate } from 'react-router';
import { useRecoilValueLoadable } from 'recoil';
import { customerState } from '../../../stores/customer/atoms';
import styles from './styles/User.module.scss';

const User = () => {
  const navigate = useNavigate();

  const customer = useRecoilValueLoadable(customerState);

  return (
    <div
      className={styles['div']}
      onClick={() => {
        if (!customer.contents) {
          navigate('/login');
          return;
        }
        navigate('/account');
      }}
    >
      <img src="/icons/user.svg" alt="사용자" />
    </div>
  );
};

export default User;

import { PromotionDetailResponse, PromotionResponse } from '../../@types/response-mkm';
import instance from './instance';

export async function getPromotions(page: number) {
  const response = await instance.get<PromotionResponse>('/promotion', {
    params: { page },
  });

  return response.data;
}

export async function getPromotionByLink(link: string) {
  const response = await instance.get<PromotionDetailResponse>(`/promotion/${link}`);

  return response.data;
}

import { CSSProperties, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Part } from '../../utils/getCategories';
import styles from './Dropdown.module.scss';

type DropdownType = {
  menu: string;
  data: Part[];
  isOpen: boolean;
  customStyles?: CSSProperties;
};

const Dropdown = ({ menu, data, isOpen, customStyles }: DropdownType) => {
  const { t } = useTranslation();
  return (
    <ul
      style={{ ...customStyles }}
      className={`${styles['ul']} ${isOpen ? styles['ul-open'] : ''}`}
    >
      {data.map((dd) => {
        if (dd.id == 1 && dd.name == 'All') return <Fragment key={dd.name}></Fragment>;
        return (
          <Link
            to={`/categories?menu=${menu}&submenu=${dd.handle}`}
            key={dd.name}
            state={{ categoryTitle: dd.name }}
          >
            <li>{t(`category_name_${dd.handle}`)}</li>
          </Link>
        );
      })}
    </ul>
  );
};

export default Dropdown;

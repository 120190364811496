import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { ViewportContext } from './ViewportContext';

const ViewportProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>;
};

export default ViewportProvider;

import { MouseEventHandler, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { ProductType } from '../../@types/product';
import { getCapitalizeLetters } from '../../helper/letter';
import { customerState } from '../../stores/customer/atoms';
import { modalState } from '../../stores/modal/atoms';
import { statisticsClickCounts } from '../../utils/statistics/statisticsClickCounts';
import { NumberInput } from '../input';
import styles from './styles/Product.module.scss';

interface Props {
  item: ProductType;
  origin?: 'curation' | 'promotion' | 'search';
  originId?: number;
  align?: 'LEFT' | 'RIGHT' | 'CENTER' | 'DEFAULT';
}

const Product = ({ item, origin, originId, align = 'DEFAULT' }: Props): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const customer = useRecoilValueLoadable(customerState);
  const [modal, setModal] = useRecoilState(modalState);
  const onClickProduct = () => {
    if (originId) {
      statisticsClickCounts({
        product: item,
        originId,
      });
    }
    navigate(`/product/${encodeURIComponent(item.id)}`);
  };

  const onClickSoldoutProduct: MouseEventHandler = (event) => {
    event.stopPropagation();

    if (!customer.contents) {
      navigate('/login');
      return;
    }
  };

  const price = Number(item.priceRange.maxVariantPrice.amount) || 0;
  const originalPrice = Number(item.compareAtPriceRange?.maxVariantPrice.amount) || 0;
  const isDiscount = originalPrice > price;
  const discountRatio = Math.floor(((originalPrice - price) / originalPrice) * 100);

  const flags = item.collections
    ? [
        ...item.collections.edges
          .map(({ node }) => node.title)
          .filter((title) => title.toUpperCase() === 'BEST' || title.toUpperCase() === 'NEW'),
      ]
    : [];

  const rating = item.metafield?.value;

  return (
    <article
      className={`${styles['article']} ${styles[`article--${align}`]}`}
      onClick={onClickProduct}
    >
      <div className={styles['product-flag-image']}>
        <div className={styles['product-flag']}>
          {flags.map((flag, idx) => (
            <div
              className={`${styles['flag']} ${styles[flag.toLowerCase()]}`}
              key={`${flag}--${idx}`}
            >
              <span>{getCapitalizeLetters(flag)}</span>
            </div>
          ))}
          {isDiscount && (
            <div className={`${styles['flag']}`}>
              <span>-{discountRatio}%</span>
            </div>
          )}
        </div>

        <div className={styles['product-image']}>
          <img src={item.featuredImage?.url} alt="상품" />
          <NumberInput
            item={item}
            origin={origin}
            originId={originId}
            onClickSoldoutProduct={onClickSoldoutProduct}
            blockedModal={!customer.contents}
          />
        </div>
        {item.totalInventory === 0 && (
          <div className={styles['product-sold-out']}>
            <span>Sold Out</span>
          </div>
        )}
      </div>
      <div className={styles['product-name']}>{item.title}</div>
      <div className={styles['product-price']}>
        {isDiscount ? (
          <div>
            <span className={styles['current-price']}>${price}</span>
            <small className={styles['original-price']}>${originalPrice}</small>
          </div>
        ) : (
          <span className={styles['current-price']}>${price}</span>
        )}
      </div>
      {rating && (
        <div className={styles['product-rating']}>
          <img src="/icons/star-rating.svg" alt="별점" />
          <small>{Number(rating).toFixed(1)}</small>
        </div>
      )}
    </article>
  );
};

export default Product;

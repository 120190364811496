import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Coupon } from '../../@types/coupon';
import { getCoupons } from '../../api/backend/coupon';
import CouponContainer from '../../components/account/coupons/CouponContainer';
import { AccountLayout } from '../../components/layout';
import { MobileNavItem } from '../../components/layout/BaseLayout';
import Tab from '../../components/tab/Tab';
import styles from './styles/Coupons.module.scss';

const Coupons = (): ReactElement => {
  const { t } = useTranslation();
  const tabs = [
    { key: 'AVAILABLE', name: t('account_coupon_tab1') },
    { key: 'USED', name: t('account_coupon_tab2') },
    { key: 'EXPIRED', name: t('account_coupon_tab3') },
  ];
  const [clickedTab, setClickedTab] = useState<number>(0);

  const [coupons, setCoupons] = useState<Coupon[]>([]);

  const getCurrentCouponData = (clickedTab: number) => {
    switch (clickedTab) {
      case 0:
        return coupons.filter((coupon) => coupon.status === tabs[0].key);
      case 1:
        return coupons.filter((coupon) => coupon.status === tabs[1].key);
      case 2:
        return coupons.filter((coupon) => coupon.status === tabs[2].key);
    }
  };

  const getCouponsRequest = async () => {
    const response = await getCoupons();

    setCoupons(response.data.data.coupons);
  };

  useEffect(() => {
    getCouponsRequest();
  }, []);

  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.SHOW_ALL, title: t('account_coupon') }}
      pageTitle={t('account_coupon_page')}
      className="no-padding-bottom"
    >
      <div>
        <Tab data={tabs} clickedTab={clickedTab} setClickedTab={setClickedTab} align="LEFT" />
        <div className={styles['div']}>
          <CouponContainer data={getCurrentCouponData(clickedTab) ?? []} />
        </div>
      </div>
    </AccountLayout>
  );
};

export default Coupons;

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import ReviewsWriteEditContainer, {
  ReviewsWriteEditContainerType,
} from '../../../components/account/reviews/ReviewsWriteEditContainer';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import styles from './styles/ReviewsWrite.module.scss';

const ReviewWrite = () => {
  const { t } = useTranslation();
  const {
    state: { type, rating, orderId, shopifyId, review },
  } = useLocation() as {
    state: ReviewsWriteEditContainerType;
  };

  return (
    <AccountLayout
      mobileNav={{
        type: MobileNavItem.ONLY_TITLE,
        title: type === 'WRITE' ? t('account_review_edit_write') : t('account_review_edit_edit'),
      }}
      pageTitle={
        type === 'WRITE' ? t('account_review_edit_page_write') : t('account_review_edit_page_edit')
      }
    >
      <div className={styles['div']}>
        <main className={styles['main']}>
          <ReviewsWriteEditContainer
            type={type}
            rating={rating}
            orderId={orderId}
            shopifyId={shopifyId}
            review={review}
          />
        </main>
      </div>
    </AccountLayout>
  );
};

export default ReviewWrite;

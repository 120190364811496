import { ReactElement } from 'react';
import { BaseLayout } from '../components/layout';
import { MobileNavItem } from '../components/layout/BaseLayout';
import { PasswordResetContainer } from '../components/password-assist';

const PasswordReset = (): ReactElement => {
  return (
    <BaseLayout
      className="login"
      mobileNav={{ type: MobileNavItem.ONLY_LOGO }}
      desktopNav="ONLY_TITLE"
    >
      <PasswordResetContainer />
    </BaseLayout>
  );
};

export default PasswordReset;

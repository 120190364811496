import { debounce } from 'lodash';
import { CSSProperties, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { CartItem } from '../../@types/cart';
import { ProductType } from '../../@types/product';
import { updateCartItem } from '../../api/backend/cart';
import { PRICE_FOR_FREE_SHIPPING } from '../../constants';
import { useClickOutside, useViewport } from '../../helper/hooks';
import useCart from '../../hooks/useCart';
import { customerState } from '../../stores/customer/atoms';
import { modalState } from '../../stores/modal/atoms';
import { toastState } from '../../stores/toast/atoms';
import { Button } from '../button';
import styles from './styles/NumberInput.module.scss';

const NumberInput = ({
  item,
  coverShow = true,
  detailDefaultShow = false,
  customCoverStyles,
  customDetailStyles,
  origin,
  originId,
  onClickSoldoutProduct,
  blockedModal,
}: {
  item: ProductType;
  coverShow?: boolean;
  detailDefaultShow?: boolean;
  customCoverStyles?: CSSProperties;
  customDetailStyles?: CSSProperties;
  origin?: 'curation' | 'promotion' | 'search';
  originId?: number;
  onClickSoldoutProduct?: MouseEventHandler;
  blockedModal?: boolean; // soldout modal 동작 방지용
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const customer = useRecoilValueLoadable(customerState);
  const cart = useCart();

  const [count, setCount] = useState<number>(cart.getQuantity(item.id));
  const [show, setShow] = useState<boolean>(false);

  const targetItem = cart.getItem(item.id);

  const updateQuantity = useCallback(
    debounce(async (newCount: number) => {
      if (!customer.contents) {
        return;
      }

      await updateCartItem(item.id, newCount);
    }, 1000),
    [customer.contents],
  );

  const [modal, setModal] = useRecoilState(modalState);
  const setToast = useSetRecoilState(toastState);
  const { isMobile } = useViewport();
  const totalPrice = cart.getTotalPriceActive;

  const isCorrectQuantityLtMax = targetItem && targetItem.quantity < (targetItem.product.max ?? 10);

  const onToast = (product: CartItem, type: 'sum' | 'sub' | 'del') => {
    const unitPrice =
      Number(item.priceRange.maxVariantPrice) > Number(item.priceRange.minVariantPrice)
        ? Number(item.priceRange.minVariantPrice.amount)
        : Number(item.priceRange.maxVariantPrice.amount);
    const groupPrice =
      Number(item.priceRange.maxVariantPrice) > Number(item.priceRange.minVariantPrice)
        ? Number(item.priceRange.minVariantPrice.amount) * product.quantity
        : Number(item.priceRange.maxVariantPrice.amount) * product.quantity;
    const total =
      type == 'sum'
        ? totalPrice + unitPrice
        : type == 'sub'
        ? totalPrice - unitPrice
        : totalPrice - groupPrice;
    const current = Number(PRICE_FOR_FREE_SHIPPING - total);
    const content = (
      <span>
        {t('cart_toast_a')}
        <b style={{ color: '#fceb4e', padding: '0 4px' }}>${current.toFixed(2)}</b>
        {t('cart_toast_b', { price: PRICE_FOR_FREE_SHIPPING })}
      </span>
    );
    if (current > 0 && isMobile) {
      setToast({ content: content });
    } else if (current <= 0) {
      setToast(null);
    }
  };

  const onCartPlus = async () => {
    if (!isCorrectQuantityLtMax) {
      return;
    }

    const newCount = count + 1;

    setCount((count) => count + 1);

    onToast(targetItem!, 'sum');

    if (cart.has(item.id)) {
      cart.update(item.id, 1);
      await updateQuantity(newCount);
      return;
    }

    await cart.add(item.id, origin, originId);
  };

  const onCartMinus = async () => {
    const newCount = count - 1;

    setCount((count) => count - 1);

    cart.update(item.id, -1);
    await updateQuantity(newCount);

    if (targetItem && targetItem.quantity > 0) {
      onToast(targetItem!, 'sub');
      return;
    }
  };

  const onClickPlus: MouseEventHandler = async (event) => {
    event.stopPropagation();

    setShow((show) => !show);

    if (count > 0) {
      return;
    }

    const newCount = count + 1;
    if (!count) setCount((count) => count + 1);

    if (cart.has(item.id)) {
      cart.update(item.id, 1);
      updateQuantity(newCount);
      return;
    }

    const cartItem = await cart.add(item.id, origin, originId);

    onToast(cartItem, 'sum');
  };

  const onDeleteCart = async () => {
    setCount(0);
    setShow((show) => !show);
    onToast(targetItem!, 'del');

    await cart.remove([item.id]);
  };

  const ref = useClickOutside(() => {
    setShow(false);
  });

  const onClickSoldout: MouseEventHandler = (event) => {
    onClickSoldoutProduct && onClickSoldoutProduct!(event);

    if (blockedModal) return;

    // todo: 세영님 api response에 아래 modal 넣어주세요
    setModal({
      modal: true,
      childrenText: t('product_notify_modal'),
      buttons: [
        {
          text: t('product_notify_modal_button'),
        },
      ],
    });
  };

  if (item.totalInventory < 1) {
    return (
      <div
        className={`${styles['notify-top-container']} ${
          location.pathname.includes('/wishlist') && styles['wishlist_notify-container']
        }`}
      >
        <Button className={styles['notify-container']} action={onClickSoldout}>
          <span>{t('product_notify')}</span>
        </Button>
      </div>
    );
  }

  useEffect(() => {
    if (!targetItem) {
      return;
    }

    setCount(targetItem?.quantity);
  }, [targetItem]);

  return (
    <>
      <div
        style={{ ...customCoverStyles }}
        className={`${styles['div-plus']} ${!coverShow || show ? styles['hide'] : ''}`}
        onClick={onClickPlus}
      >
        {targetItem && targetItem.quantity ? (
          <span>{targetItem.quantity}</span>
        ) : (
          <img src={'/icons/plus-white.svg'} alt="플러스" />
        )}
      </div>

      {(detailDefaultShow || show) && (
        <div
          style={{ ...customDetailStyles }}
          className={styles['div-plus-detail']}
          ref={ref}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {count > 1 ? (
            <img
              className={styles['minus-blue-icon']}
              src="/icons/minus-blue.svg"
              alt="빼기"
              onClick={onCartMinus}
            />
          ) : (
            <img
              className={styles['trash-icon']}
              src="/icons/trash.svg"
              alt="휴지통"
              onClick={onDeleteCart}
            />
          )}

          <span>{count}</span>

          <img
            className={styles['plus-blue-icon']}
            src={isCorrectQuantityLtMax ? '/icons/plus-blue.svg' : '/icons/plus-disabled.svg'}
            alt="더하기"
            onClick={onCartPlus}
          />
        </div>
      )}
    </>
  );
};

export default NumberInput;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { WrittenReviewType } from '../../../@types/review';
import { postReview, updateReview } from '../../../api/backend/review';
import { Button } from '../../button';
import StarRating from '../../star-rating/StarRating';
import Upload from '../../upload/Upload';
import styles from './styles/ReviewsWriteEditContainer.module.scss';

export type ReviewsWriteEditContainerType = {
  type: 'WRITE' | 'EDIT';
  rating?: number;
  orderId?: number;
  shopifyId?: string;
  review?: WrittenReviewType;
};

const ReviewsWriteEditContainer = ({
  type,
  rating,
  orderId,
  shopifyId,
  review,
}: ReviewsWriteEditContainerType) => {
  const { t } = useTranslation();
  const isWrite = type === 'WRITE';
  const go = useNavigate();

  const [starRating, setStarRating] = useState<number>(
    isWrite ? rating || 0 : parseInt(review?.rating || '0'),
  );
  const [content, setContent] = useState(review?.content || '');
  const [images, setImages] = useState<File[]>([]);

  const onChangeTextarea: React.ChangeEventHandler<HTMLTextAreaElement> = ({
    currentTarget: { value },
  }) => {
    setContent(value);
  };

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append('content', content);
    formData.append('rating', starRating.toString());
    images.forEach((image) => formData.append('images', image));

    if (isWrite) {
      formData.append('shopifyId', shopifyId ?? '');
      formData.append('orderId', (orderId ?? -1).toString());
      await postReview(formData);
    } else {
      await updateReview(formData, review?.id);
    }

    go('/account/reviews');
    window.location.reload();
  };

  const getFileFromUrl = async () => {
    if (!review) {
      return;
    }

    const files = [];

    for (const { url } of review.images) {
      const res = await fetch(url);
      const blob = await res.blob();
      const file = new File([blob], url.split('/').reverse()[0]);

      files.push(file);
    }

    setImages(files);
  };

  useEffect(() => {
    getFileFromUrl();
  }, []);

  return (
    <>
      <section className={styles['section']}>
        <article>
          <div className={styles['header']}>
            <h3>{t('account_review_edit_header')}</h3>
            <StarRating
              maxStars={5}
              rating={starRating}
              setRating={setStarRating}
              defaultImage={'/icons/account__big-star-grey.svg'}
              activeImage={'/icons/account__big-star-yellow.svg'}
            />
          </div>

          <div className={styles['textarea']}>
            <h5>{t('account_review_edit_text_title')}</h5>
            <textarea
              placeholder={t('account_review_edit_text_placeholder')}
              onChange={onChangeTextarea}
              defaultValue={content}
            ></textarea>
          </div>

          <div className={styles['image__uploader']}>
            <Upload files={images} setFiles={setImages} />
          </div>
        </article>
      </section>
      <div className={styles['button-container']}>
        <Button
          className={`${styles['button']} ${styles[content ? 'active' : 'disabled']}`}
          action={onSubmit}
        >
          <span>
            {type === 'EDIT'
              ? t('account_review_edit_edit_button')
              : t('account_review_edit_write_button')}
          </span>
        </Button>
      </div>
    </>
  );
};

export default ReviewsWriteEditContainer;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type onCopyFn = (text: string) => Promise<boolean>;

const useCopyClipboard = (): [boolean, onCopyFn] => {
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const { t } = useTranslation();
  const onCopy: onCopyFn = async (text: string) => {
    try {
      await window.navigator.clipboard.writeText(text);
      alert(t('alert_copied'));
      setIsCopy(true);
      return true;
    } catch (error) {
      alert(error);
      setIsCopy(false);
      return false;
    }
  };
  return [isCopy, onCopy];
};

export default useCopyClipboard;

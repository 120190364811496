import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { ClickedCoupon, OpenStatus, PaymentMethodInfo } from '../../@types/checkout';
import { Coupon, INITAIL_COUPON } from '../../@types/coupon';
import { MailingAddress } from '../../@types/customer';
import {
  applyDiscountCode,
  associateCustomer,
  checkoutShippingAddressUpdate,
  createCheckout,
  getCustomerInfo,
} from '../../api/storefront/checkouts';
import { AddressType } from '../../components/account/address-book/delivery-info-add/AddressBookAdd';
import { OrderButton } from '../../components/button';
import CheckoutDesktop from '../../components/checkout/CheckoutDesktop';
import useCheckoutOverlay from '../../components/checkout/hooks/useCheckoutOverlay';
import {
  TCoupons,
  TPurchaseSummary,
  TShippingInformation,
} from '../../components/checkout/templates';
import TGuideInfo from '../../components/checkout/templates/TGuideInfo';
import { BaseLayout } from '../../components/layout';
import { MobileNavItem } from '../../components/layout/BaseLayout';
import Loading from '../../components/loading/Loading';
import { useShippingRate, useViewport } from '../../helper/hooks';
import styles from './styles/Checkout.module.scss';
import { modalState } from '../../stores/modal/atoms';
import zipcodes from 'zipcodes';
import useCart from '../../hooks/useCart';

export const INITIAL_IS_OPEN_STATUS_OBJECT = {
  deliveryInfo: false,
  deliveryInfoAdd: false,
  paymentMethod: false,
  addNewCard: false,
  couponApply: false,
  purchaseSummary: false,
};

const Checkout = (): ReactElement => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const cart = useCart();
  const [modal, setModal] = useRecoilState(modalState);

  const hasCustomer = !!localStorage.getItem('access-token');

  const [isOpenStatusObject, setIsOpenStatusObject] = useState<OpenStatus>(
    INITIAL_IS_OPEN_STATUS_OBJECT,
  );

  const cartItems = cart.cartItemsWithProduct;

  const totalPrice = cart.getTotalPriceActive;

  const [addressType, setAddressType] = useState<AddressType>('NONE');
  const [selectedShippingInfo, setSelectedShippingInfo] = useState<MailingAddress>();

  // const [shippingRate, setShippingRate] = useState<number>(0);
  const shippingRate = useShippingRate();
  const [shippingInfoArray, setShippingInfoArray] = useState<MailingAddress[]>([]);

  const [coupon, setCoupon] = useState<Coupon>(INITAIL_COUPON);
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon>(coupon ?? INITAIL_COUPON);
  const [clickedCouponType, setClickedCouponType] = useState<ClickedCoupon>('Input');
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodInfo>({
    type: null,
  });

  const [isAddressLoading, setIsAddressLoading] = useState<boolean>(true);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState<boolean>(false);

  const openAddressForm = (type: AddressType, id?: string) => {
    if (type === 'NONE') {
      setAddressType('NONE');
      return;
    }

    if (type === 'ADD') {
      setAddressType(type);
      return;
    }

    const editTarget = shippingInfoArray.find((info) => info.id === id);

    if (!editTarget) {
      return;
    }

    setSelectedShippingInfo(editTarget);
    setAddressType(type);
  };

  const handleChangeShippingInfo = (currentShippingInfo?: MailingAddress, id?: string) => {
    if (addressType === 'ADD') {
      setShippingInfoArray((prev) => (currentShippingInfo ? [...prev, currentShippingInfo] : prev));
    }

    if (addressType === 'EDIT') {
      setShippingInfoArray((prev) =>
        currentShippingInfo
          ? prev.map((data) => (data.id === id ? currentShippingInfo : data))
          : prev,
      );
    }

    setSelectedShippingInfo(currentShippingInfo);
    setAddressType('NONE');
  };

  const checkoutOverlay = useCheckoutOverlay({
    isOpenStatusObject,
    setIsOpenStatusObject,
    selectedShippingInfo,
    setSelectedShippingInfo,
    coupon,
    setCoupon,
    selectedCoupon,
    setSelectedCoupon,
    clickedCouponType,
    setClickedCouponType,
    paymentMethod,
    setPaymentMethod,
    shippingInfoArray,
    setShippingInfoArray,
    addressType,
    setAddressType,
    openAddressForm,
    handleChangeShippingInfo,
  });

  const getCustomerInfoRequest = async () => {
    const { addresses, defaultAddressId } = await getCustomerInfo();

    setShippingInfoArray(addresses);

    const currentAddress = addresses.find((address) => address.id === defaultAddressId);
    setSelectedShippingInfo(currentAddress);
  };

  // const getShippingRateRequest = async (checkoutId: string) => {
  //   const response = await getShippingRate(checkoutId);

  //   if (response.data.node.availableShippingRates.shippingRates.length < 1) {
  //     setShippingRate(0);
  //     return;
  //   }

  //   setShippingRate(
  //     Number(response.data.node.availableShippingRates.shippingRates[0].price.amount),
  //   );
  // };

  const createCheckoutRequest = async () => {
    setIsCheckoutLoading(true);
    const response = await createCheckout(cartItems);

    if (!response.data) {
      setModal({
        modal: true,
        childrenText: t('checkout_modal_error'),
        buttons: [
          {
            text: t('checkout_modal_error_button'),
          },
        ],
      });
      return;
    }

    const { id: checkoutId, webUrl: redirectUrl } = response.data.checkoutCreate.checkout;

    if (coupon.code && coupon.code !== 'Deleted') {
      await applyDiscountCode(checkoutId, coupon.code);
    }

    if (hasCustomer) {
      if (selectedShippingInfo) {
        const cityByZip = zipcodes.lookup(selectedShippingInfo.zip);
        const res = await checkoutShippingAddressUpdate(checkoutId, selectedShippingInfo);

        if (
          // zip 코드 에러
          !cityByZip ||
          res?.data.checkoutShippingAddressUpdateV2.checkoutUserErrors[0]?.code ==
            'INVALID_FOR_COUNTRY'
        ) {
          setModal({
            modal: true,
            childrenText: t('checkout_modal_error_zip'),
          });
          return;
        } else if (
          // province 에러
          res?.data.checkoutShippingAddressUpdateV2.checkoutUserErrors[0]?.code ==
          'INVALID_FOR_COUNTRY_AND_PROVINCE'
        ) {
          setModal({
            modal: true,
            childrenText: t('checkout_modal_error_state'),
          });
          return;
        } else if (cityByZip.city.toUpperCase() !== selectedShippingInfo.city.toUpperCase()) {
          // city error
          setModal({
            modal: true,
            childrenText: t('checkout_modal_error_city'),
          });
          return;
        } else if (res?.data.checkoutShippingAddressUpdateV2.checkoutUserErrors.length > 0) {
          // await getShippingRateRequest(checkoutId);
          setModal({
            modal: true,
            childrenText: t('checkout_modal_error'),
          });
        } else {
          await associateCustomer(checkoutId);
        }
      } else {
        setModal({
          modal: true,
          childrenText: t('checkout_modal_error_select'),
          buttons: [
            {
              text: t('checkout_modal_error_select_button'),
            },
          ],
        });

        return;
      }

      // await associateCustomer(checkoutId);
    }

    return { checkoutId, redirectUrl };
  };

  const checkTotalPrice = () => {
    if (totalPrice >= 800) {
      setModal({
        modal: true,
        childrenText: t('checkout_modal_alert'),
        buttons: [
          {
            text: t('modal_default_cancel_button'),
            action: () => {
              return;
            },
          },
          {
            text: t('modal_default_button'),
            action: () => {
              onClickPurchaseButton();
            },
          },
        ],
      });
    } else {
      onClickPurchaseButton();
    }
  };

  const onClickPurchaseButton = async () => {
    const checkoutResponse = await createCheckoutRequest();

    setIsCheckoutLoading(false);

    if (!checkoutResponse) return;

    const { redirectUrl } = checkoutResponse;

    setTimeout(() => {
      window.open(redirectUrl, '_blank');
    });
  };

  useEffect(() => {
    if (hasCustomer) {
      getCustomerInfoRequest();
    }
    setIsAddressLoading(false);
  }, []);

  return (
    <>
      {Object.values(isOpenStatusObject).filter((b) => b).length === 0 && (
        <BaseLayout
          mobileNav={{ type: MobileNavItem.HOME_SEARCH, title: t('checkout') }}
          className={'checkout-mobile-layout'}
        >
          <TGuideInfo />
          <>
            {isAddressLoading ? (
              <Loading />
            ) : isMobile ? (
              <form className={styles['checkout-item-container']}>
                {hasCustomer && (
                  <TShippingInformation
                    selectedShippingInfo={selectedShippingInfo}
                    setSelectedShippingInfo={setSelectedShippingInfo}
                    setIsOpenStatusObject={setIsOpenStatusObject}
                    type="Checkout"
                  />
                )}
                <div className={styles['checkout__alert']}>{t('checkout_carrier_alert')}</div>
                {/* <TCarrierInformation shippingRate={shippingRate} hasCustomer={hasCustomer} /> */}
                {/* <TPaymentMethod
                    setIsOpenStatusObject={setIsOpenStatusObject}
                    paymentMethod={paymentMethod}
                  /> */}
                <TCoupons
                  setIsOpenStatusObject={setIsOpenStatusObject}
                  coupon={coupon}
                  setCoupon={setCoupon}
                  setSelectedCoupon={setSelectedCoupon}
                />
                <TPurchaseSummary
                  setIsOpenStatusObject={setIsOpenStatusObject}
                  coupon={coupon}
                  shippingRate={shippingRate}
                />
                <OrderButton
                  type="Purchase"
                  text={t('checkout_purcahse_button')}
                  action={checkTotalPrice}
                  shippingRate={shippingRate}
                  isCheckoutLoading={isCheckoutLoading}
                />
              </form>
            ) : (
              <CheckoutDesktop
                selectedShippingInfo={selectedShippingInfo}
                setSelectedShippingInfo={setSelectedShippingInfo}
                coupon={coupon}
                setCoupon={setCoupon}
                selectedCoupon={selectedCoupon}
                setSelectedCoupon={setSelectedCoupon}
                clickedCouponType={clickedCouponType}
                setClickedCouponType={setClickedCouponType}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                shippingInfoArray={shippingInfoArray}
                setShippingInfoArray={setShippingInfoArray}
                addressType={addressType}
                setAddressType={setAddressType}
                openAddressForm={openAddressForm}
                handleChangeShippingInfo={handleChangeShippingInfo}
                onClickPurchaseButton={checkTotalPrice}
                shippingRate={shippingRate}
                hasCustomer={hasCustomer}
                isCheckoutLoading={isCheckoutLoading}
              />
            )}
          </>
        </BaseLayout>
      )}

      {checkoutOverlay}
    </>
  );
};

export default Checkout;

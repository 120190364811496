import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MailingAddress } from '../../../@types/customer';
import { getAddresses } from '../../../api/storefront/addresses';
import { AddressBookList } from '../../../components/account/address-book';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import Loading from '../../../components/loading/Loading';

const DeliveryInfo = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [addresses, setAddresses] = useState<MailingAddress[]>([]);
  const [defaultAddress, setDefaultAddress] = useState<MailingAddress>();

  const getAddressesRequest = async () => {
    const { response, addresses } = await getAddresses();

    setAddresses(addresses);
    setDefaultAddress(
      addresses.find((address) => address.id === response.data.customer.defaultAddress.id),
    );
    setLoading(response.loading);
  };

  useEffect(() => {
    getAddressesRequest();
  }, []);

  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.HOME_SEARCH, title: t('account_profile_delivery_title') }}
      pageTitle={t('account_profile_delivery_page_title')}
    >
      {loading ? (
        <Loading />
      ) : (
        <AddressBookList
          selectedShippingInfo={defaultAddress}
          setSelectedShippingInfo={setDefaultAddress}
          shippingInfoArray={addresses}
        />
      )}
    </AccountLayout>
  );
};

export default DeliveryInfo;

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { InvitationsDescription, InvitationsModal } from '../../components/account/invitations';
import { useDrawer } from '../../components/drawer/Drawer';
import { AccountLayout } from '../../components/layout';
import { MobileNavItem } from '../../components/layout/BaseLayout';
import styles from './styles/Invitations.module.scss';

const Invitations = (): ReactElement => {
  const { t } = useTranslation();
  const invited = 0;
  const rewards = 0;

  const { open, close, isOpened, render } = useDrawer();

  const modalMobile = render(<InvitationsModal close={close} />);

  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.SHOW_ALL, title: t('account_invite') }}
      pageTitle={t('account_invite_page')}
    >
      <div>
        <figure className={styles['invitations__banner']}>
          <img
            className={styles['invitations__banner__img']}
            src="/icons/invitations__banner.png"
            alt="배너"
          />
        </figure>
        <InvitationsDescription invited={invited} rewards={rewards} open={open} />

        {isOpened && modalMobile}
      </div>
    </AccountLayout>
  );
};

export default Invitations;

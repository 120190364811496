import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { SHOPIFY_STORE_ACCESS_TOKEN, SHOPIFY_STORE_DOMAIN } from './constants';

const httpLink = createHttpLink({
  uri: `https://${SHOPIFY_STORE_DOMAIN}/api/2023-01/graphql.json`,
});

const middlewareLink = setContext(() => ({
  headers: {
    'Content-Type': 'application/json',
    'X-Shopify-Storefront-Access-Token': SHOPIFY_STORE_ACCESS_TOKEN,
  },
}));

const client = new ApolloClient({
  link: middlewareLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          collectionByHandle: {
            merge: true,
          },
          products: {
            merge: true,
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'ignore',
    },
  },
});

export default client;

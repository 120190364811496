import { gql } from '@apollo/client';
import { CustomerAddress, CheckoutCreate, ShippingRate } from '../../@types/response';
import client from '../client';
import { MailingAddress } from '../../@types/customer';
import { CartItemWithProduct } from '../../@types/cart';

export async function getCustomerInfo() {
  const customerAccessToken = localStorage.getItem('access-token') || '';

  const query = gql`
    query getCustomerAddresses($customerAccessToken: String!) {
      customer(customerAccessToken: $customerAccessToken) {
        email
        defaultAddress {
          id
        }
        addresses(first: 10) {
          edges {
            node {
              id
              name
              firstName
              lastName
              address1
              address2
              country
              province
              phone
              city
              zip
            }
          }
        }
      }
    }
  `;

  const response = await client.query<CustomerAddress>({
    query,
    variables: {
      customerAccessToken,
    },
  });
  const email = response.data.customer.email;
  const defaultAddressId = response.data.customer.defaultAddress?.id ?? null;
  const addresses = response.data.customer.addresses.edges.map(({ node }) => node);

  return { response, addresses, defaultAddressId, email };
}

export async function createCheckout(products: CartItemWithProduct[]) {
  const lineItems = products
    .filter((product) => product.active)
    .map((product) => ({
      variantId: product.product?.variants.edges[0].node.id,
      quantity: product.quantity,
    }));

  const query = gql`
    mutation checkoutCreate($input: CheckoutCreateInput!) {
      checkoutCreate(input: $input) {
        checkout {
          id
          webUrl
          lineItems(first: 5) {
            edges {
              node {
                title
                quantity
              }
            }
          }
        }
        checkoutUserErrors {
          field
          message
        }
      }
    }
  `;

  const response = await client.mutate<CheckoutCreate>({
    mutation: query,
    variables: {
      input: {
        lineItems,
      },
    },
  });

  return response;
}

export async function associateCustomer(checkoutId: string) {
  const customerAccessToken = localStorage.getItem('access-token') || '';

  const query = gql`
    mutation associateCustomerWithCheckout($checkoutId: ID!, $customerAccessToken: String!) {
      checkoutCustomerAssociateV2(
        checkoutId: $checkoutId
        customerAccessToken: $customerAccessToken
      ) {
        checkout {
          id
        }
        checkoutUserErrors {
          code
          field
          message
        }
        customer {
          id
        }
      }
    }
  `;

  const response = await client.mutate({
    mutation: query,
    variables: {
      checkoutId,
      customerAccessToken,
    },
  });

  return response;
}

export async function checkoutShippingAddressUpdate(
  checkoutId?: string,
  currentAddress?: MailingAddress,
) {
  if (!checkoutId || !currentAddress) {
    return;
  }

  const query = gql`
    mutation checkoutShippingAddressUpdateV2(
      $shippingAddress: MailingAddressInput!
      $checkoutId: ID!
    ) {
      checkoutShippingAddressUpdateV2(shippingAddress: $shippingAddress, checkoutId: $checkoutId) {
        checkoutUserErrors {
          code
          field
          message
        }
        checkout {
          id
          shippingAddress {
            firstName
            lastName
            address1
            province
            country
            zip
          }
        }
      }
    }
  `;

  const response = await client.mutate({
    mutation: query,
    variables: {
      checkoutId,
      shippingAddress: {
        address1: currentAddress?.address1,
        address2: currentAddress?.address2,
        city: currentAddress?.city,
        country: currentAddress?.country,
        firstName: currentAddress?.firstName,
        lastName: currentAddress?.lastName,
        phone: currentAddress?.phone,
        province: currentAddress?.province,
        zip: currentAddress?.zip,
      },
    },
  });

  return response;
}

export async function getShippingRate(checkoutId: string) {
  const query = gql`
    query GetShippingRate($id: ID!) {
      node(id: $id) {
        ... on Checkout {
          id
          webUrl
          availableShippingRates {
            ready
            shippingRates {
              handle
              price {
                amount
              }
              title
            }
          }
        }
      }
    }
  `;

  const response = await client.query<ShippingRate>({ query, variables: { id: checkoutId } });

  return response;
}

export async function applyDiscountCode(checkoutId: string, discountCode: string) {
  const query = gql`
    mutation applyDiscountCodeToCheckout($checkoutId: ID!, $discountCode: String!) {
      checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
        checkout {
          discountApplications(first: 10) {
            edges {
              node {
                allocationMethod
                targetSelection
                targetType
              }
            }
          }
        }
        checkoutUserErrors {
          message
          code
          field
        }
      }
    }
  `;

  const resposne = await client.mutate({
    mutation: query,
    variables: { checkoutId, discountCode },
  });

  return resposne;
}

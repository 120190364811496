import styles from './styles/Notification.module.scss';

const Notification = ({ count, customClassName }: { count: number; customClassName?: string }) => {
  return (
    <>
      {count > 0 && (
        <div
          className={`${styles['cart-number-notification']} 
            ${customClassName}
          }`}
        >
          <span>{count}</span>
        </div>
      )}
    </>
  );
};

export default Notification;

import { ChangeEventHandler, FormEventHandler, ReactElement, useState } from 'react';
import { useViewport } from '../../../helper/hooks';
import { INITIAL_IS_OPEN_STATUS_OBJECT } from '../../../pages/checkout/Checkout';
import { OpenHandler, PaymentMethodInfoType, PaymentMethodState } from '../../../@types/checkout';
import { Button, FixedBottomButtonContainer } from '../../button';
import Checkbox from '../../checkbox/Checkbox';
import { FormButton } from '../../login/atoms';
import Radio from '../../radio/Radio';
import Select from '../../select/Select';
import styles from './CheckoutOverlays.module.scss';

type PaymentMethodType = 'Simple Payment' | 'Credit Card';
type BillingAddressType = 'Same Address' | 'Different Address';

type SimplePaymentDataType = Array<{
  type: 'Paypal' | 'Amazon' | 'Google' | 'Shop';
  imageUrl: string;
}>;

type CreditCardDataType = Array<{
  id: string;
  cardInfo: string;
}>;

const simplePaymentData: SimplePaymentDataType = [
  {
    type: 'Paypal',
    imageUrl: '/icons/checkout__payment-item-paypal.svg',
  },
  {
    type: 'Amazon',
    imageUrl: '/icons/checkout__payment-item-amazon.svg',
  },
  {
    type: 'Google',
    imageUrl: '/icons/checkout__payment-item-google.svg',
  },
  {
    type: 'Shop',
    imageUrl: '/icons/checkout__payment-item-shop.svg',
  },
];

const creditCardData: CreditCardDataType = [
  {
    id: '1',
    cardInfo: 'Card Name 1 / 1234******123*',
  },
  {
    id: '2',
    cardInfo: 'Card Name 2  / 1234******123*',
  },
  {
    id: '3',
    cardInfo: 'Card Name 2',
  },
  {
    id: '4',
    cardInfo: 'Card Name 3  / 1234******123*',
  },
];

type PaymentMethodProps = PaymentMethodState & Partial<OpenHandler>;

const PaymentMethod = ({
  paymentMethod,
  setPaymentMethod,
  setIsOpenStatusObject,
  onClickAddNewCreditCardModal,
}: PaymentMethodProps & {
  onClickAddNewCreditCardModal?: () => void;
}): ReactElement => {
  const hasCreditCard = true;
  const [selectedPaymentMethodType, setSelectedPaymentMethodType] =
    useState<PaymentMethodType>('Simple Payment');
  const [selectedBillingAddressType, setBillingAddressType] =
    useState<BillingAddressType>('Same Address');
  const [currentCardId, setCurrentCardId] = useState<string>('1');
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const { width, isMobile } = useViewport();

  const onChangePaymentMethod: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setSelectedPaymentMethodType(value as PaymentMethodType);
  };
  const onChangeBillingAddress: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setBillingAddressType(value as BillingAddressType);
  };
  const onToggleCheckbox = (id: number | string, checked: boolean) => {
    setIsChecked((checked) => !checked);
  };
  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
  };
  const onClickSimplePaymentItem = (type: PaymentMethodInfoType) => {
    setPaymentMethod((prev) => ({
      ...prev,
      type,
    }));
  };
  const onClickAddNewCreditCard = () => {
    if (setIsOpenStatusObject) {
      setIsOpenStatusObject((prev) => ({
        ...prev,
        addNewCard: true,
      }));
    } else if (onClickAddNewCreditCardModal) {
      onClickAddNewCreditCardModal();
    }
  };
  const onClickSave = () => {
    if (setIsOpenStatusObject) {
      setIsOpenStatusObject(INITIAL_IS_OPEN_STATUS_OBJECT);
    }
  };

  return (
    <main className={styles['payment-method-main']}>
      <form onSubmit={onSubmit}>
        <section className={styles['payment-method-section']}>
          <h2 className={styles['payment-method-title']}>Payment method</h2>
          <div className={styles['payment-method__simple-payment']}>
            <Radio
              title="Simple Payment"
              value="Simple Payment"
              checked={selectedPaymentMethodType === 'Simple Payment'}
              handleChange={onChangePaymentMethod}
            />
            {selectedPaymentMethodType === 'Simple Payment' && (
              <div className={styles['simple-payment__inner-container']}>
                {simplePaymentData.map((sp) => (
                  <div
                    key={sp.type}
                    className={styles['simple-payment__item']}
                    onClick={() => onClickSimplePaymentItem(sp.type as PaymentMethodInfoType)}
                  >
                    <img src={sp.imageUrl} alt={sp.type} />
                    {paymentMethod.type === sp.type && (
                      <img
                        className={styles['select-checked']}
                        src="/icons/help__check.svg"
                        alt="체크 이미지"
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={styles['payment-method__credit-card']}>
            <Radio
              title={`Credit Card - ${
                creditCardData.find((cData) => cData.id === currentCardId)?.cardInfo
              }`}
              value="Credit Card"
              checked={selectedPaymentMethodType === 'Credit Card'}
              handleChange={onChangePaymentMethod}
            />
            {selectedPaymentMethodType === 'Credit Card' && (
              <div className={styles['credit-card__inner-container']}>
                {hasCreditCard ? (
                  <>
                    <Select
                      value={currentCardId}
                      setValue={setCurrentCardId}
                      placeholder="Credit Card"
                      fontStyle={{ padding: '14px 16px' }}
                    >
                      {creditCardData.map((c) => (
                        <Select.Option
                          label={c.cardInfo}
                          key={c.id}
                          value={c.id}
                          isSelected={currentCardId === c.id}
                        />
                      ))}
                    </Select>
                    <div className={styles['add-new-card-button--small-container']}>
                      <button
                        className={`${styles['add-new-card-button']} ${styles['add-new-card-button--small']}`}
                        onClick={onClickAddNewCreditCard}
                      >
                        <img src="/icons/checkout__payment-method-plus.svg" alt="" />
                        <span>Add New Card</span>
                      </button>
                    </div>
                  </>
                ) : (
                  <button
                    className={`${styles['add-new-card-button']} ${styles['add-new-card-button--big']}`}
                    onClick={onClickAddNewCreditCard}
                  >
                    <img src="/icons/checkout__payment-method-plus.svg" alt="" />
                    <span>Add New Card</span>
                  </button>
                )}
              </div>
            )}
          </div>
          <div className={styles['payment-method-checkbox-container']}>
            <Checkbox
              id="payment-method"
              checked={isChecked}
              onToggle={onToggleCheckbox}
              label="Use it as basic payment method"
              className={styles['payment-method-checkbox']}
            />
            {!isMobile && (
              <Button className={styles['payment-method-desktop-save']}>
                <span>Save</span>
              </Button>
            )}
          </div>
        </section>
        <section className={styles['billing-address-container']}>
          <h2 className={styles['payment-method-title']}>Billing address</h2>
          <p>Select the address that matches your card or payment method.</p>
          <div className={styles['billing-address__inner-container']}>
            <div className={styles['billing-address__inner-item']}>
              <Radio
                title="Same as shipping address"
                value="Same Address"
                checked={selectedBillingAddressType === 'Same Address'}
                handleChange={onChangeBillingAddress}
              />
            </div>
            <div className={styles['billing-address__inner-item']}>
              <Radio
                title="Use a different billing address"
                value="Different Address"
                checked={selectedBillingAddressType === 'Different Address'}
                handleChange={onChangeBillingAddress}
              />
            </div>
          </div>
        </section>
        {width < 768 && (
          <FixedBottomButtonContainer>
            <FormButton contents="SAVE" onClick={onClickSave} />
          </FixedBottomButtonContainer>
        )}
      </form>
    </main>
  );
};

export default PaymentMethod;

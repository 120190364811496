import { ReactElement } from 'react';
import styles from './styles/GoBack.module.scss';

const GoBack = (): ReactElement => {
  return (
    <div className={styles['div']}>
      <img src="/icons/category-nav__chevron-left.svg" alt="뒤로가기" />
    </div>
  );
};

export default GoBack;

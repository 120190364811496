import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountLayout } from '../../components/layout';
import { MobileNavItem } from '../../components/layout/BaseLayout';
import { FormButton, Radio } from '../../components/login/atoms';
import styles from './styles/Languages.module.scss';

const LanguageDescription = ({ icon, name }: { icon: string; name: string }): ReactElement => {
  return (
    <div className={styles['language__description']}>
      <img src={icon} alt={name} />
      {name}
    </div>
  );
};

const Language = (): ReactElement => {
  const { t } = useTranslation();

  const [currentLang, setCurrentLang] = useState<string>(
    () => localStorage.getItem('language') ?? window.navigator.language.slice(0, 2),
  );

  const languages = [
    { lang: 'en', name: t('account_language_en'), icon: '/icons/language__eng.svg' },
    { lang: 'ko', name: t('account_language_ko'), icon: '/icons/language__kor.svg' },
  ];

  const handleChange = (language: string) => {
    setCurrentLang(language);
  };

  const onSave = () => {
    localStorage.setItem('language', currentLang);

    window.location.reload();
  };

  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.SHOW_ALL, title: t('account_language') }}
      pageTitle={t('account_language_page')}
      className="no-padding-bottom"
    >
      <div className={styles['container']}>
        <div className={styles['language__items']}>
          {languages.map(({ lang, name, icon }) => (
            <div className={styles['language__item']} key={lang}>
              <Radio
                name="language"
                value={lang}
                handleChange={() => handleChange(lang)}
                checked={currentLang === lang}
              >
                <LanguageDescription icon={icon} name={name} />
              </Radio>
            </div>
          ))}
        </div>
        <div className={styles['button']}>
          <FormButton contents={t('account_language_button')} onClick={onSave} />
        </div>
      </div>
    </AccountLayout>
  );
};

export default Language;

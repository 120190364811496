import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useViewport } from '../../../helper/hooks';
import CheckoutItem from '../CheckoutItem';
import styles from './CheckoutTemplates.module.scss';
import { ShippingInfoState, OpenHandler } from '../../../@types/checkout';
import { useTranslation } from 'react-i18next';
import { customerState } from '../../../stores/customer/atoms';
import { useRecoilValueLoadable } from 'recoil';

type TShippingInformationType = Pick<
  ShippingInfoState,
  'selectedShippingInfo' | 'setSelectedShippingInfo'
> &
  Partial<OpenHandler>;

const TShippingInformation = ({
  selectedShippingInfo,
  // setSelectedShippingInfo,
  setIsOpenStatusObject,
  type,
  accordionElement,
}: TShippingInformationType & {
  type?: 'Checkout' | 'Checkout-Completed' | 'Checkout Desktop';
  accordionElement?: ReactNode;
}): ReactElement => {
  // TODO: Check for user hasAddress
  const hasAddress = true;
  const { width, isMobile } = useViewport();
  const { t } = useTranslation();
  const customer = useRecoilValueLoadable(customerState);

  const onClickShippingInformation = () => {
    if (setIsOpenStatusObject) {
      if (hasAddress) {
        setIsOpenStatusObject((prev) => ({
          ...prev,
          deliveryInfo: true,
        }));
      } else {
        setIsOpenStatusObject((prev) => ({
          ...prev,
          deliveryInfoAdd: true,
        }));
      }
    }
  };
  const shippingAddress = () => {
    let add = '';
    if (selectedShippingInfo?.address2) {
      add = add + ' ,' + selectedShippingInfo?.address2;
    }
    if (selectedShippingInfo?.address1) {
      add = add + selectedShippingInfo?.address1;
    }
    if (selectedShippingInfo?.city) {
      add = add + ' ,' + selectedShippingInfo?.city;
    }
    if (selectedShippingInfo?.country) {
      add = add + ' ,' + selectedShippingInfo?.country;
    }
    if (selectedShippingInfo?.zip) {
      add = add + ' ' + selectedShippingInfo?.zip;
    }
    return add;
  };

  const userShippingInfoElement = useMemo(
    () => (
      <div className={styles['shipping-information-container']}>
        <h3>{`${selectedShippingInfo?.name}`}</h3>
        <div>
          <p>{shippingAddress()}</p>
        </div>
        <div>
          <p>{customer?.contents?.email}</p>
          <p>{selectedShippingInfo?.phone}</p>
        </div>
        {/* <div>
          <p>{t('checkout_shipping_address_description')}</p>
        </div> */}
        {type === 'Checkout' && (
          <button onClick={onClickShippingInformation}>
            <img src="/icons/checkout__edit.svg" alt="결제 편집" />
            <span>{t('checkout_shipping_address_edit')}</span>
          </button>
        )}
      </div>
    ),
    [selectedShippingInfo, type, customer],
  );

  if (selectedShippingInfo?.id) {
    return (
      <CheckoutItem
        title={type === 'Checkout-Completed' && isMobile ? '' : t('checkout_shipping')}
        element={userShippingInfoElement}
        options={{ chevronShow: true }}
        accordionElement={accordionElement}
        accordionArrow={true}
      />
    );
  } else {
    return (
      <CheckoutItem
        title={t('checkout_shipping')}
        placeholder={t('checkout_shipping_placeholder')}
        onClickOuter={onClickShippingInformation}
        options={{ chevronShow: true }}
        accordionElement={accordionElement}
        accordionArrow={true}
      />
    );
  }
};

export default TShippingInformation;

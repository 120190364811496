import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { CartItemWithProduct } from '../../@types/cart';
import { useViewport } from '../../helper/hooks';
import useCart from '../../hooks/useCart';
import { modalState } from '../../stores/modal/atoms';
import Checkbox from '../checkbox/Checkbox';
import { NumberInput } from '../input';
import styles from './styles/CartItem.module.scss';

type CartItemChildrenProps = {
  itemControl: {
    price: number;
    originalPrice: number;
    handleDeleteCartItem: () => void;
    isDiscount: boolean;
  };
} & CartItemProps;

type CartItemProps = {
  item: CartItemWithProduct;
  onToggleCart?: (id: string | number, checked: boolean) => void;
  hasStock?: boolean;
};

const CartItemMobile = ({
  item,
  onToggleCart,
  itemControl,
}: Omit<CartItemChildrenProps, 'hasStock'>) => {
  const navigate = useNavigate();
  const onClickProduct = () => {
    navigate(`/product/${encodeURIComponent(item.id)}`);
  };
  const { price, originalPrice, isDiscount, handleDeleteCartItem } = itemControl;

  return (
    <>
      <div className={styles['header']}>
        <div className={styles['header__container']}>
          {item.product && (
            <Checkbox
              id={item.product.id}
              checked={item.active}
              onToggle={(id: string | number, checked: boolean) => {
                if (!onToggleCart) {
                  return;
                }
                onToggleCart(id, checked);
              }}
            />
          )}
          <h4 className={styles['header__title']} onClick={onClickProduct}>
            {item.product?.title}
          </h4>
        </div>
        <img onClick={handleDeleteCartItem} src={'/icons/cart__x.svg'} alt="닫기" />
      </div>

      <div className={styles['description']}>
        <div className={styles['thumbnail']} onClick={onClickProduct}>
          <img src={item.product?.featuredImage?.url} alt="" />
        </div>

        <div className={styles['price']} onClick={onClickProduct}>
          <div className={styles['price--selling']}>${price}</div>
          {isDiscount && <div className={styles['price--original']}>${originalPrice}</div>}
        </div>

        <div className={styles['bucket__control']}>
          {item.product && (
            <NumberInput
              item={item.product}
              coverShow={false}
              detailDefaultShow={true}
              customDetailStyles={{ position: 'unset' }}
            />
          )}
        </div>
      </div>
    </>
  );
};

const CartItemDesktop = ({
  item,
  onToggleCart,
  itemControl,
}: Omit<CartItemChildrenProps, 'hasStock'>) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onClickProduct = () => {
    if (item.product) {
      navigate(`/product/${encodeURIComponent(item.product.id)}`);
    }
  };
  const { price, originalPrice, isDiscount, handleDeleteCartItem } = itemControl;

  return (
    <>
      <div className={styles['inner']}>
        {item.product && (
          <Checkbox
            id={item.product.id}
            checked={item.active}
            onToggle={(id: string | number, checked: boolean) => {
              if (!onToggleCart) {
                return;
              }
              onToggleCart(id, checked);
            }}
          />
        )}

        <div className={styles['header']} onClick={onClickProduct}>
          <div className={styles['thumbnail']}>
            <img src={item.product?.featuredImage?.url} alt="" />
          </div>

          <div className={styles['description']}>
            <h4 className={styles['header__title']}>{item.product?.title}</h4>
            <div className={styles['price']}>
              <div className={styles['price--selling']}>${price}</div>
              {isDiscount && <div className={styles['price--original']}>${originalPrice}</div>}
            </div>
          </div>
        </div>
      </div>

      <div className={styles['inner-number']}>
        <div className={styles['bucket__control']}>
          {item.product && (
            <NumberInput
              item={item.product}
              coverShow={false}
              detailDefaultShow={true}
              customDetailStyles={{ position: 'unset' }}
            />
          )}
          <button className={styles['bucket__control--delete']} onClick={handleDeleteCartItem}>
            {t('cart_item_remove')}
          </button>
        </div>

        <div className={styles['total__price']}>${(item.quantity * price).toFixed(2)}</div>
      </div>
    </>
  );
};

const CartItemEmpty = ({ item, itemControl }: CartItemChildrenProps) => {
  const { isMobile } = useViewport();
  const { price, originalPrice, isDiscount, handleDeleteCartItem } = itemControl;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modal, setModal] = useRecoilState(modalState);
  const onClickProduct = () => {
    if (item.product) {
      navigate(`/product/${encodeURIComponent(item.product.id)}`);
    }
  };
  const onClickSoldout: MouseEventHandler = (event) => {
    // todo: 세영님 api response에 아래 modal 넣어주세요
    setModal({
      modal: true,
      childrenText: t('product_notify_modal'),
      buttons: [
        {
          text: t('product_notify_modal_button'),
        },
      ],
    });
  };

  return (
    <div className={styles['container--empty']}>
      <div className={styles['inner']}>
        <div className={styles['thumbnail']} onClick={onClickProduct}>
          <img src={item.product?.featuredImage?.url} alt="" />
          <div className={styles['thumbnail--soldout']}>
            <div className={styles['cover']}></div>
            <div className={styles['text']}>{t('cart_item_empty_soldout')}</div>
          </div>
        </div>

        <div className={styles['description']}>
          <h4 className={styles['header__title']} onClick={onClickProduct}>
            {item.product?.title}
          </h4>
          <div className={styles['price']}>
            <div className={styles['price--selling']}>${price}</div>
            {isDiscount && <div className={styles['price--original']}>${originalPrice}</div>}
          </div>
        </div>
      </div>

      <div className={styles['inner']}>
        <div className={styles['bucket__control']}>
          <button className={styles['bucket__control--notice']} onClick={onClickSoldout}>
            {t('cart_item_empty_notify')}
          </button>
          <button className={styles['bucket__control--delete']} onClick={handleDeleteCartItem}>
            {t('cart_item_empty_remove')}
          </button>
        </div>

        {!isMobile && <div className={styles['total__price']}>${price.toFixed(2)}</div>}
      </div>
    </div>
  );
};

const CartItem = ({ item, onToggleCart, hasStock = true }: CartItemProps) => {
  const cart = useCart();

  const handleDeleteCartItem = async () => {
    if (!item.product) {
      return;
    }

    await cart.remove([item.product.id]);
  };

  const { isMobile } = useViewport();

  const price = Number(item.product?.priceRange.maxVariantPrice.amount);
  const originalPrice = Number(item.product?.compareAtPriceRange?.maxVariantPrice.amount);
  const isDiscount = originalPrice > price;
  const itemControl = { price, originalPrice, isDiscount, handleDeleteCartItem };

  if (!hasStock) {
    return <CartItemEmpty item={item} onToggleCart={onToggleCart} itemControl={itemControl} />;
  }

  if (isMobile) {
    return (
      <div className={styles['container']}>
        <CartItemMobile
          key={item.quantity}
          item={item}
          onToggleCart={onToggleCart}
          itemControl={itemControl}
        />
      </div>
    );
  }

  return (
    <div className={styles['container']}>
      <CartItemDesktop
        key={item.quantity}
        item={item}
        onToggleCart={onToggleCart}
        itemControl={itemControl}
      />
    </div>
  );
};

export default CartItem;

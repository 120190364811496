import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Order } from '../../../@types/customer';
import { getOrder } from '../../../api/storefront/orders';
import { OrderItemDetail } from '../../../components/account/orders';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import Loading from '../../../components/loading/Loading';
import styles from './styles/MyOrderDetail.module.scss';

const MyOrderDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<Order>();

  const getOrderRequest = async () => {
    const { response, order } = await getOrder(id);

    setOrder(order);
    setLoading(response.loading);
  };

  useEffect(() => {
    getOrderRequest();
  }, []);

  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.HOME_SEARCH, title: t('account_orders_detail_title') }}
      pageTitle={t('account_orders_detail_page')}
    >
      <div className={styles['div']}>
        {loading || !order ? <Loading /> : <OrderItemDetail order={order} />}
      </div>
    </AccountLayout>
  );
};

export default MyOrderDetail;

import {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FormButton } from '../../login/atoms';
import styles from './styles/PointRedeem.module.scss';

type PointRedeemProps = {
  currentPoint: number;
  point: number;
  setPoint: Dispatch<SetStateAction<number>>;
  handleSubmit: FormEventHandler;
  isLoading: boolean;
};

const PointRedeem = ({
  currentPoint,
  point,
  setPoint,
  handleSubmit,
  isLoading,
}: PointRedeemProps) => {
  const { t } = useTranslation();
  const maxConversion = Math.floor(currentPoint / 100);
  const maxPoint = maxConversion * 100;
  const [rate, setRate] = useState(0);

  const container = useRef<HTMLDivElement>(null);
  const description = useRef<HTMLDivElement>(null);

  const handleChage: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setPoint(Math.round(Number(value)) * 100);
  };

  useEffect(() => {
    if (!container.current || !description.current) {
      return;
    }

    const containerWidth = container.current.clientWidth - 32;
    const descriptionWidth = description.current.offsetWidth;

    const marginLeft = ((containerWidth - descriptionWidth) * rate) / 100;

    description.current.style.transform = `translate(${marginLeft}px, 0)`;

    setRate(Math.ceil((point / maxPoint) * 100));
  }, [container.current, description.current, point]);
  useEffect(() => {
    if (!container.current || !description.current) {
      return;
    }
    const calcRate = Math.ceil((point / maxPoint) * 100);

    const containerWidth = container.current.clientWidth - 32;
    const descriptionWidth = description.current.offsetWidth;

    const marginLeft = ((containerWidth - descriptionWidth) * calcRate) / 100;

    description.current.style.transform = `translate(${marginLeft}px, 0)`;

    setRate(calcRate);
  }, [container.current, description.current, point]);

  return (
    <div className={styles['container']} ref={container}>
      <h2 className={styles['title']}>{t('account_point_redeem')}</h2>
      <div className={styles['subtitle']}>{t('account_point_redeem_subtitle')}</div>
      <form onSubmit={handleSubmit}>
        <div className={styles['input-description']} ref={description}>
          {`${point}${t('account_point_redeem_description')}${point / 100}${t(
            'account_point_redeem_description2',
          )}`}
        </div>

        <div className={styles['input-container']}>
          <input
            className={styles['input']}
            type="range"
            min={0}
            max={maxConversion}
            name="point"
            defaultValue={point}
            onChange={handleChage}
            style={{ backgroundSize: `${rate}%` }}
          />
          <div className={styles['point-range']}>
            <div className={styles['point-number']}>0 P</div>
            <div className={styles['point-number']}>{`${maxPoint} P`}</div>
          </div>
        </div>
        <div>
          <FormButton
            contents={t('account_point_redeem_button')}
            disabled={point < 1 || isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default PointRedeem;

import styles from './styles/BaseNavBar.module.scss';
import { useResponsiveElement } from '../../helper/hooks';
import { ReactElement, ReactNode, useCallback } from 'react';
import {
  CustomActions,
  MobileNavItem,
  MobileNavItemType,
  MobileNavType,
} from '../layout/BaseLayout';
import MobileNavBar from './mobile/MobileNavBar';
import DesktopNavBar, { DesktopNavType } from './desktop/DesktopNavBar';

const BaseNavBar = ({
  mobileNav,
  desktopNav = 'DEFAULT',
  customActions,
  customChildren,
}: {
  mobileNav: MobileNavType;
  desktopNav?: DesktopNavType;
  customActions?: CustomActions;
  customChildren?: ReactNode;
}): ReactElement => {
  const mobileNavOnlyTitle = MobileNavBar([
    {
      pos: 'LEFT',
      items: ['GO_BACK'],
      props: {
        customActions,
        customChildren,
      },
    },
    {
      pos: 'CENTER',
      items: ['TITLE'],
      props: {
        title: mobileNav.title,
        customActions,
        customChildren,
      },
    },
  ]);
  const mobileNavOnlyLogo = MobileNavBar([
    {
      pos: 'LEFT',
      items: ['GO_BACK'],
      props: {
        customActions,
        customChildren,
      },
    },
    {
      pos: 'CENTER',
      items: ['LOGO'],
      props: {
        customActions,
        customChildren,
      },
    },
  ]);
  const mobileNavTitleCart = MobileNavBar([
    {
      pos: 'LEFT',
      items: ['GO_BACK'],
      props: {
        customActions,
        customChildren,
      },
    },
    {
      pos: 'CENTER',
      items: ['TITLE'],
      props: {
        title: mobileNav.title,
        customActions,
        customChildren,
      },
    },
    {
      pos: 'RIGHT',
      items: ['CART'],
      props: {
        customActions,
        customChildren,
      },
    },
  ]);
  const mobileNavTitleNotificationCart = MobileNavBar([
    {
      pos: 'LEFT',
      items: ['GO_BACK'],
      props: {
        customActions,
        customChildren,
      },
    },
    {
      pos: 'CENTER',
      items: ['TITLE'],
      props: {
        title: mobileNav.title,
        customActions,
        customChildren,
      },
    },
    {
      pos: 'RIGHT',
      items: ['NOTIFICATION', 'CART'],
      props: {
        customActions,
        customChildren,
      },
    },
  ]);
  const mobileNavTitleExternalLink = MobileNavBar([
    {
      pos: 'LEFT',
      items: ['GO_BACK'],
      props: {
        customActions,
        customChildren,
      },
    },
    {
      pos: 'CENTER',
      items: ['TITLE'],
      props: {
        title: mobileNav.title,
        customActions,
        customChildren,
      },
    },
    {
      pos: 'RIGHT',
      items: ['EXTERNAL_LINK'],
      props: {
        customActions,
        customChildren,
      },
    },
  ]);

  const mobileNavTitleShowAll = MobileNavBar([
    {
      pos: 'LEFT',
      items: ['GO_BACK', 'TITLE'],
      props: {
        customActions,
        customChildren,
        title: mobileNav.title,
      },
    },

    {
      pos: 'RIGHT',
      items: ['HOME', 'SEARCH_ICON', 'CART'],

      props: {
        customActions,
        customChildren,
      },
    },
  ]);
  const mobileNavTitleHomeSearch = MobileNavBar([
    {
      pos: 'LEFT',
      items: ['GO_BACK', 'TITLE'],
      props: {
        customActions,
        customChildren,
        title: mobileNav.title,
      },
    },

    {
      pos: 'RIGHT',
      items: ['HOME', 'SEARCH_ICON'],

      props: {
        customActions,
        customChildren,
      },
    },
  ]);
  const mobileNavTitleCategoryAll = MobileNavBar([
    {
      pos: 'LEFT',
      items: ['GO_BACK', 'CATEGORY', 'TITLE'],
      props: {
        customActions,
        customChildren,
        title: mobileNav.title,
      },
    },

    {
      pos: 'RIGHT',
      items: ['HOME', 'SEARCH_ICON', 'CART'],

      props: {
        customActions,
        customChildren,
      },
    },
  ]);

  const getMobileNav = useCallback(
    (type: MobileNavItemType) => {
      switch (type) {
        case MobileNavItem.ONLY_TITLE:
          return mobileNavOnlyTitle;
        case MobileNavItem.ONLY_LOGO:
          return mobileNavOnlyLogo;
        case MobileNavItem.TITLE_CART:
          return mobileNavTitleCart;
        case MobileNavItem['TITLE_BELL-CART']:
          return mobileNavTitleNotificationCart;
        case MobileNavItem.TITLE_SHARE:
          return mobileNavTitleExternalLink;
        case MobileNavItem.SHOW_ALL:
          return mobileNavTitleShowAll;
        case MobileNavItem.HOME_SEARCH:
          return mobileNavTitleHomeSearch;
        case MobileNavItem.CATEGORY_ALL:
          return mobileNavTitleCategoryAll;
        default:
          return <></>;
      }
    },
    [
      mobileNavOnlyTitle,
      mobileNavOnlyLogo,
      mobileNavTitleCart,
      mobileNavTitleNotificationCart,
      mobileNavTitleExternalLink,
    ],
  );
  const element = useResponsiveElement({
    MOBILE: getMobileNav(mobileNav.type),
    DESKTOP: DesktopNavBar(desktopNav),
  });
  return <nav className={styles['nav']}>{element}</nav>;
};

export default BaseNavBar;

import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { TRequest } from '../../../@types/help';
import { getRequestById } from '../../../api/backend/help';
import styles from './styles/HelpRequestDetail.module.scss';
import Loading from '../../../components/loading/Loading';

const HelpRequestDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [request, setRequest] = useState<TRequest>();

  const getRequestByIdRequest = useCallback(async () => {
    if (!id) {
      return;
    }

    const { data: request } = await getRequestById(id);

    setRequest(request);
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getRequestByIdRequest();
  }, [id]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={styles['container']}>
          <h2 className={styles['title']}>My Request</h2>
          <article className={styles['request']}>
            <h2 className={styles['request__title']}>{request?.title}</h2>
            <p className={styles['request__content']}>{request?.content}</p>
            <div className={styles['image__container']}>
              {request?.images.map((image) => (
                <img key={image.id} src={image.url} alt="문의사항 이미지" />
              ))}
            </div>
            <div className={styles['date']}>
              {new Date(request?.createdAt ?? '').toLocaleDateString()}
            </div>
          </article>
        </div>
      )}
    </>
  );
};

export default HelpRequestDetail;

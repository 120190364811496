import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { SearchProps } from '../SearchBar';
import styles from '../SearchBar.module.scss';

const SearchBarBasic = ({ searchValue, setIsSearching, hasBack }: SearchProps) => {
  const { t } = useTranslation();
  const go = useNavigate();

  const handleClickBack: MouseEventHandler<HTMLDivElement> = () => {
    if (!hasBack) {
      setIsSearching(false);
      return;
    }

    go('/');
  };

  return (
    <div className={styles['container']}>
      <div className={`${styles['search__container']} ${styles['search__container--searching']}`}>
        <div
          className={hasBack ? styles['back'] : styles['back--hidden']}
          onClick={handleClickBack}
        >
          <img src="/icons/category-nav__chevron-left.svg" alt="뒤로가기" />
        </div>

        <div className={styles['search']}>
          <img
            className={styles['search__img']}
            src="/icons/search__question.svg"
            alt="검색 이미지"
          />
          <input
            className={styles['search__input']}
            placeholder={t('search_bar_basic__placeholder')}
            defaultValue={searchValue}
            onFocus={() => setIsSearching(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBarBasic;

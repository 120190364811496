import { useTranslation } from 'react-i18next';
import styles from './styles/PointDescription.module.scss';

const DescriptinoItem = ({ index, content }: { index: number; content: string }) => {
  return (
    <li className={styles['description-item']}>
      <span className={styles['description-number']}>{index}</span>
      <span className={styles['description-content']}>{content}</span>
    </li>
  );
};

const PointDescription = () => {
  const { t } = useTranslation();
  return (
    <div className={styles['description']}>
      <div className={styles['description-title']}>{t('account_point_redeem_description_title')}</div>
      <ul className={styles['description-items']}>
        <DescriptinoItem index={1} content={t('account_point_redeem_description_item1')} />
        <DescriptinoItem index={2} content={t('account_point_redeem_description_item2')} />
        <DescriptinoItem index={3} content={t('account_point_redeem_description_item3')} />
      </ul>
      <div className={styles['description-alert']}>{t('account_point_redeem_description_alert')}</div>
    </div>
  );
};

export default PointDescription;

import { useTranslation } from 'react-i18next';
import NotificationsContainer from '../../../components/account/notifications/NotificationsContainer';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import styles from './Notifications.module.scss';
import { useState } from 'react';
import { customerUpdate } from '../../../api/storefront/customer';
import { useRecoilValueLoadable } from 'recoil';
import { customerState } from '../../../stores/customer/atoms';

const Notifications = () => {
  const { t } = useTranslation();
  // TODO: Add api for updating user notification settings
  // TODO: Add state
  const customer = useRecoilValueLoadable(customerState);
  const [acceptMarketing, setAcceptMarketing] = useState(false);
  return (
    <AccountLayout
      mobileNav={{ type: MobileNavItem.SHOW_ALL, title: t('account_notification') }}
      pageTitle={t('account_notification_page')}
      className="no-padding-bottom"
    >
      <div className={styles['container']}>
        <NotificationsContainer
          title={t('account_notification_sales_title')}
          description={t('account_notification_sales_description')}
          checkList={[t('account_notification_sales_toggle')]}
          toggle={acceptMarketing}
          setToggle={setAcceptMarketing}
          handleToggle={() => {
            const response = customerUpdate({
              acceptsMarketing: !acceptMarketing,
            });
          }}
        />
        {/* <NotificationsContainer
          title={t('account_notification_personalized_title')}
          description={t('account_notification_personalized_description')}
          checkList={[t('account_notification_personalized_toggle')]}
        /> */}
        <NotificationsContainer
          title={t('account_notification_order_title')}
          description={t('account_notification_order_description')}
          checkList={[]}
        />
      </div>
    </AccountLayout>
  );
};

export default Notifications;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useRecoilValueLoadable } from 'recoil';
import { customerUpdate } from '../../../api/storefront/customer';
import { UserInfo } from '../../../components/account';
import UserProfile from '../../../components/account/profile/UserProfile';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import Loading from '../../../components/loading/Loading';
import Overlay from '../../../components/overlay/Overlay';
import { customerState } from '../../../stores/customer/atoms';
import styles from './styles/EditProfile.module.scss';

const EditProfile = () => {
  const go = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const customer = useRecoilValueLoadable(customerState);

  const [checked, setChecked] = useState<boolean>(false);
  const [overlayLoading, setOverlayLoading] = useState<boolean>(false);

  const onToggleCheckbox = async () => {
    setOverlayLoading(true);
    const response = await customerUpdate({ acceptsMarketing: !checked });
    setOverlayLoading(false);
    if (response.data) {
      setChecked(response.data.customerUpdate.customer.acceptsMarketing);
    }
  };

  useEffect(() => {
    if (customer.state === 'loading') {
      return;
    }

    if (!customer.contents) {
      go('/');
    }

    setChecked(customer.contents.acceptsMarketing);
  }, [customer]);

  return (
    <>
      {pathname !== '/account/profile/edit' ? (
        <Outlet />
      ) : (
        <AccountLayout
          mobileNav={{ type: MobileNavItem.HOME_SEARCH, title: t('account_profile_edit_title') }}
          pageTitle={t('account_profile_edit_page_title')}
          className="account-profile-edit-mobile-layout"
        >
          <div className={styles['container']}>
            <div className={styles['user__container']}>
              <UserProfile userImage={customer.contents.profileImageUrl} type="EDIT" />
            </div>

            <div className={styles['user__container']}>
              <div className={styles['user__information']}>
                <UserInfo
                  link="/account/profile/edit/name"
                  mainText={t('account_profile_name')}
                  detailInfo={customer.contents.displayName}
                />
                <UserInfo
                  link="/account/profile/edit/phone"
                  mainText={t('account_profile_phone')}
                  detailInfo={customer.contents.phone || ''}
                />
                <UserInfo
                  link="#"
                  mainText={t('account_profile_email')}
                  detailInfo={customer.contents.email}
                  hasArrow={false}
                  className={styles['not-active']}
                />
              </div>

              <UserInfo
                link="/account/profile/delivery-info"
                mainText={t('account_profile_address_book')}
                options={{ borderShow: true }}
              />
              {/* <UserInfo link="#" mainText="Payment Methods" options={{ borderShow: true }} /> */}
              <UserInfo
                link="/account/profile/change-password"
                mainText={t('account_profile_change_password')}
                options={{ borderShow: true }}
              />
            </div>
          </div>
          {overlayLoading && (
            <Overlay>
              <Loading />
            </Overlay>
          )}
        </AccountLayout>
      )}
    </>
  );
};

export default EditProfile;

import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { customerUpdate } from '../../../api/storefront/customer';
import { Input } from '../../../components/input';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import { FormButton } from '../../../components/login/atoms';
import Select from '../../../components/select/Select';
import countryCodes from '../../../constants/country-code';
import styles from './styles/ChangePhone.module.scss';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { customerState } from '../../../stores/customer/atoms';
import { modalState } from '../../../stores/modal/atoms';

const ChangePhone = () => {
  const { t } = useTranslation();
  const customer = useRecoilValueLoadable(customerState);
  const [modal, setModal] = useRecoilState(modalState);
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState<string | CountryCode>('');
  const [country, setCountry] = useState<{
    name: string;
    dial_code: string;
    code: string;
  }>({ code: '', dial_code: '', name: '' });

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!phone) {
      // TODO: Handle phone format error
      setModal({
        modal: true,
        childrenText: t('account_profile_change_phone_error_empty'),
        buttons: [
          {
            text: t('account_profile_change_phone_error_empty_button'),
          },
        ],
      });
      return;
    }
    let parsePhone;
    try {
      parsePhone = parsePhoneNumber(phone, countryCode as CountryCode);
      if (!parsePhone.isValid()) {
        setModal({
          modal: true,
          childrenText: t('account_profile_change_phone_error'),
          buttons: [
            {
              text: t('account_profile_change_phone_error_button'),
            },
          ],
        });
        return;
      }

      await customerUpdate({ phone: parsePhone.number });
      setModal({
        modal: true,
        childrenText: t('account_profile_change_phone_done'),
        buttons: [
          {
            text: t('account_profile_change_phone_done_button'),
            action: () => {
              location.href = '/account/profile/edit';
            },
          },
        ],
        closeButtonAction: () => {
          location.href = '/account/profile/edit';
        },
      });
    } catch {
      setModal({
        modal: true,
        childrenText: t('account_profile_change_phone_error'),
        buttons: [
          {
            text: t('account_profile_change_phone_error_button'),
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (customer.state == 'hasValue' && !!customer.contents?.phone) {
      const parsePhone = parsePhoneNumber(customer.contents!.phone as string);
      setPhone(parsePhone.formatNational().replace(/-/g, ''));
      setCountryCode(parsePhone.country!);
    }
  }, [customer]);

  useEffect(() => {
    setCountry(countryCodes.find(({ code }) => code === countryCode)!);
  }, [countryCode]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setPhone((v) => (event.target.validity.valid ? event.target.value : v));
  };

  return (
    <AccountLayout
      mobileNav={{
        type: MobileNavItem.HOME_SEARCH,
        title: t('account_profile_change_phone_title'),
      }}
      pageTitle={t('account_profile_change_phone_title')}
    >
      <form className={styles['form']} onSubmit={onSubmit}>
        <div className={styles['form__container']}>
          <Select
            value={country ? `${country?.name} ${country?.dial_code}` : ''}
            setValue={setCountryCode}
            containerStyle={{ marginBottom: '8px' }}
            alreadySelected={true}
          >
            {countryCodes.map((code) => (
              <Select.Option
                key={code.code}
                label={`${code.name} ${code.dial_code}`}
                value={code.code}
                isSelected={countryCode === code.code}
              ></Select.Option>
            ))}
          </Select>
          <Input
            name="phone"
            type="number"
            pattern="[0-9]*"
            placeholder={t('account_profile_change_phone_placeholder')}
            value={phone}
            onChange={handleChange}
          />
        </div>
        <div className={styles['form__button__container']}>
          <FormButton contents={t('account_profile_change_phone_button')} />
        </div>
      </form>
    </AccountLayout>
  );
};

export default ChangePhone;

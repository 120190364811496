import styles from './Checkbox.module.scss';

type Checkbox = {
  id: number | string;
  checked: boolean;
  onToggle: (id: number | string, checked: boolean) => void;
  label?: string;
  className?: string;
};

const Checkbox = ({ id, checked, onToggle, label, className }: Checkbox) => {
  return (
    <div
      id={id.toString()}
      className={`${styles['div']} ${className ? className : ''}`}
      onClick={(e) => {
        onToggle(e.currentTarget.id, !checked);
      }}
    >
      {checked ? (
        <img src={'/icons/checkbox-active.svg'} alt="체크박스 체크됨" />
      ) : (
        <img src={'/icons/checkbox-default.svg'} alt="체크박스 기본" />
      )}
      {label ? <span>{label}</span> : <></>}
    </div>
  );
};

export default Checkbox;

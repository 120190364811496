import styled from'./styles/Policy.module.scss';
export const EnPrivacy = () => {
  return (
    <div className={styled['rte']}>
      <p>
        This Privacy Policy describes how{' '}
        <span>
          <a href="http://mykmarket.com">http://mykmarket.com</a>
        </span>{' '}
        collects, uses, and discloses your Personal Information when you visit or make a purchase
        from the Site.
      </p>
      <p>
        <strong>Collecting Personal Information</strong>
      </p>
      <p>
        When you visit the Site, we collect certain information about your device, your interaction
        with the Site, and information necessary to process your purchases. We may also collect
        additional information if you contact us for customer support. In this Privacy Policy, we
        refer to any information that can uniquely identify an individual (including the information
        below) as “Personal Information”. See the list below for more information about what
        Personal Information we collect and why.
      </p>
      <p>
        <u>Device information</u>
      </p>
      <ul>
        <li>
          <strong>Examples of Personal Information collected:</strong>&nbsp;version of web browser,
          IP address, time zone, cookie information, what sites or products you view, search terms,
          and how you interact with the Site.
        </li>
        <li>
          <strong>Purpose of collection:</strong>&nbsp;to load the Site accurately for you, and to
          perform analytics on Site usage to optimize our Site.
        </li>
        <li>
          <strong>Source of collection:</strong>&nbsp;Collected automatically when you access our
          Site using cookies, log files, web beacons, tags, or pixels&nbsp;
        </li>
        <li>
          <strong>Disclosure for a business purpose:</strong>&nbsp;shared with our processor
          Shopify, Brandless Co.,Ltd., MXN Commerce Korea, MXN Commerce USA Inc., and SIA Products
          LLC.
        </li>
      </ul>
      <p>
        <u>Order information</u>
      </p>
      <ul>
        <li>
          <strong>Examples of Personal Information collected:</strong>&nbsp;name, billing address,
          shipping address, payment information (including credit card numbers, PayPal accounts, and
          Amazon Pay accounts), email address, and phone number.
        </li>
        <li>
          <strong>Purpose of collection:</strong>&nbsp;to provide products or services to you to
          fulfill our contract, to process your payment information, arrange for shipping, and
          provide you with invoices and/or order confirmations, communicate with you, screen our
          orders for potential risk or fraud, and when in line with the preferences you have shared
          with us, provide you with information or advertising relating to our products or services.
        </li>
        <li>
          <strong>Source of collection:</strong>&nbsp;collected from you.
        </li>
        <li>
          <strong>Disclosure for a business purpose:</strong>&nbsp;shared with our processor Shopify
          <em>, </em>Brandless Co., Ltd., MXN Commerce Korea, MXN Commerce USA Inc., and SIA
          Products LLC.
        </li>
      </ul>
      <p>
        <u>Customer support information</u>
      </p>
      <ul>
        <li>
          <strong>Examples of Personal Information collected:</strong>&nbsp;Order number and Order
          Information.
        </li>
        <li>
          <strong>Purpose of collection:</strong>&nbsp;to provide customer support.
        </li>
        <li>
          <strong>Source of collection:</strong>&nbsp;collected from you.
        </li>
        <li>
          <strong>Disclosure for a business purpose:</strong>&nbsp;Brandless Co., Ltd., MXN Commerce
          Korea, SIA Products LLC.
        </li>
      </ul>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Sharing Personal Information</strong>
      </p>
      <p>
        We share your Personal Information with service providers to help us provide our services
        and fulfill our contracts with you, as described above. For example:
      </p>
      <ul>
        <li>
          We use Shopify to power our online store. You can read more about how Shopify uses your
          Personal Information here:&nbsp;
          <a href="https://www.shopify.com/legal/privacy">https://www.shopify.com/legal/privacy</a>.
        </li>
        <li>
          We may share your Personal Information to comply with applicable laws and regulations, to
          respond to a subpoena, search warrant or other lawful request for information we receive,
          or to otherwise protect our rights.
        </li>
      </ul>
      <p>
        <strong>Behavioural Advertising</strong>
      </p>
      <p>
        As described above, we use your Personal Information to provide you with targeted
        advertisements or marketing communications we believe may be of interest to you. For
        example:
      </p>
      <ul>
        <li>
          We use Google Analytics to help us understand how our customers use the Site. You can read
          more about how Google uses your Personal Information here:&nbsp;
          <a href="https://policies.google.com/privacy?hl=en">
            https://policies.google.com/privacy?hl=en
          </a>
          .You can also opt-out of Google Analytics here:&nbsp;
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>
          .
        </li>
        <li>
          We share information about your use of the Site, your purchases, and your interaction with
          our ads on other websites with our advertising partners. We collect and share some of this
          information directly with our advertising partners, and in some cases through the use of
          cookies or other similar technologies (which you may consent to, depending on your
          location).
        </li>
      </ul>
      <p>
        For more information about how targeted advertising works, you can visit the Network
        Advertising Initiative’s (“NAI”) educational page at&nbsp;
        <span>
          <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
          </a>
        </span>
        .
      </p>
      <p>You can opt out of targeted advertising by:</p>
      <ul>
        <li>
          FACEBOOK -&nbsp;
          <a href="https://www.facebook.com/settings/?tab=ads">
            https://www.facebook.com/settings/?tab=ads
          </a>
        </li>
        <li>
          GOOGLE -&nbsp;
          <a href="https://www.google.com/settings/ads/anonymous">
            https://www.google.com/settings/ads/anonymous
          </a>
        </li>
        <li>
          BING -&nbsp;
          <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
            https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
          </a>
          ]
        </li>
      </ul>
      <p>
        Additionally, you can opt out of some of these services by visiting the Digital Advertising
        Alliance’s opt-out portal at:&nbsp;
        <span>
          <a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>
        </span>
        .
      </p>
      <p>
        <strong>Using Personal Information</strong>
      </p>
      <p>
        We use your personal Information to provide our services to you, which includes: offering
        products for sale, processing payments, shipping and fulfillment of your order, and keeping
        you up to date on new products, services, and offers.
      </p>
      <p>
        <strong>
          <em>&nbsp;</em>
        </strong>
      </p>
      <p>
        <strong>Selling Personal Information</strong>
      </p>
      <p>
        Our Site sells Personal Information, as defined by the California Consumer Privacy Act of
        2018 (“CCPA”).
      </p>
      <ul>
        <li>CATEGORIES OF INFORMATION SOLD;</li>
        <li>INSTRUCTIONS ON HOW TO OPT-OUT OF SALE;</li>
        <li>
          WHETHER YOUR BUSINESS SELLS INFORMATION OF MINORS (UNDER 16) AND WHETHER YOU OBTAIN
          AFFIRMATIVE AUTHORIZATION;
        </li>
        <li>
          IF YOU PROVIDE A FINANCIAL INCENTIVE TO NOT SELL INFORMATION, PROVIDE INFORMATION ABOUT
          WHAT THAT INCENTIVE IS.
        </li>
      </ul>
      <p>
        <strong>CCPA</strong>
      </p>
      <p>
        If you are a resident of California, you have the right to access the Personal Information
        we hold about you (also known as the ‘Right to Know’), to port it to a new service, and to
        ask that your Personal Information be corrected, updated, or erased. If you would like to
        exercise these rights, please contact us through the contact information below&nbsp;
      </p>
      <p>
        If you would like to designate an authorized agent to submit these requests on your behalf,
        please contact us at the address below.
      </p>
      <p>
        <strong>Cookies</strong>
      </p>
      <p>
        A cookie is a small amount of information that’s downloaded to your computer or device when
        you visit our Site. We use a number of different cookies, including functional, performance,
        advertising, and social media or content cookies. Cookies make your browsing experience
        better by allowing the website to remember your actions and preferences (such as login and
        region selection). This means you don’t have to re-enter this information each time you
        return to the site or browse from one page to another. Cookies also provide information on
        how people use the website, for instance whether it’s their first time visiting or if they
        are a frequent visitor.
      </p>
      <p>
        We use the following cookies to optimize your experience on our Site and to provide our
        services.
      </p>
      <p>
        <strong>Cookies Necessary for the Functioning of the Store</strong>
      </p>
      <table width="534">
        <thead>
          <tr>
            <td>
              <p>
                <strong>Name</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Function</strong>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>_ab</p>
            </td>
            <td>
              <p>Used in connection with access to admin.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_secure_session_id</p>
            </td>
            <td>
              <p>Used in connection with navigation through a storefront.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>cart</p>
            </td>
            <td>
              <p>Used in connection with shopping cart.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>cart_sig</p>
            </td>
            <td>
              <p>Used in connection with checkout.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>cart_ts</p>
            </td>
            <td>
              <p>Used in connection with checkout.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>checkout_token</p>
            </td>
            <td>
              <p>Used in connection with checkout.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>secret</p>
            </td>
            <td>
              <p>Used in connection with checkout.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>secure_customer_sig</p>
            </td>
            <td>
              <p>Used in connection with customer login.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>storefront_digest</p>
            </td>
            <td>
              <p>Used in connection with customer login.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_shopify_u</p>
            </td>
            <td>
              <p>Used to facilitate updating customer account information.</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>Reporting and Analytics</strong>
      </p>
      <table width="534">
        <tbody>
          <tr>
            <td>
              <p>
                <strong>Name</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Function</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_tracking_consent</p>
            </td>
            <td>
              <p>Tracking preferences.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_landing_page</p>
            </td>
            <td>
              <p>Track landing pages</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_orig_referrer</p>
            </td>
            <td>
              <p>Track landing pages</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_s</p>
            </td>
            <td>
              <p>Shopify analytics.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_shopify_fs</p>
            </td>
            <td>
              <p>Shopify analytics.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_shopify_s</p>
            </td>
            <td>
              <p>Shopify analytics.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_shopify_sa_p</p>
            </td>
            <td>
              <p>Shopify analytics relating to marketing &amp; referrals.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_shopify_sa_t</p>
            </td>
            <td>
              <p>Shopify analytics relating to marketing &amp; referrals.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_shopify_y</p>
            </td>
            <td>
              <p>Shopify analytics.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_y</p>
            </td>
            <td>
              <p>Shopify analytics.</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        The length of time that a cookie remains on your computer or mobile device depends on
        whether it is a “persistent” or “session” cookie. Session cookies last until you stop
        browsing and persistent cookies last until they expire or are deleted. Most of the cookies
        we use are persistent and will expire between 30 minutes and two years from the date they
        are downloaded to your device.
      </p>
      <p>
        You can control and manage cookies in various ways. Please keep in mind that removing or
        blocking cookies can negatively impact your user experience and parts of our website may no
        longer be fully accessible.
      </p>
      <p>
        Most browsers automatically accept cookies, but you can choose whether or not to accept
        cookies through your browser controls, often found in your browser’s “Tools” or
        “Preferences” menu. For more information on how to modify your browser settings or how to
        block, manage or filter cookies can be found in your browser’s help file or through such
        sites as&nbsp;
        <span>
          <a href="https://food-by-k.myshopify.com/admin/settings/www.allaboutcookies.org">
            www.allaboutcookies.org
          </a>
        </span>
        .
      </p>
      <p>
        Additionally, please note that blocking cookies may not completely prevent how we share
        information with third parties such as our advertising partners. To exercise your rights or
        opt-out of certain uses of your information by these parties, please follow the instructions
        in the “Behavioural Advertising” section above.
      </p>
      <p>
        <strong>Do Not Track</strong>
      </p>
      <p>
        Please note that because there is no consistent industry understanding of how to respond to
        “Do Not Track” signals, we do not alter our data collection and usage practices when we
        detect such a signal from your browser.
      </p>
      <p>
        <strong>Changes</strong>
      </p>
      <p>
        We may update this Privacy Policy from time to time in order to reflect, for example,
        changes to our practices or for other operational, legal
        <em>, or regulatory reasons.</em>
      </p>
      <p>
        <strong>Contact</strong>
      </p>
      <p>
        For more information about our privacy practices, if you have questions, or if you would
        like to make a complaint, please contact us by e-mail at help@brandless.kr or by mail using
        the details provided below:
      </p>
      <p>
        Brandless, 17, Achasan-ro, #1305 Seoul Forest L-Tower, Seongdong-gu, 04789, Seoul, Republic
        of Korea
      </p>
      <p>Last updated:&nbsp;[2021.11.01]</p>
      <p>
        If you are not satisfied with our response to your complaint, you have the right to lodge
        your complaint with the relevant data protection authority. You can contact your local data
        protection authority, or our supervisory authority here:&nbsp;https://www.pipc.go.kr/np/
      </p>
    </div>
  );
};
export const KoPrivacy = () => {
  return (
    <div className={styled['rte']}>
      <p>
        이 개인 정보 보호 정책은 귀하가 사이트를 방문하거나 사이트에서 구매할 때{' '}
        <span>
          <a href="http://mykmarket.com">
            http://mykmarket.com이 귀하의 개인 정보를 수집, 사용 및 공개하는 방법을 설명합니다.
          </a>
        </span>
      </p>
      <p>
        <strong>개인정보 수집</strong>
      </p>
      <p>
        귀하가 사이트를 방문할 때 당사는 귀하의 장치, 사이트와의 상호 작용 및 구매를 처리하는 데
        필요한 정보에 대한 특정 정보를 수집합니다. 고객 지원을 위해 당사에 문의하는 경우 추가 정보를
        수집할 수도 있습니다. 본 개인 정보 보호 정책에서는 개인을 고유하게 식별할 수 있는 모든
        정보(아래 정보 포함)를 "개인 정보"라고 합니다. 당사가 수집하는 개인 정보와 그 이유에 대한
        자세한 내용은 아래 목록을 참조하십시오.
      </p>
      <p>
        <u>장치 정보</u>
      </p>
      <ul>
        <li>
          <strong>수집되는 개인 정보의 예:</strong> &nbsp;웹 브라우저 버전, IP 주소, 시간대, 쿠키
          정보, 조회하는 사이트 또는 제품, 검색어, 사이트와 상호 작용하는 방식.
        </li>
        <li>
          <strong>수집 목적:</strong> &nbsp;사이트를 정확하게 로드하고 사이트 사용에 대한 분석을
          수행하여 사이트를 최적화합니다.
        </li>
        <li>
          <strong>수집 출처:</strong> &nbsp;쿠키, 로그 파일, 웹 비콘, 태그 또는 픽셀을 사용하여 당사
          사이트에 액세스할 때 자동으로 수집됩니다.&nbsp;
        </li>
        <li>
          <strong>비즈니스 목적을 위한 공개:</strong> &nbsp;프로세서 Shopify, Brandless Co.,Ltd.,
          MXN Commerce Korea, MXN Commerce USA Inc. 및 SIA Products LLC와 공유.
        </li>
      </ul>
      <p>
        <u>주문 정보</u>
      </p>
      <ul>
        <li>
          <strong>수집되는 개인 정보의 예:</strong> &nbsp;이름, 청구서 수신 주소, 배송 주소, 결제
          정보(신용 카드 번호, PayPal 계정 및 Amazon Pay 계정 포함), 이메일 주소 및 전화번호.
        </li>
        <li>
          <strong>수집 목적:</strong> &nbsp;계약 이행을 위해 제품 또는 서비스를 제공하고, 결제
          정보를 처리하고, 배송을 준비하고, 송장 및/또는 주문 확인서를 제공하고, 귀하와 연락하고,
          잠재적인 위험 또는 사기에 대한 주문을 선별하고, 귀하가 당사와 공유한 기본 설정에 따라 당사
          제품 또는 서비스와 관련된 정보 또는 광고를 귀하에게 제공합니다.
        </li>
        <li>
          <strong>수집 출처:</strong> &nbsp;귀하로부터 수집.
        </li>
        <li>
          <strong>비즈니스 목적을 위한 공개:</strong> &nbsp;프로세서와 공유 Shopify <em>,</em>{' '}
          Brandless Co., Ltd., MXN Commerce Korea, MXN Commerce USA Inc., SIA Products LLC.
        </li>
      </ul>
      <p>
        <u>고객 지원 정보</u>
      </p>
      <ul>
        <li>
          <strong>수집되는 개인 정보의 예:</strong> &nbsp;주문 번호 및 주문 정보.
        </li>
        <li>
          <strong>수집 목적:</strong> &nbsp;고객 지원 제공.
        </li>
        <li>
          <strong>수집 출처:</strong> &nbsp;귀하로부터 수집.
        </li>
        <li>
          <strong>사업목적공시 :</strong> &nbsp;Brandless Co., Ltd., MXN Commerce Korea, SIA
          Products LLC.
        </li>
      </ul>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>개인 정보 공유</strong>
      </p>
      <p>
        당사는 위에서 설명한 바와 같이 서비스를 제공하고 귀하와의 계약을 이행하는 데 도움이 되도록
        귀하의 개인 정보를 서비스 제공업체와 공유합니다. 예를 들어:
      </p>
      <ul>
        <li>
          우리는 Shopify를 사용하여 온라인 상점을 강화합니다.{' '}
          <a href="https://www.shopify.com/legal/privacy">
            Shopify가 귀하의 개인 정보를 사용하는 방법에 대한 자세한 내용은
            https://www.shopify.com/legal/privacy
          </a>{' '}
          에서 확인할 수 있습니다&nbsp; .
        </li>
        <li>
          당사는 해당 법률 및 규정을 준수하고, 소환장, 수색 영장 또는 기타 당사가 받은 정보에 대한
          합법적 요청에 응답하거나 당사의 권리를 보호하기 위해 귀하의 개인 정보를 공유할 수
          있습니다.
        </li>
      </ul>
      <p>
        <strong>행동 기반 광고</strong>
      </p>
      <p>
        위에서 설명한 바와 같이 당사는 귀하의 개인 정보를 사용하여 귀하가 관심을 가질 만한 타겟 광고
        또는 마케팅 커뮤니케이션을 제공합니다. 예를 들어:
      </p>
      <ul>
        <li>
          당사는 Google Analytics를 사용하여 고객이 사이트를 사용하는 방식을 이해하는 데 도움을
          줍니다.{' '}
          <a href="https://policies.google.com/privacy?hl=en">
            Google이 귀하의 개인 정보를 사용하는 방법에 대한 자세한 내용은
            https://policies.google.com/privacy?hl=en에서
          </a>{' '}
          확인할 수 있습니다&nbsp; . 또한 여기에서 Google Analytics를 거부할 수 있습니다.&nbsp;{' '}
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/ dlpage/gaoptout
          </a>{' '}
          .
        </li>
        <li>
          당사는 귀하의 사이트 사용, 구매 및 다른 웹사이트에서 당사 광고와의 상호 작용에 대한 정보를
          광고 파트너와 공유합니다. 당사는 이러한 정보 중 일부를 당사의 광고 파트너와 직접 수집하고
          공유하며 경우에 따라 쿠키 또는 기타 유사한 기술(귀하의 위치에 따라 동의할 수 있음)을
          사용하여 공유합니다.
        </li>
      </ul>
      <p>
        <span>
          <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
            대상 광고가 작동하는 방법에 대한 자세한 내용은 NAI(Network Advertising Initiative)의
            교육 페이지(
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
          </a>
        </span>{' '}
        )를 방문하십시오&nbsp; .
      </p>
      <p>다음을 통해 타겟 광고를 거부할 수 있습니다.</p>
      <ul>
        <li>
          페이스북 -&nbsp;{' '}
          <a href="https://www.facebook.com/settings/?tab=ads">
            https://www.facebook.com/settings/?tab=ads
          </a>
        </li>
        <li>
          GOOGLE -&nbsp;{' '}
          <a href="https://www.google.com/settings/ads/anonymous">
            https://www.google.com/settings/ads/anonymous
          </a>
        </li>
        <li>
          빙 -&nbsp;{' '}
          <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
            https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
          </a>{' '}
          ]
        </li>
      </ul>
      <p>
        또한 Digital Advertising Alliance의 옵트아웃 포털(&nbsp;{' '}
        <span>
          <a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>
        </span>{' '}
        )을 방문하여 이러한 서비스 중 일부를 옵트아웃할 수 있습니다 .
      </p>
      <p>
        <strong>개인 정보 사용</strong>
      </p>
      <p>
        당사는 귀하에게 서비스를 제공하기 위해 귀하의 개인 정보를 사용합니다. 여기에는 판매 제품
        제안, 결제 처리, 주문 배송 및 이행, 새로운 제품, 서비스 및 제안에 대한 최신 정보 유지 등이
        포함됩니다.
      </p>
      <p>
        <strong>
          <em>&nbsp;</em>
        </strong>
      </p>
      <p>
        <strong>개인 정보 판매</strong>
      </p>
      <p>
        당사 사이트는 2018년 캘리포니아 소비자 개인 정보 보호법("CCPA")에 정의된 개인 정보를
        판매합니다.
      </p>
      <ul>
        <li>판매된 정보의 범주</li>
        <li>판매 거부 방법에 대한 지침</li>
        <li>
          귀하의 비즈니스가 미성년자(16세 미만)의 정보를 판매하는지 여부 및 귀하가 긍정적인 승인을
          받았는지 여부
        </li>
        <li>
          정보를 판매하지 않도록 금전적 인센티브를 제공하는 경우 해당 인센티브가 무엇인지에 대한
          정보를 제공하십시오.
        </li>
      </ul>
      <p>
        <strong>CCPA</strong>
      </p>
      <p>
        귀하가 캘리포니아 거주자인 경우 귀하는 당사가 보유하고 있는 귀하의 개인 정보('알 권리'라고도
        함)에 액세스하고 이를 새로운 서비스로 이전하고 귀하의 개인 정보 수정을 요청할 권리가
        있습니다. , 업데이트 또는 삭제됨. 이러한 권리를 행사하고자 하는 경우 아래 연락처 정보를 통해
        문의하시기 바랍니다.&nbsp;
      </p>
      <p>귀하를 대신하여 이러한 요청을 제출할 공인 대리인을 지정하려면 아래 주소로 문의하십시오.</p>
      <p>
        <strong>쿠키</strong>
      </p>
      <p>
        쿠키는 귀하가 당사 사이트를 방문할 때 귀하의 컴퓨터나 장치에 다운로드되는 소량의 정보입니다.
        당사는 기능, 성능, 광고, 소셜 미디어 또는 콘텐츠 쿠키를 포함하여 다양한 쿠키를 사용합니다.
        쿠키는 웹사이트가 귀하의 작업 및 기본 설정(예: 로그인 및 지역 선택)을 기억할 수 있도록 하여
        귀하의 브라우징 경험을 개선합니다. 즉, 사이트로 돌아가거나 한 페이지에서 다른 페이지를
        탐색할 때마다 이 정보를 다시 입력할 필요가 없습니다. 쿠키는 또한 사람들이 웹사이트를 어떻게
        사용하는지, 예를 들어 처음 방문하는지 또는 자주 방문하는지에 대한 정보를 제공합니다.
      </p>
      <p>
        당사는 당사 사이트에서 귀하의 경험을 최적화하고 서비스를 제공하기 위해 다음 쿠키를
        사용합니다.
      </p>
      <p>
        <strong>스토어 기능에 필요한 쿠키</strong>
      </p>
      <table width="534">
        <thead>
          <tr>
            <td>
              <p>
                <strong>이름</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>기능</strong>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>_ab</p>
            </td>
            <td>
              <p>admin 접근과 관련하여 사용합니다.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_secure_session_id</p>
            </td>
            <td>
              <p>점포를 통한 탐색과 관련하여 사용됩니다.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>카트</p>
            </td>
            <td>
              <p>장바구니와 연동하여 사용합니다.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>cart_sig</p>
            </td>
            <td>
              <p>결제와 관련하여 사용됩니다.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>cart_ts</p>
            </td>
            <td>
              <p>결제와 관련하여 사용됩니다.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>checkout_token</p>
            </td>
            <td>
              <p>결제와 관련하여 사용됩니다.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>비밀</p>
            </td>
            <td>
              <p>결제와 관련하여 사용됩니다.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>secure_customer_sig</p>
            </td>
            <td>
              <p>고객 로그인과 연계하여 사용합니다.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>storefront_digest</p>
            </td>
            <td>
              <p>고객 로그인과 연계하여 사용합니다.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_shopify_u</p>
            </td>
            <td>
              <p>고객 계정 정보 업데이트를 용이하게 하는 데 사용됩니다.</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>보고 및 분석</strong>
      </p>
      <table width="534">
        <tbody>
          <tr>
            <td>
              <p>
                <strong>이름</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>기능</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_tracking_consent</p>
            </td>
            <td>
              <p>추적 기본 설정.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_방문 페이지</p>
            </td>
            <td>
              <p>방문 페이지 추적</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_orig_referrer</p>
            </td>
            <td>
              <p>방문 페이지 추적</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_에스</p>
            </td>
            <td>
              <p>Shopify 분석.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_shopify_fs</p>
            </td>
            <td>
              <p>Shopify 분석.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_shopify_s</p>
            </td>
            <td>
              <p>Shopify 분석.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_shopify_sa_p</p>
            </td>
            <td>
              <p>마케팅 및 추천과 관련된 Shopify 분석.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_shopify_sa_t</p>
            </td>
            <td>
              <p>마케팅 및 추천과 관련된 Shopify 분석.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_shopify_y</p>
            </td>
            <td>
              <p>Shopify 분석.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_와이</p>
            </td>
            <td>
              <p>Shopify 분석.</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        쿠키가 귀하의 컴퓨터나 모바일 장치에 남아 있는 기간은 "영구" 쿠키인지 "세션" 쿠키인지에 따라
        다릅니다. 세션 쿠키는 브라우징을 중지할 때까지 지속되며 영구 쿠키는 만료되거나 삭제될 때까지
        지속됩니다. 우리가 사용하는 대부분의 쿠키는 영구적이며 장치에 다운로드된 날로부터 30분에서
        2년 사이에 만료됩니다.
      </p>
      <p>
        귀하는 다양한 방법으로 쿠키를 통제하고 관리할 수 있습니다. 쿠키를 제거하거나 차단하면 사용자
        경험에 부정적인 영향을 미칠 수 있으며 당사 웹 사이트의 일부에 더 이상 완전히 액세스하지 못할
        수 있음을 명심하십시오.
      </p>
      <p>
        대부분의 브라우저는 자동으로 쿠키를 허용하지만 브라우저의 "도구" 또는 "기본 설정" 메뉴에
        있는 브라우저 컨트롤을 통해 쿠키 허용 여부를 선택할 수 있습니다.{' '}
        <span>
          <a href="https://food-by-k.myshopify.com/admin/settings/www.allaboutcookies.org">
            브라우저 설정을 수정하는 방법이나 쿠키를 차단, 관리 또는 필터링하는 방법에 대한 자세한
            내용은 브라우저의 도움말 파일이나 www.allaboutcookies.org
          </a>
        </span>{' '}
        와 같은 사이트를 통해 확인할 수 있습니다&nbsp; .
      </p>
      <p>
        또한 쿠키를 차단해도 광고 파트너와 같은 제3자와 정보를 공유하는 방식을 완전히 차단할 수는
        없습니다. 귀하의 권리를 행사하거나 이러한 당사자에 의한 귀하의 정보 사용을 거부하려면 위의
        "행동 광고" 섹션의 지침을 따르십시오.
      </p>
      <p>
        <strong>추적하지 않음</strong>
      </p>
      <p>
        "추적 금지" 신호에 대응하는 방법에 대한 일관된 업계 이해가 없기 때문에 당사는 귀하의
        브라우저에서 그러한 신호를 감지할 때 데이터 수집 및 사용 관행을 변경하지 않습니다.
      </p>
      <p>
        <strong>변경 사항</strong>
      </p>
      <p>
        <em>
          당사는 예를 들어 당사 관행의 변경 사항을 반영하기 위해 또는 기타 운영, 법적 또는 규제상의
          이유로
        </em>{' '}
        이 개인정보 보호정책을 수시로 업데이트할 수 있습니다 .<em></em>
      </p>
      <p>
        <strong>연락하다</strong>
      </p>
      <p>
        당사의 개인 정보 보호 관행에 대한 자세한 내용, 질문이 있거나 불만 사항을 제기하려면
        help@brandless.kr로 이메일을 보내거나 아래 제공된 세부 정보를 사용하여 우편으로
        문의하십시오.
      </p>
      <p>서울특별시 성동구 아차산로 17 서울숲 엘타워 1305호 브랜드리스</p>
      <p>최종 업데이트: [2021.11.01]</p>
      <p>
        불만 사항에 대한 당사의 응답이 만족스럽지 않은 경우 관련 데이터 보호 기관에 불만 사항을
        제기할 권리가 있습니다. https://www.pipc.go.kr/np/에서 현지 데이터 보호 기관 또는 감독
        기관에 문의할 수 있습니다.
      </p>
    </div>
  );
};

import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useRecoilValueLoadable } from 'recoil';
import { Customer, CustomerFromMkm } from '../../@types/customer';
import { ShipmentStatus, UserInfo } from '../../components/account';
import { Button } from '../../components/button';
import { AccountLayout } from '../../components/layout';
import { MobileNavItem } from '../../components/layout/BaseLayout';
import { useViewport } from '../../helper/hooks';
import { customerState } from '../../stores/customer/atoms';
import { logout } from '../../utils/logout';
import { MyOrders } from './order';
import styles from './styles/Account.module.scss';

type AccountProps = {
  customer: (Customer & CustomerFromMkm) | null;
};

const Account = ({ customer }: AccountProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <AccountLayout
      mobileNav={{
        type: MobileNavItem['SHOW_ALL'],
        title: t('account_title'),
      }}
    >
      <div className={styles['div']}>
        <UserInfo
          link={'/account/profile'}
          mainText={customer?.displayName}
          image={customer?.profileImageUrl || '/icons/account__profile-default.svg'}
          className={styles['user-info-profile']}
        />
        <UserInfo
          link="/account/my-points"
          mainText={t('account_user_info_point')}
          image="/icons/account__point.svg"
          count={customer?.points}
          className={styles['user-info-point']}
        />
        <UserInfo
          link="/account/invitations"
          mainText={
            <div>
              {t('account_user_info_refer')} <span>{t('account_user_info_refer_span')}</span>
            </div>
          }
          className={styles['user-info-refer']}
          options={{ borderShow: true }}
        />
        <UserInfo
          link="/account/orders"
          mainText={t('account_user_info_my_orders')}
          className={styles['user-info-my-orders']}
          options={{ borderShow: true }}
        />
        <ShipmentStatus />
        <UserInfo
          link="/account/wishlist"
          mainText={t('account_user_info_wish')}
          options={{ borderShow: true }}
          count={customer?.wishlist}
          image={'/icons/account__wish-list.svg'}
        />
        <UserInfo
          link="/account/coupons"
          mainText={t('account_user_info_coupon')}
          options={{ borderShow: true }}
          count={customer?.coupons}
          image={'/icons/account__coupons.svg'}
        />
        <UserInfo
          link="/account/reviews"
          mainText={t('account_user_info_review')}
          className={styles['account__post-review']}
          options={{ borderShow: true }}
          count={customer?.reviews}
          image={'/icons/account__post-review.svg'}
        />
        <UserInfo
          link="/account/help-center"
          mainText={t('account_user_info_help')}
          subText={t('account_user_info_help_sub')}
          options={{ borderShow: true }}
          image={'/icons/account__help-center.svg'}
        />
        <UserInfo
          link="/account/notifications"
          mainText={t('account_user_info_setting')}
          subText={t('account_user_info_setting_sub')}
          className={styles['account__settings']}
          options={{ borderShow: true }}
          image={'/icons/account__settings.svg'}
        />
        <UserInfo
          link="/account/language"
          mainText={t('account_user_info_language')}
          subText={t('account_user_info_language_sub')}
          className={styles['account__language']}
          options={{ borderShow: true }}
          image={'/icons/account__language.svg'}
        />
        <UserInfo
          link="/account/information"
          mainText={t('account_user_info_info')}
          options={{ borderShow: true }}
          image={'/icons/account__information.svg'}
        />
        <UserInfo
          link="/account/policy"
          mainText={t('account_user_info_policy')}
          className={styles['account__policy']}
          options={{ borderShow: true }}
          image="/icons/account__carbon_policy.svg"
        />
        <Button
          className={styles['account-logout']}
          action={() => {
            logout();
          }}
        >
          <span>{t('account_logout')}</span>
        </Button>
      </div>
    </AccountLayout>
  );
};

const AccountPage = () => {
  const initWidth = window.innerWidth;
  const [containerWidth, setContainerWidth] = useState<number>(initWidth);
  const { isMobile } = useViewport();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const customer = useRecoilValueLoadable(customerState);
  const exceptionPath = ['information', 'policy', 'partnership'];

  useEffect(() => {
    const { body } = document;

    function handleResize() {
      setContainerWidth(body.clientWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (customer.state !== 'hasValue') {
      return;
    }
    for (let i = 0; i < exceptionPath.length; i++) {
      if (pathname.includes(exceptionPath[i])) return;
    }
    if (!customer.contents) {
      window.location.replace('/login');
    }
  }, [customer, pathname]);

  return (
    <>
      {pathname !== '/account' ? (
        <Outlet />
      ) : isMobile ? (
        <Account customer={customer.contents} />
      ) : (
        <MyOrders />
      )}
    </>
  );
};

export default AccountPage;

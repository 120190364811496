import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CurationModule } from '../@types/curation';
import { getPromotionByLink } from '../api/backend/promotion';
import Banner, { BannerImageType } from '../components/banner/Banner';
import { BaseLayout } from '../components/layout';
import { MobileNavItem } from '../components/layout/BaseLayout';
import { ProductContainer } from '../components/products';
import { BANNER_COMMON_BG_STYLES, BANNER_COMMON_SLICK_STYLES } from '../constants/slick';
import { useViewport } from '../helper/hooks';
import styles from './styles/Promotion.module.scss';

const Promotion = (): ReactElement => {
  const { isMobile } = useViewport();
  const { id } = useParams();
  const navigate = useNavigate();

  const [topBannerImage, setTopBannerImage] = useState<{
    mobile: BannerImageType;
    desktop: BannerImageType;
  }>();
  const [moduleItems, setModuleItems] = useState<CurationModule[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const moduleItemsLength = moduleItems.length;

  const getPromotion = useCallback(async (id: string) => {
    const res = await getPromotionByLink(id);
    if (!res.data) {
      navigate('/');
    }
    const { topBanner, promotionItems } = res.data.promotion;
    setTopBannerImage({
      mobile: [
        {
          imageUrl: topBanner.image.mobileImage,
          curationTopBannerType: 'PROMOTION',
        },
      ],
      desktop: [
        {
          imageUrl: topBanner.image.desktopImage,
          curationTopBannerType: 'PROMOTION',
        },
      ],
    });
    setModuleItems(promotionItems);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!id) {
      navigate('/');
    } else {
      getPromotion(id);
    }
  }, []);
  return (
    <BaseLayout mobileNav={{ type: MobileNavItem.HOME_SEARCH, title: 'PROMOTION' }}>
      <div className={styles['main']}>
        <div className={styles['top-banner-image']}>
          <div
            style={{
              ...BANNER_COMMON_BG_STYLES,
              borderRadius: '8px',
              backgroundImage: `url(${
                isMobile ? topBannerImage?.mobile[0].imageUrl : topBannerImage?.desktop[0].imageUrl
              })`,
            }}
          />
        </div>

        {moduleItems?.map((moduleItem, idx) =>
          moduleItem.curationItemType === 'PRODUCT' ? (
            <ProductContainer
              key={moduleItem.id}
              item={moduleItem}
              options={{
                borderShow:
                  idx < moduleItemsLength - 1 &&
                  moduleItems[idx + 1].curationItemType === 'PRODUCT',
                infiniteScrollMode: moduleItem.type.text === '2xN',
                headerType: 'ONLY_TITLE',
              }}
              origin="promotion"
              originId={moduleItem.productId}
            />
          ) : (
            <Banner
              key={moduleItem.id}
              images={{
                mobileImages: (moduleItem.images ?? [])?.map((image) => ({
                  id: image.id,
                  imageUrl: image.mobileImage,
                  curationItemType: moduleItem.curationItemType,
                })),
                desktopImages: (moduleItem.images ?? [])?.map((image) => ({
                  id: image.id,
                  imageUrl: image.desktopImage,
                  curationItemType: moduleItem.curationItemType,
                })),
              }}
              type={moduleItem.curationItemType}
              options={{
                arrowDefaultShow: false,
                bothSideBlurShow: false,
                slickSettingStyles: {
                  ...BANNER_COMMON_SLICK_STYLES,
                  slidesToShow: (moduleItem.images ?? []).length > 1 && !isMobile ? 2 : 1,
                  slidesToScroll: (moduleItem.images ?? []).length > 1 && !isMobile ? 2 : 1,
                  responsive:
                    (moduleItem.images ?? []).length > 1
                      ? [
                          {
                            breakpoint: 1023,
                            settings: {
                              centerMode: true,
                              centerPadding: '12px',
                            },
                          },
                        ]
                      : [],
                },
              }}
            />
          ),
        )}
      </div>
    </BaseLayout>
  );
};

export default Promotion;

import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { CurationModule, CurationModuleSortingType, CurationProductType } from '../@types/curation';
import { useDrawer } from '../components/drawer/Drawer';
import { BaseLayout } from '../components/layout';
import { MobileNavItem } from '../components/layout/BaseLayout';
import { ProductContainer } from '../components/products';
import Select from '../components/select/Select';
import { useViewport } from '../helper/hooks';
import styles from './styles/BrandProducts.module.scss';

const BrandProducts = (): ReactElement => {
  const { vendor } = useParams();
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const { open, close, isOpened, render } = useDrawer();

  const title = decodeURIComponent(vendor ?? '');

  const [item, setItem] = useState<CurationModule>({
    id: -1,
    curationItemType: 'PRODUCT',
    title,
    amount: 0,
    type: {
      text: '2xN' as CurationProductType,
      row: Number.MAX_SAFE_INTEGER,
      column: 2,
    },
    sorting: 'BEST_SELLING',
    filters: [
      {
        id: -1,
        filterType: 'Brand',
        value1: title,
      },
    ],
  });
  const [productCounts, setProductCounts] = useState<number>(12);

  const onClickMobileFilter = (sortType: CurationModuleSortingType) => {
    setItem((prev) => ({ ...prev, sorting: sortType }));
    close();
  };

  const searchSortKeyMap = {
    BEST_SELLING: t('search_filter_best_selling'),
    NEWEST: t('search_filter_newest'),
    PRICE_LOW_TO_HIGH: t('search_filter_price_low_to_high'),
    PRICE_HIGH_TO_LOW: t('search_filter_price_high_to_low'),
  };

  const mobileFilterModal = render(
    <>
      <Select.Option
        label={t('search_filter_best_selling')}
        value="BEST_SELLING"
        isSelected={item.sorting === 'BEST_SELLING'}
        onClick={() => onClickMobileFilter('BEST_SELLING')}
      />
      <Select.Option
        label={t('search_filter_newest')}
        value="NEWEST"
        isSelected={item.sorting === 'NEWEST'}
        onClick={() => onClickMobileFilter('NEWEST')}
      />
      <Select.Option
        label={t('search_filter_price_low_to_high')}
        value="PRICE_LOW_TO_HIGH"
        isSelected={item.sorting === 'PRICE_LOW_TO_HIGH'}
        onClick={() => onClickMobileFilter('PRICE_LOW_TO_HIGH')}
      />
      <Select.Option
        label={t('search_filter_price_high_to_low')}
        value="PRICE_HIGH_TO_LOW"
        isSelected={item.sorting === 'PRICE_HIGH_TO_LOW'}
        onClick={() => onClickMobileFilter('PRICE_HIGH_TO_LOW')}
      />
    </>,
  );

  const desktopFilter = (
    <div className={styles['select__container']}>
      <Select
        value={item.sorting ?? 'BEST_SELLING'}
        setValue={(selectedSortKey) => {
          setItem((prev) => ({
            ...prev,
            sorting: selectedSortKey as CurationModuleSortingType,
          }));
        }}
        placeholder={searchSortKeyMap[item.sorting as CurationModuleSortingType]}
      >
        <Select.Option
          label={t('search_filter_best_selling')}
          value="BEST_SELLING"
          isSelected={item.sorting === 'BEST_SELLING'}
        />
        <Select.Option
          label={t('search_filter_newest')}
          value="NEWEST"
          isSelected={item.sorting === 'NEWEST'}
          onClick={() => onClickMobileFilter('NEWEST')}
        />
        <Select.Option
          label={t('search_filter_price_low_to_high')}
          value="PRICE_LOW_TO_HIGH"
          isSelected={item.sorting === 'PRICE_LOW_TO_HIGH'}
        />
        <Select.Option
          label={t('search_filter_price_high_to_low')}
          value="PRICE_HIGH_TO_LOW"
          isSelected={item.sorting === 'PRICE_HIGH_TO_LOW'}
        />
      </Select>
    </div>
  );

  return (
    <BaseLayout desktopNav="CATEGORY" mobileNav={{ type: MobileNavItem.TITLE_CART, title }}>
      <>
        <div className={styles['result']}>
          {isMobile ? (
            <div className={styles['result__header']}>
              <div className={styles['result__header__count']}>
                <span>{productCounts}</span> results found
              </div>
              <img src="/icons/search__filter.svg" alt="검색 결과 필터링" onClick={open} />
            </div>
          ) : (
            desktopFilter
          )}

          <ProductContainer
            key={vendor}
            item={item}
            options={{
              borderShow: false,
              infiniteScrollMode: true,
              headerType: 'ONLY_TITLE',
            }}
            productCounts={productCounts}
            setProductCounts={setProductCounts}
          />
          {isOpened && mobileFilterModal}
        </div>
      </>
    </BaseLayout>
  );
};

export default BrandProducts;

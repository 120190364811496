import { gql } from '@apollo/client';
import client from '../client';
import { CustomerRecoverResponse, CustomerUpdateResponse } from '../../@types/response';
import {
  AccessTokenResponse,
  CustomerResponse,
  CustomerCreateResponse,
} from '../../@types/response';
import { CustomerUpdateInput } from '../../@types/customer';

/**
 * Get customer information
 * @param customerAccessToken token
 * @returns customer information
 */
export async function getCustomer() {
  const customerAccessToken = localStorage.getItem('access-token') || '';

  const query = gql`
    query customer($customerAccessToken: String!) {
      customer(customerAccessToken: $customerAccessToken) {
        id
        acceptsMarketing
        displayName
        createdAt
        updatedAt
        firstName
        lastName
        email
        phone
      }
    }
  `;

  return await client.query<CustomerResponse>({
    query,
    variables: {
      customerAccessToken,
    },
  });
}

/**
 * create new user
 * @param email
 * @param password
 * @returns
 */
export async function customerCreate(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  phone: string,
) {
  const query = gql`
    mutation customerCreate($input: CustomerCreateInput!) {
      customerCreate(input: $input) {
        customerUserErrors {
          code
          field
          message
        }
        customer {
          id
        }
      }
    }
  `;

  return await client.mutate<CustomerCreateResponse>({
    mutation: query,
    variables: {
      input: {
        email,
        password,
        firstName,
        lastName,
        phone,
      },
    },
  });
}

/**
 * login user
 * @param email
 * @param password
 * @returns
 */
export async function customerAccessTokenCreate(email: string, password: string) {
  const query = gql`
    mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
      customerAccessTokenCreate(input: $input) {
        customerUserErrors {
          code
          field
          message
        }
        customerAccessToken {
          accessToken
          expiresAt
        }
      }
    }
  `;

  const response = await client.mutate<AccessTokenResponse>({
    mutation: query,
    variables: {
      input: {
        email: email,
        password: password,
      },
    },
  });

  return response;
}

/**
 * recover password
 * @param email
 * @returns
 */
export async function customerRecover(email: string) {
  const query = gql`
    mutation customerRecover($email: String!) {
      customerRecover(email: $email) {
        customerUserErrors {
          code
          field
          message
        }
      }
    }
  `;

  return await client.mutate<CustomerRecoverResponse>({
    mutation: query,
    variables: {
      email,
    },
  });
}

export async function customerUpdate(customer: CustomerUpdateInput) {
  const customerAccessToken = localStorage.getItem('access-token');

  const query = gql`
    mutation customerUpdate($customer: CustomerUpdateInput!, $customerAccessToken: String!) {
      customerUpdate(customer: $customer, customerAccessToken: $customerAccessToken) {
        customer {
          id
          acceptsMarketing
          firstName
          lastName
          phone
        }
        customerAccessToken {
          accessToken
          expiresAt
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }
  `;

  const response = await client.mutate<CustomerUpdateResponse>({
    mutation: query,
    variables: {
      customer,
      customerAccessToken,
    },
  });

  return response;
}

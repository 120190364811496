import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { CartItem } from '../@types/cart';
import { ProductType } from '../@types/product';
import { ProductsByIdsResponse } from '../@types/response';
import { deleteCartItem, getCart, postCartItem, updateCartItemStatus } from '../api/backend/cart';
import { GET_PRODUCTS_BY_PRODUCT_IDS_QUERY } from '../api/storefront/products';
import { cartState } from '../stores/cart/atoms';
import { customerState } from '../stores/customer/atoms';

const useCart = () => {
  const [cartItems, setCartItems] = useRecoilState(cartState);
  const customer = useRecoilValueLoadable(customerState);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [ids, setIds] = useState<string[]>([]);

  const { data, loading } = useQuery<ProductsByIdsResponse>(GET_PRODUCTS_BY_PRODUCT_IDS_QUERY, {
    variables: { ids },
    skip: ids.length < 1,
  });

  const getCartRequest = async () => {
    const res = await getCart();

    const { items } = res.data.data;

    setCartItems(items);
  };

  const add = async (
    shopifyId: string,
    origin?: 'curation' | 'promotion' | 'search',
    originId?: number,
  ) => {
    const item: CartItem = {
      id: -1,
      active: true,
      quantity: 1,
      origin: origin ?? '',
      product: { shopifyId },
    };

    if (!customer.contents) {
      setCartItems((prev) => [...prev, item]);
      return item;
    }

    const returnedItem = await postCartItem(shopifyId, origin, originId);

    setCartItems((prev) => [...prev, returnedItem]);
    return returnedItem;
  };

  const update = async (shopifyId: string, quantity: number) => {
    const itemIdx = cartItems.findIndex((item) => item.product.shopifyId === shopifyId);

    if (itemIdx < 0) {
      return;
    }

    if (quantity < 0 && cartItems[itemIdx].quantity <= 1) {
      remove([shopifyId]);
      return;
    }

    setCartItems((prev) =>
      prev.map((item, idx) =>
        idx === itemIdx ? { ...item, quantity: item.quantity + quantity } : item,
      ),
    );
  };

  const remove = async (shopifyId: string[]) => {
    const [removed, unremoved] = cartItems.reduce<CartItem[][]>(
      (prev, curr) => {
        if (shopifyId.includes(curr.product.shopifyId)) {
          prev[0].push(curr);
          return prev;
        }

        prev[1].push(curr);
        return prev;
      },
      [[], []],
    );

    setCartItems(unremoved);

    if (!customer.contents) {
      return;
    }

    await deleteCartItem(removed.map((item) => item.id));
  };

  const has = useCallback(
    (shopifyId: string) => {
      return !!cartItems.find((item) => item.product.shopifyId === shopifyId);
    },
    [cartItems],
  );

  const getQuantity = (shopifyId: string) => {
    const item = cartItems.find((item) => item.product.shopifyId === shopifyId);

    if (!item) {
      return 0;
    }

    return item.quantity;
  };

  const getItem = useCallback(
    (shopifyId: string) => {
      return cartItems.find((item) => item.product.shopifyId === shopifyId);
    },
    [cartItems],
  );

  const handleToggle = async (shopifyId: string, checked?: boolean) => {
    const itemIdx = cartItems.findIndex((item) => item.product.shopifyId === shopifyId);

    if (itemIdx < 0) {
      return;
    }

    const active = typeof checked === 'undefined' ? !cartItems[itemIdx].active : checked;

    setCartItems((prev) => prev.map((item, idx) => (idx === itemIdx ? { ...item, active } : item)));

    if (!customer.contents) {
      return;
    }

    await updateCartItemStatus([{ id: cartItems[itemIdx].id, active }]);
  };

  const handleToggleAll = async (active: boolean) => {
    setCartItems((prev) => prev.map((item) => ({ ...item, active })));

    if (!customer.contents) {
      return;
    }

    await updateCartItemStatus(cartItems.map((item) => ({ id: item.id, active })));
  };

  const getTotalPriceActive = useMemo(() => {
    return products.reduce((prev, curr) => {
      const item = cartItems.find((item) => item.product.shopifyId === curr.id);

      if (!item || !item.active) {
        return prev;
      }

      const price =
        Number(curr.priceRange.maxVariantPrice) > Number(curr.priceRange.minVariantPrice)
          ? Number(curr.priceRange.minVariantPrice.amount) * item.quantity
          : Number(curr.priceRange.maxVariantPrice.amount) * item.quantity;
      return prev + price;
    }, 0);
  }, [cartItems, products]);

  const getTotal = useMemo(() => {
    return cartItems.length;
  }, [cartItems]);

  const getItemsTotal = useMemo(() => {
    return cartItems.reduce((a, item) => a + item.quantity, 0);
  }, [cartItems]);

  const cartItemsWithProduct = useMemo(() => {
    return cartItems.map((item) => ({
      ...item,
      product: products.find((product) => product.id === item.product.shopifyId),
    }));
  }, [cartItems, products]);

  useEffect(() => {
    setIds(cartItems.map((item) => item.product.shopifyId));
  }, [cartItems]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setProducts(data.nodes);
  }, [data]);

  return {
    getCartRequest,
    add,
    update,
    remove,
    has,
    getQuantity,
    getItem,
    handleToggle,
    handleToggleAll,
    getTotalPriceActive,
    getTotal,
    getItemsTotal,
    cartItems,
    products,
    cartItemsWithProduct,
    loading,
  };
};

export default useCart;

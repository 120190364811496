import { useTranslation } from 'react-i18next';
import { ProductType } from '../../@types/product';
import Empty from '../empty/Empty';
import { Product } from '../products';
import styles from './styles/CategoryProducts.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { customerState } from '../../stores/customer/atoms';
import { useRecoilValueLoadable } from 'recoil';

type CategoryProductsProps = {
  products: ProductType[];
  categoryPath: JSX.Element;
  originId?: number;
};

const CategoryProducts = ({ products, categoryPath, originId }: CategoryProductsProps) => {
  const { t } = useTranslation();
  const dummyArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const { pathname } = useLocation();
  const go = useNavigate();
  const customer = useRecoilValueLoadable(customerState);
  const onClickEmpty = () => {
    if (customer.contents) {
      go('/account/help-center/request');
      return;
    }
    go('/login');
  };

  return (
    <div
      className={`${styles['category__products']} ${
        products.length == 0 ? styles['category__empty'] : ''
      }`}
    >
      <div className={styles['category__products__title']}>{categoryPath}</div>
      {products.length > 0 ? (
        <div className={styles['category__products__list']}>
          {products.map((product) => (
            <Product item={product} key={product.id} origin="search" originId={originId} />
          ))}
          {dummyArr.map((v, i) => (
            <div key={`${v}--${i}`} />
          ))}
        </div>
      ) : (
        <div>
          {pathname.includes('/search') ? (
            <Empty
              message={t('search_empty')}
              buttonMessage={t('search_empty_button')}
              onClickEmpty={onClickEmpty}
            />
          ) : (
            <Empty message={t('category_empty')} />
          )}
        </div>
      )}
    </div>
  );
};

export default CategoryProducts;

import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router';
import { MailingAddress } from '../../@types/customer';
import BorderSeparator from '../../components/border/BorderSeparator';
import { Button } from '../../components/button';
import { TShippingInformation } from '../../components/checkout/templates';
import { BaseLayout } from '../../components/layout';
import { MobileNavItem } from '../../components/layout/BaseLayout';
import Home from '../../components/navbar/atoms/Home';
import { ProductContainer } from '../../components/products';
import { useViewport } from '../../helper/hooks';
import { useModularization } from '../../hooks/useModularization';
import styles from './styles/CheckoutCompleted.module.scss';

const CheckoutCompleted = (): ReactElement => {
  // TODO: Get order information by API
  // To do : 다국어
  const orderDate = new Date();
  const orderNo = 202209162348291;
  // TODO: Add used coupon data
  // const coupon = couponData[0];
  const card = 'Card Name 1 / Lump-sum payment';
  const [selectedShippingInfo, setSelectedShippingInfo] = useState<MailingAddress>();
  const navigate = useNavigate();
  const { width, isMobile } = useViewport();

  const { moduleItems } = useModularization({ type: 'checkoutCompleted' });

  return (
    <>
      <BaseLayout
        mobileNav={{ type: MobileNavItem.ONLY_TITLE, title: 'ORDER COMPLETED' }}
        desktopNav={'ONLY_TITLE'}
        customChildren={<Home />}
        customActions={{
          onHome: () => {
            navigate('/');
          },
        }}
      >
        {!isMobile && (
          <div className={styles['desktop-header2']}>
            <h2>Order Completed</h2>
          </div>
        )}
        <div className={styles['order-completed-smile-container']}>
          <div>
            <img src="/icons/checkout__smile.svg" alt="스마일" />
            <strong>Your order has been completed.</strong>
          </div>
        </div>
        <div className={styles['header']}>
          <div className={styles['header__date']}>Order | {orderDate.toLocaleDateString()}</div>
          <div className={styles['header__no']}>
            <span>Order No. </span>
            <span>{orderNo}</span>
          </div>
        </div>
        <div className={styles['order-completed-payment-info']}>
          {/* <TPurchaseSummary type="Checkout-Completed" coupon={coupon} card={card} /> */}
          <TShippingInformation
            selectedShippingInfo={selectedShippingInfo}
            setSelectedShippingInfo={setSelectedShippingInfo}
            type="Checkout-Completed"
          />
        </div>
        {!isMobile && (
          <>
            <div className={styles['checkout-completed-desktop-buttons']}>
              <Button
                className={styles['secondary']}
                action={() => {
                  navigate(`/account/orders/${orderNo}`);
                }}
              >
                ORDER DETAILS
              </Button>
              <Button
                className={styles['primary']}
                action={() => {
                  navigate('/');
                }}
              >
                GO TO HOME
              </Button>
            </div>
            <div className={styles['checkout-completed-border-separator-container']}>
              <BorderSeparator />
            </div>
          </>
        )}
        {moduleItems?.map((moduleItem) => (
          <ProductContainer
            key={moduleItem.id}
            item={moduleItem}
            options={{
              borderShow: true,
              infiniteScrollMode: moduleItem.type.text === '2xN',
              originName: 'checkout-completed',
            }}
          />
        ))}
      </BaseLayout>
    </>
  );
};

export default CheckoutCompleted;

import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CouponDataState, OpenHandler } from '../../../@types/checkout';
import useCart from '../../../hooks/useCart';
import BorderSeparator from '../../border/BorderSeparator';
import CheckoutItem from '../CheckoutItem';
import styles from './CheckoutTemplates.module.scss';

type TPurchaseSummaryType = Pick<Partial<OpenHandler>, 'setIsOpenStatusObject'> &
  Pick<Partial<CouponDataState>, 'coupon'> & {
    type?: 'Checkout' | 'Checkout-Completed';
  } & {
    shippingRate?: number;
    card?: string;
  };

type ExtraPriceType = 'taxes' | 'shipping' | 'pointDeduction';

export type ExtraPriceObject = {
  [key in ExtraPriceType]: number;
};

const TPurchaseSummary = ({
  coupon,
  card,
  setIsOpenStatusObject,
  type = 'Checkout',
  shippingRate = 0,
}: TPurchaseSummaryType): ReactElement => {
  const { t } = useTranslation();
  const cart = useCart();

  const cartItems = cart.cartItemsWithProduct;

  const totalPrice = cart.getTotalPriceActive;
  const [extraPriceObject, setExtraPriceObject] = useState<ExtraPriceObject>({
    taxes: 0,
    shipping: 0,
    pointDeduction: 0,
  });
  const total = totalPrice + extraPriceObject.taxes + extraPriceObject.shipping;
  const final = total - extraPriceObject.pointDeduction;
  const checkedCartItems = cartItems.filter(
    (item) => item.active && item.product?.availableForSale,
  );
  const totalQuantity = checkedCartItems.reduce((a, c) => {
    return a + c.quantity;
  }, 0);

  useEffect(() => {
    setExtraPriceObject((prev) => ({
      ...prev,
      shipping: shippingRate,
    }));
    if (coupon && coupon.amount > 0) {
      setExtraPriceObject((prev) => ({
        ...prev,
        pointDeduction: coupon.amount,
      }));
    } else {
      setExtraPriceObject((prev) => ({
        ...prev,
        pointDeduction: 0,
      }));
    }
  }, [totalPrice, coupon]);

  return (
    <CheckoutItem
      title={type === 'Checkout' ? t('checkout_purcahse') : t('checkout_purcahse_payment')}
      className={styles['purchase-summary-custom']}
      element={
        <>
          <div>
            <h4>{t('checkout_purcahse_subtotal', { items: totalQuantity })}</h4>
            <span>${totalPrice.toFixed(2)}</span>
          </div>
          <div
            onClick={() => {
              if (setIsOpenStatusObject) {
                setIsOpenStatusObject((prev) => ({
                  ...prev,
                  purchaseSummary: true,
                }));
              }
            }}
          >
            <div>
              {checkedCartItems.slice(0, 4).map((item) => (
                <div key={item.product?.id}>
                  <img src={item.product?.featuredImage?.url} alt="장바구니 상품" />
                </div>
              ))}
              {checkedCartItems.length > 4 && (
                <div>
                  <span>+{checkedCartItems.length - 4}</span>
                </div>
              )}
            </div>
            <img src={'/icons/checkout__chevron-right.svg'} alt="오른쪽 화살표" />
          </div>
          <div>
            <BorderSeparator className={'no-margin-top-and-height-1px'} />
          </div>
          <div>
            <h4>{t('checkout_purcahse_taxes')}</h4>
            <span>${extraPriceObject.taxes.toFixed(2)}</span>
          </div>
          <div>
            <h4>{t('checkout_purcahse_shipping')}</h4>
            <span>${extraPriceObject.shipping.toFixed(2)}</span>
          </div>
          <div>
            <h4>{t('checkout_purcahse_total')}</h4>
            <span>${totalPrice.toFixed(2)}</span>
          </div>
          <div>
            <h4>{t('checkout_purcahse_point')}</h4>
            <span className={styles['point-deduction-text']}>
              -${extraPriceObject.pointDeduction.toFixed(2)}
            </span>
          </div>
          <div>
            <BorderSeparator className={'no-margin-top-and-height-1px'} />
          </div>
          {type === 'Checkout-Completed' && (
            <div>
              <h4>{card}</h4>
              <span>${final < 0 ? (0).toFixed(2) : final.toFixed(2)}</span>
            </div>
          )}
          <div>
            <h4>{t('checkout_purcahse_amount')}</h4>
            <span>${final < 0 ? (0).toFixed(2) : final.toFixed(2)}</span>
          </div>
        </>
      }
    />
  );
};

export default TPurchaseSummary;

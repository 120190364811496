import { ReactElement } from 'react';
import { Coupon } from '../../../@types/coupon';
import CouponItem from './atoms/CouponItem';
import Empty from '../../empty/Empty';
import { useTranslation } from 'react-i18next';
import styles from './CouponContainer.module.scss';

type CouponContainerType = {
  data: Array<Coupon>;
};

const CouponContainer = ({ data }: CouponContainerType): ReactElement => {
  const { t } = useTranslation();
  return (
    <>
      {data.length > 0 ? (
        data.map((coupon) => <CouponItem key={coupon.code} data={coupon} />)
      ) : (
        <div className={styles['container--empty']}>
          <Empty message={t('account_coupon_empty_message')} isProduct={false} className='empty_coupon'/>
        </div>
      )}
    </>
  );
};

export default CouponContainer;

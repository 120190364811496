export function setCookie(name: string, value: string, expired: number) {
  document.cookie = `${name}=${value};max-age=${expired}`;
}

export function getCookieByKey(name: string) {
  const cookie = document.cookie.split('; ').find((value) => value.split('=')[0] === name);

  if (!cookie) {
    return;
  }

  const [, value] = cookie.split('=');

  return value;
}

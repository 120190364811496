import { ReactElement } from 'react';
import { BaseLayout } from '../components/layout';
import { MobileNavItem } from '../components/layout/BaseLayout';
import LoginContainer from '../components/login/LoginContainer';

const Login = (): ReactElement => {
  return (
    <BaseLayout
      className="login"
      mobileNav={{ type: MobileNavItem.ONLY_LOGO }}
      desktopNav="ONLY_TITLE"
    >
      <LoginContainer />
    </BaseLayout>
  );
};

export default Login;

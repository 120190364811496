import { useEffect, useState } from 'react';
import { SearchKeyword } from '../@types/search';

const useSearchHistories = () => {
  const [histories, setHistories] = useState<SearchKeyword[]>([]);

  const addHistory = (keyword: string) => {
    if (!histories.map(({ keyword }) => keyword).includes(keyword)) {
      localStorage.setItem(
        'histories',
        JSON.stringify([
          { id: Math.max(1, ...histories.map(({ id }) => id + 1)), keyword },
          ...histories.slice(0, 4),
        ]),
      );
    }
  };

  const removeHistory = (keyword: string) => {
    const curr = histories.filter(({ keyword: prev }) => prev !== keyword);

    localStorage.setItem('histories', JSON.stringify(curr));

    setHistories(curr);
  };

  const clearHistories = () => {
    localStorage.setItem('histories', JSON.stringify([]));
    setHistories([]);
  };

  useEffect(() => {
    const historiesInLocal = JSON.parse(localStorage.getItem('histories') || '[]');

    setHistories(historiesInLocal);
  }, []);

  return { addHistory, removeHistory, clearHistories, histories };
};

export default useSearchHistories;

import { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  ClickedCouponState,
  CouponDataState,
  PaymentMethodState,
  SelectedCouponState,
  ShippingInfoState,
} from '../../@types/checkout';
import AddressBookAdd from '../account/address-book/delivery-info-add/AddressBookAdd';
import AddressBookList from '../account/address-book/delivery-info/AddressBookList';
import BorderSeparator from '../border/BorderSeparator';
import FormButton from '../form/atoms/FormButton';
import CheckoutItem from './CheckoutItem';
import { AddNewCard, CouponApply } from './overlays';
import { INITIAL_CARD_INFO, NewCardObjectType } from './overlays/AddNewCard';
import { TCoupons, TShippingInformation } from './templates';
import { ExtraPriceObject } from './templates/TPurchaseSummary';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { CartItemWithProduct } from '../../@types/cart';
import useCart from '../../hooks/useCart';
import { INITIAL_MODAL_OBJECT, modalState } from '../../stores/modal/atoms';
import styles from './CheckoutDesktop.module.scss';

type CheckoutDesktopType = ShippingInfoState &
  CouponDataState &
  SelectedCouponState &
  ClickedCouponState &
  PaymentMethodState & {
    onClickPurchaseButton: () => void;
    shippingRate: number;
    hasCustomer: boolean;
    isCheckoutLoading?: boolean;
  };

const CheckoutDesktop = ({
  selectedShippingInfo,
  setSelectedShippingInfo,
  coupon,
  setCoupon,
  selectedCoupon,
  setSelectedCoupon,
  clickedCouponType,
  setClickedCouponType,
  // paymentMethod,
  // setPaymentMethod,
  shippingInfoArray,
  handleChangeShippingInfo,
  openAddressForm,
  addressType,
  setAddressType,
  onClickPurchaseButton,
  shippingRate,
  hasCustomer,
  isCheckoutLoading,
}: CheckoutDesktopType) => {
  // TODO: Check for hasAddress
  const { t } = useTranslation();
  const cart = useCart();
  const cartItems = cart.cartItems;
  const cartItemsWithProduct = cart.cartItemsWithProduct;
  const totalPrice = cart.getTotalPriceActive;
  const [extraPriceObject, setExtraPriceObject] = useState<ExtraPriceObject>({
    taxes: 0,
    shipping: 0,
    pointDeduction: 0,
  });
  const [modal, setModal] = useRecoilState(modalState);
  const total = totalPrice + extraPriceObject.taxes + extraPriceObject.shipping;
  const final = total - extraPriceObject.pointDeduction;
  const checkedCartItem = cartItemsWithProduct.filter(
    (item) => item.active && item.product?.availableForSale,
  );
  const totalQuantity = checkedCartItem.reduce((a, c) => {
    return a + c.quantity;
  }, 0);
  const navigate = useNavigate();
  const onClickProduct = (item: CartItemWithProduct) => {
    if (!item.product) {
      return;
    }

    navigate(`/product/${encodeURIComponent(item.product.id)}`);
  };

  const [hasAddress, setHasAddress] = useState<boolean>(false);

  const shippingInformationElement = useMemo(() => {
    if (hasAddress && addressType === 'NONE') {
      return (
        <AddressBookList
          shippingInfoArray={shippingInfoArray}
          selectedShippingInfo={selectedShippingInfo}
          setSelectedShippingInfo={setSelectedShippingInfo}
          openAddressForm={openAddressForm}
          type="Checkout Desktop"
        />
      );
    } else {
      return (
        <AddressBookAdd
          addressType={addressType}
          shippingInfoArray={shippingInfoArray}
          selectedShippingInfo={selectedShippingInfo}
          setSelectedShippingInfo={setSelectedShippingInfo}
          handleChangeShippingInfo={handleChangeShippingInfo}
          openAddressForm={openAddressForm}
        />
      );
    }
  }, [hasAddress, shippingInfoArray, addressType, selectedShippingInfo]);

  useEffect(() => {
    if (shippingInfoArray.length > 0) {
      setHasAddress(true);
      return;
    }

    setAddressType('ADD');
  }, [shippingInfoArray]);

  const purchaseItemsElement = useMemo(
    () => (
      <div className={styles['purchase-info-container']}>
        {checkedCartItem.map((item) => (
          <div className={styles['purchase-info__item']} key={item.product?.id}>
            <div onClick={() => onClickProduct(item)}>
              <img src={item.product?.featuredImage?.url} alt={item.product?.title} />
            </div>
            <div>
              <p onClick={() => onClickProduct(item)}>{item.product?.title}</p>
              <span>
                {Number(item.product?.priceRange.maxVariantPrice.amount) >
                Number(item.product?.priceRange.minVariantPrice.amount)
                  ? `$${item.product?.priceRange.minVariantPrice.amount}`
                  : `$${item.product?.priceRange.maxVariantPrice.amount}`}
              </span>
            </div>
            <span>{item.quantity}</span>
            <span>
              {Number(item.product?.priceRange.maxVariantPrice.amount) >
              Number(item.product?.priceRange.minVariantPrice.amount)
                ? `$${(
                    Number(item.product?.priceRange.minVariantPrice.amount) * item.quantity
                  ).toFixed(2)}`
                : `$${(
                    Number(item.product?.priceRange.maxVariantPrice.amount) * item.quantity
                  ).toFixed(2)}`}
            </span>
          </div>
        ))}
      </div>
    ),
    [cartItemsWithProduct],
  );

  const modalCoupon = () => {
    setModal({
      modal: true,
      headerText: t('checkout_coupon_apply_title'),
      children: (
        <section className={styles['checkout-desktop-coupon-apply']}>
          <CouponApply
            coupon={coupon}
            setCoupon={setCoupon}
            selectedCoupon={selectedCoupon}
            setSelectedCoupon={setSelectedCoupon}
            clickedCouponType={clickedCouponType}
            setClickedCouponType={setClickedCouponType}
          />
        </section>
      ),
      buttons: [
        {
          text: t('checkout_coupon_modal_cancel'),
          type: 'Secondary',
        },
        {
          text: t('checkout_coupon_modal_save'),
          type: 'Primary',
          action: () => {
            setCoupon(selectedCoupon);
          },
        },
      ],
    });
  };

  const modalAdd = () => {
    setModal({
      modal: true,
      headerText: t('checkout_coupon_modal_add'),
      children: (
        <section className={styles['checkout-desktop-add-new-card']}>
          <AddNewCard currentCard={currentCard} setCurrentCard={setCurrentCard} />
        </section>
      ),
      buttons: [
        {
          text: t('checkout_coupon_modal_add_cancel'),
          type: 'Secondary',
        },
        {
          text: t('checkout_coupon_modal_add_save'),
          type: 'Primary',
          action: () => {
            if (currentCard) {
              setModal(INITIAL_MODAL_OBJECT);
            }
          },
        },
      ],
    });
  };

  const [currentCard, setCurrentCard] = useState<NewCardObjectType>(INITIAL_CARD_INFO);

  useEffect(() => {
    setExtraPriceObject((prev) => ({
      ...prev,
      shipping: shippingRate,
    }));
    if (coupon && coupon.amount > 0) {
      setExtraPriceObject((prev) => ({
        ...prev,
        pointDeduction: coupon.amount,
      }));
    } else {
      setExtraPriceObject((prev) => ({
        ...prev,
        pointDeduction: 0,
      }));
    }
  }, [totalPrice, coupon]);

  useEffect(() => {
    if (modal?.modal) {
      modalCoupon();
    }
  }, [coupon, clickedCouponType, selectedCoupon]);

  useEffect(() => {
    const isCartItemsQuantityAbusing = cartItems.some(
      (cartItem) =>
        cartItem.quantity < cartItem.product.min! || cartItem.quantity > cartItem.product.max!,
    );
    if (isCartItemsQuantityAbusing) {
      navigate('/cart');
    }
  }, []);

  return (
    <div className={styles['checkout-desktop-bg']}>
      <div className={styles['checkout-desktop-container']}>
        <div className={styles['checkout-item-container']}>
          <div className={`${styles['checkout-item__left-inner-container']}`}>
            {hasCustomer && (
              <TShippingInformation
                selectedShippingInfo={selectedShippingInfo}
                setSelectedShippingInfo={setSelectedShippingInfo}
                accordionElement={shippingInformationElement}
                type="Checkout Desktop"
              />
            )}
            <div className={styles['checkout__alert']}>{t('checkout_carrier_alert')}</div>
            {/* <TCarrierInformation shippingRate={shippingRate} hasCustomer={hasCustomer} /> */}
            {/* <TPaymentMethod
              paymentMethod={paymentMethod}
              accordionElement={
                <PaymentMethod
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                  onClickAddNewCreditCardModal={onClickAddNewCreditCardModal}
                />
              }
            /> */}
            <TCoupons
              coupon={coupon}
              setCoupon={setCoupon}
              type="Checkout Desktop"
              onClickCouponModal={modalCoupon}
              setSelectedCoupon={setSelectedCoupon}
            />
            <CheckoutItem
              title={t('checkout_item', { items: totalQuantity })}
              className={styles['purchase-summary-custom']}
              element={
                <>
                  <div>
                    <div>
                      {checkedCartItem.slice(0, 4).map((item) => (
                        <div key={item.product?.id}>
                          <img src={item.product?.featuredImage?.url} alt="장바구니 상품" />
                        </div>
                      ))}
                      {checkedCartItem.length > 4 && (
                        <div>
                          <span>+{checkedCartItem.length - 4}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              }
              accordionArrow={true}
              accordionElement={purchaseItemsElement}
            />
          </div>
          <div className={styles['checkout-item__right-inner-container']}>
            <FormButton
              contents={t('checkout_purcahse_button')}
              onClick={onClickPurchaseButton}
              disabled={isCheckoutLoading}
            />
            <CheckoutItem
              className={styles['purchase-summary-custom']}
              element={
                <>
                  <div>
                    <BorderSeparator className={'no-margin-top-and-height-1px'} />
                  </div>
                  <h3>{t('checkout_purcahse')}</h3>
                  <div>
                    <h4>{t('checkout_purcahse_taxes')}</h4>
                    <span>${extraPriceObject.taxes.toFixed(2)}</span>
                  </div>
                  <div>
                    <h4>{t('checkout_purcahse_shipping')}</h4>
                    <span>${extraPriceObject.shipping.toFixed(2)}</span>
                  </div>
                  <div>
                    <h4>{t('checkout_purcahse_total')}</h4>
                    <span>${totalPrice.toFixed(2)}</span>
                  </div>
                  <div>
                    <h4>{t('checkout_purcahse_point')}</h4>
                    <span className={styles['point-deduction-text']}>
                      -${extraPriceObject.pointDeduction.toFixed(2)}
                    </span>
                  </div>
                  <div>
                    <BorderSeparator className={'no-margin-top-and-height-1px'} />
                  </div>
                  <div>
                    <h4>{t('checkout_purcahse_amount')}</h4>
                    <span>${final < 0 ? (0).toFixed(2) : final.toFixed(2)}</span>
                  </div>
                </>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutDesktop;

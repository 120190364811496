import { ProfileImage, UserRegisterForInvitationPayload } from '../../@types/customer';
import {
  RegisterForInviterNameResponse,
  ResponseMkm,
  UserInviteUrlResponse,
} from '../../@types/response-mkm';
import instance from './instance';

export async function getCustomerFromBackend() {
  const response = await instance.get('/users');

  return response.data.data;
}

export async function postProfile(body: FormData) {
  const response = await instance.post<ResponseMkm<ProfileImage>>(`/users/profile`, body);

  return response.data;
}

export async function deleteProfile() {
  const response = await instance.patch<ResponseMkm<null>>(`/users/profile`);

  return response.data;
}

export async function getUsersInviteUrl() {
  const response = await instance.get<UserInviteUrlResponse>('/users/invite');

  return response.data;
}

export async function postRegisterForInvitation(body: UserRegisterForInvitationPayload) {
  const response = await instance.post<ResponseMkm<null>>('/users/register', body);

  return response.data;
}

export async function getRegisterForInviterName(inviteCode: string) {
  const response = await instance.get<RegisterForInviterNameResponse>(
    `/users/recommend?invite_code=${inviteCode}`,
  );

  return response.data;
}

import React, { LegacyRef, ReactElement, ReactNode } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styles from './Slick.module.scss';

export type SlickDataType = {
  elements: ReactNode;
  options?: {
    slickSettings: object;
  };
};

const Slick = ({
  slickData,
  slickRef,
}: {
  slickData: SlickDataType;
  slickRef?: LegacyRef<Slider> | null;
}): ReactElement => {
  const settings = {
    ...slickData.options?.slickSettings,
  };
  return (
    <div className={styles['div']}>
      <Slider {...settings} ref={slickRef}>
        {Array.isArray(slickData.elements)
          ? slickData.elements?.map((element, idx) => (
              <React.Fragment key={`${element}--${idx}`}>{element}</React.Fragment>
            ))
          : slickData.elements}
      </Slider>
    </div>
  );
};

export default Slick;

import { ReactElement, useCallback, useEffect, useState } from 'react';
import { CurationModule } from '../@types/curation';
import { getCuration } from '../api/backend/curation';
import Banner, { BannerImageType } from '../components/banner/Banner';
import MainLayout from '../components/layout/MainLayout';
import Loading from '../components/loading/Loading';
import { ProductContainer } from '../components/products';
import { BANNER_COMMON_SLICK_STYLES, TOP_BANNER_COMMON_ARROW_STYLES } from '../constants/slick';
import { useViewport } from '../helper/hooks';
import styles from './styles/Main.module.scss';

const Main = (): ReactElement => {
  const { isMobile } = useViewport();

  const [curationId, setCurationId] = useState<number>();
  const [topBannerImages, setTopBannerImages] = useState<{
    mobile: BannerImageType;
    desktop: BannerImageType;
  }>();
  const [curationModuleItems, setCurationModuleItems] = useState<CurationModule[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const curationModuleItemsLength = curationModuleItems.length;

  const isMultipleTopBannerImages =
    (topBannerImages?.mobile ?? []).length > 1 || (topBannerImages?.desktop ?? []).length > 1;

  const getCurationMain = useCallback(async () => {
    const res = await getCuration();
    if (res.isOk && res.data.curation) {
      const { id: curationId, topBanners, curationItems } = res.data.curation;
      const topBannerMobileImages = topBanners.map((topBanner) => ({
        id: topBanner.image.id,
        imageUrl: topBanner.image.mobileImage,
        link: topBanner.link,
        content: topBanner.content,
        curationTopBannerType: topBanner.type,
        curationBannerId: topBanner.curationBannerId,
      }));
      const topBannerDesktopImages = topBanners.map((topBanner) => ({
        id: topBanner.image.id,
        imageUrl: topBanner.image.desktopImage,
        link: topBanner.link,
        content: topBanner.content,
        curationTopBannerType: topBanner.type,
        curationBannerId: topBanner.curationBannerId,
      }));
      setTopBannerImages({
        mobile: topBannerMobileImages,
        desktop: topBannerDesktopImages,
      });
      setCurationModuleItems(curationItems);
      setCurationId(curationId);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getCurationMain();
  }, []);

  return (
    <MainLayout>
      {!isLoading ? (
        <main className={styles['main']}>
          <Banner
            images={{
              mobileImages: topBannerImages?.mobile ?? [],
              desktopImages: topBannerImages?.desktop ?? [],
            }}
            type="TOP"
            originId={curationId}
            options={{
              arrowDefaultShow: isMultipleTopBannerImages,
              bothSideBlurShow: isMultipleTopBannerImages,
              arrowStyles: {
                commonArrowStyles: TOP_BANNER_COMMON_ARROW_STYLES,
              },
              slickSettingStyles: {
                ...BANNER_COMMON_SLICK_STYLES,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: isMultipleTopBannerImages,
                centerPadding: '840px',
                responsive: isMultipleTopBannerImages
                  ? [
                      {
                        breakpoint: 767,
                        settings: {
                          centerPadding: '20px',
                        },
                      },
                      {
                        breakpoint: 1023,
                        settings: {
                          centerPadding: '200px',
                        },
                      },
                      {
                        breakpoint: 1025,
                        settings: {
                          centerPadding: '50px',
                        },
                      },
                      {
                        breakpoint: 1280,
                        settings: {
                          centerPadding: '60px',
                        },
                      },
                      {
                        breakpoint: 1441,
                        settings: {
                          centerPadding: '140px',
                        },
                      },
                      {
                        breakpoint: 1720,
                        settings: {
                          centerPadding: '280px',
                        },
                      },
                      {
                        breakpoint: 1920,
                        settings: {
                          centerPadding: '380px',
                        },
                      },
                      {
                        breakpoint: 2320,
                        settings: {
                          centerPadding: '580px',
                        },
                      },
                      {
                        breakpoint: 2562,
                        settings: {
                          centerPadding: '700px',
                        },
                      },
                    ]
                  : [],
              },
            }}
          />

          {curationModuleItems?.map((curationModuleItem, idx) =>
            curationModuleItem.curationItemType === 'PRODUCT' ? (
              <ProductContainer
                key={curationModuleItem.id}
                item={curationModuleItem}
                options={{
                  borderShow:
                    idx < curationModuleItemsLength - 1 &&
                    curationModuleItems[idx + 1].curationItemType === 'PRODUCT',
                  infiniteScrollMode: curationModuleItem.type.text === '2xN',
                  originName: 'main',
                }}
                origin="curation"
                originId={curationModuleItem.curationProductId}
              />
            ) : (
              <Banner
                key={curationModuleItem.id}
                images={{
                  mobileImages: (curationModuleItem.images ?? [])?.map((image) => ({
                    id: image.id,
                    curationBannerId: curationModuleItem.curationBannerId,
                    imageUrl: image.mobileImage,
                    link: image.link,
                    curationItemType: curationModuleItem.curationItemType,
                  })),
                  desktopImages: (curationModuleItem.images ?? [])?.map((image) => ({
                    id: image.id,
                    curationBannerId: curationModuleItem.curationBannerId,
                    imageUrl: image.desktopImage,
                    link: image.link,
                    curationItemType: curationModuleItem.curationItemType,
                  })),
                }}
                type={curationModuleItem.curationItemType}
                options={{
                  arrowDefaultShow: false,
                  bothSideBlurShow: false,
                  slickSettingStyles: {
                    ...BANNER_COMMON_SLICK_STYLES,
                    slidesToShow: (curationModuleItem.images ?? []).length > 1 && !isMobile ? 2 : 1,
                    slidesToScroll:
                      (curationModuleItem.images ?? []).length > 1 && !isMobile ? 2 : 1,
                    responsive:
                      (curationModuleItem.images ?? []).length > 1
                        ? [
                            {
                              breakpoint: 767,
                              settings: {
                                centerMode: true,
                                centerPadding: '12px',
                              },
                            },
                            {
                              breakpoint: 1024,
                              settings: {
                                centerMode: true,
                                centerPadding: '200px',
                              },
                            },
                          ]
                        : [],
                  },
                }}
                originId={curationId}
              />
            ),
          )}
        </main>
      ) : (
      <div className={styles['loading']}>
          <Loading />
        </div>
      )}
    </MainLayout>
  );
};

export default Main;

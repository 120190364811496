import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchKeyword } from '../../../@types/search';
import styles from './KeywordsInSearch.module.scss';

type KeywordsInSearchProps = {
  keywords: SearchKeyword[];
  handleClearHistory: MouseEventHandler<HTMLDivElement>;
  handleRemoveHistory: MouseEventHandler<HTMLDivElement>;
  handleSearchByKeyword: MouseEventHandler<HTMLDivElement>;
  keywordType?: 'POPULAR' | 'HISTORY';
};

const KeywordsInSearch = ({
  keywords,
  handleClearHistory,
  handleRemoveHistory,
  handleSearchByKeyword,
  keywordType = 'POPULAR',
}: KeywordsInSearchProps) => {
  const { t } = useTranslation();
  const isHistory = keywordType === 'HISTORY';

  return (
    <div
      className={`${
        isHistory ? styles['search__history__container'] : styles['search__popular__container']
      }`}
    >
      <div className={styles['search__header']}>
        <h3>{isHistory ? t('search_bar_search_hitory') : t('search_bar_search_popular')} </h3>
        {isHistory && (
          <div className={styles['search__header__delete']} onMouseDown={handleClearHistory}>
            <img src="/icons/search__trash.svg" alt="검색기록 전체 삭제" />{' '}
            {t('search_bar_search_hitory_delete')}
          </div>
        )}
      </div>

      {keywords?.length > 0 ? (
        <div className={styles['search__items']}>
          {keywords.map((keyword) => (
            <div key={keyword.id} className={styles['search__item']}>
              <div onMouseDown={handleSearchByKeyword} data-name={keyword.keyword}>
                {keyword.keyword}
              </div>
              {isHistory && (
                <img
                  src="/icons/search__x.svg"
                  alt="검색기록 삭제"
                  data-name={keyword.keyword}
                  onMouseDown={handleRemoveHistory}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles['search--empty']}>{t('search_bar_search_popular_empty')}</div>
      )}
    </div>
  );
};

export default KeywordsInSearch;

import { debounce } from 'lodash';
import { MouseEvent, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { ProductType } from '../../@types/product';
import { updateCartItem } from '../../api/backend/cart';
import { PRICE_FOR_FREE_SHIPPING } from '../../constants';
import { useViewport } from '../../helper/hooks';
import useCart from '../../hooks/useCart';
import { customerState } from '../../stores/customer/atoms';
import { modalState } from '../../stores/modal/atoms';
import { toastState } from '../../stores/toast/atoms';
import Button from './Button';
import styles from './styles/CartButton.module.scss';

interface ICartButton {
  item: ProductType;
  hasWish?: boolean;
  handleSubmitWish?: () => Promise<void>;
  isSoldout?: boolean;
}

const CartButton = ({ item, hasWish, handleSubmitWish, isSoldout = false }: ICartButton) => {
  const { t } = useTranslation();
  const customer = useRecoilValueLoadable(customerState);
  const cart = useCart();

  const targetItem = cart.getItem(item.id);

  const updateQuantity = useCallback(
    debounce(async (newCount: number) => {
      if (!customer.contents) {
        return;
      }

      await updateCartItem(item.id, newCount);
    }, 1000),
    [customer.contents],
  );

  const [countInCart, setCountInCart] = useState<number>(targetItem?.quantity ?? 0);
  const [open, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useRecoilState(modalState);
  const setToast = useSetRecoilState(toastState);
  const { isMobile } = useViewport();
  const totalPrice = cart.getTotalPriceActive;
  const currentPrice = Number((PRICE_FOR_FREE_SHIPPING - totalPrice).toFixed(2));
  const toastContent = (
    <span>
      {t('cart_toast_a')}
      <b style={{ color: '#fceb4e', padding: '0 4px' }}>${currentPrice}</b>
      {t('cart_toast_b', { price: PRICE_FOR_FREE_SHIPPING })}
    </span>
  );

  const onToastButton = useCallback(() => {
    if (currentPrice > 0 && isMobile) {
      setToast({ content: toastContent });
    } else if (currentPrice <= 0) {
      setToast(null);
    }
  }, [currentPrice]);

  const onClickAddCartButton = async () => {
    onToastButton();

    setOpen(true);

    if (countInCart === 0) {
      setCountInCart(1);

      await cart.add(item.id, 'curation');
    }
  };

  const onCount = {
    MINUS: (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onToastButton();

      const newCount = countInCart - 1;
      setCountInCart(newCount);

      cart.update(item.id, -1);
      updateQuantity(newCount);
    },
    PLUS: (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onToastButton();

      const newCount = countInCart + 1;
      setCountInCart(newCount);

      if (cart.has(item.id)) {
        cart.update(item.id, 1);
        updateQuantity(newCount);
        return;
      }

      cart.add(item.id, 'curation');
    },
  };
  const onClickSoldout: MouseEventHandler = (event) => {
    // todo: 세영님 api response에 아래 modal 넣어주세요
    setModal({
      modal: true,
      childrenText: t('product_notify_modal'),
      buttons: [
        {
          text: t('product_notify_modal_button'),
        },
      ],
    });
  };

  useEffect(() => {
    if (countInCart === 0) {
      cart.remove([item.id]);
      setOpen(false);
    }
  }, [countInCart, item.id]);

  if (isSoldout) {
    return (
      <div className={styles['cart-button-container']}>
        <Button className={styles['cart-button-container--right']} action={onClickSoldout}>
          <div className={styles['cart-button-container--right-inner']}>
            <img
              className={styles['cart-button-bell']}
              src="/icons/shopping-cart__bell.svg"
              alt=""
            />
            <span>{t('product_cart_soldout')}</span>
          </div>
        </Button>
      </div>
    );
  }

  return (
    <div className={styles['cart-button-container']}>
      <Button action={handleSubmitWish} className={`${styles['cart-button-container--left']}`}>
        <img
          src={hasWish ? '/icons/button__bookmark-active.svg' : '/icons/button__bookmark.svg'}
          alt="북마크"
        />
      </Button>

      <Button className={styles['cart-button-container--right']} action={onClickAddCartButton}>
        <div className={styles['cart-button-container--right-inner']}>
          {open && (
            <div onClick={onCount['MINUS']}>
              {countInCart > 1 ? (
                <img
                  className={styles['cart-button__minus']}
                  src={'/icons/shopping-cart__minus.svg'}
                  alt="장바구니 빼기"
                />
              ) : (
                <img
                  className={styles['cart-button__trash']}
                  src={'/icons/shopping-cart__trash.svg'}
                  alt="장바구니 휴지통"
                />
              )}
            </div>
          )}

          {open ? (
            <span>
              {countInCart} {t('product_cart_in')}
            </span>
          ) : (
            <span className={styles['cart-button-cart']}>
              <img src="/icons/shopping-cart_white_border.svg" alt="카트" />
              {t('product_cart_add')}
            </span>
          )}

          {open && (
            <div onClick={onCount['PLUS']}>
              <img src={'/icons/shopping-cart__plus.svg'} alt="장바구니 추가" />
            </div>
          )}
        </div>
      </Button>
    </div>
  );
};

export default CartButton;

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { Outlet } from 'react-router';
import { useRecoilValueLoadable } from 'recoil';
import UserProfile from '../../../components/account/profile/UserProfile';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import { customerState } from '../../../stores/customer/atoms';
import styles from './styles/AccountProfile.module.scss';

const AccountProfile = () => {
  const { pathname } = useLocation();
  const go = useNavigate();
  const { t } = useTranslation();
  const customer = useRecoilValueLoadable(customerState);

  useEffect(() => {
    if (customer.state !== 'hasValue') {
      return;
    }

    if (!customer.contents) {
      go('/');
    }
  }, [customer]);

  return (
    <>
      {pathname === '/account/profile' ? (
        <AccountLayout
          mobileNav={{
            type: MobileNavItem.SHOW_ALL,
            title: t('account_profile_title'),
          }}
          pageTitle={t('account_profile_page_title')}
        >
          <div className={styles['container']}>
            <UserProfile
              userImage={customer.contents.profileImageUrl}
              type="ACCOUNT"
              userNickname={customer.contents?.displayName}
            />
          </div>
        </AccountLayout>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default AccountProfile;

import { ChangeEvent, ChangeEventHandler, ReactElement, useEffect, useState } from 'react';
import { useViewport } from '../../../helper/hooks';
import { INITIAL_IS_OPEN_STATUS_OBJECT } from '../../../pages/checkout/Checkout';
import {
  ClickedCoupon,
  ClickedCouponState,
  CouponDataState,
  OpenHandler,
  SelectedCouponState,
} from '../../../@types/checkout';
import CouponItem from '../../account/coupons/atoms/CouponItem';
import { FixedBottomButtonContainer } from '../../button';
import { Input } from '../../input';
import { FormButton } from '../../login/atoms';
import Radio from '../../radio/Radio';
import styles from './CheckoutOverlays.module.scss';
import { getCoupons } from '../../../api/backend/coupon';
import { Coupon, INITAIL_COUPON } from '../../../@types/coupon';
import { useTranslation } from 'react-i18next';
import { useRecoilValueLoadable } from 'recoil';
import { customerState } from '../../../stores/customer/atoms';

type CouponApplyType = CouponDataState &
  SelectedCouponState &
  ClickedCouponState &
  Partial<OpenHandler>;

const CouponApply = ({
  setCoupon,
  selectedCoupon,
  setSelectedCoupon,
  clickedCouponType,
  setClickedCouponType,
  setIsOpenStatusObject,
}: CouponApplyType): ReactElement => {
  const { width, isMobile } = useViewport();
  const customer = useRecoilValueLoadable(customerState);
  const [inputCoupon, setInputCoupon] = useState<string>('');
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const { t } = useTranslation();

  const onChangeType = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setClickedCouponType(value as ClickedCoupon);
  };
  const onChangeInputCode: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputCoupon(event.target.value);
  };
  const onClickCoupon = (data: Coupon) => {
    if (clickedCouponType === 'Input') {
      return;
    }

    setSelectedCoupon({
      ...data,
    });
  };
  const onClickSave = () => {
    if (setIsOpenStatusObject) {
      setIsOpenStatusObject(INITIAL_IS_OPEN_STATUS_OBJECT);
    }

    setCoupon(selectedCoupon);
  };

  const getCouponsRequest = async () => {
    const res = await getCoupons();

    setCoupons(res.data.data.coupons);
  };

  useEffect(() => {
    if (!inputCoupon) {
      return;
    }

    setSelectedCoupon({
      ...INITAIL_COUPON,
      code: inputCoupon,
    });
  }, [inputCoupon]);

  useEffect(() => {
    if (customer.state !== 'hasValue') {
      return;
    }

    if (!customer.contents) {
      return;
    }

    getCouponsRequest();
  }, [customer.state]);

  return (
    <>
      <main className={styles['apply-coupon-main']}>
        <Radio
          name="Apply Coupon"
          value="Input"
          title={t('checkout_coupon_apply_code')}
          checked={clickedCouponType === 'Input'}
          handleChange={onChangeType}
        />
        <div
          className={`${styles['coupon-code-input']} ${
            styles[clickedCouponType !== 'Input' ? 'dim' : '']
          }`}
        >
          <Input
            name="userId"
            type="text"
            placeholder={t('checkout_coupon_apply_placeholder')}
            defaultValue={inputCoupon}
            onInput={(e) => {
              e.currentTarget.value = e.currentTarget.value.toUpperCase();
            }}
            onChange={onChangeInputCode}
            disabled={clickedCouponType === 'Select'}
          />
        </div>
        {customer.contents && (
          <>
            <div className={styles['separator']}>
              <div></div>
              <span>{t('checkout_coupon_apply_or')}</span>
              <div></div>
            </div>
            <Radio
              name="Apply Coupon"
              value="Select"
              title={t('checkout_coupon_apply_select')}
              checked={clickedCouponType === 'Select'}
              handleChange={onChangeType}
            />
            <section
              className={`${styles['coupon-select-container']} ${
                styles[clickedCouponType !== 'Select' ? 'dim' : '']
              }`}
            >
              {coupons
                .filter((c) => c.status === 'AVAILABLE')
                .map((coupon) => (
                  <div onClick={() => onClickCoupon(coupon)} key={coupon.code}>
                    <CouponItem
                      data={coupon}
                      selected={
                        clickedCouponType === 'Select' && coupon.code === selectedCoupon?.code
                      }
                    />
                  </div>
                ))}
            </section>
          </>
        )}
        {isMobile && (
          <FixedBottomButtonContainer>
            <FormButton contents={t('checkout_coupon_apply_save')} onClick={onClickSave} />
          </FixedBottomButtonContainer>
        )}
      </main>
    </>
  );
};

export default CouponApply;

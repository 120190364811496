import { SearchProps } from '../SearchBar';
import styles from '../SearchBar.module.scss';

const SearchBarIcon = ({ setIsSearching }: SearchProps) => {
  return (
    <div className={styles['search']} onClick={() => setIsSearching(true)}>
      <img className={styles['search__img']} src="/icons/nav_search.svg" alt="검색 이미지" />
    </div>
  );
};

export default SearchBarIcon;

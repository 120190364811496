import { Fragment, ReactElement, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import useCopyClipboard from '../../../hooks/useCopyClipboard';
import styles from './styles/ProductShareModal.module.scss';

type ProductShareModalProps = {
  close: () => void;
  title?: string;
};

const ProductShareModal = ({ title, close }: ProductShareModalProps): ReactElement => {
  const { t } = useTranslation();
  const [, onCopy] = useCopyClipboard();

  // FIXME: Need to get language setting
  // const [selectedLang, setSelectedLang] = useState<string>('English');

  // const langs = ['English', '한국어', '中國語', '日本語', 'Español', 'Tiếng Việt'];

  const links = {
    'Copy link': '/icons/invitations__link.svg',
    Facebook: '/icons/invitations__facebook.svg',
    Email: '/icons/invitations__email.svg',
    Share: '/icons/invitations__share.svg',
  };

  // const handleClickLang: MouseEventHandler<HTMLDivElement> = (event) => {
  //   const { lang } = event.currentTarget.dataset;

  //   setSelectedLang(lang ?? 'English');
  // };

  // const renderLangs = () =>
  //   langs.map((lang) => (
  //     <div
  //       key={lang}
  //       className={`${styles['modal__langs']} ${
  //         selectedLang === lang ? `${styles['modal__langs--selected']}` : ''
  //       }`}
  //       data-lang={lang}
  //       onClick={handleClickLang}
  //     >
  //       {lang}
  //     </div>
  //   ));

  const location = useLocation();
  const sharedLink = window.location.origin + location.pathname;

  const onLink = useCallback(async (text: keyof typeof links) => {
    switch (text) {
      case 'Copy link':
        onCopy(title ? `${title}\n${sharedLink}` : sharedLink);
        break;
      case 'Facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${sharedLink}`);
        break;
      case 'Email':
        if (isMobile) {
          window.location.href = `mailto:?body=${sharedLink}`;
        } else {
          window.open(`mailto:?body=${sharedLink}`);
        }
        break;
      // case 'Get Image':
      //   console.log('get image clicked');
      //   break;
      case 'Share':
        if (window.navigator.share) {
          await window.navigator
            .share({
              title: 'WebShare on Mobile',
              url: sharedLink,
            })
            .then(() => {
              console.log('Thanks for sharing');
            })
            .catch(console.error);
        } else {
          alert('공유하기를 지원하지 않는 환경입니다.');
        }
        break;
    }
  }, []);

  const renderLinks = () =>
    Object.entries(links).map(([text, imageUrl]) =>
      text === 'Share' && (!isMobile || !window.navigator.share || !window.navigator.clipboard) ? (
        <Fragment key="empty"></Fragment>
      ) : (
        <div
          key={imageUrl}
          className={styles['link__container']}
          onClick={() => onLink(text as keyof typeof links)}
        >
          <img className={styles['link__image']} src={imageUrl} alt={text} />
          <div className={styles['link__description']}>{text}</div>
        </div>
      ),
    );

  return (
    <>
      <div className={styles['modal__header']}>
        <h2>Share</h2>
        <img src="/icons/invitations__x.svg" alt="close" onClick={() => close()} />
      </div>
      <div className={styles['modal__description']}>
        <img className={styles['modal__logo']} src="/icons/invitations__logo-main.png" alt="logo" />
        <h3 className={styles['modal__text']}></h3>
        <p className={styles['modal__subtext']}>{t('product_detail_share_modal_description')}</p>
      </div>

      <div className={styles['modal__langs__container']}>{/* {renderLangs()} */}</div>

      <div className={styles['modal__links__container']}>
        <div className={styles['modal__message']}></div>
        <div className={styles['modal__links']}>{renderLinks()}</div>
      </div>
    </>
  );
};

export default ProductShareModal;

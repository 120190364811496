import { ReactNode } from 'react';
import SearchBar from '../../search/SearchBar';
import { Cart, Logo, MultiLanguage, User } from '../atoms';
import styles from './DesktopNavBar.module.scss';
import CategoryMenu from '../../category/CategoryMenu';

export type DesktopNavType = 'ONLY_TITLE' | 'DEFAULT' | 'CATEGORY';

export const DesktopNavBar = (type?: DesktopNavType) => {
  const desktopNav: { [key: string]: ReactNode } = {
    ONLY_TITLE: (
      <div className={styles['nav--only-title']}>
        <div>
          <Logo imgUrl="/images/desktop/logo.svg" customStyle={{ height: '48px' }} />
        </div>
      </div>
    ),
    DEFAULT: (
      <div className={styles['nav_container']}>
        <div className={styles['nav']}>
          <div className={styles['nav__logo']}>
            <Logo imgUrl="/images/desktop/logo.svg" />
          </div>

          <div className={styles['nav__search']}>
            <SearchBar />
          </div>

          <div className={styles['nav__items']}>
            <div>
              <MultiLanguage />
            </div>
            <div>
              <User />
            </div>
            <div>
              <Cart />
            </div>
          </div>
        </div>
      </div>
    ),
    CATEGORY: (
      <div className={styles['category_nav']}>
        <div className={styles['nav']}>
          <div className={styles['nav__logo']}>
            <Logo imgUrl="/images/desktop/logo.svg" />
          </div>

          <div className={styles['nav__search']}>
            <SearchBar />
          </div>

          <div className={styles['nav__items']}>
            <div>
              <MultiLanguage />
            </div>
            <div>
              <User />
            </div>
            <div>
              <Cart />
            </div>
          </div>
        </div>
        <CategoryMenu />
      </div>
    ),
  };

  return type ? desktopNav[type] : desktopNav['DEFAULT'];
};

export default DesktopNavBar;

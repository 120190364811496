import { ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import BorderSeparator from '../border/BorderSeparator';
import styles from './styles/UserInfo.module.scss';

type UserInfoType = {
  link: string;
  mainText: ReactNode;
  status?: 'Unconnected' | 'Unverified';
  subText?: string;
  image?: string;
  count?: number;
  detailInfo?: string;
  hasArrow?: boolean;
  nextArrowImage?: string;
  className?: string;
  isSelected?: boolean;
  options?: {
    borderShow?: boolean;
  };
};

const UserInfo = ({
  link,
  mainText,
  status,
  subText,
  image,
  count,
  detailInfo,
  hasArrow = true,
  nextArrowImage = '/icons/account__chevron-right-grey.svg',
  className = '',
  isSelected = false,
  options = {
    borderShow: false,
  },
}: UserInfoType): ReactElement => {
  const go = useNavigate();
  const handleClick = () => {
    go(link);
  };

  return (
    <>
      <section
        className={`${styles['section']} ${className} ${
          isSelected ? styles['section--current'] : ''
        }`}
        onClick={handleClick}
      >
        <div className={styles['main']}>
          <div className={styles['main__container']}>
            {image && (
              <div className={styles['icon']}>
                <img src={image} alt="이미지" />
              </div>
            )}
            <div className={styles['description']}>
              <div className={styles['description__title']}>{mainText}</div>
              {subText && <div className={styles['description__subtitle']}>{subText}</div>}
            </div>
            {status && <div className={styles['description__status']}>{status}</div>}
          </div>

          <div className={styles['main__control']}>
            <span className={styles['main__detail']}>{detailInfo}</span>
            <span className={styles['main__count']}>{count}</span>
            {hasArrow && <img src={nextArrowImage} alt="오른쪽 화살표" />}
          </div>
        </div>
      </section>
      {options?.borderShow && <BorderSeparator className={'no-margin-top-and-height-1px'} />}
    </>
  );
};
export default UserInfo;

import { ReactElement } from 'react';
import styles from './ToggleSwitch.module.scss';

type ToggleSwitchProps = {
  active: boolean;
  handleToggle: () => void;
};

const ToggleSwitch = ({ active, handleToggle }: ToggleSwitchProps): ReactElement => {
  return (
    <div
      className={`${styles['toggle']} ${active ? styles['toggle-active'] : ''}`}
      onClick={handleToggle}
    >
      <div className={styles['toggle--circle']}></div>
    </div>
  );
};

export default ToggleSwitch;

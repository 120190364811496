import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FormButton } from '../../login/atoms';
import styles from './styles/InvitationsDescription.module.scss';

type InvitationsDescriptionProps = {
  invited: number;
  rewards: number;
  open: () => void;
};

const InvitationsDescription = ({
  invited,
  rewards,
  open,
}: InvitationsDescriptionProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={styles['invitations__description']}>
      <div className={styles['invitations__description__text']}>
        {t('account_invite_description')}
      </div>

      <div className={styles['invitations__button__container']}>
        <FormButton contents={t('account_invite_button')} onClick={() => open()} />
      </div>

      <div className={styles['invitations__meta']}>
        <div className={styles['invitations__info']}>
          <div className={styles['invitations__count']}>{invited}</div>
          <div className={styles['invitations__text']}>{t('account_invite_invited')}</div>
        </div>
        <div className={styles['invitations__info']}>
          <div className={styles['invitations__count']}>${rewards}</div>
          <div className={styles['invitations__text']}>{t('account_invite_earned')}</div>
        </div>
      </div>
    </div>
  );
};

export default InvitationsDescription;

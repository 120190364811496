import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Faq } from '../../../@types/help';
import { getFaqs } from '../../../api/backend/help';
import Accordion from '../../../components/accordion/Accordion';
import { HelpMail } from '../../../components/account/help-center';
import Empty from '../../../components/empty/Empty';
import styles from './styles/HelpCenter.module.scss';
import Loading from '../../../components/loading/Loading';

const HelpFaq = () => {
  const { t } = useTranslation();

  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getFaqsRequest = useCallback(async () => {
    const { isOk, data } = await getFaqs();

    if (!isOk) {
      return;
    }

    setIsLoading(false);
    setFaqs(data.faqs);
  }, []);

  useEffect(() => {
    getFaqsRequest();
  }, []);

  return (
    <div className={styles['container']}>
      <h2 className={styles['title']}>{t('account_help_faq_header')}</h2>
      <div className={styles['layout']}>
        <HelpMail />
      </div>

      <h2 className={styles['title']}>{t('account_help_faq_title')}</h2>
      <div className={styles['faq']}>
        {isLoading ? (
          <Loading />
        ) : faqs.length > 0 ? (
          faqs.map(({ question, answer }) => (
            <Accordion
              key={question}
              title={<h3 className={styles['faq__title']}>{question}</h3>}
              description={<p className={styles['faq__description']}>{answer}</p>}
            />
          ))
        ) : (
          <div className={styles['faq--empty']}>
            <Empty message={t('account_help_faq_empty')} isProduct={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HelpFaq;

import { ReactElement } from 'react';
import { LineItem } from '../../../@types/customer';
import styles from './styles/OrderItemProduct.module.scss';
import { useTranslation } from 'react-i18next';

const OrderItemProduct = ({
  product,
  isCancelled,
}: {
  product: LineItem;
  isCancelled?: boolean;
}): ReactElement => {
  const { variant, title, originalTotalPrice, quantity, currentQuantity } = product;
  const { t } = useTranslation();

  return (
    <div className={styles['product__container']}>
      <div className={styles['product__thumnail']}>
        {variant && <img src={variant.image.originalSrc} alt={variant.image.altText} />}
      </div>
      <div className={styles['product__detail']}>
        <div className={styles['product__title']}>{title}</div>
        <div className={styles['product__price-quantity']}>
          <div className={styles['product__price']}>
            ${Number(variant.priceV2.amount).toFixed(2)}
          </div>
          <div className={styles['product__quantity']}>
            {t('order_detail_item_qty', {
              quantity: isCancelled ? quantity - currentQuantity : currentQuantity,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItemProduct;

import { ReactNode } from 'react';
import { atom } from 'recoil';
import { ModalButtonProps } from '../../components/modal/Modal';

export interface ModalState {
  modal: boolean;
  headerText?: string;
  childrenText?: string;
  children?: ReactNode;
  buttons?: ModalButtonProps[];
  closeButtonAction?: () => void; // close button & click outside 시 동작
}

export const INITIAL_MODAL_OBJECT: ModalState = {
  modal: false,
};

export const modalState = atom<ModalState | null>({
  key: 'modalState',
  default: INITIAL_MODAL_OBJECT,
});

import { Dispatch, MouseEventHandler, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { modalState } from '../../stores/modal/atoms';
import styles from './Modal.module.scss';

export type ModalButtonProps = {
  text: string;
  action?: () => void;
  type?: 'Primary' | 'Secondary';
};

type ModalProps = {
  headerText?: string;
  children?: ReactNode;
  buttons?: ModalButtonProps[];
};

type ReturnedModal = {
  open: () => void;
  close: () => void;
  setData: Dispatch<SetStateAction<ModalProps>>;
  render: () => ReactNode;
  isOpened: boolean;
};

const useModal: () => ReturnedModal = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [data, setData] = useState<ModalProps>({
    children: '',
  });

  const open = () => setIsOpened(true);
  const close = () => setIsOpened(false);

  const render = () => (
    <Modal
      headerText={data.headerText}
      children={data.children}
      buttons={data.buttons}
      setIsOpened={setIsOpened}
    />
  );

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isOpened]);

  return { isOpened, open, close, setData, render };
};

export const Modal = ({
  headerText,
  children,
  buttons,
  setIsOpened,
}: ModalProps & {
  setIsOpened?: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useRecoilState(modalState);
  const onClose = () => {
    setModal({ modal: false });
    modal?.closeButtonAction && modal.closeButtonAction();
  };
  const onClickOutside: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
    }
  };
  return (
    <div className={styles['modal__outter']} onClick={onClickOutside}>
      <div className={styles['modal__inner']}>
        <header className={styles['modal__header']}>
          <span>{modal?.headerText || t('modal_default_header')}</span>
          <img src="/icons/cart__x.svg" alt="닫기 이미지" onClick={onClose} />
        </header>
        {modal?.children ? (
          modal.children
        ) : (
          <div className={styles['modal__description']}>{modal?.childrenText}</div>
        )}
        <div className={styles['modal__button-container']}>
          {!modal?.buttons ? (
            <div
              className={`${styles['modal__button']}`}
              onClick={() => {
                onClose();
              }}
            >
              {t('modal_default_button')}
            </div>
          ) : (
            modal?.buttons?.map((button) => (
              <div
                key={button.text}
                className={`${styles['modal__button']} ${button.type && styles[button.type]}`}
                onClick={() => {
                  button?.action && button?.action!();
                  onClose();
                }}
              >
                {button?.text}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default useModal;

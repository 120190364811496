import { ReactElement, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import InfoAlarm from './components/info-alarm/InfoAlarm';
import { PRICE_FOR_FREE_SHIPPING } from './constants';
import {
  Cart,
  Categories,
  Login,
  Main,
  PasswordAssist,
  PasswordReset,
  ProductDetail,
  Register,
  Search,
  WishList,
} from './pages';
import { AccountPage, Coupons, Invitations, Language, MyPoints } from './pages/account';
import { HelpCenter } from './pages/account/help';
import { Information } from './pages/account/information';

import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { Modal } from './components/modal/Modal';
import BrandProducts from './pages/BrandProducts';
import Promotion from './pages/Promotion';
import { MyOrderDetail, MyOrders } from './pages/account/order';
import { Policy } from './pages/account/policies';
import {
  AccountProfile,
  ChangeName,
  ChangePassword,
  DeliveryInfo,
  DeliveryInfoAdd,
  EditProfile,
} from './pages/account/profile';
import ChangePhone from './pages/account/profile/ChangePhone';
import { ReviewWriteEdit, Reviews } from './pages/account/reviews';
import Notifications from './pages/account/setting/Notifications';
import { Checkout, CheckoutCompleted } from './pages/checkout';
import CartMoreProducts from './pages/module/CartMoreProducts';
import CheckoutCompletedMoreProducts from './pages/module/CheckoutCompletedMoreProducts';
import MainMoreProducts from './pages/module/MainMoreProducts';
import ProductDetailMoreProducts from './pages/module/ProductDetailMoreProducts';
import { modalState } from './stores/modal/atoms';
import { customerState } from './stores/customer/atoms';
import useCart from './hooks/useCart';

function App(): ReactElement {
  const location = useLocation();
  const cart = useCart();

  const [modal, setModal] = useRecoilState(modalState);
  const customer = useRecoilValueLoadable(customerState);
  const infoAlarmNotShowPaths = [
    '/account',
    '/login',
    '/register',
    '/password-assist',
    '/password-reset',
    '/checkout-completed',
  ];

  const isInfoAlarmShow = !infoAlarmNotShowPaths.find((path) => location.pathname.startsWith(path));

  const getCartRequest = async () => {
    await cart.getCartRequest();
  };

  useEffect(() => {
    const element = document.getElementById('app_main');
    element?.scrollIntoView({ block: 'start' });
  }, [location]);

  useEffect(() => {
    if (!customer.contents) {
      return;
    }

    // TODO: Get carts
    getCartRequest();
  }, [customer]);

  return (
    <div id="app_main">
      {isInfoAlarmShow && (
        <InfoAlarm
          text={`Free shipping for all products $${PRICE_FOR_FREE_SHIPPING}`}
          bgColor="#E8EAF0"
        />
      )}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/module">
          <Route path="main/:title" element={<MainMoreProducts />} />
          <Route path="product/:title" element={<ProductDetailMoreProducts />} />
          <Route path="cart/:title" element={<CartMoreProducts />} />
          <Route path="checkout-completed/:title" element={<CheckoutCompletedMoreProducts />} />
        </Route>
        <Route path="/brand/:vendor" element={<BrandProducts />} />
        <Route path="/search" element={<Search />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/promotion/:id" element={<Promotion />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/checkout-completed" element={<CheckoutCompleted />} />
        <Route path="/account" element={<AccountPage />}>
          <Route path="profile" element={<AccountProfile />}>
            <Route path="edit" element={<EditProfile />}>
              <Route path="name" element={<ChangeName />} />
              <Route path="phone" element={<ChangePhone />} />
            </Route>
            <Route path="delivery-info" element={<DeliveryInfo />} />
            <Route path="delivery-info-add" element={<DeliveryInfoAdd />} />
            <Route path="change-password" element={<ChangePassword />} />
          </Route>
          <Route path="my-points" element={<MyPoints />} />
          <Route path="invitations" element={<Invitations />} />
          <Route path="orders" element={<MyOrders />} />
          <Route path="orders/:id" element={<MyOrderDetail />} />
          <Route path="orders/delivery-info/:id" element={<DeliveryInfo />} />
          <Route path="orders/delivery-info-add/:id" element={<DeliveryInfoAdd />} />
          <Route path="wishlist" element={<WishList />} />
          <Route path="coupons" element={<Coupons />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="reviews/write" element={<ReviewWriteEdit />} />
          <Route path="reviews/edit" element={<ReviewWriteEdit />} />
          <Route path="help-center/*" element={<HelpCenter />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="language" element={<Language />} />
          <Route path="information/*" element={<Information />} />
          <Route path="policy/*" element={<Policy />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password-assist" element={<PasswordAssist />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {/* <ScrollToTop /> */}
      {modal?.modal && <Modal />}
    </div>
  );
}

export default App;

import { useTranslation } from 'react-i18next';

import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { CompletedReview, ToReview, ToReviewType, WrittenReviewType } from '../../../@types/review';
import { Button } from '../../button';
import Empty from '../../empty/Empty';
import StarRating from '../../star-rating/StarRating';
import styles from './styles/ReviewContainer.module.scss';
import getLocaleDate from '../../../utils/getDate';

type ReviewContainerType = {
  reviews: ToReview['result'] | CompletedReview['reviews'];
  isWritten: boolean;
};

const WriteReview = ({
  review,
  selectedReview,
  setSelectedReview,
}: {
  review: ToReviewType;
  selectedReview: number;
  setSelectedReview: Dispatch<SetStateAction<number>>;
}): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [rating, setRating] = useState<number>(0);

  const onNavigate = () => {
    navigate('/account/reviews/write', {
      state: {
        type: 'WRITE',
        rating,
        orderId: review.orderId,
        shopifyId: review.product.id,
      },
    });
  };

  const onNavigateProductDetailPage = (productId: string) => {
    navigate(`/product/${encodeURIComponent(productId)}`);
  };

  useEffect(() => {
    if (rating < 1) {
      return;
    }

    setSelectedReview(review.product.mkmId);
  }, [rating]);

  useEffect(() => {
    if (selectedReview === review.product.mkmId) {
      return;
    }

    setRating(0);
  }, [selectedReview]);

  return (
    <article className={styles['reivew__container']}>
      {review.product.image ? (
        <img
          src={review.product.image}
          alt="리뷰 제품 대표 썸네일"
          onClick={() => onNavigateProductDetailPage(review.product.id)}
        />
      ) : (
        <div className={styles['image__empty']}></div>
      )}
      <div className={styles['review__description']}>
        <h3 onClick={() => onNavigateProductDetailPage(review.product.id)}>
          {review.product.title}
        </h3>

        <h4>Delivery Completion Date: {getLocaleDate(review.deliveryCompletedAt)}</h4>

        <div className={styles['review__rating']}>
          <StarRating
            maxStars={5}
            rating={rating}
            setRating={setRating}
            defaultImage={'/icons/account__big-star-grey.svg'}
            activeImage={'/icons/account__big-star-yellow.svg'}
          />
          {rating >= 1 && (
            <Button className={styles['review__button']} action={onNavigate}>
              <span>{t('account_review_write_button')}</span>
            </Button>
          )}
        </div>
        <small>
          {t('account_review_write_deadline')}
          {getLocaleDate(review.deadlineDate)}
        </small>
      </div>
    </article>
  );
};

const WrittenReview = ({ review }: { review: WrittenReviewType }): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  console.log(review);
  const onNavigate = () => {
    navigate('/account/reviews/edit', {
      state: { type: 'EDIT', review },
    });
  };

  const onClickDetail = () => {
    navigate(`/product/${encodeURIComponent(review.product.id)}`);
  };

  return (
    <article className={styles['reivew__container']}>
      {review.product.image ? (
        <img src={review.product.image} alt="제품 대표 썸네일" onClick={onClickDetail} />
      ) : (
        <div className={styles['image__empty']}></div>
      )}
      <div className={styles['review__description--written']}>
        <div className={styles['review__description__metadata']}>
          <h3 onClick={onClickDetail}>{review.product.title}</h3>
          {/* TODO */}
          <h4>Delivery Completion Date: {getLocaleDate(review.order.completedAt)}</h4>
          <div>
            <img src="/icons/star-rating.svg" alt="별점" />
            <span>{review.rating}</span>
            <time>{new Date(review.createdAt).toLocaleDateString()}</time>
          </div>
        </div>

        <div className={styles['reivew__description__content']}>
          <p>{review.content}</p>
          <div className={styles['review__description__edit']}>
            {review.images[0]?.url ? (
              <img src={review.images[0]?.url} alt="작성된 리뷰 대표 썸네일" />
            ) : (
              <div></div>
            )}
            <Button className={styles['review__button--edit']} action={onNavigate}>
              <span>{t('account_review_written_button')}</span>
            </Button>
          </div>
        </div>
      </div>
    </article>
  );
};

const ReviewContainer = ({ reviews, isWritten }: ReviewContainerType): ReactElement => {
  const { t } = useTranslation();

  const [selectedReview, setSelectedReview] = useState<number>(-1);

  return (
    <section className={styles['section']}>
      <header className={styles['header']}>
        {!isWritten && reviews.length > 0 && (
          <>
            <h2>{t('account_review_write')}</h2>
          </>
        )}
      </header>

      {reviews.length > 0 ? (
        reviews.map((review, idx) =>
          isWritten ? (
            <WrittenReview key={idx} review={review as WrittenReviewType} />
          ) : (
            <WriteReview
              key={idx}
              review={review as ToReviewType}
              selectedReview={selectedReview}
              setSelectedReview={setSelectedReview}
            />
          ),
        )
      ) : (
        <div className={styles['container--empty']}>
          <Empty
            message={
              isWritten
                ? t('account_review_empty_edit_message')
                : t('account_review_empty_write_message')
            }
            isProduct={false}
            className="empty_review"
          />
        </div>
      )}
    </section>
  );
};

export default ReviewContainer;

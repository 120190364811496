import { CountryCode } from 'libphonenumber-js';
import { ChangeEvent, Dispatch, FormEvent, MouseEvent, ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CHATBOT_HASH } from '../../../constants/chatbot';
import countryCodes from '../../../constants/country-code';
import Label from '../../label/Label';
import { FormButton, Input } from '../../login/atoms';
import Select from '../../select/Select';
import { RegisterUserType } from '../RegisterContainer';
import styles from './RegisterForm.module.scss';

type RegisterFormProps = {
  user: RegisterUserType;
  countryCode: string;
  setCountryCode: Dispatch<SetStateAction<string | CountryCode>>;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: FormEvent) => void;
};

const RegisterForm = ({
  user,
  countryCode,
  setCountryCode,
  handleChange,
  handleSubmit,
}: RegisterFormProps): ReactElement => {
  const { t } = useTranslation();
  const selectedCountry = countryCodes.find(({ code }) => code === countryCode);

  const onClickCustomerService = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    window.location.hash = CHATBOT_HASH;
  };

  return (
    <form className={styles['register-form']} onSubmit={handleSubmit}>
      <Label label={t('register_form_email')}>
        <Input
          name="userId"
          type="email"
          placeholder={t('register_form_email_placeholder')}
          defaultValue={user.userId}
          onChange={handleChange}
        />
      </Label>

      <Label label={t('register_form_first_name')}>
        <Input
          name="firstName"
          type="text"
          placeholder={t('register_form_first_name_placeholder')}
          pattern="[a-zA-Z]*"
          value={user.firstName}
          onChange={handleChange}
        />
      </Label>

      <Label label={t('register_form_last_name')}>
        <Input
          name="lastName"
          type="text"
          placeholder={t('register_form_last_name_placeholder')}
          pattern="[a-zA-Z]*"
          value={user.lastName}
          onChange={handleChange}
        />
      </Label>

      <Label label={t('register_form_phone')}>
        <></>
      </Label>
      <Select
        placeholder={`${selectedCountry?.name} ${selectedCountry?.dial_code}`}
        value={`${selectedCountry?.name} ${selectedCountry?.dial_code}`}
        setValue={setCountryCode}
        containerStyle={{ marginBottom: '8px' }}
      >
        {countryCodes.map((code) => (
          <Select.Option
            key={code.code}
            label={`${code.name} ${code.dial_code}`}
            value={code.code}
            isSelected={countryCode === code.code}
          ></Select.Option>
        ))}
      </Select>
      <Input
        name="phone"
        type="number"
        pattern="[0-9]*"
        placeholder={t('register_form_phone_placeholder')}
        value={user.phone}
        onChange={handleChange}
      />

      <Label label={t('register_form_password')}>
        <Input
          name="password"
          type="password"
          placeholder={t('register_form_password_placeholder')}
          defaultValue={user.password}
          onChange={handleChange}
        />
      </Label>

      <Label label={t('register_form_password_confirm')}>
        <Input
          name="passwordConfirm"
          type="password"
          placeholder={t('register_form_password_confirm_placeholder')}
          defaultValue={user.passwordConfirm}
          onChange={handleChange}
        />
      </Label>

      <FormButton contents={t('register_form_button')} />

      <p className={styles['register-description']}>{t('register_descrition')}</p>
      <p className={styles['register-link']}>
        <img src="/icons/login-polygon2.svg" alt="need help" />
        <Link to="" onClick={onClickCustomerService}>
          {t('register_link_help')}
        </Link>
      </p>
      <p className={styles['register-link']}>
        <img src="/icons/login-polygon2.svg" alt="return store" />
        <Link to="/">{t('register_link_return')}</Link>
      </p>
    </form>
  );
};

export default RegisterForm;

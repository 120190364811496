import { ReactElement, useState } from 'react';
import { INFO_ALARM_EXPIRED_TIME } from '../../constants/cookie';
import { getCookieByKey, setCookie } from '../../utils/cookie';
import styles from './InfoAlarm.module.scss';

type InfoAlarmType = {
  text: string;
  bgColor: string;
};

const InfoAlarm = ({ text, bgColor }: InfoAlarmType): ReactElement => {
  const [isShow, setIsShow] = useState<boolean>(getCookieByKey('info-alarm') !== 'false');

  const onClickClose = () => {
    setIsShow(false);
    setCookie('info-alarm', 'false', INFO_ALARM_EXPIRED_TIME);
  };

  return (
    <>
      {isShow && (
        <div style={{ backgroundColor: bgColor }} className={styles['info-alarm--container']}>
          <span className={styles['info-alarm--container__text']}>{text}</span>
          <img
            className={styles['info-alarm--container__close']}
            src={'/icons/info-alarm-x.svg'}
            alt="공지 닫기"
            onClick={onClickClose}
          />
        </div>
      )}
    </>
  );
};

export default InfoAlarm;

import { ReactElement, useCallback, useState } from 'react';
import { useClickOutside } from '../../../helper/hooks';
import styles from './styles/MultiLanguage.module.scss';

const multiLanguagesMap = {
  en: {
    src: '/images/common/lang-en.svg',
    alt: '영어',
  },
  ko: {
    src: '/images/common/lang-ko.svg',
    alt: '한국어',
  },
  default: {
    src: '/images/common/lang-en.svg',
    alt: '영어',
  },
} as const;

const MultiLanguage = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedLang, setSelectedLang] = useState<string>(
    () => localStorage.getItem('language') ?? window.navigator.language.slice(0, 2),
  );

  const onPopup = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const onClickLanguage = useCallback((lang: 'ko' | 'en') => {
    setSelectedLang(lang);
    localStorage.setItem('language', lang);
    window.location.reload();
  }, []);

  const currentLanguage =
    multiLanguagesMap[selectedLang as keyof typeof multiLanguagesMap] ??
    multiLanguagesMap['default'];

  const ref = useClickOutside(() => setOpen(false));

  return (
    <div className={styles['div']} ref={ref} onClick={onPopup}>
      <img src={currentLanguage.src} alt={currentLanguage.alt} />
      {open && (
        <>
          <div className={styles['triangle']} />
          <ul className={styles['popup']}>
            <li onClick={() => onClickLanguage('en')}>
              <img src={multiLanguagesMap['en'].src} alt={multiLanguagesMap['en'].alt} />
              <span>영어</span>
              {selectedLang === 'en' && (
                <img
                  className={styles['select-checked']}
                  src="/icons/help__check.svg"
                  alt="체크 이미지"
                />
              )}
            </li>
            <div className={styles['separator']} />
            <li onClick={() => onClickLanguage('ko')}>
              <img src={multiLanguagesMap['ko'].src} alt={multiLanguagesMap['ko'].alt} />
              <span>한국어</span>
              {selectedLang === 'ko' && (
                <img
                  className={styles['select-checked']}
                  src="/icons/help__check.svg"
                  alt="체크 이미지"
                />
              )}
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default MultiLanguage;

import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import styles from './StarRating.module.scss';

interface StarRatingProps {
  maxStars: number;
  rating: number;
  setRating: Dispatch<SetStateAction<number>>;
  defaultImage?: string;
  activeImage?: string;
}

const Star = ({
  index,
  defaultImage,
  activeImage,
  rating,
  setRating,
}: {
  index: number;
  defaultImage: string;
  activeImage: string;
  rating?: number;
  setRating?: Dispatch<SetStateAction<number>>;
}): ReactElement => {
  const onClickStar = () => {
    if (setRating) {
      setRating(index);
    }
  };

  return (
    <div className={styles['star']} onClick={onClickStar}>
      {rating && index <= rating ? (
        <img src={activeImage} alt="리뷰 별점 노란색" />
      ) : (
        <img src={defaultImage} alt="리뷰 별점 회색" />
      )}
    </div>
  );
};

const StarRating = ({
  maxStars,
  rating,
  setRating,
  defaultImage,
  activeImage,
}: StarRatingProps): ReactElement => {
  const stars = Array.from({ length: maxStars }, (_, i) => (
    <Star
      index={i + 1}
      defaultImage={defaultImage ?? '/icons/review__star-grey.svg'}
      activeImage={activeImage ?? '/icons/review__star-yellow.svg'}
    />
  ));
  return (
    <div className={styles['star-rating-container']}>
      {stars.map((_, idx) => (
        <React.Fragment key={`star--${idx}`}>
          <Star
            index={idx + 1}
            rating={rating}
            setRating={setRating}
            defaultImage={defaultImage ?? '/icons/review__star-grey.svg'}
            activeImage={activeImage ?? '/icons/review__star-yellow.svg'}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default StarRating;

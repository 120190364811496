import { atom, AtomEffect } from 'recoil';
import { CartItem } from '../../@types/cart';

const localStorageCartEffect: (key: string) => AtomEffect<CartItem[]> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset: boolean) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const cartState = atom<CartItem[]>({
  key: 'CartItemsState',
  default: [],
  effects: [localStorageCartEffect('cart-items')],
});

import { ReactElement } from 'react';
import styles from './BorderSeparator.module.scss';

const BorderSeparator = ({ className }: { className?: string }): ReactElement => {
  return (
    <div className={`${styles['border-separator']} ${styles[className ? className : '']}`}></div>
  );
};

export default BorderSeparator;

import React, { CSSProperties, ReactElement, useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import { Settings } from 'react-slick';
import { CurationModule, CurationTopBannerType } from '../../@types/curation';
import { postStatisticsBanner } from '../../api/backend/statistics';
import { BANNER_COMMON_BG_STYLES } from '../../constants/slick';
import { useResponsiveElement } from '../../helper/hooks';
import { NextArrow, PrevArrow } from '../arrow/Arrow';
import Slick from '../slick/Slick';
import styles from './Banner.module.scss';

export type BannerImageType = Array<{
  id?: number;
  imageUrl: string;
  link?: string;
  content?: string;
  curationTopBannerType?: CurationTopBannerType;
  curationItemType?: CurationModule['curationItemType'];
  curationBannerId?: number;
}>;

type BannerType = {
  images: {
    mobileImages: BannerImageType;
    desktopImages: BannerImageType;
  };
  type: 'TOP' | 'BANNER_FIXING' | 'BANNER_ROLLING';
  options?: {
    arrowDefaultShow: boolean;
    bothSideBlurShow: boolean;
    arrowStyles?: {
      commonArrowStyles: CSSProperties;
    };
    slickSettingStyles?: Settings;
  };
  originId?: number;
};

const Banner = ({ images, type, options, originId }: BannerType): ReactElement => {
  const location = useLocation();

  const [dragging, setDragging] = useState<boolean>(false);

  const isTopBannerImage = images.desktopImages.find(
    (desktopImage) => desktopImage.curationTopBannerType,
  );
  const statisticsBanner = useCallback(async () => {
    const curationBannerId = images.mobileImages[0].curationBannerId;
    const bannerBody = {
      curationId: originId,
      bannerId: curationBannerId,
    };

    if (bannerBody.curationId && bannerBody.bannerId) {
      await postStatisticsBanner(bannerBody);
    }
  }, []);
  const onNavigateBanner = (image: BannerImageType[number]) => {
    if (location.pathname === '/' && (image.link || image.content)) {
      statisticsBanner();
    }

    if (image.link) {
      window.location.href = image.link;
    } else if (image.content) {
      window.location.href = image.content;
    }
  };
  const makeBannerImages = (images: Array<BannerImageType[number]>) =>
    images?.map((image, idx) => (
      <article key={`${image.toString()}--${idx}`} className={styles['banner-container']}>
        {image.curationTopBannerType ? (
          <div
            style={{
              ...BANNER_COMMON_BG_STYLES,
              backgroundImage: `url(${image.imageUrl})`,
              cursor: image.link || image.content ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (dragging) {
                return;
              }
              onNavigateBanner(image);
            }}
          >
            {image.curationTopBannerType && images.length > 1 && (
              <div className={styles['banner-numbering']}>
                <span>
                  {idx + 1}/{images.length}
                </span>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              ...BANNER_COMMON_BG_STYLES,
              backgroundImage: `url(${image.imageUrl})`,
              cursor: image.link || image.content ? 'pointer' : 'default',
              borderRadius: type === 'BANNER_FIXING' ? 0 : '8px',
              marginTop: '32px',
            }}
            onClick={() => {
              if (dragging) {
                return;
              }
              onNavigateBanner(image);
            }}
          ></div>
        )}
      </article>
    ));

  const element = useResponsiveElement({
    MOBILE: makeBannerImages(images.mobileImages),
    DESKTOP: makeBannerImages(images.desktopImages),
  });

  const slickSettings = {
    ...options?.slickSettingStyles,
    prevArrow: options?.arrowDefaultShow && (
      <PrevArrow
        customStyles={{
          ...options.arrowStyles?.commonArrowStyles,
        }}
        customImage={isTopBannerImage && '/icons/slick__chevron-left--white.svg'}
      />
    ),
    nextArrow: options?.arrowDefaultShow && (
      <NextArrow
        customStyles={{
          ...options.arrowStyles?.commonArrowStyles,
        }}
        customImage={isTopBannerImage && '/icons/slick__chevron-right--white.svg'}
      />
    ),
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
  };

  return (
    <section className={`${styles['section']} ${styles[`banner-${type}-styles`]}`}>
      {options?.bothSideBlurShow && <div className={styles['stick-blur']}></div>}
      <Slick
        slickData={{
          elements: element,
          options: { slickSettings },
        }}
      />
      {options?.bothSideBlurShow && <div className={styles['stick-blur']}></div>}
    </section>
  );
};

export default React.memo(Banner);

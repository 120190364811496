import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Order } from '../../@types/customer';
import { getOrders } from '../../api/backend/order';
import styles from './styles/ShipmentStatus.module.scss';

type ShipmentStatusType = {
  imageUrl: string;
  status: string;
  count: number;
};

const ShipmentStatus = (): ReactElement => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<Order[]>([]);

  const getOrdersRequest = async () => {
    const { orders } = await getOrders();
    setOrders(orders);
  };

  useEffect(() => {
    getOrdersRequest();
  }, []);

  const pendingOrdersCount = !!orders
    ? orders.filter((order) => order.status === 'PENDING' || order.status === 'READY').length
    : 0;

  const shippingOrdersCount = !!orders
    ? orders.filter((order) => order.status === 'SHIPPING').length
    : 0;

  const shippedOrdersCount = !!orders
    ? orders.filter((order) => order.status === 'SHIPPED').length
    : 0;

  const cancelledOrdersCount = !!orders
    ? orders.filter((order) => order.status === 'CANCELLED').length
    : 0;

  const shipmentData: ShipmentStatusType[] = [
    {
      imageUrl: '/icons/account-order01.svg',
      status: t('account_user_info_my_orders_pending'),
      count: pendingOrdersCount,
    },
    {
      imageUrl: '/icons/account-order02.svg',
      status: t('account_user_info_my_orders_shipping'),
      count: shippingOrdersCount,
    },
    {
      imageUrl: '/icons/account-order03.svg',
      status: t('account_user_info_my_orders_shipped'),
      count: shippedOrdersCount,
    },
    {
      imageUrl: '/icons/account-order04.svg',
      status: t('account_user_info_my_orders_return'),
      count: cancelledOrdersCount,
    },
  ];

  return (
    <section className={styles['section']}>
      {shipmentData.map((data, idx) => (
        <Link key={`shipmentData--${idx}`} to="/account/orders">
          <div>
            <img src={data.imageUrl} alt="마이페이지 주문" />
          </div>
          <div>
            <span>{data.status}</span>
            <span>{data.count}</span>
          </div>
        </Link>
      ))}
    </section>
  );
};
export default ShipmentStatus;

import { ReactElement, ReactNode } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { useResponsiveElement } from '../../helper/hooks';
import { customerState } from '../../stores/customer/atoms';
import Aside from '../account/aside/Aside';
import Footer from '../footer/Footer';
import { BaseNavBar } from '../navbar';
import { DesktopNavType } from '../navbar/desktop/DesktopNavBar';
import { CustomActions, MobileNavType } from './BaseLayout';
import styles from './styles/AccountLayout.module.scss';

interface IBaseLayout {
  children: ReactNode;
  mobileNav: MobileNavType;
  pageTitle?: string;
  className?: string;
  desktopNav?: DesktopNavType;
  customActions?: CustomActions;
  hideFooter?: boolean;
}

const AccountLayout = ({
  children,
  className,
  mobileNav,
  desktopNav,
  pageTitle = '',
  hideFooter = false,
}: IBaseLayout): ReactElement => {
  const customer = useRecoilValueLoadable(customerState);

  const element = useResponsiveElement({
    MOBILE: children,
    DESKTOP: (
      <div className={`${styles['container--desktop']} ${styles[className ? className : '']}`}>
        <Aside customer={customer.contents} />
        <div className={styles['container__content']}>
          <h2 className={styles['title']}>{pageTitle}</h2>
          {children}
        </div>
      </div>
    ),
  });

  return (
    <div className={`${styles['div']} ${styles[className ? className : '']}`}>
      <BaseNavBar mobileNav={mobileNav} desktopNav={desktopNav} />
      {element}
      {!hideFooter && <Footer />}
    </div>
  );
};

export default AccountLayout;

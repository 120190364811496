import { ChangeEventHandler, Dispatch, SetStateAction, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../button';
import Slick from '../slick/Slick';
import styles from './Upload.module.scss';

const Upload = ({
  files,
  setFiles,
  isImage = true,
}: {
  files: Array<File>;
  setFiles: Dispatch<SetStateAction<File[]>>;
  isImage?: boolean;
}) => {
  const { t } = useTranslation();
  const imageUploadRef = useRef<HTMLInputElement | null>(null);
  const onConnectRef = () => {
    imageUploadRef.current?.click();
  };
  const onChangeImageUpload: ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { files: currentFiles },
  }) => {
    if (!currentFiles) {
      return;
    }

    const files: File[] = [];
    for (let i = 0; i < currentFiles.length; i++) {
      const file = currentFiles.item(i);

      if (!file) {
        return;
      }

      files.push(file);
    }

    setFiles((prev) => [...prev, ...files]);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    imageUploadRef.current!.value = '';
  };

  const deletePreviewFile = (file: File) => {
    setFiles((prev) => prev && prev.filter((curr) => curr !== file));
  };

  const slickSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.2,
    slidesToScroll: 4,
    swipe: true,
    useTransform: false, // prevent last slide blinking
    responsive: [],
  };

  if (isImage) {
    return (
      <section className={styles['section']}>
        <input
          ref={imageUploadRef}
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          onChange={onChangeImageUpload}
          multiple
        />
        <Button className={styles['attach-pictures-button']} action={onConnectRef}>
          <div>
            <img src="/icons/account__camera.svg" alt="카메라" />
            <span>{t('account_review_edit_pictures')}</span>
          </div>
        </Button>
        <div>
          {files && files?.length > 0 ? (
            <Slick
              slickData={{
                elements: files?.map((image, idx) => (
                  <div key={`${image}--${idx}`} className={styles['thumbnail']}>
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        backgroundImage: `url(${URL.createObjectURL(image)})`,
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                    ></div>
                    <img
                      onClick={() => deletePreviewFile(image)}
                      src={'/icons/account__x.svg'}
                      alt="썸네일 삭제"
                    />
                  </div>
                )),
                options: { slickSettings },
              }}
            />
          ) : (
            <div className={styles['image-thumbnail']}>
              <img src="/images/mobile/account-thumbnail-default.png" alt="썸네일 표시" />
              <img
                className={styles['image-plus']}
                src="/icons/image-plus.svg"
                alt="이미지 추가"
                onClick={onConnectRef}
              />
            </div>
          )}
        </div>
      </section>
    );
  }

  return (
    <section className={styles['section']}>
      <input ref={imageUploadRef} type="file" onChange={onChangeImageUpload} multiple />
      <Button className={styles['attach-pictures-button']} action={onConnectRef}>
        <div>
          <img src={'/icons/account__clip.svg'} alt="파일 첨부" />
          <span>{t('account_review_edit_files')}</span>
        </div>
      </Button>

      <div>
        {files.map((file, idx) => (
          <div className={styles['attach__filename']} key={`${file.name}-${idx}`}>
            <div>{file.name}</div>
            <img src="/icons/account__x__2.svg" alt="" onClick={() => deletePreviewFile(file)} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Upload;

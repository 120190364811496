import styles from './styles/Notification.module.scss';

const Notification = () => {
  return (
    <div className={styles['div']}>
      <img src="/icons/account-nav__bell.svg" alt="알림" />
    </div>
  );
};

export default Notification;

import { CompletedReviewResponse, ResponseMkm, ToReviewResponse } from '../../@types/response-mkm';
import { WrittenReviewType } from '../../@types/review';
import instance from './instance';

export async function getCompletedReviews(page: number) {
  const response = await instance.get<CompletedReviewResponse>('/review', {
    params: { page },
  });

  return response.data;
}

export async function getToReviews(page: number) {
  const response = await instance.get<ToReviewResponse>('/review/not', {
    params: {
      page,
    },
  });

  return response.data;
}

export async function getReviewById(reviewId: number) {
  const response = await instance.get<WrittenReviewType>(`/review/${reviewId}`);

  return response.data;
}

export async function postReview(body: FormData) {
  const response = await instance.post<ResponseMkm<null>>('/review', body);

  return response.data;
}

export async function updateReview(body: FormData, reviewId?: number) {
  if (!reviewId) {
    return;
  }

  const response = await instance.patch<ResponseMkm<null>>(`/review/${reviewId}`, body);

  return response.data;
}

export const CURATION_MODULE_PRODUCT_MAIN_CATEGORY_TYPE = {
  foods: 'Foods',
  beauty: 'Beauty',
  'health-supplements': 'Health Supplements',
  baby: 'Baby',
  home: 'Home',
  kitchen: 'Kitchen',
  'stationary-office': 'Stationary & Office',
  toys: 'Toys',
  books: 'Books',
  'sports-outdoors': 'Sports/Outdoors',
  automotive: 'Automotive',
  fashion: 'Fashion',
  pets: 'Pets',
} as const;

export const CURATION_MODULE_PRODUCT_SUB_CATEGORY_TYPE: {
  [k in keyof typeof CURATION_MODULE_PRODUCT_MAIN_CATEGORY_TYPE]: {
    [p in string]: string;
  };
} = {
  foods: {
    default: 'All',
    'nuts-dried-fruits': 'Nuts/Dried Fruits',
    'rice-mixed-grains': 'Rice/Mixed Grains',
    'dried-seafood': 'Dried Seafood',
    'coffee-tea': 'Coffee/Tea',
    'snacks-cereals': 'Snacks/Cereals',
    'candy-jelly': 'Candy/Jelly',
    ramen: 'Ramen',
    'noodles-can-processed-food': 'Noodles/Can/Processed Food',
    'sauce-oil-seasoning': 'Sauce/Oil/Seasoning',
    'baby-kids-food': 'Baby & Kids Food',
  },
  beauty: {
    default: 'All',
    'skin-care': 'Skin Care',
    'shave-hair-removal': 'Shave/Hair Removal',
    'cleansing-peeling': 'Cleansing/Peeling',
    makeup: 'Makeup',
    fragrances: 'Fragrances',
    'mens-care': 'Mens Care',
    'hair-care-body-care': 'Hair Care/Body Care',
    'foot-hand-nail-care': 'Foot, Hand & Nail Care',
    'tools-accessories': 'Tools & Accessories',
    'kids-beauty': 'Kids Beauty',
  },
  'health-supplements': {
    default: 'All',
    'vitamins-minerals': 'Vitamins/Minerals',
    supplements: 'Supplements',
    'ginseng-red-ginseng': 'Ginseng/Red Ginseng',
    'concentrated-juice-drinks': 'Concentrated Juice/Drinks',
    'honey-propolis': 'Honey/Propolis',
    'powder-pills': 'Powder/Pills',
    'weight-management': 'Weight Management',
    'medical-supplies-equipments': 'Health Equipments',
  },
  baby: {
    default: 'All',
    'baby-fashion': 'Baby Fashion',
    nursing: 'Nursing',
    'baby-carrier-outdoor': 'Baby Carrier/Outdoor',
    'baby-bedding': 'Baby Bedding',
    'baby-safety': 'Baby Safety',
    'baby-hair-care-body-care': 'Baby Hair Care/Body Care',
    'pregnancy-maternity': 'Pregnancy/Maternity',
    'baby-tableware': 'Baby Tableware',
  },
  home: {
    default: 'All',
    laundry: 'Laundry',
    'household-cleaning-air-fresheners': 'Household Cleaning/Air Fresheners',
    'bathroom-supplies': 'Bathroom Supplies',
    'home-storage-organization': 'Home Storage/Organization',
    'household-supplies': 'Household Supplies',
  },
  kitchen: {
    default: 'All',
    cookware: 'Cookware',
    'cutlery-knife-accessories': 'Cutlery/Knife Accessories',
    'utensils-gadgets': 'Utensils/Gadgets',
    dinnerware: 'Dinnerware',
    flatware: 'Flatware',
    'glassware-drinkware': 'Glassware/Drinkware',
    'kitchen-storage-organization': 'Kitchen Storage/Organization',
    'food-containers': 'Food Containers',
    'kitchen-accessories': 'Kitchen Accessories',
    'thermocoolers-thermoses': 'Thermocoolers/Thermoses',
    'baking-supplies': 'Baking Supplies',
    'korean-traditional-supplies': 'Korean Traditional Supplies',
  },
  'stationary-office': {
    default: 'All',
    'office-supplies': 'Office Supplies',
    'arts-crafts': 'Arts & Crafts',
    'character-stationery': 'Character Stationery',
    'party-decorations': 'Party Decorations',
    'gift-wrapping': 'Gift Wrapping',
    'cards-letters-envelopes': 'Cards/Letters/Envelopes',
    albums: 'Albums',
  },
  toys: {
    default: 'All',
    'character-toys': 'Character Toys',
    'baby-toddler-toys': 'Baby & Toddler Toys',
    'robots-electronic-toys': 'Robots/Electronic Toys',
    'sports-outdoor-play': 'Sports / Outdoor Play',
    'learning-education': 'Learning/Education',
    'games-puzzles': 'Games/Puzzles',
    'remote-control-play': 'Remote Control & Play',
    'action-figures-statues': 'Action Figures/Statues',
    'musical-instraments-equipments': 'Musical Instraments/Equipments',
  },
  books: {
    default: 'All',
    'childrens-books': "Children's Books",
    'novel-essay-poet': 'Novel/Essay/Poet',
    'cookbooks-food-wine': 'Cookbooks/Food&Wine',
    'health-fitness-hobby': 'Health/Fitness/Hobby',
    'comics-graphic-novels': 'Comics/Graphic Novels',
    'politics-social-sciences': 'Politics/Social Sciences',
    history: 'History',
    humanities: 'Humanities',
    'computers-technology': 'Computers/Technology',
    'cd-lp': 'CD/LP',
  },
  'sports-outdoors': {
    default: 'All',
    camping: 'Camping',
    'exercise-fitness': 'Exercise/Fitness',
    'water-sports': 'Water Sports',
    golf: 'Golf',
    bikes: 'Bikes',
    fishing: 'Fishing',
    'sports-goods-accessories': 'Sports Goods & Accessories',
    'racquet-sports': 'Racquet Sports',
    'boxing-martial-arts': 'Boxing/Martial Arts',
  },
  automotive: {
    default: 'All',
    'interior-accessories': 'Interior Accessories',
    'exterior-accessories': 'Exterior Accessories',
    'car-care-cleaning-supplies-fluids': 'Car Care/Cleaning Supplies/Fluids',
    'tools-equipments-safety': 'Tools/Equipments/Safety',
  },
  fashion: {
    default: 'All',
    'womens-fashion': "Women's Fashion",
    'mens-fashion': "Men's Fashion",
  },
  pets: {
    default: 'All',
    'dog-treats': 'Dog Treats',
    'dog-supplies': 'Dog Supplies',
    'cat-treats': 'Cat Treats',
    'cat-supplies': 'Cat Supplies',
  },
};

import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CurationModule, CurationProductType } from '../@types/curation';
import { getCuration } from '../api/backend/curation';

export const useMainMoreProducts = () => {
  const go = useNavigate();
  const { title } = useParams();

  const [curationModuleItem, setCurationModuleItem] = useState<CurationModule>();

  const getCurationModuleItem = useCallback(async () => {
    const res = await getCuration();
    if (res.isOk && res.data.curation) {
      const { curationItems } = res.data.curation;
      const targetCurationModuleItem = curationItems.find(
        (curationItem) =>
          curationItem.curationItemType === 'PRODUCT' && curationItem.title === title,
      );
      if (!targetCurationModuleItem) {
        go('/');
      }
      setCurationModuleItem(targetCurationModuleItem);
      return;
    }
    go('/');
  }, []);

  const preprocessedModuleItem = {
    ...curationModuleItem,
    id: curationModuleItem?.id ?? -1,
    curationItemType: curationModuleItem?.curationItemType ?? 'PRODUCT',
    amount: 0,
    type: {
      text: '2xN' as CurationProductType,
      row: Number.MAX_SAFE_INTEGER,
      column: 2,
    },
  };

  useEffect(() => {
    getCurationModuleItem();
  }, []);

  return { preprocessedModuleItem, title };
};

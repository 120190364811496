import { AnimatePresence, motion, usePresence } from 'framer-motion';
import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useRecoilState } from 'recoil';
import { toastState } from '../../stores/toast/atoms';
import styles from './Toast.module.scss';

const Toast = (): ReactElement => {
  const { pathname } = useLocation();
  const [toast, setToast] = useRecoilState(toastState);

  const [isPresent, safeToRemove] = usePresence();

  const bottomButtonExistForPaths = ['/cart', '/product', '/checkout-completed', '/account/wishlist'];

  const isBottomButtonExist = bottomButtonExistForPaths.find((path) => pathname.startsWith(path));

  useEffect(() => {
    if (!toast) {
      return;
    }
    const timeoutId = setTimeout(() => setToast(null), 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [toast]);

  useEffect(() => {
    !isPresent && setTimeout(safeToRemove, 3000);
  }, [isPresent]);

  return (
    <div
      className={styles['toast-container']}
      style={{
        bottom: isBottomButtonExist ? '80px' : '32px',
      }}
    >
      <AnimatePresence mode="wait">
        {toast && (
          <motion.button
            className={styles['toast-button']}
            key={toast.content?.toString()}
            onClick={() => setToast(null)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div>{toast.content}</div>
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Toast;

export type Coupon = {
  id: number;
  amount: number;
  code: string;
  status: string;
  expiredAt: string;
  type: string;
  standardPrice: number;
  origin: string;
};

export type Coupons = {
  coupons: Coupon[];
};

export const INITAIL_COUPON: Coupon = {
  id: -1,
  code: '',
  amount: 0,
  standardPrice: 0,
  status: '',
  type: '',
  expiredAt: new Date().toDateString(),
  origin: '',
};

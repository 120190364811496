import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PRICE_FOR_FREE_SHIPPING } from '../../../constants';
import useCart from '../../../hooks/useCart';
import { GuideInfo } from '../atoms';
import styles from './CheckoutTemplates.module.scss';

const TGuideInfo = (): ReactElement => {
  const { t } = useTranslation();
  const cart = useCart();
  const totalPrice = cart.getTotalPriceActive;
  if (PRICE_FOR_FREE_SHIPPING - totalPrice > 0) {
    return (
      <GuideInfo
        textElement={
          <span className={styles['checkout-guide-info-custom']}>
            {t('checkout_guide_a')}
            <b>${Number(PRICE_FOR_FREE_SHIPPING - totalPrice).toFixed(2)} </b>
            {t('checkout_guide_b')}
          </span>
        }
        bgColor={'#FFE2E0'}
        link={{ text: t('checkout_guide_more'), path: '/', color: '#DA3730' }}
      />
    );
  } else
    return (
      <GuideInfo
        textElement={
          <span className={styles['checkout-guide-info-custom']}>
            <img src={`/icons/guide_info_check.svg`} />
            {t('checkout_guide_free')}
          </span>
        }
        bgColor={'#EBF6E5'}
      />
    );
};

export default TGuideInfo;

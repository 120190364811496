import { ChangeEvent, FormEvent, useState } from 'react';
import Label from '../label/Label';
import { FormButton, Input } from '../login/atoms';
import styles from './PasswordContainer.module.scss';

const PasswordResetContainer = () => {
  const [passwords, setPasswords] = useState({
    password: '',
    passwordConfirm: '',
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setPasswords((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    // TODO: Request change password
  };

  return (
    <div className={styles['password-container']}>
      <h2 className={styles['password-title']}>Reset your password</h2>
      <p className={styles['password-subdescription']}>Please enter the password to reset.</p>
      <form onSubmit={handleSubmit}>
        <Label label="New Password">
          <Input
            name="password"
            type="password"
            placeholder="At least 6 characters"
            defaultValue={passwords.password}
            onChange={handleChange}
          />
        </Label>

        <Label label="Re-enter New Password">
          <Input
            name="passwordConfirm"
            type="password"
            placeholder="At least 6 characters"
            defaultValue={passwords.passwordConfirm}
            onChange={handleChange}
          />
        </Label>
        <FormButton contents="RESET PASSWORD" />
      </form>
    </div>
  );
};

export default PasswordResetContainer;

import { ReactElement } from 'react';
import { BaseLayout } from '../../components/layout';
import { MobileNavItem } from '../../components/layout/BaseLayout';
import { ProductContainer } from '../../components/products';
import { useModularizationMoreProducts } from '../../hooks/useModularizationMoreProducts';

const CheckoutCompletedMoreProducts = (): ReactElement => {
  const { preprocessedModuleItem, title } = useModularizationMoreProducts('checkoutCompleted');

  return (
    <BaseLayout desktopNav="CATEGORY" mobileNav={{ type: MobileNavItem.TITLE_CART, title }}>
      <>
        <ProductContainer
          key={preprocessedModuleItem.id}
          item={preprocessedModuleItem}
          options={{
            borderShow: false,
            infiniteScrollMode: true,
            headerType: 'ONLY_TITLE',
          }}
        />
      </>
    </BaseLayout>
  );
};

export default CheckoutCompletedMoreProducts;

import React, { TouchEvent, useEffect, useState } from 'react';
import { useClickOutside } from '../../helper/hooks';
import styles from './Drawer.module.scss';

export interface IDrawer {
  open: () => void;
  close: () => void;
  render: (children: React.ReactNode) => React.ReactNode;
  isOpened: boolean;
}

type DrawerProps = {
  setIsOpened: (value: boolean) => void;
  children?: React.ReactNode;
};

export const useDrawer: () => IDrawer = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const open = () => {
    setIsOpened(true);
  };

  const close = () => {
    setIsOpened(false);
  };

  const render = (children: React.ReactNode) => (
    <Drawer setIsOpened={setIsOpened}>{children}</Drawer>
  );

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflowY = 'hidden';
    }

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isOpened]);

  return {
    open,
    close,
    render,
    isOpened,
  };
};

export const Drawer = ({ setIsOpened, children }: DrawerProps) => {
  const bgRef = useClickOutside(() => setIsOpened(false));
  const onTouchOutside = (event: TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };
  return (
    <div className={styles['drawer__outside']} onTouchStart={onTouchOutside}>
      <div className={styles['drawer__inside']} ref={bgRef}>
        {children}
      </div>
    </div>
  );
};

import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { MailingAddress } from '../../../../@types/customer';
import {
  customerAddressDelete,
  customerDefaultAddressUpdate,
} from '../../../../api/storefront/addresses';
import { INITIAL_IS_OPEN_STATUS_OBJECT } from '../../../../pages/checkout/Checkout';
import { OpenHandler } from '../../../../@types/checkout';
import { Button } from '../../../button';
import { FormButton } from '../../../login/atoms';
import Radio from '../../../radio/Radio';
import { AddressType } from '../delivery-info-add/AddressBookAdd';
import styles from './AddressBookList.module.scss';
import { useTranslation } from 'react-i18next';
import Empty from '../../../empty/Empty';
import { putChangeOrderAddress } from '../../../../api/backend/order';
import { modalState } from '../../../../stores/modal/atoms';
import { useRecoilState } from 'recoil';

const AddressBookList = ({
  shippingInfoArray,
  selectedShippingInfo,
  setSelectedShippingInfo,
  openAddressForm,
  type,
  setIsOpenStatusObject,
  onBack,
}: {
  shippingInfoArray: MailingAddress[];
  selectedShippingInfo?: MailingAddress;
  setSelectedShippingInfo: Dispatch<SetStateAction<MailingAddress | undefined>>;
  openAddressForm?: (type: AddressType, id?: string) => void;
  type?: 'Checkout Desktop' | 'Checkout Mobile';
  onBack?: () => void;
} & Partial<OpenHandler>) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentShippingInfo, setCurrentShippingInfo] = useState<MailingAddress | undefined>(
    selectedShippingInfo,
  );
  const { id } = useParams();

  const [modal, setModal] = useRecoilState(modalState);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const targetAddressData = shippingInfoArray.find((address) => address.id === value);

    if (targetAddressData) {
      setCurrentShippingInfo(targetAddressData);
    }

    if (type === 'Checkout Desktop' && targetAddressData) {
      setSelectedShippingInfo(targetAddressData);
    }
  };

  const onClickSelectButton = () => {
    if (setIsOpenStatusObject) {
      setIsOpenStatusObject(INITIAL_IS_OPEN_STATUS_OBJECT);
    }

    setSelectedShippingInfo(currentShippingInfo);
    if (currentShippingInfo?.id) customerDefaultAddressUpdate(currentShippingInfo?.id!);
    if (location.pathname.includes('/orders/')) {
      putChangeOrderAddress(id!, currentShippingInfo!).then((res) => {
        if (res.isOk) {
          setModal({
            modal: true,
            childrenText: t('account_orders_modal_address'),
            buttons: [
              {
                text: t('modal_default_button'),
                action: () =>
                  (window.location.href = `/account/orders/${encodeURIComponent(id!)}`),
              },
            ],
            closeButtonAction: () =>
              (window.location.href = `/account/orders/${encodeURIComponent(id!)}`),
          });
          return;
        }
        setModal({
          modal: true,
          childrenText: t('account_orders_modal_address_error'),
          buttons: [
            {
              text: t('modal_default_button'),
            },
          ],
        });
      });
      return;
    }
    if (location.pathname !== '/checkout') {
      window.location.href = '/account/profile/edit';
    }
  };

  const onClickUpdateAddress = (addressId?: string) => {
    if (!addressId) {
      return;
    }

    if (location.pathname.includes('/orders/')) {
      console.log('id');
      navigate(`/account/orders/delivery-info-add/${encodeURIComponent(id!)}`, {
        state: {
          address: shippingInfoArray.find((a) => a.id === addressId),
        },
      });
      return;
    }

    if (openAddressForm) {
      openAddressForm('EDIT', addressId);
      return;
    }

    navigate('/account/profile/delivery-info-add', {
      state: {
        address: shippingInfoArray.find((a) => a.id === addressId),
      },
    });
  };

  const onClickDeleteAddress = (id?: string) => {
    if (!id) {
      return;
    }

    // TODO: Fix confirm style and message
    // if (!confirm('Are you sure?')) {
    //   return;
    // }

    customerAddressDelete(id);
    location.reload();
  };

  const onClickAddNewAddress = () => {
    if (location.pathname.includes('/orders/')) {
      window.location.href = `/account/orders/delivery-info-add/${encodeURIComponent(id!)}`;
      return;
    }
    if (type !== 'Checkout Desktop' && !openAddressForm) {
      navigate('/account/profile/delivery-info-add');
      return;
    }

    if (openAddressForm) {
      openAddressForm('ADD');
    }
  };

  const onClickCancel = () => {
    if (location.pathname.includes('/orders/')) {
      window.location.href = `/account/orders/${encodeURIComponent(id!)}`;
      return;
    }
    location.href = '/account/profile/edit';
  };

  const renderAddress = () => {
    if (!shippingInfoArray.length) {
      return (
        <div className={styles['address__empty-outer-container']}>
          <Empty
            message={t('account_profile_delivery_address_empty')}
            buttonMessage="account_profile_delivery_address_empty_button"
            onClickEmpty={onClickAddNewAddress}
          />
        </div>
      );
    }
    return (
      <div className={styles['main']}>
        <h3 className={styles['address__title']}>{t('account_profile_delivery_address_title')}</h3>
        <div className={styles['address__outer-container']}>
          {shippingInfoArray.map((address) => (
            <div key={address.id} className={styles['address__container']}>
              <Radio
                name={address.id}
                checked={currentShippingInfo?.id === address.id}
                title={`${address.name}`}
                subtitle={`${address.zip} ${address.address1} ${
                  address.address2 ? address.address2 : ''
                } ${address.city}, ${address.country}`}
                handleChange={handleChange}
                option={{ direction: 'column' }}
              />

              <div className={styles['address__container__button']}>
                <Button>
                  <img
                    src="/icons/account__delivery-info-edit.svg"
                    alt="편집"
                    onClick={() => onClickUpdateAddress(address.id)}
                  />
                </Button>

                <Button>
                  <img
                    src="/icons/account__delivery-info-delete.svg"
                    alt="삭제"
                    onClick={() => {
                      onClickDeleteAddress(address.id);
                    }}
                  />
                </Button>
              </div>
            </div>
          ))}
          <Button action={onClickAddNewAddress} className={styles['address__button']}>
            <img src="/icons/account__delivery-info-add.svg" alt="편집" />
            <span>{t('account_profile_delivery_address_add')}</span>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles['container']}>
      {renderAddress()}
      <div className={styles['form__button']}>
        <Button className={styles['secondary']} action={onBack ? onBack : onClickCancel}>
          {t('account_profile_delivery_add_cancel')}
        </Button>
        <FormButton
          disabled={!shippingInfoArray.length}
          contents={t('account_profile_delivery_address_button')}
          onClick={onClickSelectButton}
        />
      </div>
      {/* {type === 'Checkout Mobile' ? (
        <div className={styles['form__button']}>
          <Button className={styles['secondary']} action={onBack}>
            {t('account_profile_delivery_add_cancel')}
          </Button>
          <FormButton
            disabled={!shippingInfoArray.length}
            contents={t('account_profile_delivery_address_button')}
            onClick={onClickSelectButton}
          />
        </div>
      ) : (
        type !== 'Checkout Desktop' && (
          <div className={styles['button']}>
            <FormButton
              disabled={!shippingInfoArray.length}
              contents={t('account_profile_delivery_address_button')}
              onClick={onClickSelectButton}
            />
          </div>
        )
      )} */}
    </div>
  );
};

export default AddressBookList;

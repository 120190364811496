import { PaginationParams } from '../../@types/common';
import { MailingAddress, Order } from '../../@types/customer';
import { OrderStatusResponse, OrdersResponse, ResponseMkm } from '../../@types/response-mkm';
import instance from './instance';

export async function getOrders(
  params?: PaginationParams & {
    type: 'pending' | 'shipping' | 'shipped' | 'cancelled' | null;
  },
) {
  const res = await instance.get<OrdersResponse>('/order', {
    params: { page: params?.page, per_page: params?.perPage, type: params?.type },
  });

  return res.data.data;
}

export async function postOrders(orders: Order[]) {
  await instance.post('/order', JSON.stringify({ orders }));
}

export async function cancelOrder(orderId: string, orderNumber: number) {
  await instance.patch(`/order/${encodeURIComponent(orderId)}`, JSON.stringify({ orderNumber }));
}

export async function getOrderStatus(orderId: string) {
  const res = await instance.get<OrderStatusResponse>(`/order/${orderId}`);

  return res.data.data;
}
export async function putChangeOrderAddress(orderId: string, address: MailingAddress) {
  const id = orderId.split('?')[0].split('/')[4];
  const res = await instance.put<ResponseMkm<MailingAddress>>(
    `/order/${encodeURIComponent(id)}/address`,
    JSON.stringify(address),
  );

  return res.data;
}

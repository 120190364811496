import { WrittenReviewType } from '../../../@types/review';
import Slick from '../../slick/Slick';
import styles from './styles/ProductReviewItems.module.scss';

const ProductReviewItem = ({ review }: { review: WrittenReviewType }) => {
  console.log(review);
  return (
    <article className={styles['article']}>
      <div>
        <div>
          {/* TODO: Get user profile image */}
          {/* <img src={review.images[0].url} alt="리뷰 프로필 이미지" /> */}
          <span>{`${review.user?.firstName} ${review.user?.lastName}`}</span>
        </div>
        <p>{review.content}</p>
        <div>
          <img src={'/icons/review__star-yellow.svg'} alt="리뷰 별점 노란색" />
          <span>{review.rating}</span>
          <small>{new Date(review.createdAt).toLocaleDateString()}</small>
        </div>
      </div>
      {review.images.length > 0 && (
        <div>
          <img src={review.images[0].url} alt="리뷰 이미지" />
        </div>
      )}
    </article>
  );
};

const ProductReviewItems = ({ reviews }: { reviews: WrittenReviewType[] }) => {
  const elements = reviews.map((review) => <ProductReviewItem key={review.id} review={review} />);

  const slickSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: 1.07,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipe: true,
    useTransform: false, // prevent last slide blinking
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4.6,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1248,
        settings: {
          slidesToShow: 4.2,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 3.8,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1048,
        settings: {
          slidesToShow: 3.6,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3.4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 948,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 670,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={styles['div']}>
      <Slick slickData={{ elements: elements, options: { slickSettings } }} />
    </div>
  );
};

export default ProductReviewItems;

import { ButtonHTMLAttributes, ReactElement } from 'react';
import styles from './styles/FormButton.module.scss';

interface FormButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  contents: string;
}

const FormButton = ({ contents, disabled, onClick, ...props }: FormButtonProps): ReactElement => {
  return (
    <button
      disabled={disabled}
      type="submit"
      className={`${styles['form-button']} ${styles[disabled ? 'disabled' : '']}`}
      onClick={onClick}
      {...props}
    >
      {contents}
    </button>
  );
};

export default FormButton;

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { customerUpdate } from '../../../api/storefront/customer';
import { Input } from '../../../components/input';
import Label from '../../../components/label/Label';
import { AccountLayout } from '../../../components/layout';
import { MobileNavItem } from '../../../components/layout/BaseLayout';
import { FormButton } from '../../../components/login/atoms';
import styles from './styles/ChangePassword.module.scss';
import { logout } from '../../../utils/logout';
import { modalState } from '../../../stores/modal/atoms';
import { useRecoilState } from 'recoil';

const ChangePassword = () => {
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const [isMatch, setIsMatch] = useState<boolean>(false);
  const go = useNavigate();
  const { t } = useTranslation();
  const [modal, setModal] = useRecoilState(modalState);

  const onSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsDisabledButton(true);

    if (!isMatch) {
      setModal({
        modal: true,
        childrenText: t('account_profile_change_password_modal_error'),
      });
      setIsDisabledButton(false);
      return;
    }

    const response = await customerUpdate({ password: inputs.newPassword });

    localStorage.setItem(
      'access-token',
      response.data?.customerUpdate.customerAccessToken.accessToken || '',
    );

    setModal({
      modal: true,
      childrenText: t('account_profile_change_password_modal'),
      buttons: [
        {
          text: t('account_profile_change_password_modal_button'),
          action: () => {
            logout();
            location.href = '/login';
          },
        },
      ],
      closeButtonAction: () => {
        logout();
        location.href = '/login';
      },
    });
  };

  const [inputs, setInputs] = useState({
    newPassword: '',
    newPasswordConfirm: '',
  });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setInputs({ ...inputs, [name]: value });
  };

  useEffect(() => {
    const { newPassword, newPasswordConfirm } = inputs;
    setIsDisabledButton(newPassword.length < 6);
    setIsMatch(newPassword === newPasswordConfirm);
  }, [inputs]);

  return (
    <AccountLayout
      mobileNav={{
        type: MobileNavItem.HOME_SEARCH,
        title: t('account_profile_change_password_title'),
      }}
      pageTitle={t('account_profile_change_password_page_title')}
    >
      <form className={styles['form']} onSubmit={onSave}>
        <div className={styles['form__container']}>
          <Label label={t('account_profile_change_password_new')}>
            <Input
              name="newPassword"
              type="password"
              placeholder={t('account_profile_change_password_new_placeholder')}
              onChange={onChange}
            />
          </Label>

          <Label label={t('account_profile_change_password_re')}>
            <Input
              name="newPasswordConfirm"
              type="password"
              placeholder={t('account_profile_change_password_re_placeholder')}
              onChange={onChange}
            />
          </Label>
        </div>
        <div className={styles['form__button__container']}>
          <FormButton
            contents={t('account_profile_change_password_button')}
            disabled={isDisabledButton}
          />
        </div>
      </form>
    </AccountLayout>
  );
};

export default ChangePassword;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useRecoilValueLoadable } from 'recoil';
import { WrittenReviewType } from '../../../@types/review';
import { customerState } from '../../../stores/customer/atoms';
import { Button } from '../../button';
import StarRating from '../../star-rating/StarRating';
import ProductReviewItems from './ProductReviewItems';
import styles from './styles/ProductReviews.module.scss';

type ProductReviewsType = {
  reviews: WrittenReviewType[];
  title: string;
  hasPurchaseHistory: boolean;
  productId: string;
  options?: {
    borderShow?: boolean;
  };
};

const ProductReviews = ({ reviews, title, hasPurchaseHistory, productId }: ProductReviewsType) => {
  const { t } = useTranslation();
  const go = useNavigate();

  const customer = useRecoilValueLoadable(customerState);
  const [rating, setRating] = useState<number>(0);

  const handleClick = () => {
    if (!customer.contents) {
      go('/login');
      return;
    }

    if (hasPurchaseHistory) {
      // TODO: Check already written
      go('/account/reviews/write', {
        state: { type: 'WRITE', rating, title, shopifyId: productId },
      });
      return;
    }

    go('/account/reviews');
  };

  if (!reviews || reviews.length === 0) {
    return (
      <section className={styles['section']}>
        <div className={styles['review-top-container']}>
          <div>
            <span>{t('product_review')}</span>
            <small>({0})</small>
          </div>
          <div>
            <div className={styles['review-top-container--right-star-grey']}>
              <img src={'/icons/review__star-grey-fill.svg'} alt="리뷰 별점 회색 fill" />
              <span>0.0</span>
            </div>
          </div>
        </div>
        <div className={styles['review-contents-container']}>
          <div className={styles['first-write-review-container']}>
            <StarRating maxStars={5} rating={rating} setRating={setRating} />
            <Button action={handleClick} className={styles['review-write-button']}>
              <span className={styles['review-first-button--text']}>
                {t('product_review_write')}
              </span>
            </Button>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className={styles['section']}>
      <div className={styles['review-top-container']}>
        <div>
          <span>Reviews</span>
          <small>({reviews.length})</small>
          <div className={styles['review-star-rating--average']}>
            <img src={'/icons/review__star-yellow.svg'} alt="별점 노란색" />
            <span>
              {Number(
                reviews
                  .map((review) => parseInt(review.rating))
                  .reduce((acc, cur) => acc + cur, 0) / reviews.length,
              ).toFixed(1)}
            </span>
          </div>
        </div>
        <div>
          {hasPurchaseHistory && (
            <Button action={handleClick}>
              <img src={'/icons/review__edit.svg'} alt="리뷰 편집" />
              <span className={styles['review-write__text']}>WRITE A REVIEW</span>
              <img src={'/icons/review__chevron-right.svg'} alt="리뷰 오른쪽 화살표" />
            </Button>
          )}
        </div>
      </div>
      <div className={styles['review-contents-container']}>
        <ProductReviewItems reviews={reviews} />
      </div>
    </section>
  );
};

export default ProductReviews;
